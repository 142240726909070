import React, { Fragment } from "react";
import { Grid, Header, Image, Button } from "semantic-ui-react";
import DropzoneInput from "./DropzoneInput";

export const UploadPhotoMenu = ({
  setLoadedImageFile,
  onUploadImageButtonClick,
  droppedFiles,
  isUploading,
  hideUploadButton,
  preview,
  noPreview,
  upload,
  dropImage,
}) => {
  return (
    <Fragment>
      <Grid.Row columns={hideUploadButton ? 2 : 3}>
        <Grid.Column style={{ display: "flex" }}>
          <DropzoneInput
            setLoadedImageFile={setLoadedImageFile}
            isUpLoading={isUploading}
            dropImage={dropImage}
          />
        </Grid.Column>
        <Grid.Column verticalAlign="middle">
          {droppedFiles.length > 0 ? (
            <Fragment>
              <Header as="h3">{preview}</Header>
              <Image
                src={droppedFiles[0].preview}
                centered
                size="medium"
                style={{ borderRadius: "50%", height: "160px", width: "160px" }}
              />
            </Fragment>
          ) : (
            <Header as="h4">{noPreview}</Header>
          )}
        </Grid.Column>
        {hideUploadButton ? null : (
          <Grid.Column verticalAlign="middle">
            <Button
              color="green"
              disabled={droppedFiles.length === 0 || isUploading}
              onClick={onUploadImageButtonClick}
              loading={isUploading}
            >
              {upload}
            </Button>
          </Grid.Column>
        )}
      </Grid.Row>
    </Fragment>
  );
};
