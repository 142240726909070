import {
  LOAD_USER_DATA,
  UPDATE_USER_LANGUAGE,
  UPDATE_LESSON_PROGRESS,
  UPDATE_QUIZ_PROGRESS,
  UPDATE_EXAM_PROGRESS,
  UPDATE_LESSON_ACHIEVEMENT_PROGRESS,
  UPDATE_QUIZ_ACHIEVEMENT_PROGRESS,
  PROGRESS_RESET_START,
  PROGRESS_RESET_SUCCESS,
  PROGRESS_RESET_FAILURE,
  UPDATE_USER_DATA,
  UPDATE_LANGUAGE,
} from "./authConstant";
import { loadTranslationData } from "../translation/translationActions";
import { operationTypeEnum } from "../OperationTypeConstant";
import {
  operationStarted,
  operationCompleted,
  operationFailed,
} from "../operation/operationActions";
import { loadStatisticsData } from "../statistics/statisticsActions";
import { loadAchievementsData } from "../achievement/achievementActions";
import { loadAllCourse } from "../course/courseActions";
import { loadAllLanguageInfo } from "../general/generalActions";
import _ from "lodash";

export const storeEcodeForUse = (ecode) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirebase().firestore();

    try {
      let ecodeQuerySnap = await db
        .collection("ecodes")
        .where("code", "==", ecode)
        .where("status", "==", "Available")
        .get();

      if (ecodeQuerySnap.docs.length !== 0) {
        console.log("Found ecode doc");
        let ecodeDoc = ecodeQuerySnap.docs[0].data();
        ecodeDoc.docId = ecodeQuerySnap.docs[0].id;
        return ecodeDoc;
      } else {
        console.log("Ecode is invalid");
        return { code: "Error", message: "E-Code does not exist." };
      }
    } catch (err) {
      console.log(err);
      return err;
    }
  };
};

export const updateLanguage = (language) => {
  return async (dispatch, getState, { getFirebase }) => {
    try {
      //console.log(language)
      dispatch(operationStarted(operationTypeEnum.UPDATE_USER_LANGUAGE));

      dispatch({
        type: UPDATE_LANGUAGE,
        payload: {
          language,
        },
      });
      dispatch(operationCompleted());
    } catch (err) {
      console.log(err);
      dispatch(operationFailed());
      throw err;
    }
  };
};

export const signUpWithEmail = (signUpObj, ecodeData) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const db = getFirebase().firestore();

    try {
      await firebase
        .auth()
        .createUserWithEmailAndPassword(signUpObj.email, signUpObj.password);

      //update profile
      await firebase.auth().currentUser.updateProfile({
        //displayName: `${signUpObj.surname},${signUpObj.firstname}`
        displayName: signUpObj.firstname,
      });
      let currentUser = firebase.auth().currentUser;

      let userData = {
        uid: currentUser.uid,
        email: signUpObj.email,
        name: signUpObj.name,
        dob: signUpObj.dob,
        gender: signUpObj.gender ?? "",
        imagePath: "",
      };
      await createNewUserWithEcode(userData, ecodeData, db);
      return null;
    } catch (err) {
      console.log(err);
      return err;
    }
  };
};

export const signInWithEmail = (creds, ecodeData) => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const db = firebase.firestore();

    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(creds.email, creds.password);

      let currentUser = firebase.auth().currentUser;

      let userDoc = await firebase
        .firestore()
        .doc(`users/${currentUser.uid}`)
        .get();
      if (userDoc.exists) {
        // console.log(userDoc.data());
        if (userDoc.data().admin) {
          return null;
        } else {
          let expiryDate = new Date(
            userDoc.data().subscription.endDate.seconds * 1000
          );
          if (expiryDate < new Date().getTime()) {
            if (!_.isEmpty(ecodeData)) {
              let userData = { uid: currentUser.uid, email: currentUser.email };
              await updateUserWithNewEcode(userData, ecodeData, db);
            } else {
              return {
                code: "Expired E-Code",
                message:
                  "Your account's E-Code has expired. Please use a new E-Code.",
              };
            }
          } else {
            if (!_.isEmpty(ecodeData)) {
              return {
                code: "Note",
                message:
                  "New E-Code was NOT used because your account has an existing E-Code that hasn't expired yet.",
              };
            } else {
              return null;
            }
          }
        }
      } else {
        await currentUser.delete();
        return {
          code: "No Account is found",
          message: "Please register with an E-Code.",
        };
      }
      return null;
    } catch (err) {
      if (err.code === "auth/user-not-found") {
        return {
          code: "No Account is found",
          message: "Please register with an E-Code.",
        };
      } 
      else if (err.code === "auth/wrong-password") {
        return {
          code: "Wrong password",
          message: "The password is invalid.",
        };
      }else return err;
    }
  };
};

export const signInWithGoogle = (ecodeData) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const db = firebase.firestore();

    console.log("SIGN IN WITH GOOGLE");
    try {
      //pop-up
      let provider = new firebase.auth.GoogleAuthProvider();
      // provider.addScope("https://www.googleapis.com/auth/user.birthday.readonly");
      // provider.addScope("https://www.googleapis.com/auth/user.gender.readonly");
      let result = await firebase.auth().signInWithPopup(provider);
      let isNewUser = result.additionalUserInfo.isNewUser;
      console.log(result);
      if (isNewUser) {
        //let imagePath = `users/${result.user.uid}`;
        if (!_.isEmpty(ecodeData)) {
          let userData = {
            uid: result.user.uid,
            email: result.user.email,
            name: result.additionalUserInfo.profile.given_name,
            imagePath: "",
          };
          //uploadProfilePicture(result.additionalUserInfo.profile.picture, result.user.uid, firebase);
          await createNewUserWithEcode(userData, ecodeData, db);
          return null;
        } else {
          return {
            code: "No Account is found",
            message: "Please register with an E-Code.",
          };
        }
      } else {
        let userDoc = await firebase
          .firestore()
          .doc(`users/${result.user.uid}`)
          .get();
        if (userDoc.exists) {
          let expiryDate = new Date(
            userDoc.data().subscription.endDate.seconds * 1000
          );
          if (expiryDate < new Date().getTime()) {
            if (!_.isEmpty(ecodeData)) {
              let userData = { uid: result.user.uid, email: result.user.email };
              await updateUserWithNewEcode(userData, ecodeData, db);
            } else {
              return {
                code: "Expired E-Code",
                message:
                  "Your account's E-Code has expired. Please use a new E-Code.",
              };
            }
          } else {
            if (!_.isEmpty(ecodeData)) {
              return {
                code: "Note",
                message:
                  "New E-Code was NOT used because your account has an existing E-Code that hasn't expired yet.",
              };
            } else {
              return null;
            }
          }
        } else {
          if (!_.isEmpty(ecodeData)) {
            let userData = {
              uid: result.user.uid,
              email: result.user.email,
              name: result.additionalUserInfo.profile.given_name,
              imagePath: "",
            };
            await createNewUserWithEcode(userData, ecodeData, db);
            return null;
          } else {
            return {
              code: "No Account is found",
              message: "Please register with an E-Code.",
            };
          }
        }
      }
    } catch (err) {
      console.log(err);
      return {
        code: "Error",
        message: "Please contact admin.",
      };
    }
  };
};

export const signInWithApple = (ecodeData) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const db = firebase.firestore();

    console.log("SIGN IN WITH APPLE");
    try {
      //pop-up
      let provider = new firebase.auth.OAuthProvider("apple.com");
      let result = await firebase.auth().signInWithPopup(provider);
      console.log(result.user);
      let isNewUser = result.additionalUserInfo.isNewUser;

      if (isNewUser) {
        if (!_.isEmpty(ecodeData)) {
          let userData = {
            uid: result.user.uid,
            email: result.user.email,
            name: result.additionalUserInfo.profile.given_name,
          };
          await createNewUserWithEcode(userData, ecodeData, db);
          return null;
        } else {
          return {
            code: "No Account is found",
            message: "Please register with an E-Code.",
          };
        }
      } else {
        let userDoc = await firebase
          .firestore()
          .doc(`users/${result.user.uid}`)
          .get();
        if (userDoc.exists) {
          let expiryDate = new Date(
            userDoc.data().subscription.endDate.seconds * 1000
          );
          if (expiryDate < new Date().getTime()) {
            if (!_.isEmpty(ecodeData)) {
              let userData = { uid: result.user.uid, email: result.user.email };
              await updateUserWithNewEcode(userData, ecodeData, db);
            } else {
              return {
                code: "Expired E-Code",
                message:
                  "Your account's E-Code has expired. Please use a new E-Code.",
              };
            }
          } else {
            if (!_.isEmpty(ecodeData)) {
              return {
                code: "Note",
                message:
                  "New E-Code was NOT used because your account has an existing E-Code that hasn't expired yet.",
              };
            } else {
              return null;
            }
          }
        } else {
          if (!_.isEmpty(ecodeData)) {
            let userData = {
              uid: result.user.uid,
              email: result.user.email,
              name: result.additionalUserInfo.profile.given_name,
            };
            await createNewUserWithEcode(userData, ecodeData, db);
            return null;
          } else {
            return {
              code: "No Account is found",
              message: "Please register with an E-Code.",
            };
          }
        }
      }
    } catch (err) {
      console.log(err);
      return err;
    }
  };
};

async function createNewUserWithEcode(userData, ecodeData, db) {
  let uid = userData.uid;
  let now = new Date();
  let ecodeEndDate = new Date(now);
  ecodeEndDate.setDate(ecodeEndDate.getDate() + ecodeData.durationInDays);

  let batch = db.batch();
  batch.set(db.collection("users").doc(uid), {
    email: userData.email,
    name: userData.name,
    dob: userData.dob ?? "",
    gender: userData.gender ?? "",
    nativeLanguage: "en",
    createdAt: now,
    updatedAt: now,
    imagePath: userData.imagePath,
    subscription: {
      startTimeMillis: now,
      endDate: ecodeEndDate,
      lastVerified: now,
    },
  });

  batch.set(
    db.collection("ecodes").doc(ecodeData.docId),
    {
      status: "Used",
      user: { id: uid, email: userData.email },
      updatedAt: now,
      activationDate: now,
    },
    { merge: true }
  );
  await batch.commit();
}

async function updateUserWithNewEcode(userData, ecodeData, db) {
  let uid = userData.uid;
  let now = new Date();
  let ecodeEndDate = new Date(now);
  ecodeEndDate.setDate(ecodeEndDate.getDate() + ecodeData.durationInDays);

  let batch = db.batch();
  batch.set(
    db.collection("users").doc(uid),
    {
      updatedAt: now,
      subscription: {
        startTimeMillis: now,
        endDate: ecodeEndDate,
        lastVerified: now,
      },
    },
    { merge: true }
  );
  batch.set(
    db.collection("ecodes").doc(ecodeData.docId),
    {
      status: "Used",
      user: { id: uid, email: userData.email },
      updatedAt: now,
      activationDate: now,
    },
    { merge: true }
  );
  await batch.commit();
}

function getAge(birthDate) {
  var today = new Date();
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return Number(age);
}

export const updateUserNewEcode = (auth, ecodeData) => {
  return async (dispatch, getState, { getFirebase }) => {
    console.log("Update user with New E-code");
    const firebase = getFirebase();
    const db = firebase.firestore();
    try {
      let userDoc = await firebase.firestore().doc(`users/${auth.uid}`).get();
      if (userDoc.exists) {
        let expiryDate = new Date(
          userDoc.data().subscription.endDate.seconds * 1000
        );
        if (expiryDate < new Date().getTime()) {
          if (!_.isEmpty(ecodeData)) {
            let userData = { uid: auth.uid, email: auth.email };
            await updateUserWithNewEcode(userData, ecodeData, db);
          } else {
            return {
              code: "Expired E-Code",
              message:
                "Your account's E-Code has expired. Please use a new E-Code.",
            };
          }
        } else {
          if (!_.isEmpty(ecodeData)) {
            return {
              code: "Note",
              message:
                "New E-Code was NOT used because your account has an existing E-Code that hasn't expired yet.",
            };
          } else {
            return null;
          }
        }
      }
    } catch (err) {
      console.log(err);
      return err;
    }
  };
};

function uploadProfilePicture(url, uid, firebase) {
  let imagePath = `users/${uid}`;
  let fullPath = `${imagePath}/profilePicture`;
  fetch(url)
    .then((res) => {
      return res.blob();
    })
    .then((blob) => {
      //uploading blob to firebase storage
      firebase
        .storage()
        .ref()
        .child(fullPath)
        .put(blob)
        .then(function (snapshot) {
          return snapshot.ref.getDownloadURL();
        })
        .then((firebaseUrl) => {
          console.log("Firebase storage image uploaded : ", firebaseUrl);
        });
    })
    .catch((error) => {
      console.error(error);
    });
}

export const signInWithFacebook = (ecodeData) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    console.log("FACEBOOK SIGN IN");
    const firebase = getFirebase();
    const db = firebase.firestore();

    try {
      //FACEBOOK LOGIN
      let provider = new firebase.auth.FacebookAuthProvider();
      provider.addScope("user_birthday");
      provider.addScope("user_gender");
      provider.setCustomParameters({
        display: "popup",
      });
      let result = await firebase.auth().signInWithPopup(provider);

      console.log(result);
      let isNewUser = result.additionalUserInfo.isNewUser;
      let imagePath = `users/${result.user.uid}`;

      if (isNewUser) {
        if (!_.isEmpty(ecodeData)) {
          let userData = {
            uid: result.user.uid,
            email: result.user.email,
            name: result.additionalUserInfo.profile.first_name,
            dob:
              result.additionalUserInfo.profile.birthday !== ""
                ? result.additionalUserInfo.profile.birthday
                : "",
            gender:
              result.additionalUserInfo.profile.gender === "male"
                ? "Male"
                : result.additionalUserInfo.profile.gender === "female"
                ? "Female"
                : "",
            imagePath: `${imagePath}/profilePicture`,
          };
          uploadProfilePicture(
            result.additionalUserInfo.profile.picture.data.url,
            result.user.uid,
            firebase
          );
          await createNewUserWithEcode(userData, ecodeData, db);
          return null;
        } else {
          return {
            code: "No Account is found",
            message: "Please register with an E-Code.",
          };
        }
      } else {
        let userDoc = await firebase
          .firestore()
          .doc(`users/${result.user.uid}`)
          .get();
        if (userDoc.exists) {
          let expiryDate = new Date(
            userDoc.data().subscription.endDate.seconds * 1000
          );
          if (expiryDate < new Date().getTime()) {
            if (!_.isEmpty(ecodeData)) {
              let userData = { uid: result.user.uid, email: result.user.email };
              await updateUserWithNewEcode(userData, ecodeData, db);
            } else {
              return {
                code: "Expired E-Code",
                message:
                  "Your account's E-Code has expired. Please use a new E-Code.",
              };
            }
          } else {
            if (!_.isEmpty(ecodeData)) {
              return {
                code: "Note",
                message:
                  "New E-Code was NOT used because your account has an existing E-Code that hasn't expired yet.",
              };
            } else {
              return null;
            }
          }
        } else {
          if (!_.isEmpty(ecodeData)) {
            let userData = {
              uid: result.user.uid,
              email: result.user.email,
              name: result.additionalUserInfo.profile.first_name,
              dob:
                result.additionalUserInfo.profile.birthday !== ""
                  ? result.additionalUserInfo.profile.birthday
                  : "",
              gender:
                result.additionalUserInfo.profile.gender === "male"
                  ? "Male"
                  : result.additionalUserInfo.profile.gender === "female"
                  ? "Female"
                  : "",
              imagePath: `${imagePath}/profilePicture`,
            };
            uploadProfilePicture(
              result.additionalUserInfo.profile.picture.data.url,
              result.user.uid,
              firebase
            );
            await createNewUserWithEcode(userData, ecodeData, db);
            return null;
          } else {
            return {
              code: "No Account is found",
              message: "Please register with an E-Code.",
            };
          }
        }
      }
      console.log("FB RESULT", result);
      // if (isNewUser) {
      //   console.log("FB NEW USER");
      //   let uid = result.user.uid;
      //   let email = result.user.email;
      //   let firstName = result.additionalUserInfo.profile.first_name;
      //   let lastName = result.additionalUserInfo.profile.last_name;

      //   await firestore.set(
      //     {
      //       collection: "users",
      //       doc: uid,
      //     },
      //     { email, firstName, lastName }
      //   );
      // }
    } catch (err) {
      console.log(err);
      return err;
    }
  };
};

export const initDBLoad = (uid) => {
  return async (dispatch, getState, { getFirebase }) => {
    try {
      //load languages
      await dispatch(loadAllLanguageInfo());
      //load translation info
      await dispatch(loadTranslationData());

      //load statistics info
      await dispatch(loadStatisticsData());

      //load course info
      await dispatch(loadAllCourse());

      //load user info
      await dispatch(loadUserInfo(uid));

      // await loadAchievementsData(progressInfo.achievement, userInfo)
    } catch (err) {
      console.log(err);
    }
  };
};

export const loadUserInfo = (uid) => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();
    try {
      // console.log("loadUserInfo")
      let userDocSnap = await db.doc(`users/${uid}`).get();
      let userInfo = {};
      if (userDocSnap.exists) {
        userInfo = { ...userDocSnap.data(), docId: userDocSnap.id };
      }

      let progressDocSnap = await db
        .doc(`users/${uid}/progress/progress`)
        .get();
      let progressInfo = {};
      if (progressDocSnap.exists) {
        progressInfo = { ...progressDocSnap.data(), docId: progressDocSnap.id };
      }
      await dispatch(loadAchievementsData(progressInfo.achievement, userInfo));

      dispatch({ type: LOAD_USER_DATA, payload: { userInfo, progressInfo } });
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
};

export const updateUserInfo = (values, uid, image) => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();
    try {
      dispatch(operationStarted(operationTypeEnum.UPDATE_USER_DATA));
      let imagePath = `users/${uid}`;
      console.log(values.dob, values.gender);
      const [year, month, day] = values.dob && values.dob.split("-");
      const dob = values.dob ? `${month}/${day}/${year}` : values.dob;
      if (typeof image === "string" || image instanceof String) {
        await db.doc(`users/${getState().firebase.auth.uid}`).update({
          verified: true,
          gender: values.gender,
          dob: dob,
          name: values.name,
          imagePath: image,
          updatedAt: new Date(),
        });
      } else {
        await db.doc(`users/${getState().firebase.auth.uid}`).update({
          verified: true,
          gender: values.gender,
          dob: dob,
          name: values.name,
          imagePath: `${imagePath}/${image[0].name}`,
          updatedAt: new Date(),
        });

        let fullPath = `${imagePath}/${image[0].name}`;
        await getFirebase().uploadFile(imagePath, image[0]);

        values.dob = dob;
        dispatch({
          type: UPDATE_USER_DATA,
          payload: {
            values,
            fullPath,
          },
        });
      }

      dispatch(operationCompleted());
    } catch (err) {
      console.log(err);
      dispatch(operationFailed());
      throw err;
    }
  };
};

export const updateUserLanguageInfo = (language, uid) => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();
    try {
      // console.log(language)
      dispatch(operationStarted(operationTypeEnum.UPDATE_USER_LANGUAGE));
      await db
        .doc(`users/${getState().firebase.auth.uid}`)
        .update({ nativeLanguage: language, updatedAt: new Date() });

      dispatch({
        type: UPDATE_USER_LANGUAGE,
        payload: {
          language,
        },
      });
      dispatch(operationCompleted());
    } catch (err) {
      console.log(err);
      dispatch(operationFailed());
      throw err;
    }
  };
};

export const logout = () => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    try {
      await firebase.auth().signOut();
    } catch (err) {
      console.log(err);
    }
  };
};

export const resetEmailPassword = (emailAddress) => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    try {
      await firebase.auth().sendPasswordResetEmail(emailAddress);
      return null;
    } catch (err) {
      console.log(err);
      return err;
    }
  };
};

//== progress related

export const resetProgress = (uid) => {
  return async (dispatch, getState, { getFirebase }) => {
    dispatch({ type: PROGRESS_RESET_START });
    const db = getFirebase().firestore();

    db.doc(`users/${uid}/progress/progress`)
      .delete()
      .then(() => {
        dispatch({ type: PROGRESS_RESET_SUCCESS });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: PROGRESS_RESET_FAILURE,
          payload: {
            errMsg:
              "Error occured while reset progress. Please try again later.",
          },
        });
      });
  };
};
export const updateLessonProgress = (
  lessonIdList,
  courseId,
  topicId,
  subTopicId,
  groupId,
  completed,
  achievement,
  dbAuth
) => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();

    try {
      dispatch(operationStarted(operationTypeEnum.UPDATE_LESSON_PROGRESS));

      let updatedAt = new Date();
      let targetedLessonId = `${topicId}-${subTopicId}-${groupId}`;

      await db
        .doc(`users/${getState().firebase.auth.uid}/progress/progress`)
        .set(
          {
            lessons: { [courseId]: { [targetedLessonId]: lessonIdList } },
            updatedAt,
          },
          { merge: true }
        );
      //console.log(completed);
      if (completed) {
        var quizCompelete = false;
        var lessonCompelete = false;
        var currentAchievementCompleted = false;
        var allCompleted = false;
        var currentAchievementId = "A1";
        var videoWatched = false;

        if (dbAuth.progressInfo.achievement) {
          if (dbAuth.progressInfo.achievement.lesson) {
            if (
              Object.entries(dbAuth.progressInfo.achievement.lesson).length ===
              achievement.currentWeekAchievement.lesson.totalToComplete
            ) {
              lessonCompelete = true;
            }
          }
          if (dbAuth.progressInfo.achievement.quiz) {
            if (
              Object.entries(dbAuth.progressInfo.achievement.quiz).length ===
              achievement.currentWeekAchievement.quiz.totalToComplete
            ) {
              quizCompelete = true;
            }
          }
          if (
            dbAuth.progressInfo.achievement.videoWatched &&
            lessonCompelete &&
            quizCompelete
          ) {
            currentAchievementCompleted = true;
          }
          videoWatched = dbAuth.progressInfo.achievement.videoWatched;

          currentAchievementId = `A${achievement.currentWeekAchievement.order}`;
          console.log("same Id");
          await db
            .doc(`users/${getState().firebase.auth.uid}/progress/progress`)
            .set(
              {
                achievement: {
                  updatedAt,
                  videoWatched: videoWatched,
                  lesson: { [targetedLessonId]: { completed: completed } },
                  currentAchievementCompleted: currentAchievementCompleted,
                  currentAchievementId: currentAchievementId,
                },
                updatedAt,
              },
              { merge: true }
            );
        } else {
          console.log("new achievement");
          await db
            .doc(`users/${getState().firebase.auth.uid}/progress/progress`)
            .set(
              {
                achievement: {
                  lesson: { [targetedLessonId]: { completed: completed } },
                  allCompleted: allCompleted,
                  currentAchievementCompleted: currentAchievementCompleted,
                  currentAchievementId: currentAchievementId,
                  updatedAt,
                  videoWatched: videoWatched,
                },
                updatedAt,
              },
              { merge: true }
            );
        }
      }

      dispatch({
        type: UPDATE_LESSON_PROGRESS,
        payload: {
          courseId,
          targetedLessonId,
          lessonIdList,
          completed,
          allCompleted,
          currentAchievementCompleted,
          currentAchievementId,
          videoWatched,
        },
      });

      dispatch(operationCompleted());
    } catch (err) {
      console.log(err);
      dispatch(operationFailed());
    }
  };
};

export const updateLessonAchievementProgress = (
  courseId,
  topicId,
  subTopicId,
  groupId,
  completed,
  achievement,
  dbAuth
) => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();

    try {
      dispatch(operationStarted(operationTypeEnum.UPDATE_LESSON_PROGRESS));

      let updatedAt = new Date();
      let targetedLessonId = `${topicId}-${subTopicId}-${groupId}`;

      if (completed) {
        var quizCompelete = false;
        var lessonCompelete = false;
        var currentAchievementCompleted = false;
        var allCompleted = false;
        var currentAchievementId = "A1";
        var videoWatched = false;
        console.log(dbAuth);
        if (dbAuth.progressInfo.achievement) {
          if (dbAuth.progressInfo.achievement.lesson) {
            if (
              Object.entries(dbAuth.progressInfo.achievement.lesson).length ===
              achievement.currentWeekAchievement.lesson.totalToComplete
            ) {
              lessonCompelete = true;
            }
          }
          if (dbAuth.progressInfo.achievement.quiz) {
            if (
              Object.entries(dbAuth.progressInfo.achievement.quiz).length ===
              achievement.currentWeekAchievement.quiz.totalToComplete
            ) {
              quizCompelete = true;
            }
          }
          if (
            dbAuth.progressInfo.achievement.videoWatched &&
            lessonCompelete &&
            quizCompelete
          ) {
            currentAchievementCompleted = true;
          }
          videoWatched = dbAuth.progressInfo.achievement.videoWatched;

          currentAchievementId = `A${achievement.currentWeekAchievement.order}`;
          console.log("same Id");
          await db
            .doc(`users/${getState().firebase.auth.uid}/progress/progress`)
            .set(
              {
                achievement: {
                  updatedAt,
                  videoWatched: videoWatched,
                  lesson: { [targetedLessonId]: { completed: completed } },
                  currentAchievementCompleted: currentAchievementCompleted,
                  currentAchievementId: currentAchievementId,
                },
                updatedAt,
              },
              { merge: true }
            );
        } else {
          console.log("new achievement");
          await db
            .doc(`users/${getState().firebase.auth.uid}/progress/progress`)
            .set(
              {
                achievement: {
                  lesson: { [targetedLessonId]: { completed: completed } },
                  allCompleted: allCompleted,
                  currentAchievementCompleted: currentAchievementCompleted,
                  currentAchievementId: currentAchievementId,
                  updatedAt,
                  videoWatched: videoWatched,
                },
                updatedAt,
              },
              { merge: true }
            );
        }

        dispatch({
          type: UPDATE_LESSON_ACHIEVEMENT_PROGRESS,
          payload: {
            courseId,
            targetedLessonId,
            completed,
            allCompleted,
            currentAchievementCompleted,
            currentAchievementId,
            videoWatched,
          },
        });
      }

      dispatch(operationCompleted());
    } catch (err) {
      console.log(err);
      dispatch(operationFailed());
    }
  };
};

export const updateQuizProgress = (
  questionIdList,
  quizId,
  topicId,
  groupId,
  completed,
  achievement,
  dbAuth
) => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();

    try {
      dispatch(operationStarted(operationTypeEnum.UPDATE_QUIZ_PROGRESS));

      let updatedAt = new Date();
      let targetedQuestionId = `${topicId}-${groupId}`;

      await db
        .doc(`users/${getState().firebase.auth.uid}/progress/progress`)
        .set(
          {
            quiz: { [quizId]: { [targetedQuestionId]: questionIdList } },
            updatedAt,
          },
          { merge: true }
        );
      console.log(completed);
      if (completed) {
        var quizCompelete = false;
        var lessonCompelete = false;
        var currentAchievementCompleted = false;
        var allCompleted = false;
        var currentAchievementId = "A1";
        var videoWatched = false;

        if (dbAuth.progressInfo.achievement) {
          if (dbAuth.progressInfo.achievement.quiz) {
            if (
              Object.entries(dbAuth.progressInfo.achievement.quiz).length ===
              achievement.currentWeekAchievement.quiz.totalToComplete
            ) {
              lessonCompelete = true;
            }
          }
          if (dbAuth.progressInfo.achievement.quiz) {
            if (
              Object.entries(dbAuth.progressInfo.achievement.quiz).length ===
              achievement.currentWeekAchievement.quiz.totalToComplete
            ) {
              quizCompelete = true;
            }
          }
          if (
            dbAuth.progressInfo.achievement.videoWatched &&
            lessonCompelete &&
            quizCompelete
          ) {
            currentAchievementCompleted = true;
          }
          videoWatched = dbAuth.progressInfo.achievement.videoWatched;

          currentAchievementId = `A${achievement.currentWeekAchievement.order}`;
          console.log("same Id");
          await db
            .doc(`users/${getState().firebase.auth.uid}/progress/progress`)
            .set(
              {
                achievement: {
                  updatedAt,
                  videoWatched: videoWatched,
                  quiz: {
                    [targetedQuestionId]: { completed: completed },
                  },
                  currentAchievementCompleted: currentAchievementCompleted,
                  currentAchievementId: currentAchievementId,
                },
                updatedAt,
              },
              { merge: true }
            );
        } else {
          console.log("new achievement");
          await db
            .doc(`users/${getState().firebase.auth.uid}/progress/progress`)
            .set(
              {
                achievement: {
                  quiz: {
                    [targetedQuestionId]: { completed: completed },
                  },
                  allCompleted: allCompleted,
                  currentAchievementCompleted: currentAchievementCompleted,
                  currentAchievementId: currentAchievementId,
                  updatedAt,
                  videoWatched: videoWatched,
                },
                updatedAt,
              },
              { merge: true }
            );
        }
      }

      dispatch({
        type: UPDATE_QUIZ_PROGRESS,
        payload: {
          quizId,
          targetedQuestionId,
          questionIdList,
          completed,
          allCompleted,
          currentAchievementCompleted,
          currentAchievementId,
          videoWatched,
        },
      });
      console.log("success");
      dispatch(operationCompleted());
    } catch (err) {
      console.log(err);
      dispatch(operationFailed());
    }
  };
};

export const updateQuizAchievementProgress = (
  questionIdList,
  quizId,
  topicId,
  groupId,
  completed,
  achievement,
  dbAuth
) => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();

    try {
      dispatch(operationStarted(operationTypeEnum.UPDATE_QUIZ_PROGRESS));

      let updatedAt = new Date();
      let targetedQuestionId = `${topicId}-${groupId}`;
      if (completed) {
        var quizCompelete = false;
        var lessonCompelete = false;
        var currentAchievementCompleted = false;
        var allCompleted = false;
        var currentAchievementId = "A1";
        var videoWatched = false;

        if (dbAuth.progressInfo.achievement) {
          if (dbAuth.progressInfo.achievement.quiz) {
            if (
              Object.entries(dbAuth.progressInfo.achievement.quiz).length ===
              achievement.currentWeekAchievement.quiz.totalToComplete
            ) {
              lessonCompelete = true;
            }
          }
          if (dbAuth.progressInfo.achievement.quiz) {
            if (
              Object.entries(dbAuth.progressInfo.achievement.quiz).length ===
              achievement.currentWeekAchievement.quiz.totalToComplete
            ) {
              quizCompelete = true;
            }
          }
          if (
            dbAuth.progressInfo.achievement.videoWatched &&
            lessonCompelete &&
            quizCompelete
          ) {
            currentAchievementCompleted = true;
          }
          videoWatched = dbAuth.progressInfo.achievement.videoWatched;

          currentAchievementId = `A${achievement.currentWeekAchievement.order}`;
          console.log("same Id");
          await db
            .doc(`users/${getState().firebase.auth.uid}/progress/progress`)
            .set(
              {
                achievement: {
                  updatedAt,
                  videoWatched: videoWatched,
                  quiz: {
                    [targetedQuestionId]: { completed: targetedQuestionId },
                  },
                  currentAchievementCompleted: currentAchievementCompleted,
                  currentAchievementId: currentAchievementId,
                },
                updatedAt,
              },
              { merge: true }
            );
        } else {
          console.log("new achievement");
          await db
            .doc(`users/${getState().firebase.auth.uid}/progress/progress`)
            .set(
              {
                achievement: {
                  quiz: {
                    [targetedQuestionId]: { completed: targetedQuestionId },
                  },
                  allCompleted: allCompleted,
                  currentAchievementCompleted: currentAchievementCompleted,
                  currentAchievementId: currentAchievementId,
                  updatedAt,
                  videoWatched: videoWatched,
                },
                updatedAt,
              },
              { merge: true }
            );
        }
        dispatch({
          type: UPDATE_QUIZ_ACHIEVEMENT_PROGRESS,
          payload: {
            quizId,
            targetedQuestionId,
            questionIdList,
            completed,
            allCompleted,
            currentAchievementCompleted,
            currentAchievementId,
            videoWatched,
          },
        });
      }

      dispatch(operationCompleted());
    } catch (err) {
      console.log(err);
      dispatch(operationFailed());
    }
  };
};

export const updateExamProgress = (currentScore, examId, groupId) => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();
    try {
      dispatch(operationStarted(operationTypeEnum.UPDATE_EXAM_PROGRESS));
      let examData = { [examId]: { [`test${groupId}`]: currentScore } };
      await db
        .doc(`users/${getState().firebase.auth.uid}/progress/progress`)
        .set({ exams: examData }, { merge: true });

      dispatch({
        type: UPDATE_EXAM_PROGRESS,
        payload: { examId, groupId, currentScore },
      });
      dispatch(operationCompleted());
    } catch (err) {
      console.log(err);
      dispatch(operationFailed());
    }
  };
};
