import React, { Component, Fragment } from "react";
import "./QuizGroupPage.css";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  loadTargetedQuizGroups,
  resetTargetedQuizGroups,
} from "../../../course/courseActions";
import BottomBar from "../../../BottomBar/BottomBar";
import { Col, Row, Breadcrumb } from "antd";
import WeeklyGoal from "../../WeeklyGoal/WeeklyGoal";
import UserDashboardNavBar from "../../UserDashboardNavBar/UserDashboardNavBar";
import UserDashboardNavBottomBar from "../../UserDashboardNavBar/UserDashboardNavBottomBar";
import LoadingComponent from "../../../app/common/loading/LoadingComponent";
import { courseColorMap, quizStylingMap } from "../../../SharedConstant";
import { getNativeLangTransText } from "../../../SharedUtils";
import { Link } from "react-router-dom";
import _ from "lodash";
import { withFirebase } from "react-redux-firebase";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";

class QuizGroupPage extends Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    const { match } = this.props;
    let quizId = match.params.quizId;
    let topicId = match.params.topicId;

    this.setState(
      {
        quizId,
        topicId,
      },
      () => this.props.loadTargetedQuizGroups(quizId, topicId)
    );
  }

  componentWillUnmount() {
    this.props.resetTargetedQuizGroups();
  }

  async componentDidUpdate(prevProps) {
    const { quizId, topicId } = this.state;
    const { course, dbAuth, firebase, history } = this.props;
    const storageRef = firebase.storage().ref();
    if (
      !_.isEqual(prevProps.course.quizGroupInfoObj, course.quizGroupInfoObj) &&
      course.quizGroupInfoObj !== null
    ) {
      if (course.quizGroupInfoObj.quizGroupInfoList.length === 1) {
        history.push(
          `/user-dashboard/quiz/all-quizzes/${quizId}/topics/${topicId}/groups/${course.quizGroupInfoObj.quizGroupInfoList[0].docId}`
        );
      } else {
        //track quiz progress here...
        let progressObj = {
          quizObj: {},
          //achivement:{},
        };

        course.quizGroupInfoObj.quizGroupInfoList.forEach((item) => {
          let targetedQuestionId = `${topicId}-${item.docId}`;

          let questionIdList = null;
          if (
            dbAuth.progressInfo.quiz &&
            dbAuth.progressInfo.quiz[quizId] &&
            dbAuth.progressInfo.quiz[quizId][targetedQuestionId]
          ) {
            questionIdList =
              dbAuth.progressInfo.quiz[quizId][targetedQuestionId];
          }

          if (questionIdList) {
            let remainingQuestionList = [];

            //check completion rate
            remainingQuestionList = item.questions.filter(
              (item) => !questionIdList.includes(item.questionId)
            );

            if (remainingQuestionList.length === 0) {
              progressObj.quizObj[item.docId] = 100;
            } else {
              progressObj.quizObj[item.docId] =
                ((item.questions.length - remainingQuestionList.length) /
                  item.questions.length) *
                100;
            }
          } else {
            progressObj.quizObj[item.docId] = 0;
          }
        });

        //==
        let imageUrlPromises = [];

        course.quizGroupInfoObj.quizGroupInfoList.forEach((item) => {
          if (item.imagePath && item.imagePath.length > 0) {
            imageUrlPromises.push(
              storageRef.child(item.imagePath).getDownloadURL()
            );
          }
        });

        let iconImageUrl = await Promise.all(imageUrlPromises);

        this.setState({
          isLoading: false,
          iconImageUrl,
          progressObj,
        });
      }
    }
  }

  onQuizzesClick = () => {
    this.props.history.push(`/user-dashboard/quiz/all-quizzes`);
  };

  render() {
    const { isLoading, iconImageUrl, quizId, topicId, progressObj } =
      this.state;
    const {
      course,
      onHandleResetLabelClick,
      dbAuth,
      configuration,
      translation,
    } = this.props;

    if (isLoading) return <LoadingComponent />;

    return (
      <Fragment>
        <UserDashboardNavBar
          onHandleResetLabelClick={onHandleResetLabelClick}
        />
        <Row>
          <Col className="page-container" span={24}>
            <Row style={{ marginBottom: "16px" }}>
              <Col xs={1} sm={4} md={2} lg={4} xl={5} xxl={6} />
              <Col
                xs={22}
                sm={16}
                md={20}
                lg={16}
                xl={14}
                xxl={12}
                className="content-wrap"
              >
                <Breadcrumb
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "22px",
                  }}
                >
                  <Breadcrumb.Item onClick={this.onQuizzesClick}>
                    Quizzes
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    href={`/user-dashboard/quiz/all-quizzes/${quizId}`}
                  >
                    {courseColorMap.get(quizId).label}
                  </Breadcrumb.Item>

                  <Breadcrumb.Item>
                    {course.quizGroupInfoObj.topicInfoObj.name}
                  </Breadcrumb.Item>
                </Breadcrumb>

                <Row>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <Row>
                      <Col span={24} style={{ marginTop: "16px" }}>
                        <Row>
                          <Col span={24}>
                            <Link
                              style={{
                                fontFamily: "Poppins-Bold",
                                fontSize: "22px",
                                color: "#4252BE",
                                cursor: "pointer",
                              }}
                              to={`/user-dashboard/quiz/all-quizzes/${quizId}`}
                            >
                              {"< " +
                                getNativeLangTransText(
                                  dbAuth.userInfo.nativeLanguage,
                                  configuration.projectUI.back,
                                  translation.translationObj
                                ).text}
                            </Link>
                            <div
                              style={{
                                fontFamily: "Poppins-Bold",
                                fontSize: "35px",
                                color: "#4252BE",
                                lineHeight: "normal",
                              }}
                            >
                              {
                                getNativeLangTransText(
                                  dbAuth.userInfo.nativeLanguage,
                                  configuration.projectUI.quiz,
                                  translation.translationObj
                                ).text
                              }
                            </div>
                          </Col>

                          <Col span={24} style={{ marginTop: "16px" }}>
                            {/* Style Code 0 */}
                            {quizStylingMap.get(quizId) &&
                              quizStylingMap.get(quizId)[topicId]
                                .groupStyleCode === 0 && (
                                <Row gutter={[0, 32]}>
                                  {course.quizGroupInfoObj.quizGroupInfoList.map(
                                    (quizGroupInfo, idx) => (
                                      <Col span={8} key={idx}>
                                        <div style={{ width: "90px" }}>
                                          <Link
                                            to={{
                                              pathname: `/user-dashboard/quiz/all-quizzes/${quizId}/topics/${topicId}/groups/${quizGroupInfo.docId}`,
                                            }}
                                          >
                                            <CircularProgressbarWithChildren
                                              value={0}
                                              strokeWidth={4}
                                              styles={{
                                                path: { stroke: "transparent" },
                                                trail: {
                                                  stroke: "transparent",
                                                },
                                              }}
                                            >
                                              <div style={{ width: "92%" }}>
                                                <CircularProgressbar
                                                  strokeWidth={4}
                                                  background={true}
                                                  value={
                                                    progressObj.quizObj[
                                                      quizGroupInfo.docId
                                                    ]
                                                  }
                                                  text={quizGroupInfo.name}
                                                  styles={{
                                                    path: { stroke: "#00FF00" },
                                                    trail: {
                                                      stroke:
                                                        "rgb(211,211,211)",
                                                    },
                                                    text: {
                                                      fontFamily:
                                                        "Poppins-Bold",
                                                      fontSize: "20px",
                                                      fill: "black",
                                                    },

                                                    background: {
                                                      fill: courseColorMap.get(
                                                        quizId
                                                      ).hexColorCode,
                                                    },
                                                  }}
                                                />
                                              </div>
                                            </CircularProgressbarWithChildren>
                                          </Link>
                                        </div>

                                        {/* <Link
                                      to={{
                                        pathname: `/user-dashboard/quiz/all-quizzes/${quizId}/topics/${topicId}/groups/${quizGroupInfo.docId}`
                                      }}
                                      style={{ color: "black" }}
                                    >
                                      <div
                                        style={{
                                          cursor: "pointer",
                                          fontFamily: "Poppins-Bold",
                                          fontSize: "20px",
                                          borderRadius: "50%",
                                          width: "80px",
                                          lineHeight: "80px",
                                          border:
                                            "1px solid rgba(112, 112, 112, 0.4)",
                                          boxShadow:
                                            "0 0 5px rgba(0, 0, 0, 0.14)",
                                          textAlign: "center",
                                          background: courseColorMap.get(quizId)
                                            .hexColorCode
                                        }}
                                      >
                                        {quizGroupInfo.name}
                                      </div>
                                    </Link> */}
                                      </Col>
                                    )
                                  )}
                                </Row>
                              )}

                            {/* Style Code 1 */}
                            {quizStylingMap.get(quizId) &&
                              quizStylingMap.get(quizId)[topicId]
                                .groupStyleCode === 1 && (
                                <Row>
                                  {course.quizGroupInfoObj.quizGroupInfoList.map(
                                    (quizGroupInfo, idx) => (
                                      <Col
                                        span={24}
                                        key={idx}
                                        style={{ marginTop: "24px" }}
                                      >
                                        <Link
                                          to={`/user-dashboard/quiz/all-quizzes/${quizId}/topics/${topicId}/groups/${quizGroupInfo.docId}`}
                                          style={{
                                            color: "black",
                                            boxShadow:
                                              "0 0 5px rgba(0,0,0,0.14)",
                                            borderRadius: "24px",
                                            border:
                                              progressObj.quizObj[
                                                quizGroupInfo.docId
                                              ] === 100
                                                ? "3px solid green"
                                                : "1px solid rgba(112,112,112,0.4)",
                                            fontFamily: "Poppins-Regular",
                                            fontSize: "23px",
                                            display: "block",
                                            overflowWrap: "break-word",
                                            alignItems: "center",
                                            padding: "8px 24px",
                                            background:
                                              courseColorMap.get(quizId)
                                                .hexColorCode,
                                          }}
                                        >
                                          {iconImageUrl.length > 0 && (
                                            <img
                                              src={iconImageUrl[idx]}
                                              alt=""
                                            />
                                          )}
                                          <div>{quizGroupInfo.name}</div>
                                        </Link>
                                      </Col>
                                    )
                                  )}
                                </Row>
                              )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    offset={1}
                    xs={0}
                    sm={0}
                    md={11}
                    lg={11}
                    xl={11}
                    xxl={11}
                  >
                    <WeeklyGoal />
                  </Col>
                </Row>
              </Col>
              <Col xs={3} sm={5} md={0} lg={0} xl={0} xxl={0} />
              <Col xs={18} sm={14} md={0} lg={0} xl={0} xxl={0}>
                <br />
                <WeeklyGoal />
              </Col>
            </Row>
            <Row>
              <Col
                xs={0}
                sm={0}
                md={24}
                lg={24}
                xl={24}
                className="footer-bottom"
              >
                <BottomBar />
              </Col>
              <Col
                xs={0}
                sm={24}
                md={0}
                lg={0}
                xl={0}
                style={{ marginBottom: "100px" }}
              >
                <BottomBar />
              </Col>
              <Col
                xs={24}
                sm={0}
                md={0}
                lg={0}
                xl={0}
                style={{ marginBottom: "70px" }}
              >
                <BottomBar />
              </Col>
            </Row>
            <Row>
              <Col
                xs={24}
                sm={24}
                md={0}
                lg={0}
                xl={0}
                style={{
                  textAlign: "center",
                  borderTop: "4px solid #F1F1F1",
                }}
                className="footer"
              >
                <UserDashboardNavBottomBar
                  onHandleResetLabelClick={onHandleResetLabelClick}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  course: state.course,
  dbAuth: state.auth,
  translation: state.translation,
  configuration: state.configuration,
});

const mapDispatchToProps = {
  loadTargetedQuizGroups,
  resetTargetedQuizGroups,
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(QuizGroupPage);
