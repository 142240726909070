import { createReducer } from "../app/common/utils/reducerUtils";
import {
  LOAD_USER_DATA,
  UPDATE_USER_DATA,
  UPDATE_USER_LANGUAGE,
  UPDATE_LANGUAGE,
  UPDATE_LESSON_PROGRESS,
  UPDATE_QUIZ_PROGRESS,
  UPDATE_EXAM_PROGRESS,
  UPDATE_LESSON_ACHIEVEMENT_PROGRESS,
  UPDATE_QUIZ_ACHIEVEMENT_PROGRESS,
  PROGRESS_RESET_START,
  PROGRESS_RESET_SUCCESS,
  PROGRESS_RESET_FAILURE,
} from "./authConstant";

const initialState = {
  userInfo: null,
  progressInfo: null,
  selectedLanguage: "en",
  // loading: false,
  // progressResetSuccess: false,
  // progressResetFailure: false,
  // errMsg: ""
};

const loadUserData = (state, payload) => {
  return {
    ...state,
    userInfo: payload.userInfo,
    progressInfo: payload.progressInfo,
  };
};

const updateUserData = (state, payload) => {
  return {
    ...state,
    userInfo: {
      ...state.userInfo,
      name: payload.values.name,
      age: payload.values.age,
      gender: payload.values.gender,
      imagePath: payload.fullPath,
      verified: true,
    },
  };
};

const updateUserLanguageInfo = (state, payload) => {
  return {
    ...state,
    userInfo: {
      ...state.userInfo,
      nativeLanguage: payload.language,
    },
  };
};

const updateLanguage = (state, payload) => {
  return {
    ...state,
    selectedLanguage: payload.language,
  };
};

// const resetUserData = state => {
//   return {
//     ...state,
//     userInfo: null,
//     progressInfo: null
//   };
// };

const updateLessonProgress = (state, payload) => {
  if (
    state.progressInfo.lessons &&
    state.progressInfo.lessons[payload.courseId]
  ) {
    if (payload.completed) {
      if (
        state.progressInfo.achievement &&
        state.progressInfo.achievement.lesson
      ) {
        return {
          ...state,
          progressInfo: {
            ...state.progressInfo,
            lessons: {
              ...state.progressInfo.lessons,
              [payload.courseId]: {
                ...state.progressInfo.lessons[payload.courseId],
                [payload.targetedLessonId]: payload.lessonIdList,
              },
            },
            achievement: {
              ...state.progressInfo.achievement,
              lesson: {
                ...state.progressInfo.achievement.lesson,
                [payload.targetedLessonId]: { completed: payload.completed },
              },
              allCompleted: payload.allCompleted,
              currentAchievementCompleted: payload.currentAchievementCompleted,
              currentAchievementId: payload.currentAchievementId,
              videoWatched: payload.videoWatched,
            },
          },
        };
      } else {
        return {
          ...state,
          progressInfo: {
            ...state.progressInfo,
            lessons: {
              ...state.progressInfo.lessons,
              [payload.courseId]: {
                ...state.progressInfo.lessons[payload.courseId],
                [payload.targetedLessonId]: payload.lessonIdList,
              },
            },
            achievement: {
              ...state.progressInfo.achievement,
              lesson: {
                [payload.targetedLessonId]: { completed: payload.completed },
              },
              allCompleted: payload.allCompleted,
              currentAchievementCompleted: payload.currentAchievementCompleted,
              currentAchievementId: payload.currentAchievementId,
              videoWatched: payload.videoWatched,
            },
          },
        };
      }
    } else {
      return {
        ...state,
        progressInfo: {
          ...state.progressInfo,
          lessons: {
            ...state.progressInfo.lessons,
            [payload.courseId]: {
              ...state.progressInfo.lessons[payload.courseId],
              [payload.targetedLessonId]: payload.lessonIdList,
            },
          },
        },
      };
    }
  } else {
    if (payload.completed) {
      if (state.progressInfo.achievement) {
        return {
          ...state,
          progressInfo: {
            ...state.progressInfo,
            lessons: {
              ...state.progressInfo.lessons,
              [payload.courseId]: {
                [payload.targetedLessonId]: payload.lessonIdList,
              },
            },
            achievement: {
              ...state.progressInfo.achievement,
              lesson: {
                ...state.progressInfo.achievement.lesson,
                [payload.targetedLessonId]: { completed: payload.completed },
              },
              allCompleted: payload.allCompleted,
              currentAchievementCompleted: payload.currentAchievementCompleted,
              currentAchievementId: payload.currentAchievementId,
              videoWatched: payload.videoWatched,
            },
          },
        };
      } else {
        return {
          ...state,
          progressInfo: {
            ...state.progressInfo,
            lessons: {
              ...state.progressInfo.lessons,
              [payload.courseId]: {
                [payload.targetedLessonId]: payload.lessonIdList,
              },
            },
            achievement: {
              ...state.progressInfo.achievement,
              lesson: {
                [payload.targetedLessonId]: { completed: payload.completed },
              },
              allCompleted: payload.allCompleted,
              currentAchievementCompleted: payload.currentAchievementCompleted,
              currentAchievementId: payload.currentAchievementId,
              videoWatched: payload.videoWatched,
            },
          },
        };
      }
    } else {
      return {
        ...state,
        progressInfo: {
          ...state.progressInfo,
          lessons: {
            ...state.progressInfo.lessons,
            [payload.courseId]: {
              [payload.targetedLessonId]: payload.lessonIdList,
            },
          },
        },
      };
    }
  }
};

const updateLessonAchievementProgress = (state, payload) => {
  if (state.progressInfo.achievement.lesson) {
    return {
      ...state,
      progressInfo: {
        ...state.progressInfo,
        achievement: {
          ...state.progressInfo.achievement,
          lesson: {
            ...state.progressInfo.achievement.lesson,
            [payload.targetedLessonId]: { completed: payload.completed },
          },
          allCompleted: payload.allCompleted,
          currentAchievementCompleted: payload.currentAchievementCompleted,
          currentAchievementId: payload.currentAchievementId,
          videoWatched: payload.videoWatched,
        },
      },
    };
  } else {
    return {
      ...state,
      progressInfo: {
        ...state.progressInfo,
        achievement: {
          ...state.progressInfo.achievement,
          lesson: {
            [payload.targetedLessonId]: { completed: payload.completed },
          },
          allCompleted: payload.allCompleted,
          currentAchievementCompleted: payload.currentAchievementCompleted,
          currentAchievementId: payload.currentAchievementId,
          videoWatched: payload.videoWatched,
        },
      },
    };
  }
};

const updateQuizProgress = (state, payload) => {
  if (state.progressInfo.quiz && state.progressInfo.quiz[payload.quizId]) {
    if (payload.completed) {
      return {
        ...state,
        progressInfo: {
          ...state.progressInfo,
          quiz: {
            ...state.progressInfo.quiz,
            [payload.quizId]: {
              ...state.progressInfo.quiz[payload.quizId],
              [payload.targetedQuestionId]: payload.questionIdList,
            },
          },
          achievement: {
            ...state.progressInfo.achievement,
            quiz: {
              ...state.progressInfo.achievement.quiz,
              [payload.targetedQuestionId]: { completed: payload.completed },
            },
            allCompleted: payload.allCompleted,
            currentAchievementCompleted: payload.currentAchievementCompleted,
            currentAchievementId: payload.currentAchievementId,
            videoWatched: payload.videoWatched,
          },
        },
      };
    } else {
      return {
        ...state,
        progressInfo: {
          ...state.progressInfo,
          quiz: {
            ...state.progressInfo.quiz,
            [payload.quizId]: {
              ...state.progressInfo.quiz[payload.quizId],
              [payload.targetedQuestionId]: payload.questionIdList,
            },
          },
        },
      };
    }
  } else {
    if (payload.completed) {
      return {
        ...state,
        progressInfo: {
          ...state.progressInfo,
          quiz: {
            ...state.progressInfo.quiz,
            [payload.quizId]: {
              [payload.targetedQuestionId]: payload.questionIdList,
            },
          },
          achievement: {
            ...state.progressInfo.achievement,
            quiz: {
              [payload.targetedQuestionId]: { completed: payload.completed },
            },
            allCompleted: payload.allCompleted,
            currentAchievementCompleted: payload.currentAchievementCompleted,
            currentAchievementId: payload.currentAchievementId,
            videoWatched: payload.videoWatched,
          },
        },
      };
    } else {
      return {
        ...state,
        progressInfo: {
          ...state.progressInfo,
          quiz: {
            ...state.progressInfo.quiz,
            [payload.quizId]: {
              [payload.targetedQuestionId]: payload.questionIdList,
            },
          },
        },
      };
    }
  }
};

const updateQuizAchievementProgress = (state, payload) => {
  return {
    ...state,
    progressInfo: {
      ...state.progressInfo,
      achievement: {
        ...state.progressInfo.achievement,
        quiz: {
          ...state.progressInfo.achievement.quiz,
          [payload.targetedQuestionId]: { completed: payload.completed },
        },
        allCompleted: payload.allCompleted,
        currentAchievementCompleted: payload.currentAchievementCompleted,
        currentAchievementId: payload.currentAchievementId,
        videoWatched: payload.videoWatched,
      },
    },
  };
};

const updateExamProgress = (state, payload) => {
  if (state.progressInfo.exams) {
    // console.log(...state.progressInfo.exams)
    return {
      ...state,
      progressInfo: {
        ...state.progressInfo,
        exams: {
          ...state.progressInfo.exams,
          [payload.examId]: {
            ...state.progressInfo.exams[payload.examId],
            [`test${payload.groupId}`]: payload.currentScore,
          },
        },
      },
    };
  } else {
    return {
      ...state,
      progressInfo: {
        ...state.progressInfo,
        exams: {
          ...state.progressInfo.exams,
          [payload.examId]: {
            [`test${payload.groupId}`]: payload.currentScore,
          },
        },
      },
    };
  }
};

const progressResetStart = (state) => {
  return {
    ...state,
    progressResetSuccess: false,
    progressResetFailure: false,
    errMsg: "",
    loading: true,
  };
};

const progressResetSuccess = (state) => {
  return {
    ...state,
    progressResetSuccess: true,
    progressInfo: {},
    loading: false,
  };
};

const progressResetFailure = (state, payload) => {
  return {
    ...state,
    progressResetFailure: true,
    errMsg: payload.errMsg,
    loading: false,
  };
};

export default createReducer(initialState, {
  [LOAD_USER_DATA]: loadUserData,
  [UPDATE_USER_DATA]: updateUserData,
  [UPDATE_USER_LANGUAGE]: updateUserLanguageInfo,
  [UPDATE_LANGUAGE]: updateLanguage,
  // [RESET_USER_DATA]: resetUserData,
  [UPDATE_LESSON_PROGRESS]: updateLessonProgress,
  [UPDATE_QUIZ_PROGRESS]: updateQuizProgress,
  [UPDATE_EXAM_PROGRESS]: updateExamProgress,
  [UPDATE_LESSON_ACHIEVEMENT_PROGRESS]: updateLessonAchievementProgress,
  [UPDATE_QUIZ_ACHIEVEMENT_PROGRESS]: updateQuizAchievementProgress,
  [PROGRESS_RESET_START]: progressResetStart,
  [PROGRESS_RESET_SUCCESS]: progressResetSuccess,
  [PROGRESS_RESET_FAILURE]: progressResetFailure,
});
