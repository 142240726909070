import React from "react";
import { Spin, Row, Col } from "antd";

const LoadingComponent = () => {
  return (
    <Row justify="center" align="middle">
      <Col style={{ textAlign: "center", marginTop: "256px" }}>
        <Spin tip="Loading..." size="large" />
      </Col>
    </Row>
  );
};

export default LoadingComponent;
