import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { initDBLoad } from "./auth/authActions";
import LoadingComponent from "./app/common/loading/LoadingComponent";

class DbAuthLoad extends Component {
  state = { isLoading: true };

  componentDidMount() {
    const { auth, initDBLoad } = this.props;
    const isAuthenticated = auth.uid && !auth.isAnonymous;
    //console.log(auth)
    if (isAuthenticated) {
      // history.push("/user-dashboard");
      
      initDBLoad(auth.uid); //load this IFF user already logged in
    } else {
      this.setState({
        isLoading: false
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { dbAuth } = this.props;
    //trigger IFF admin info get updated(IFF completed login)
    if (!_.isEqual(prevProps.dbAuth.userInfo, dbAuth.userInfo)) {
      this.setState({
        isLoading: false
      });
    }
  }

  render() {
    const { children } = this.props;
    const { isLoading } = this.state;

    if (isLoading) return <LoadingComponent />;
    return children;
  }
}
const mapStateToProps = state => ({
  auth: state.firebase.auth,
  dbAuth: state.auth
});

const mapDispatchToProps = {
  initDBLoad
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DbAuthLoad
);
