import React, { Fragment } from "react";
import { Modal } from "antd";

const TimeRunningOutModal = ({ isVisible, onCancel }) => {
  return (
    <Fragment>
      <Modal
        visible={isVisible}
        onCancel={onCancel}
        closeIcon={<img src="/assets/blue-close-icon.svg" alt="" />}
        footer={null}
        width={400}
      >
        <div
          style={{
            fontFamily: "Poppins-Regular",
            fontSize: "25px",
            textAlign: "center",
            margin : "100px 0px"
          }}
        >
          Sorry, you have run out of time!
        </div>

        <div
          style={{
            fontFamily: "Poppins-Bold",
            fontSize: "25px",
            color: "#4252BE",
            padding: "8px",
            borderTop: "1px solid rgba(112,112,112,0.4)",
            marginTop: "24px",
            width: "100%",
            textAlign: "center"
          }}
        >
          No
        </div>
      </Modal>
    </Fragment>
  );
};

export default TimeRunningOutModal;
