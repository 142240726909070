//course related
export const LOAD_ALL_COURSE = "LOAD_ALL_COURSE";
export const LOAD_COURSE_TOPICS = "LOAD_COURSE_TOPICS";
export const RESET_COURSE_TOPICS = "RESET_COURSE_TOPICS";
export const LOAD_COURSE_SUBTOPICS = "LOAD_COURSE_SUBTOPICS";
export const RESET_COURSE_SUBTOPICS = "RESET_COURSE_SUBTOPICS";
export const LOAD_COURSE_LESSON_GROUPS = "LOAD_COURSE_LESSON_GROUPS";
export const RESET_COURSE_LESSON_GROUPS = "RESET_COURSE_LESSON_GROUPS";
export const LOAD_COURSE_LESSON = "LOAD_COURSE_LESSON";
export const RESET_COURSE_LESSON = "RESET_COURSE_LESSON";

//quiz related
export const LOAD_QUIZ_TOPICS = "LOAD_QUIZ_TOPICS";
export const RESET_QUIZ_TOPICS = "RESET_QUIZ_TOPICS";
export const LOAD_QUIZ_GROUPS = "LOAD_QUIZ_GROUPS";
export const RESET_QUIZ_GROUPS = "RESET_QUIZ_GROUPS";
export const LOAD_QUIZ_GROUP_QUESTIONS ="LOAD_QUIZ_GROUP_QUESTIONS";
export const RESET_QUIZ_GROUP_QUESTIONS ="RESET_QUIZ_GROUP_QUESTIONS";

//exam related
export const LOAD_ALL_EXAM_LEVELS = "LOAD_ALL_EXAM_LEVELS";
export const RESET_ALL_EXAM_LEVELS = "RESET_ALL_EXAM_LEVELS";

export const LOAD_TARGETED_EXAM_GROUPS = "LOAD_TARGETED_EXAM_GROUPS";
export const RESET_TARGETED_EXAM_GROUPS = "RESET_TARGETED_EXAM_GROUPS";

export const LOAD_EXAM_GROUP_QUESTIONS ="LOAD_EXAM_GROUP_QUESTIONS";
export const RESET_EXAM_GROUP_QUESTIONS ="RESET_EXAM_GROUP_QUESTIONS";