import {
    SUBSCRIBE_START,
    SUBSCRIBE_SUCCESS,
    SUBSCRIBE_FAILURE
} from './SubscribeConstants'
import { createReducer } from "../app/common/utils/reducerUtils"

const initialState = {
    loading: false,
    subscribeSuccess: false,
    subscribeFailure: false,
    errMsg: ""
}

const subscribeStart = (state) => {
    return {
        ...initialState,
        loading: true,
    }
  }
  
  const subscribeSuccess = (state) => {
    return {
        ...state,
        subscribeSuccess: true,
        loading: false,
    }
  }
  
  const subscribeFailure = (state, payload) => {
    return {
        ...state,
        subscribeFailure: true,
        errMsg: payload.errMsg,
        loading: false,
    }
  }
  
  export default createReducer(initialState, {
    [SUBSCRIBE_START]: subscribeStart,
    [SUBSCRIBE_SUCCESS]: subscribeSuccess,
    [SUBSCRIBE_FAILURE]: subscribeFailure
  });