import {
  UPDATE_LESSON_PROGRESS,
  UPDATE_QUIZ_PROGRESS,
  UPDATE_EXAM_PROGRESS,
  UPDATE_USER_DATA,
  UPDATE_USER_LANGUAGE
} from "./auth/authConstant";

export const operationTypeEnum = {
  NO_OPS: "NO_OPS",
  UNRECOVERABLE_ERROR: "UNRECOVERABLE_ERROR",
  UPDATE_LESSON_PROGRESS,
  UPDATE_QUIZ_PROGRESS,
  UPDATE_EXAM_PROGRESS,
  UPDATE_USER_DATA,
  UPDATE_USER_LANGUAGE
};
