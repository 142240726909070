import React, { Component, Fragment } from "react";
import "./EditProfilePage.css";
import BottomBar from "../../../BottomBar/BottomBar";
import { Col, Row, Form, Input, Button, Spin, Select } from "antd";
import UserDashboardNavBar from "../../UserDashboardNavBar/UserDashboardNavBar";
import UserDashboardNavBottomBar from "../../UserDashboardNavBar/UserDashboardNavBottomBar";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";
import { withFirebase } from "react-redux-firebase";
import { updateUserInfo } from "../../../auth/authActions";
import { operationTypeEnum } from "../../../OperationTypeConstant";
import _ from "lodash";
import LoadingComponent from "../../../app/common/loading/LoadingComponent";
import { UploadPhotoMenu } from "../EditProfilePage/UploadPhotoMenu";
import { getNativeLangTransText } from "../../../SharedUtils";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Moment from "moment";
const { Option } = Select;

class EditProfilePage extends Component {
  state = {
    loading: true,
    name: this.props.dbAuth.userInfo.name,
    dob: this.props.dbAuth.userInfo.dob,
    gender: this.props.dbAuth.userInfo.gender,
    imagePath: this.props.dbAuth.userInfo.imagePath,
    isModifyingProfileThumbnail: false,
    isUpdating: false,
    droppedFilesForThumbnail: [],
    genderError: null,
    onSaveClick: false
  };
  form = React.createRef();
  async componentDidMount() {
    const { imagePath } = this.state;
    const { firebase } = this.props;
    const storageRef = firebase.storage().ref();
    const [month, day, year] =
      this.props.dbAuth.userInfo.dob &&
      this.props.dbAuth.userInfo.dob.split("/");
    const dob = this.props.dbAuth.userInfo.dob
      ? `${year}-${month}-${day}`
      : this.props.dbAuth.userInfo.dob;

    if (imagePath) {
      let image = await Promise.resolve(
        storageRef.child(imagePath).getDownloadURL()
      );

      this.setState({
        loading: false,
        image: image,
        dob: dob,
      });
    } else {
      this.setState({
        loading: false,
        dob: dob,
      });
    }
  }
  componentDidUpdate(prevProps) {
    const { operation } = this.props;
    if (
      !_.isEqual(prevProps.operation.isProcessing, operation.isProcessing) &&
      !operation.isProcessing
    ) {
      console.log(this.state.onSaveClick);
      if (
        operation.operationType === operationTypeEnum.NO_OPS ||
        (prevProps.operation.operationType ===
          operationTypeEnum.UPDATE_USER_DATA &&
          operation.operationType === operationTypeEnum.NO_OPS )&& this.state.onSaveClick
      ) {
        if ( this.state.onSaveClick)
        // this.props.navigation.goBack(null);
        this.props.history.goBack();
      }
    }
  }

  handleSubmit = () => {
    const { dbAuth, translation, configuration } = this.props;
    const { image, droppedFilesForThumbnail, imagePath, gender } = this.state;

    this.form.current.validateFields().then((values) => {
      if (gender === "") {
        this.setState({
          genderError: getNativeLangTransText(
            dbAuth.userInfo.nativeLanguage,
            configuration.projectUI.please_provide_your_gender,
            translation.translationObj
          ).text,
        });
      } else {
        values.gender = gender;
        this.setState({ genderError: null });
        if (image || droppedFilesForThumbnail[0]) {
          if (droppedFilesForThumbnail[0]) {
            this.props.updateUserInfo(
              values,
              dbAuth.userInfo.docId,
              droppedFilesForThumbnail
            );
          } else {
            confirmAlert({
              title: "Confimation",
              message: "You cannot change you're details once you save them.",
              buttons: [
                {
                  label: "OK",
                  onClick: () => this.onConfirmSave(values),
                },
                {
                  label: "Cancel",
                },
              ],
            });
          }
        } else {
          confirmAlert({
            title: getNativeLangTransText(
              dbAuth.userInfo.nativeLanguage,
              configuration.projectUI.profile_picture,
              translation.translationObj
            ).text,
            message: getNativeLangTransText(
              dbAuth.userInfo.nativeLanguage,
              configuration.projectUI.upload_an_image_as_your_profile_picture,
              translation.translationObj
            ).text,
            buttons: [
              {
                label: getNativeLangTransText(
                  dbAuth.userInfo.nativeLanguage,
                  configuration.projectUI.ok,
                  translation.translationObj
                ).text,
              },
            ],
          });
        }
      }
    });
  };

  onConfirmSave = (values) => {
    const { dbAuth } = this.props;
    const { imagePath } = this.state;
    this.props.updateUserInfo(values, dbAuth.userInfo.docId, imagePath);
    this.setState({ onSaveClick: true});
  };

  openProfilePictureUploadMenuClick = () => {
    this.setState({
      isModifyingProfileThumbnail: true,
    });
  };

  onGenderChange = (e) => {
    this.setState({ gender: e, genderError: null });
  };

  setLoadedImageFileForThumbnail = (acceptedFiles) => {
    this.setState({
      droppedFilesForThumbnail: acceptedFiles.map((file) => {
        let fileObj = new File([file], "profilePicture", { type: file.type });

        return Object.assign(fileObj, {
          preview: URL.createObjectURL(fileObj),
        });
      }),
    });
  };

  render() {
    const {
      onHandleResetLabelClick,
      operation,
      dbAuth,
      translation,
      configuration,
    } = this.props;
    const {
      name,
      dob,
      gender,
      loading,
      image,
      isModifyingProfileThumbnail,
      droppedFilesForThumbnail,
      isUpdating,
      onSaveClick
    } = this.state;
    if (loading) return <LoadingComponent />;
    return (
      <Spin tip="Saving..." spinning={onSaveClick && operation.isProcessing} size="large">
        <Fragment>
          <UserDashboardNavBar
            onHandleResetLabelClick={onHandleResetLabelClick}
          />
          <Row>
            <Col className="page-container" span={24}>
              <Row style={{ marginBottom: "16px" }}>
                <Col offset={6} span={12} className="content-wrap">
                  <div
                    style={{
                      fontFamily: "Poppins-Bold",
                      fontSize: "35px",
                      lineHeight: "normal",
                    }}
                  >
                    {
                      getNativeLangTransText(
                        dbAuth.userInfo.nativeLanguage,
                        configuration.projectUI.profile,
                        translation.translationObj
                      ).text
                    }
                  </div>

                  <Form
                    ref={this.form}
                    autoComplete="off"
                    // onFinish={() => console.log("SUBMIT TEST")}
                    onFinish={this.handleSubmit}
                  >
                    <Row style={{ textAlign: "center" }}>
                      <Col offset={8} span={8}>
                        <br />
                      </Col>
                      <Col span={8} />

                      <Col span={24}>
                        <Form.Item
                          name="image"
                          // valuePropName="fileList"
                          // getValueFromEvent={normFile}
                          // extra="Edit"
                        >
                          {isModifyingProfileThumbnail ? (
                            
                            <UploadPhotoMenu
                              setLoadedImageFile={
                                this.setLoadedImageFileForThumbnail
                              }
                              onUploadImageButtonClick={
                                this.onUploadImageButtonClick
                              }
                              droppedFiles={droppedFilesForThumbnail}
                              isUploading={isUpdating}
                              hideUploadButton={true}
                              preview={
                                getNativeLangTransText(
                                  dbAuth.userInfo.nativeLanguage,
                                  configuration.projectUI.preview,
                                  translation.translationObj
                                ).text
                              }
                              noPreview={
                                getNativeLangTransText(
                                  dbAuth.userInfo.nativeLanguage,
                                  configuration.projectUI.no_preview_available,
                                  translation.translationObj
                                ).text
                              }
                              upload={
                                getNativeLangTransText(
                                  dbAuth.userInfo.nativeLanguage,
                                  configuration.projectUI.upload,
                                  translation.translationObj
                                ).text
                              }
                              dropImage={
                                getNativeLangTransText(
                                  dbAuth.userInfo.nativeLanguage,
                                  configuration.projectUI.drop_image_here,
                                  translation.translationObj
                                ).text
                              }
                            />
                          ) : (
                            <div
                              onClick={this.openProfilePictureUploadMenuClick}
                            >
                              <img
                                src={
                                  image
                                    ? image
                                    : "/assets/sample-profile-image.svg"
                                }
                                alt=""
                                style={{
                                  width: "150px",
                                  height: "150px",
                                  borderRadius: "50%",
                                }}
                              />
                            </div>
                          )}
                        </Form.Item>
                        <Form.Item
                          initialValue={name}
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI
                                  .please_provide_your_name,
                                translation.translationObj
                              ).text,
                            },
                          ]}
                        >
                          <Input
                            type="text"
                            style={{ textTransform: "uppercase" }}
                            size="large"
                            placeholder={
                              getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI.name,
                                translation.translationObj
                              ).text
                            }
                            disabled={dbAuth.userInfo.verified}
                            prefix={
                              <img
                                src="/assets/profile-black-icon.svg"
                                alt=""
                                style={{ padding: "6px" }}
                              />
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          initialValue={dob}
                          name="dob"
                          rules={[
                            {
                              required: true,
                              message: getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI.please_provide_your_dob,
                                translation.translationObj
                              ).text,
                            },
                          ]}
                        >
                          <Input
                            type="date"
                            size="large"
                            min={Moment(
                              new Date(
                                new Date().setFullYear(
                                  new Date().getFullYear() - 120
                                )
                              )
                            ).format("YYYY-MM-DD")}
                            max={Moment().format("YYYY-MM-DD")}
                            placeholder={
                              getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI.dob,
                                translation.translationObj
                              ).text
                            }
                            disabled={dbAuth.userInfo.verified}
                            prefix={
                              <img
                                src="/assets/add-user-black-icon.svg"
                                alt=""
                                style={{ padding: "8px" }}
                              />
                            }
                          />
                        </Form.Item>

                        <Form.Item
                          
                          name={
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.gender,
                              translation.translationObj
                            ).text
                          }
                          
                        >
                          <Select
                          
                          value={ gender === "" || gender === undefined ? undefined :
                            <div style={{ display: "table-cell" }} key={gender}>
                              {gender == "Male" ? (
                                <img
                                  src="/assets/gender-black-icon.svg"
                                  alt=""
                                />
                              ) : gender == "Female" ? (
                                <img src="/assets/femenine.svg" alt="" />
                              ) : gender == "Other" ? (
                                <img src="/assets/third-gender.svg" alt="" />
                              ) : null}{" "}
                              {gender == "Male" ? getNativeLangTransText(
                                    dbAuth.userInfo.nativeLanguage,
                                    configuration.projectUI.male,
                                    translation.translationObj
                                  ).text: gender === "Female" ? getNativeLangTransText(
                                    dbAuth.userInfo.nativeLanguage,
                                    configuration.projectUI.female,
                                    translation.translationObj
                                  ).text: getNativeLangTransText(
                                    dbAuth.userInfo.nativeLanguage,
                                    configuration.projectUI.others,
                                    translation.translationObj
                                  ).text}
                            </div>
                          }
                            disabled={dbAuth.userInfo.verified}
                            bordered={false}
                            style={{
                              width: "100%",
                              fontFamily: "Poppins-Regular",
                              fontSize: "18px",
                              padding: "8px",
                              border:
                                this.state.genderError === null
                                  ? "1px solid rgba(112,112,112,0.4)"
                                  : "1px solid red",
                              outline: "none",
                              flex: 6,
                            }}
                            size="large"
                            placeholder={
                              getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI.gender,
                                translation.translationObj
                              ).text
                            }
                            onChange={(e) => this.onGenderChange(e)}
                          >
                            <Option key="male" value="Male">
                              <div style={{ display: "table-cell" }}>
                                <img
                                  src="/assets/gender-black-icon.svg"
                                  alt=""
                                />{" "}
                                {
                                  getNativeLangTransText(
                                    dbAuth.userInfo.nativeLanguage,
                                    configuration.projectUI.male,
                                    translation.translationObj
                                  ).text
                                }
                              </div>
                            </Option>
                            <Option key="female" value="Female">
                              <div style={{ display: "table-cell" }}>
                                <img src="/assets/femenine.svg" alt="" />{" "}
                                {
                                  getNativeLangTransText(
                                    dbAuth.userInfo.nativeLanguage,
                                    configuration.projectUI.female,
                                    translation.translationObj
                                  ).text
                                }
                              </div>
                            </Option>
                            <Option key="other" value="Other">
                              <div style={{ display: "table-cell" }}>
                                <img
                                  style={{ color: "blue" }}
                                  src="/assets/third-gender.svg"
                                  alt=""
                                />{" "}
                                {
                                  getNativeLangTransText(
                                    dbAuth.userInfo.nativeLanguage,
                                    configuration.projectUI.others,
                                    translation.translationObj
                                  ).text
                                }
                              </div>
                            </Option>
                          </Select>
                          {this.state.genderError !== null ? (
                            <div style={{ color: "red" }}>
                              {this.state.genderError.text}
                            </div>
                          ) : null}
                        </Form.Item>
                      </Col>
                      {!dbAuth.userInfo.verified && (
                        <Col offset={8} xs={0} sm={0} md={8} lg={8} xl={8}>
                          <Form.Item>
                            <Button
                              type="primary"
                              htmlType="submit"
                              style={{
                                borderRadius: "24px",
                                padding: "12px",
                                height: "auto",
                                fontFamily: "Poppins-Bold",
                                fontSize: "23px",
                                color: "white",
                                border: "none",
                                boxShadow: "0px 6px 0  #2A3580",
                              }}
                              block
                            >
                              {
                                getNativeLangTransText(
                                  dbAuth.userInfo.nativeLanguage,
                                  configuration.projectUI.save,
                                  translation.translationObj
                                ).text
                              }
                            </Button>
                          </Form.Item>
                        </Col>
                      )}
                      {!dbAuth.userInfo.verified && (
                        <Col xs={24} sm={24} md={0} lg={0} xl={0}>
                          <Form.Item>
                            <Button
                              type="primary"
                              htmlType="submit"
                              style={{
                                borderRadius: "24px",
                                padding: "12px",
                                height: "auto",
                                fontFamily: "Poppins-Bold",
                                fontSize: "20px",
                                color: "white",
                                border: "none",
                                boxShadow: "0px 6px 0  #2A3580",
                              }}
                              block
                            >
                              {
                                getNativeLangTransText(
                                  dbAuth.userInfo.nativeLanguage,
                                  configuration.projectUI.save,
                                  translation.translationObj
                                ).text
                              }
                            </Button>
                          </Form.Item>
                        </Col>
                      )}
                      <Col span={8} />
                    </Row>
                  </Form>
                </Col>
                <Col span={6} />
              </Row>
              <Row>
                <Col
                  xs={0}
                  sm={0}
                  md={24}
                  lg={24}
                  xl={24}
                  className="footer-bottom"
                >
                  <BottomBar />
                </Col>
                <Col
                  xs={0}
                  sm={24}
                  md={0}
                  lg={0}
                  xl={0}
                  style={{ marginBottom: "100px" }}
                >
                  <BottomBar />
                </Col>
                <Col
                  xs={24}
                  sm={0}
                  md={0}
                  lg={0}
                  xl={0}
                  style={{ marginBottom: "70px" }}
                >
                  <BottomBar />
                </Col>
              </Row>
              <Row>
                <Col
                  xs={24}
                  sm={24}
                  md={0}
                  lg={0}
                  xl={0}
                  style={{
                    textAlign: "center",
                    borderTop: "4px solid #F1F1F1",
                  }}
                  className="footer"
                >
                  <UserDashboardNavBottomBar
                    onHandleResetLabelClick={onHandleResetLabelClick}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Fragment>
      </Spin>
    );
  }
}
const mapStateToProps = (state) => ({
  dbAuth: state.auth,
  feedback: state.feedback,
  operation: state.operation,
  translation: state.translation,
  configuration: state.configuration,
});
const mapDispatchToProps = {
  updateUserInfo,
};
export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(EditProfilePage);
