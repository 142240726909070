import {
  FEEDBACK_START,
  FEEDBACK_SUCCESS,
  FEEDBACK_FAILURE
} from "./FeedbackConstants";
import { createReducer } from "../app/common/utils/reducerUtils";

const initialState = {
  loading: false,
  feedbackSuccess: false,
  feedbackFailure: false,
  errMsg: ""
};

const feedbackStart = state => {
  return {
    ...initialState,
    loading: true
  };
};

const feedbackSuccess = state => {
  return {
    ...state,
    feedbackSuccess: true,
    loading: false
  };
};

const feedbackFailure = (state, payload) => {
  return {
    ...state,
    feedbackFailure: true,
    errMsg: payload.errMsg,
    loading: false
  };
};

export default createReducer(initialState, {
  [FEEDBACK_START]: feedbackStart,
  [FEEDBACK_SUCCESS]: feedbackSuccess,
  [FEEDBACK_FAILURE]: feedbackFailure
});
