import React, { Component, Fragment } from "react";
import "./ContentType6LessonPage.css";
import {
  _saveProgress,
  lessonPageInit,
  getEnText,
  getNativeLangTransText,
} from "../../../../SharedUtils";
import { operationTypeEnum } from "../../../../OperationTypeConstant";
import { Spin, Button, Col, Progress, Row } from "antd";
import _ from "lodash";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withFirebase } from "react-redux-firebase";
import {
  updateLessonProgress,
  updateLessonAchievementProgress,
} from "../../../../auth/authActions";
import SelfRecording from "../../../SelfRecording/SelfRecording";

let audioElement = new Audio();
let currentAudioIdx = 0;
let voiceoverBlobUrlList = [];
let opsPaused = false;

class ContentType6LessonPage extends Component {
  constructor(props) {
    super(props);
    this._saveProgress = _saveProgress.bind(this);
  }
  state = {
    targetedLessonList: [],
    currentStepNum: 0,
    isPlayingAudio: false,
    isAudioDownloadedLocally: false,
    clickDisabled: false,
    isAllLessonsCompleted: false,
  };

  componentDidMount() {
    const { course, match, dbAuth } = this.props;

    let courseId = match.params.courseId;
    let topicId = match.params.topicId;
    let subTopicId = match.params.subTopicId;
    let groupId = match.params.groupId;

    let { first, second, third } = lessonPageInit(
      course,
      dbAuth,
      courseId,
      topicId,
      subTopicId,
      groupId
    );

    this.setState({
      targetedLessonList: first,
      currentStepNum: second,
      isAllLessonsCompleted: third,
      courseId,
      topicId,
      subTopicId,
      groupId,
    });
  }

  componentWillUnmount() {
    audioElement.removeEventListener("ended", this.playNextAudio);
  }

  componentDidUpdate(prevProps) {
    const { courseId, topicId, subTopicId, groupId, completed } = this.state;
    const { operation, dbAuth, achievement } = this.props;
    if (
      !_.isEqual(prevProps.operation.isProcessing, operation.isProcessing) &&
      !operation.isProcessing
    ) {
      if (
        operation.operationType === operationTypeEnum.NO_OPS ||
        (prevProps.operation.operationType ===
          operationTypeEnum.UPDATE_LESSON_PROGRESS &&
          operation.operationType === operationTypeEnum.NO_OPS)
      ) {
        if (
          dbAuth.progressInfo.achievement &&
          dbAuth.progressInfo.achievement.lesson
        ) {
          let totaltoCompelete =
            achievement.currentWeekAchievement.lesson.totalToComplete;
          let totalCompleted = Object.entries(
            dbAuth.progressInfo.achievement.lesson
          ).length;
          if (totaltoCompelete >= totalCompleted && completed) {
            this.props.history.push(
              `/user-dashboard/courses/${courseId}/topics/${topicId}/subTopics/${subTopicId}/lessons/${groupId}/lesson_achievement`
            );
            let buttonSound = new Audio("/assets/Lesson_done.mp3");
            buttonSound.play();
          } else {
            this.props.history.push(
              `/user-dashboard/courses/${courseId}/topics/${topicId}`
            );
          }
        } else {
          this.props.history.push(
            `/user-dashboard/courses/${courseId}/topics/${topicId}`
          );
        }
      }
    }
  }
  onPreviousButtonClick = () => {
    let selectButtonSound = new Audio("/assets/Select_sound03.mp3");
    selectButtonSound.play();

    window.scrollTo(0, 0);

    audioElement.currentTime = 0;
    audioElement.pause();

    this.setState((prevState) => ({
      currentStepNum: prevState.currentStepNum - 1,
      isPlayingAudio: false,
      isAudioDownloadedLocally: false,
    }));
  };

  onNextButtonClick = () => {
    let selectButtonSound = new Audio("/assets/Select_sound03.mp3");
    selectButtonSound.play();
    const { targetedLessonList, currentStepNum } = this.state;
    if (currentStepNum === targetedLessonList.length - 1) {
      //Note : below equivalent to currentStepNumber + 1 === targetedLessonList.length
      //save full progress here...
      this._saveProgress();
    } else {
      window.scrollTo(0, 0);

      audioElement.currentTime = 0;
      audioElement.pause();

      this.setState((prevState) => ({
        currentStepNum: prevState.currentStepNum + 1,
        isPlayingAudio: false,
        isAudioDownloadedLocally: false,
      }));
    }
  };

  onUpButtonClick = () => {
    //reset audio to prevent audio resume in next re-entrance
    audioElement.currentTime = 0;
    audioElement.pause();

    this._saveProgress();
  };

  onHandleSoundIconClick = () => {
    const { targetedLessonList, currentStepNum, isAudioDownloadedLocally } =
      this.state;
    const { firebase } = this.props;
    const storageRef = firebase.storage().ref();

    if (
      audioElement.currentTime === 0 ||
      audioElement.currentTime === audioElement.duration
    ) {
      if (isAudioDownloadedLocally) {
        //TODO: try figure out NOW to avoid resume again 1st follow by opsPaused === true(reproduce via multiple click - 3 times, after 1st expression finished - tested in DD subtopics)
        //Also there's cached bug where if'll start with 2nd voiceover instead after 1st loop complete(if press play again)
        console.log("RESUME AGAIN");
        if (opsPaused) {
          opsPaused = false;

          this.setState(
            {
              clickDisabled: true,
            },
            () => {
              this.setState(
                {
                  isPlayingAudio: true,
                  clickDisabled: false,
                },
                () => {
                  this.playNextAudio();
                }
              );
            }
          );
          //   this.setState(
          //     {
          //       isPlayingAudio: true
          //     },
          //     () => this.playNextAudio()
          //   );
        } else {
          this.setState(
            {
              clickDisabled: true,
            },
            () => {
              this.setState(
                {
                  isPlayingAudio: true,
                  clickDisabled: false,
                },
                () => {
                  audioElement.src = voiceoverBlobUrlList[currentAudioIdx];
                  audioElement.play();
                }
              );
            }
          );
        }
      } else {
        console.log("NEW");
        this.setState(
          {
            clickDisabled: true,
          },
          async () => {
            let voiceoverUrlPromises = [];

            //get voice over url - start with expressions
            targetedLessonList[currentStepNum].expressions.forEach((item) => {
              if (item.voiceover.length > 0) {
                voiceoverUrlPromises.push(
                  storageRef.child(item.voiceover).getDownloadURL()
                );
              }
            });

            targetedLessonList[currentStepNum].examples.forEach((item) => {
              if (item.voiceover.length > 0) {
                voiceoverUrlPromises.push(
                  storageRef.child(item.voiceover).getDownloadURL()
                );
              }
            });

            let voiceoverUrls = await Promise.all(voiceoverUrlPromises);

            //download blob at once
            let voiceoverDownloadPromises = [];

            voiceoverUrls.forEach((vUrl) => {
              voiceoverDownloadPromises.push(
                new Promise((resolve, reject) => {
                  let xhr = new XMLHttpRequest();
                  xhr.responseType = "blob";
                  xhr.onload = function (event) {
                    if (xhr.status === 200) {
                      resolve(xhr.response);
                    } else {
                      reject(xhr.status);
                    }
                  };
                  xhr.open("GET", vUrl);
                  xhr.send();
                })
              );
            });

            //init force reset
            currentAudioIdx = 0;
            voiceoverBlobUrlList.length = 0;

            let downloadedBlobs = await Promise.all(voiceoverDownloadPromises);
            downloadedBlobs.forEach((blob) => {
              voiceoverBlobUrlList.push(URL.createObjectURL(blob));
            });

            audioElement.addEventListener("ended", this.playNextAudio);

            this.setState(
              {
                isAudioDownloadedLocally: true,
                isPlayingAudio: true,
                clickDisabled: false,
              },
              () => {
                audioElement.src = voiceoverBlobUrlList[currentAudioIdx];
                audioElement.play();
              }
            );
          }
        );
      }
    } else {
      //resume playing
      console.log("UNPAUSE1 ", audioElement.currentTime);
      console.log("UNPAUSE2 ", audioElement.duration);
      audioElement.play();
      this.setState({
        isPlayingAudio: true,
      });
    }
  };

  playNextAudio = () => {
    setTimeout(() => {
      if (
        audioElement.paused &&
        (audioElement.currentTime === 0 ||
          audioElement.currentTime === audioElement.duration) &&
        this.state.isPlayingAudio //access directly from latest state to potential old value
      ) {
        currentAudioIdx++;
        console.log("PLAY NEXT", currentAudioIdx);
        if (currentAudioIdx === voiceoverBlobUrlList.length) {
          //reset
          currentAudioIdx = 0;
          this.setState({
            isPlayingAudio: false,
          });
        } else {
          audioElement.src = voiceoverBlobUrlList[currentAudioIdx];
          audioElement.play();
        }
      } else {
        //NOTE:special flag to resume delayed operation...
        console.log("HOHO..");
        opsPaused = true;
      }
    }, 500);
  };

  onHandlePauseIconClick = () => {
    audioElement.pause();
    this.setState({
      isPlayingAudio: false,
    });
  };

  render() {
    const {
      targetedLessonList,
      currentStepNum,
      isPlayingAudio,
      clickDisabled,
    } = this.state;
    const { dbAuth, translation, operation, configuration } = this.props;

    if (targetedLessonList.length === 0) return null;

    return (
      <Fragment>
        <Spin
          tip="Saving lesson progress..."
          spinning={operation.isProcessing}
          size="large"
        >
          <Row>
            <Col xs={2} sm={2} md={3} lg={6} xl={6} xxl={6} />
            <Col
              xs={20}
              sm={20}
              md={18}
              lg={12}
              xl={12}
              xxl={12}
              style={{
                textAlign: "center",
                marginTop: "32px",
                // minHeight: "850px"
              }}
            >
              <div style={{ display: "flex" }}>
                <img
                  src="/assets/back-arrow-icon.svg"
                  alt=""
                  style={{ marginRight: "32px", cursor: "pointer" }}
                  onClick={this.onUpButtonClick}
                />
                <Progress
                  percent={
                    ((currentStepNum + 1) / targetedLessonList.length) * 100
                  }
                  status="normal"
                  showInfo={false}
                  strokeColor={{ "0%": "#82ABF7", "100%": "#4252BE" }}
                  strokeWidth="50px"
                />
              </div>
              <br />
              <br />
              <br />
              {isPlayingAudio ? (
                <img
                  src="/assets/pause-icon.svg"
                  alt=""
                  onClick={this.onHandlePauseIconClick}
                  className="sound-button"
                />
              ) : (
                <img
                  src="/assets/sound-icon.svg"
                  alt=""
                  onClick={clickDisabled ? null : this.onHandleSoundIconClick}
                  className="sound-button"
                />
              )}
              <br />
              <br />
              <br />
              <div
                style={{
                  fontFamily: "Poppins-Bold",
                  fontSize: "23px",
                  textAlign: "left",
                }}
              >
                Expression
              </div>
              <div
                style={{
                  fontSize: "23px",
                  textAlign: "left",
                }}
              >
                {targetedLessonList[currentStepNum].expressions.map(
                  (item, idx) => (
                    <Fragment key={idx}>
                      {getEnText(item.sentence, translation.translationObj)
                        .isHtml ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: getEnText(
                              item.sentence,
                              translation.translationObj
                            ).text,
                          }}
                        />
                      ) : (
                        <span style={{ fontFamily: "Poppins-Regular" }}>
                          {
                            getEnText(item.sentence, translation.translationObj)
                              .text
                          }
                        </span>
                      )}

                      {idx <
                        targetedLessonList[currentStepNum].expressions.length -
                          1 && <br />}
                    </Fragment>
                  )
                )}

                {dbAuth.userInfo.nativeLanguage !== "en" &&
                  targetedLessonList[currentStepNum].expressions.map(
                    (item, idx) => (
                      <Fragment key={idx}>
                        {getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          item.sentence,
                          translation.translationObj
                        ).isHtml ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                item.sentence,
                                translation.translationObj
                              ).text,
                            }}
                          />
                        ) : (
                          <span style={{ fontFamily: "Poppins-Regular" }}>
                            {
                              getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                item.sentence,
                                translation.translationObj
                              ).text
                            }
                          </span>
                        )}
                        {idx <
                          targetedLessonList[currentStepNum].expressions
                            .length -
                            1 && <br />}
                      </Fragment>
                    )
                  )}
              </div>
              <div
                style={{
                  fontFamily: "Poppins-Bold",
                  fontSize: "23px",
                  textAlign: "left",
                  marginTop: "16px",
                }}
              >
                Example
              </div>
              <div
                style={{
                  fontStyle: "italic",
                  fontSize: "23px",
                  textAlign: "left",
                }}
              >
                {targetedLessonList[currentStepNum].examples.map(
                  (item, idx) => (
                    <Fragment key={idx}>
                      {getEnText(item.sentence, translation.translationObj)
                        .isHtml ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: getEnText(
                              item.sentence,
                              translation.translationObj
                            ).text,
                          }}
                        />
                      ) : (
                        <span style={{ fontFamily: "Poppins-Regular" }}>
                          {
                            getEnText(item.sentence, translation.translationObj)
                              .text
                          }
                        </span>
                      )}

                      {idx <
                        targetedLessonList[currentStepNum].examples.length -
                          1 && <br />}
                    </Fragment>
                  )
                )}

                {dbAuth.userInfo.nativeLanguage !== "en" &&
                  targetedLessonList[currentStepNum].examples.map(
                    (item, idx) => (
                      <Fragment key={idx}>
                        {getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          item.sentence,
                          translation.translationObj
                        ).isHtml ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                item.sentence,
                                translation.translationObj
                              ).text,
                            }}
                          />
                        ) : (
                          <span style={{ fontFamily: "Poppins-Regular" }}>
                            {
                              getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                item.sentence,
                                translation.translationObj
                              ).text
                            }
                          </span>
                        )}
                        {idx <
                          targetedLessonList[currentStepNum].examples.length -
                            1 && <br />}
                      </Fragment>
                    )
                  )}
              </div>
            </Col>
            <Col xs={2} sm={2} md={3} lg={6} xl={6} xxl={6} />
            <Col
              span={24}
              style={{
                textAlign: "center",
                marginTop: "40px",
                marginBottom: "140px",
              }}
            >
              <SelfRecording currentStepNum={currentStepNum} />
            </Col>
            <Col
              span={24}
              style={{
                textAlign: "center",
              }}
              className="footer"
            >
              <Col
                xs={0}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                xxl={24}
                style={{ borderTop: "4px solid #F1F1F1" }}
              >
                {currentStepNum === 0 ? null : (
                  <Button
                    type="primary"
                    style={{
                      margin: "24px 10px",
                      borderRadius: "24px",
                      height: "auto",
                      fontFamily: "Poppins-Bold",
                      fontSize: "23px",
                      width: "250px",
                      boxShadow: "0px 6px 0  #2A3580",
                    }}
                    onClick={this.onPreviousButtonClick}
                  >
                    {
                      getNativeLangTransText(
                        dbAuth.userInfo.nativeLanguage,
                        configuration.projectUI.previous,
                        translation.translationObj
                      ).text
                    }
                  </Button>
                )}

                <Button
                  type="primary"
                  style={{
                    margin: "24px 0px",
                    borderRadius: "24px",
                    height: "auto",
                    fontFamily: "Poppins-Bold",
                    fontSize: "23px",
                    width: "250px",
                    boxShadow: "0px 6px 0  #2A3580",
                  }}
                  onClick={this.onNextButtonClick}
                >
                  {
                    getNativeLangTransText(
                      dbAuth.userInfo.nativeLanguage,
                      configuration.projectUI.next,
                      translation.translationObj
                    ).text
                  }
                </Button>
              </Col>
              <Col
                xs={24}
                sm={0}
                md={0}
                lg={0}
                xl={0}
                xxl={0}
                style={{ borderTop: "4px solid #F1F1F1" }}
              >
                {currentStepNum === 0 ? null : (
                  <Button
                    type="primary"
                    style={{
                      margin: "24px 10px",
                      borderRadius: "24px",
                      height: "auto",
                      fontFamily: "Poppins-Bold",
                      fontSize: "18px",
                      width: "120px",
                      boxShadow: "0px 6px 0  #2A3580",
                    }}
                    onClick={this.onPreviousButtonClick}
                  >
                    {
                      getNativeLangTransText(
                        dbAuth.userInfo.nativeLanguage,
                        configuration.projectUI.previous,
                        translation.translationObj
                      ).text
                    }
                  </Button>
                )}

                <Button
                  type="primary"
                  style={{
                    margin: "24px 0px",
                    borderRadius: "24px",
                    height: "auto",
                    fontFamily: "Poppins-Bold",
                    fontSize: "18px",
                    width: "120px",
                    boxShadow: "0px 6px 0  #2A3580",
                  }}
                  onClick={this.onNextButtonClick}
                >
                  {
                    getNativeLangTransText(
                      dbAuth.userInfo.nativeLanguage,
                      configuration.projectUI.next,
                      translation.translationObj
                    ).text
                  }
                </Button>
              </Col>
            </Col>
          </Row>
        </Spin>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  course: state.course,
  translation: state.translation,
  dbAuth: state.auth,
  operation: state.operation,
  achievement: state.achievement,
  configuration: state.configuration,
});

const mapDispatchToProps = {
  updateLessonProgress,
  updateLessonAchievementProgress,
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(ContentType6LessonPage);
