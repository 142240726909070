import React, { Component, Fragment } from "react";
import "./MultiChoiceSelectionExamQuestionPage.css";
import { Col, Row, Progress, Button, Spin } from "antd";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  loadTargetedExamGroupQuestions,
  resetTargetedExamGroupQuestions,
} from "../../../course/courseActions";
import _ from "lodash";
import { withFirebase } from "react-redux-firebase";
import { withRouter } from "react-router";
import LoadingComponent from "../../../app/common/loading/LoadingComponent";
import { updateExamProgress } from "../../../auth/authActions";
import moment from "moment";
import { _saveExamProgress } from "../../../SharedUtils";
import { operationTypeEnum } from "../../../OperationTypeConstant";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";

class MultiChoiceSelectionExamQuestionPage extends Component {
  constructor(props) {
    super(props);
    this._saveExamProgress = _saveExamProgress.bind(this);
  }

  state = {
    isLoading: true,
    dbAuth: this.props.dbAuth,
    currentStepNum: 0,
    targetedExamQuestionList: [],
    questionImageUrls: [],
    eventDate: moment.duration().add({ minutes: 10, seconds: 0 }),
    mins: 10,
    secs: 0,
    tappedAnswerIdx: -1,
    currentScore: 0,
    timerOn: false,
  };

  componentDidMount() {
    const { match } = this.props;
    let examId = match.params.examId;
    let groupId = match.params.groupId;

    this.setState(
      {
        examId,
        groupId,
      },
      () => this.props.loadTargetedExamGroupQuestions(examId, groupId)
    );
  }

  async componentDidUpdate(prevProps) {
    const { course } = this.props;
    const { dbAuth, groupId, examId } = this.state;

    if (
      !_.isEqual(prevProps.course.examQuestionObj, course.examQuestionObj) &&
      course.examQuestionObj !== null
    ) {
      let tempExamQuestionList = course.examQuestionObj.questions;
      let targetedExamQuestionList = [];
      for (let i = 1; i <= 20; i++) {
        let random = Math.floor(Math.random() * tempExamQuestionList.length);
        // console.log(tempExamQuestionList.length)
        targetedExamQuestionList.push(tempExamQuestionList[random]);
        tempExamQuestionList = tempExamQuestionList.filter(function (item) {
          return item !== tempExamQuestionList[random];
        });
      }

      var previousScore = 0;
      // console.log(previousScore)
      let testName = `test${groupId}`;
      if (dbAuth.progressInfo.exams) {
        // console.log(dbAuth.progressInfo.exams[examId][testName])
        if (dbAuth.progressInfo.exams[examId]) {
          if (dbAuth.progressInfo.exams[examId][testName]) {
            previousScore = dbAuth.progressInfo.exams[examId][testName];
          }
        }
      }
      // console.log(previousScore)
      this.setState({
        isLoading: false,
        targetedExamQuestionList,
        previousScore: previousScore,
      });
      this.updateTimer();
    }

    const { operation } = this.props;
    if (
      !_.isEqual(prevProps.operation.isProcessing, operation.isProcessing) &&
      !operation.isProcessing
    ) {
      if (
        operation.operationType === operationTypeEnum.NO_OPS ||
        (prevProps.operation.operationType ===
          operationTypeEnum.UPDATE_EXAM_PROGRESS &&
          operation.operationType === operationTypeEnum.NO_OPS)
      ) {
        this.props.history.push(`/user-dashboard/exam/${examId}`);
      }
    }
  }

  componentWillUnmount() {
    this.props.resetTargetedExamGroupQuestions();
  }

  onNextButtonClick = () => {
    let buttonSound = new Audio("/assets/sfx_button.mp3");
    buttonSound.play();
    const { currentStepNum, targetedExamQuestionList, tappedAnswerIdx, currentScore } =
      this.state;

    // var score = currentScore;
    if (targetedExamQuestionList[currentStepNum].answer === tappedAnswerIdx) {
      let score  = currentScore + (100 / targetedExamQuestionList.length);

      this.setState(
        {
          currentScore: score
        },
        () => {
          this.saveProgress();
        }
      );
    } else {
      this.saveProgress();
    }
  };

  saveProgress() {
    const { currentStepNum, targetedExamQuestionList, timer } = this.state;
    if (currentStepNum === targetedExamQuestionList.length - 1) {
      clearInterval(timer);
      this._saveExamProgress();
    } else {
      this.setState((prevState) => ({
        currentStepNum: prevState.currentStepNum + 1,
        tappedAnswerIdx: -1,
      }));
      window.scrollTo(0, 0);
    }
  };

  captureTappedAnswerIdx = (idx) => {
    this.setState({
      tappedAnswerIdx: idx,
    });
  };

  onUpButtonClick = async () => {
    const { examId, timer } = this.state;
    clearInterval(timer);
    this.props.history.push(`/user-dashboard/exam/${examId}`);
  };

  onTimesUp = () => {
    this._saveExamProgress();
  };

  updateTimer = () => {
    const timer = setInterval(() => {
      let { eventDate } = this.state;
      if (eventDate <= 0) {
        clearInterval(timer);
        confirmAlert({
          title: "Time's up!",
          message: "Sorry, you have run out of time!",
          buttons: [
            {
              label: "OK",
              onClick: () => this.onTimesUp(),
            },
          ],
        });
      } else {
        eventDate = eventDate.subtract(1, "s");
        const mins = eventDate.minutes();
        const secs = eventDate.seconds();

        this.setState({
          timer,
          mins,
          secs,
          eventDate,
        });
      }
    }, 1000);
  };

  render() {
    const {
      isLoading,
      currentStepNum,
      targetedExamQuestionList,
      questionImageUrls,
      mins,
      secs,
      tappedAnswerIdx,
    } = this.state;
    const { operation } = this.props;

    if (isLoading) return <LoadingComponent />;
    else {
      return (
        <Fragment>
          <Spin
            tip="Saving exam progress..."
            spinning={operation.isProcessing}
            size="large"
          >
            <Row>
              <Col xs={1} sm={2} md={3} lg={4} xl={6} xxl={6} />
              <Col
                xs={0}
                sm={0}
                md={0}
                lg={16}
                xl={12}
                xxl={12}
                style={{
                  textAlign: "center",
                  marginTop: "32px",
                  minHeight: "850px",
                }}
              >
                <div style={{ display: "flex" }}>
                  {/* <Link to={`/user-dashboard/exam/${examId}`}> */}
                  <img
                    src="/assets/back-arrow-icon.svg"
                    alt=""
                    style={{ marginRight: "32px", cursor: "pointer" }}
                    onClick={this.onUpButtonClick}
                  />
                  {/* </Link> */}
                  <Progress
                    percent={
                      ((currentStepNum + 1) / targetedExamQuestionList.length) *
                      100
                    }
                    status="normal"
                    showInfo={false}
                    strokeColor={{ "0%": "#82ABF7", "100%": "#4252BE" }}
                    strokeWidth="50px"
                  />
                </div>

                {mins > 2 ? (
                  <div
                    style={{
                      fontFamily: "Poppins-Regular",
                      fontSize: "20px",
                      marginTop: "12px",
                    }}
                  >
                    {/* {console.log(secs)} */}
                    {`Time left: ${
                      mins.toString().length === 1 ? `0${mins}` : mins
                    }: ${secs.toString().length === 1 ? `0${secs}` : secs}`}
                  </div>
                ) : (
                  <div
                    style={{
                      color: "red",
                      fontFamily: "Poppins-Regular",
                      fontSize: "20px",
                      marginTop: "12px",
                    }}
                  >
                    {/* {console.log(secs)} */}
                    {`Time left: ${
                      mins.toString().length === 1 ? `0${mins}` : mins
                    }: ${secs.toString().length === 1 ? `0${secs}` : secs}`}
                  </div>
                )}

                <br />
                <br />

                {questionImageUrls[currentStepNum] && (
                  <img src={questionImageUrls[currentStepNum]} alt="" />
                )}

                {!targetedExamQuestionList[currentStepNum].description.includes(
                  "<img",
                  0
                ) && (
                  <div
                    style={{
                      fontSize: "23px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html:
                        targetedExamQuestionList[currentStepNum].description,
                    }}
                  />
                )}
                {console.log(targetedExamQuestionList[currentStepNum].answer)}
                <div
                  style={{
                    fontSize: "23px",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: targetedExamQuestionList[currentStepNum].question,
                  }}
                />

                <Row>
                  {targetedExamQuestionList[currentStepNum].choices.map(
                    (item, idx) => (
                      <Fragment key={idx}>
                        <Col
                          className={
                            "contentType9-exam-mode" +
                            (tappedAnswerIdx === idx
                              ? " selected"
                              : " not-selected")
                          }
                          offset={7}
                          span={10}
                          style={{
                            fontFamily: "Poppins-Bold",
                            fontSize: "20px",
                            color: "#4252BE",
                            borderRadius: "24px",
                            border: "1px solid #4252BE",
                            marginTop: "16px",
                            padding: "8px",
                            cursor: "pointer",
                            // background: "white"
                          }}
                          onClick={() => this.captureTappedAnswerIdx(idx)}
                        >
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <span
                              style={{
                                padding: "0px 28px",
                                width: "100%",
                              }}
                            >
                              {item}
                            </span>
                          </div>
                        </Col>
                        <Col span={7} style={{ marginTop: "16px" }} />
                      </Fragment>
                    )
                  )}
                </Row>
              </Col>

              <Col
                xs={0}
                sm={20}
                md={18}
                lg={0}
                xl={0}
                xxl={0}
                style={{
                  textAlign: "center",
                  marginTop: "32px",
                  minHeight: "850px",
                }}
              >
                <div style={{ display: "flex" }}>
                  {/* <Link to={`/user-dashboard/exam/${examId}`}> */}
                  <img
                    src="/assets/back-arrow-icon.svg"
                    alt=""
                    style={{
                      marginRight: "32px",
                      cursor: "pointer",
                      width: "23px",
                    }}
                    onClick={this.onUpButtonClick}
                  />
                  {/* </Link> */}
                  <Progress
                    percent={
                      ((currentStepNum + 1) / targetedExamQuestionList.length) *
                      100
                    }
                    status="normal"
                    showInfo={false}
                    strokeColor={{ "0%": "#82ABF7", "100%": "#4252BE" }}
                    strokeWidth="40px"
                  />
                </div>

                {mins > 2 ? (
                  <div
                    style={{
                      fontFamily: "Poppins-Regular",
                      fontSize: "20px",
                      marginTop: "12px",
                    }}
                  >
                    {/* {console.log(secs)} */}
                    {`Time left: ${
                      mins.toString().length === 1 ? `0${mins}` : mins
                    }: ${secs.toString().length === 1 ? `0${secs}` : secs}`}
                  </div>
                ) : (
                  <div
                    style={{
                      color: "red",
                      fontFamily: "Poppins-Regular",
                      fontSize: "20px",
                      marginTop: "12px",
                    }}
                  >
                    {/* {console.log(secs)} */}
                    {`Time left: ${
                      mins.toString().length === 1 ? `0${mins}` : mins
                    }: ${secs.toString().length === 1 ? `0${secs}` : secs}`}
                  </div>
                )}

                <br />
                <br />

                {questionImageUrls[currentStepNum] && (
                  <img src={questionImageUrls[currentStepNum]} alt="" />
                )}

                {!targetedExamQuestionList[currentStepNum].description.includes(
                  "<img",
                  0
                ) && (
                  <div
                    style={{
                      fontSize: "21px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html:
                        targetedExamQuestionList[currentStepNum].description,
                    }}
                  />
                )}

                <div
                  style={{
                    fontSize: "21px",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: targetedExamQuestionList[currentStepNum].question,
                  }}
                />

                <Row>
                  {targetedExamQuestionList[currentStepNum].choices.map(
                    (item, idx) => (
                      <Fragment key={idx}>
                        <Col
                          className={
                            "contentType9-exam-mode" +
                            (tappedAnswerIdx === idx
                              ? " selected"
                              : " not-selected")
                          }
                          offset={5}
                          span={14}
                          style={{
                            fontFamily: "Poppins-Bold",
                            fontSize: "18px",
                            color: "#4252BE",
                            borderRadius: "24px",
                            border: "1px solid #4252BE",
                            marginTop: "16px",
                            padding: "8px",
                            cursor: "pointer",
                            // background: "white"
                          }}
                          onClick={() => this.captureTappedAnswerIdx(idx)}
                        >
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <span
                              style={{
                                padding: "0px 28px",
                                width: "100%",
                              }}
                            >
                              {item}
                            </span>
                          </div>
                        </Col>

                        <Col span={7} style={{ marginTop: "16px" }} />
                      </Fragment>
                    )
                  )}
                </Row>
              </Col>

              <Col
                xs={22}
                sm={0}
                md={0}
                lg={0}
                xl={0}
                xxl={0}
                style={{
                  textAlign: "center",
                  marginTop: "32px",
                  minHeight: "850px",
                }}
              >
                <div style={{ display: "flex" }}>
                  {/* <Link to={`/user-dashboard/exam/${examId}`}> */}
                  <img
                    src="/assets/back-arrow-icon.svg"
                    alt=""
                    style={{
                      marginRight: "32px",
                      cursor: "pointer",
                      width: "18px",
                    }}
                    onClick={this.onUpButtonClick}
                  />
                  {/* </Link> */}
                  <Progress
                    percent={
                      ((currentStepNum + 1) / targetedExamQuestionList.length) *
                      100
                    }
                    status="normal"
                    showInfo={false}
                    strokeColor={{ "0%": "#82ABF7", "100%": "#4252BE" }}
                    strokeWidth="30px"
                  />
                </div>

                {mins > 2 ? (
                  <div
                    style={{
                      fontFamily: "Poppins-Regular",
                      fontSize: "18px",
                      marginTop: "12px",
                    }}
                  >
                    {/* {console.log(secs)} */}
                    {`Time left: ${
                      mins.toString().length === 1 ? `0${mins}` : mins
                    }: ${secs.toString().length === 1 ? `0${secs}` : secs}`}
                  </div>
                ) : (
                  <div
                    style={{
                      color: "red",
                      fontFamily: "Poppins-Regular",
                      fontSize: "18px",
                      marginTop: "12px",
                    }}
                  >
                    {/* {console.log(secs)} */}
                    {`Time left: ${
                      mins.toString().length === 1 ? `0${mins}` : mins
                    }: ${secs.toString().length === 1 ? `0${secs}` : secs}`}
                  </div>
                )}

                <br />
                <br />

                {questionImageUrls[currentStepNum] && (
                  <img src={questionImageUrls[currentStepNum]} alt="" />
                )}

                {!targetedExamQuestionList[currentStepNum].description.includes(
                  "<img",
                  0
                ) && (
                  <div
                    style={{
                      fontSize: "20px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html:
                        targetedExamQuestionList[currentStepNum].description,
                    }}
                  />
                )}

                <div
                  style={{
                    fontSize: "20px",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: targetedExamQuestionList[currentStepNum].question,
                  }}
                />

                <Row>
                  {targetedExamQuestionList[currentStepNum].choices.map(
                    (item, idx) => (
                      <Fragment key={idx}>
                        <Col
                          className={
                            "contentType9-exam-mode" +
                            (tappedAnswerIdx === idx
                              ? " selected"
                              : " not-selected")
                          }
                          offset={4}
                          span={16}
                          style={{
                            fontFamily: "Poppins-Bold",
                            fontSize: "16px",
                            color: "#4252BE",
                            borderRadius: "24px",
                            border: "1px solid #4252BE",
                            marginTop: "10px",
                            padding: "8px",
                            cursor: "pointer",
                            // background: "white"
                          }}
                          onClick={() => this.captureTappedAnswerIdx(idx)}
                        >
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <span
                              style={{
                                padding: "0px 28px",
                                width: "100%",
                              }}
                            >
                              {item}
                            </span>
                          </div>
                        </Col>

                        <Col span={7} style={{ marginTop: "16px" }} />
                      </Fragment>
                    )
                  )}
                </Row>
              </Col>

              <Col
                span={24}
                style={{
                  textAlign: "center",
                  borderTop: "4px solid #F1F1F1",
                  backgroundColor: "white",
                  position: "sticky",
                  bottom: "0",
                }}
              >
                {/* <Button
              type="primary"
              style={{
                margin: "24px 0px",
                borderRadius: "24px",
                height: "auto",
                fontFamily: "Poppins-Bold",
                fontSize: "23px",
                width: "250px"
              }}
            >
              Check
            </Button> */}
                <Button
                  type="primary"
                  style={{
                    margin: "24px 0px",
                    borderRadius: "24px",
                    height: "auto",
                    fontFamily: "Poppins-Bold",
                    fontSize: "23px",
                    width: "250px",
                    boxShadow:
                      tappedAnswerIdx === -1
                        ? "0px 6px 0 #D4D4D4"
                        : "0px 6px 0 #2A3580",
                  }}
                  disabled={tappedAnswerIdx === -1}
                  onClick={this.onNextButtonClick}
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Spin>
        </Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  course: state.course,
  dbAuth: state.auth,
  operation: state.operation,
});

const mapDispatchToProps = {
  loadTargetedExamGroupQuestions,
  resetTargetedExamGroupQuestions,
  updateExamProgress,
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(MultiChoiceSelectionExamQuestionPage);
