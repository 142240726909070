import { OPERATION_STARTED, OPERATION_FAILED, OPERATION_COMPLETED } from "./operationConstant"

export const operationStarted = (operationType) => {
    return {
        type: OPERATION_STARTED,
        payload: { operationType }
    }
}

export const operationFailed = () => {
    return {
        type: OPERATION_FAILED
    }
}

export const operationCompleted = () => {
    return {
        type: OPERATION_COMPLETED
    }
}