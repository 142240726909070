import React from "react";
import Dropzone from "react-dropzone";
import { Icon, Header } from "semantic-ui-react";
import "./DropzoneInput.css";

// const DropzoneInput = () => {
//   const onDrop = useCallback(acceptedFiles => {
//     // Do something with the files
//     // console.log("TEST_DROP")
//     // console.log(acceptedFiles)
//   }, [])
//   const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

//   return (
//     <div {...getRootProps()} className={"dropzone "+ (isDragActive && "dropzoneActive")}>
//       <input {...getInputProps()} />
//       <Icon name="upload" size="huge"/>
//       <Header as="h3" content="Drop image here"/>
//     </div>
//   )
// }

// export default DropzoneInput

const DropzoneInput = ({ setLoadedImageFile, isUpLoading, dropImage }) => {
  return (
    <Dropzone
      onDrop={(acceptedFiles) => setLoadedImageFile(acceptedFiles)}
      noClick={isUpLoading}
      noDrag={isUpLoading}
      accept="image/jpeg, image/png"
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div
          {...getRootProps()}
          className={"dropzone " + (isDragActive && "dropzoneActive")}
        >
          <input {...getInputProps()} />
          <Icon name="upload" size="huge" />
          <Header as="h3" content={dropImage} />
        </div>
      )}
    </Dropzone>
  );
};

export default DropzoneInput;
