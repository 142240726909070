import React, { Component, Fragment } from "react";
import "./PreviousVideoPage.css";
import UserDashboardNavBar from "../../UserDashboardNavBar/UserDashboardNavBar";
import UserDashboardNavBottomBar from "../../UserDashboardNavBar/UserDashboardNavBottomBar";
import { Row, Col, Breadcrumb } from "antd";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import BottomBar from "../../../BottomBar/BottomBar";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";
import { withFirebase } from "react-redux-firebase";
import LoadingComponent from "../../../app/common/loading/LoadingComponent";

class PreviousVideoPage extends Component {
  state = {
    loading: true,
  };
  async componentDidMount() {
    const { firebase, achievement } = this.props;
    const storageRef = firebase.storage().ref();

    let videoPathList = [];
    achievement.videoPath.forEach(async (path) => {
      videoPathList.push(storageRef.child(path).getDownloadURL());
    });
    let videoList = await Promise.all(videoPathList);
    this.setState({
      loading: false,
      videoList,
    });
  }

  render() {
    const { onHandleResetLabelClick, achievement } = this.props;
    const { videoList, loading } = this.state;
    if (loading) return <LoadingComponent />;
    return (
      <Fragment>
        <UserDashboardNavBar
          onHandleResetLabelClick={onHandleResetLabelClick}
        />
        <Row style={{ marginBottom: "16px" }}>
          <Col offset={6} span={12}>
            <Breadcrumb
              style={{ fontFamily: "Poppins-Bold", fontSize: "22px" }}
            >
              <Breadcrumb.Item href="/user-dashboard/this-week">
                This Week
              </Breadcrumb.Item>
              <Breadcrumb.Item>Previous Videos</Breadcrumb.Item>
            </Breadcrumb>
            <Link
              style={{
                fontFamily: "Poppins-Bold",
                fontSize: "22px",
                color: "#4252BE",
                cursor: "pointer",
              }}
              to={`/user-dashboard/this-week`}
            >
              {"< Back"}
            </Link>

            <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
              {achievement.videoPath.map((num, idx) => (
                <Col span={6} key={idx}>
                  <div
                    style={{
                      position: "relative",
                      paddingTop: "56.25%",
                    }}
                  >
                    <ReactPlayer
                      url={videoList[idx]}
                      style={{ position: "absolute", top: 0, left: 0 }}
                      controls
                      width="100%"
                      height="100%"
                    />
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
          <Col span={6} />
        </Row>

        <Row>
          <Col xs={0} sm={0} md={24} lg={24} xl={24}>
            <BottomBar />
          </Col>
          <Col
            xs={0}
            sm={24}
            md={0}
            lg={0}
            xl={0}
            style={{ marginBottom: "100px" }}
          >
            <BottomBar />
          </Col>
          <Col
            xs={24}
            sm={0}
            md={0}
            lg={0}
            xl={0}
            style={{ marginBottom: "70px" }}
          >
            <BottomBar />
          </Col>
        </Row>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={0}
            lg={0}
            xl={0}
            style={{
              textAlign: "center",
              borderTop: "4px solid #F1F1F1",
            }}
            className="footer"
          >
            <UserDashboardNavBottomBar
              onHandleResetLabelClick={onHandleResetLabelClick}
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  dbAuth: state.auth,
  achievement: state.achievement,
});

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, null)
)(PreviousVideoPage);
