import { createReducer } from "../app/common/utils/reducerUtils";
import { LOAD_ALL_LANGUAGE_INFO } from "./generalConstant";

const initialState = {
  languageList: null,
  projectUI: null,
  dataInfoObj:null
};

const loadAllLanguageInfo = (state, payload) => {
  return {
    ...state,
    languageList: payload.languageList,
    projectUI: payload.projectUI,
    dataInfoObj:payload.dataInfoObj
  };
};

export default createReducer(initialState, {
  [LOAD_ALL_LANGUAGE_INFO]: loadAllLanguageInfo,
});
