import React, { Component, Fragment } from "react";
import { Row, Col, Menu, Button, Popover } from "antd";
import "./HomePage.less";
import { compose } from "redux";
import { connect } from "react-redux";
import { initDBLoad, updateLanguage } from "../auth/authActions";
import _ from "lodash";
import LoadingComponent from "../app/common/loading/LoadingComponent";
import { SnippetsOutlined, MenuOutlined } from "@ant-design/icons";

const key = "forgotPasswordNotification";
class HomePage extends Component {
  form = React.createRef();

  state = {
    isLoading: false,
    languagePopoverVisible: false,
    languageBigPopoverVisible: false,
  };

  onRegisterClick = () => {
    this.props.history.push("/register");
  };

  onLoginClick = () => {
    this.props.history.push("/login");
  };

  languagePopoverChange = (visible) => {
    this.setState({
      languagePopoverVisible: visible,
    });
  };

  languageBigPopoverChange = (visible) => {
    this.setState({
      languageBigPopoverVisible: visible,
    });
  };

  onLanguageMenuClick = async (e) => {
    console.log(this.props.dbAuth, e.key, "hello");
    if (this.props.dbAuth) {
      this.props.updateLanguage(e.key, this.props.dbAuth.selectedLanguage);

      console.log(e.key);
      this.setState({
        languagePopoverVisible: false,
        diroctoryCollapsed: false,
        // languagePopoverVisible: !this.state.languagePopoverVisible
      });
    }
  };

  onLanguageBigMenuClick = async (e) => {
    if (this.props.dbAuth) {
      this.props.updateLanguage(e.key, this.props.dbAuth.selectedLanguage);
      this.setState({
        languageBigPopoverVisible: false,
        diroctoryCollapsed: false,
      });
    }
  };

  componentDidMount() {
    const { dbAuth, history } = this.props;
    const isdbAuthenticated = dbAuth.uid && !dbAuth.isAnonymous;
    if (isdbAuthenticated) {
      history.push("/user-dashboard");
    }
  }

  render() {
    const { isLoading } = this.state;
    const { dbAuth } = this.props;
    if (isLoading) return <LoadingComponent />;
    return (
      <Fragment>
        <Row
          flex="auto"
          style={{
            backgroundImage: "url(/assets/Home/Background.png)",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <Col xs={1} md={2} />
          <Col xs={0} md={20}>
            <Row style={{ margin: "10px 0px" }}>
              <Col xs={0} md={20} flex="auto">
                <img
                  src="/assets/home-page-logo.png"
                  alt="Landing Page Row 1"
                  style={{ width: "250px" }}
                />
              </Col>

              <Col flex="fill" style={{ textAlign: "center" }}>
                <Popover
                  visible={this.state.languageBigPopoverVisible}
                  onVisibleChange={this.languageBigPopoverChange}
                  overlayClassName="home-page-popover-container-wrapper"
                  content={
                    <Menu
                      defaultSelectedKeys={dbAuth ? dbAuth.selectedLanguage : "en"}
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "16px",
                        borderRight: "none",
                      }}
                      onClick={(e) => this.onLanguageBigMenuClick(e)}
                    >
                      <Menu.Item key={"en"}>English</Menu.Item>
                      <Menu.Item key={"ms"}>Bahasa Malaysia</Menu.Item>
                      <Menu.Item key={"cs"}>简体中文</Menu.Item>
                      <Menu.Item key={"ct"}>繁體中文</Menu.Item>
                      <Menu.Item key={"in"}>Bahasa Indonesia</Menu.Item>
                    </Menu>
                  }
                  trigger="click"
                >
                  <span
                    className="top-login"
                    style={{
                      cursor: "pointer",
                      font: "18px Poppins-Bold",
                      color: "black",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      margin: "6px",
                      height: "100%",
                    }}
                  >
                    {dbAuth
                      ? dbAuth.selectedLanguage === "en"
                        ? "English"
                        : dbAuth.selectedLanguage === "ms"
                        ? "Malay"
                        : dbAuth.selectedLanguage === "cs"
                        ? "简体中文"
                        : dbAuth.selectedLanguage === "ct"
                        ? "繁體中文"
                        : dbAuth.selectedLanguage === "in"
                        ? "Indonesia"
                        : "ไทย"
                      : "English"}{" "}
                    &nbsp;&nbsp;
                    <img
                      src="/assets/Home/Dropdown-Icon.svg"
                      alt=""
                      width="20px"
                    />
                  </span>
                </Popover>
              </Col>
              <Col flex="fill" style={{ alignSelf: "center" }}>
                <Button
                  style={{
                    width: "min-content",
                    height: "fit-content",
                    padding: "11px",
                    margin: "0px 10px",
                    borderRadius: "12px",
                    backgroundColor: "transparent",
                    borderBlockColor: "black",
                    font: "18px Poppins-Bold",
                    color: "black",
                    letterSpacing: "1.5px",
                  }}
                  onClick={this.onLoginClick}
                >
                  Login
                </Button>
              </Col>
              <Col flex="fill" style={{ alignSelf: "center" }}>
                <Button
                  style={{
                    width: "min-content",
                    height: "fit-content",
                    padding: "11px",
                    margin: "0px 10px",
                    borderRadius: "12px",
                    backgroundColor: "black",
                    font: "18px Poppins-Bold",
                    color: "white",
                    letterSpacing: "1.5px",
                  }}
                  onClick={this.onRegisterClick}
                >
                  Register
                </Button>
              </Col>
            </Row>
            <Row style={{ marginTop: "2rem" }}>
              <Col xs={0} md={11}>
                <div
                  style={{
                    font: "54px Poppins-Bold",
                    color: "black",
                    marginTop: "60px",
                  }}
                >
                  Learn to understand and speak like native English speakers.
                </div>
                <div
                  style={{
                    width: "95%",
                    font: "24px Poppins-Medium",
                    color: "black",
                    lineHeight: "26px",
                    marginTop: "1.2em",
                  }}
                >
                  learn anytime, anywhere. Practice with simple listen and
                  response assessment packages that are updated continuously
                  {/* {LANDINGDESC[dbAuth.selectedLanguage]} */}
                </div>

                <Button
                  className="button-text-white"
                  onClick={this.onRegisterClick}
                >
                  Get Started
                </Button>
              </Col>
              <Col xs={0} md={1} />
              <Col
                xs={0}
                md={12}
                style={{ textAlign: "center", alignSelf: "center" }}
              >
                <img
                  src="/assets/Home/Hero-Image.png"
                  alt="Landing Page Row 1"
                  style={{ width: "80%" }}
                />
              </Col>
            </Row>
            <Row className="topbar">
              <Col flex="auto">
                <div
                  style={{
                    width: "95%",
                    font: "24px Poppins-Medium",
                    color: "black",
                    lineHeight: "26px",
                    marginTop: "1.2em",
                  }}
                >
                  &#169; 2023 reserved by BizMastery
                </div>
              </Col>

              <Col flex="fill">
                <div
                  style={{
                    font: "24px Poppins-Medium",
                    color: "black",
                    lineHeight: "26px",
                    marginTop: "1.2em",
                    marginRight: "1.2em",
                  }}
                >
                  Terms &amp; Condition
                </div>
              </Col>
              <Col flex="fill">
                <div
                  style={{
                    font: "24px Poppins-Medium",
                    color: "black",
                    lineHeight: "26px",
                    marginTop: "1.2em",
                  }}
                >
                  Privacy Policy
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={22} md={0}>
            <Row className="topbar">
              <Col flex="auto">
                <img
                  src="/assets/home-page-logo.png"
                  alt="Landing Page Row 1"
                  style={{ width: "200px" }}
                />
              </Col>

              <Col flex="fill" style={{ alignSelf: "center" }}>
                <Popover
                  overlayClassName="home-page-popover-container-wrapper"
                  content={
                    <Menu
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "16px",
                        borderRight: "none",
                      }}
                    >
                      <Menu.Item key="Login" onClick={this.onLoginClick}>
                        Login
                      </Menu.Item>
                      <Menu.Item key="Register" onClick={this.onRegisterClick}>
                        Register
                      </Menu.Item>
                      <Menu.Item key="Language">
                        <Popover
                          visible={this.state.languagePopoverVisible}
                          onVisibleChange={this.languagePopoverChange}
                          overlayClassName="home-page-popover-container-wrapper"
                          content={
                            <Menu
                              //defaultSelectedKeys={dbAuth ? dbAuth.selectedLanguage : "en"}
                              style={{
                                fontFamily: "Poppins-Regular",
                                fontSize: "16px",
                                borderRight: "none",
                              }}
                              onClick={(e) => this.onLanguageMenuClick(e)}
                            >
                              <Menu.Item key={"en"}>English</Menu.Item>
                              <Menu.Item key={"ms"}>Bahasa Malaysia</Menu.Item>
                              <Menu.Item key={"cs"}>简体中文</Menu.Item>
                              <Menu.Item key={"ct"}>繁體中文</Menu.Item>
                              <Menu.Item key={"in"}>Bahasa Indonesia</Menu.Item>
                            </Menu>
                          }
                          trigger="click"
                        >
                          <span>
                            {dbAuth
                              ? dbAuth.selectedLanguage === "en"
                                ? "English"
                                : dbAuth.selectedLanguage === "ms"
                                ? "Malay"
                                : dbAuth.selectedLanguage === "cs"
                                ? "简体中文"
                                : dbAuth.selectedLanguage === "ct"
                                ? "繁體中文"
                                : dbAuth.selectedLanguage === "in"
                                ? "Indonesia"
                                : "ไทย"
                              : "English"}{" "}
                            &nbsp;&nbsp;
                            <img
                              src="/assets/Home/Dropdown-Icon.svg"
                              alt=""
                              width="20px"
                            />
                          </span>
                        </Popover>
                      </Menu.Item>
                    </Menu>
                  }
                  trigger="click"
                >
                  <div
                    style={{
                      display: "flex",
                      cursor: "pointer",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Poppins-Bold",
                        fontSize: "23px",
                        color: "#707070",
                      }}
                    >
                      <MenuOutlined />
                    </span>
                  </div>
                </Popover>
              </Col>
            </Row>
            <hr className="divider" />
            <Row style={{ marginTop: "2rem" }}>
              <Col xs={24} lg={0}>
                <img
                  src="/assets/Home/Hero-Image.png"
                  alt="Landing Page Row 1"
                  style={{
                    width:"100%"
                  }}
                />
              </Col>
              <Col xs={24} lg={0}>
                <div
                  style={{
                    font: "34px Poppins-Bold",
                    color: "black",
                    marginTop: "60px",
                  }}
                >
                  Learn to understand and speak like native English speakers.
                </div>
                <div
                  style={{
                    width: "95%",
                    font: "24px Poppins-Medium",
                    color: "black",
                    lineHeight: "26px",
                    marginTop: "1.2em",
                  }}
                >
                  learn anytime, anywhere. Practice with simple listen and
                  response assessment packages that are updated continuously
                  {/* {LANDINGDESC[dbAuth.selectedLanguage]} */}
                </div>

                <Button
                  className="button-text-white"
                  onClick={this.onRegisterClick}
                >
                  Get Started
                </Button>
              </Col>
            </Row>
            <Row className="topbar">
              <Col flex="auto">
                <div
                  style={{
                    width: "95%",
                    font: "24px Poppins-Medium",
                    color: "black",
                    lineHeight: "26px",
                    marginTop: "1.2em",
                  }}
                >
                  &#169; 2023 reserved by BizMastery
                </div>
              </Col>

              <Col flex="fill">
                <div
                  style={{
                    font: "24px Poppins-Medium",
                    color: "black",
                    lineHeight: "26px",
                    marginTop: "1.2em",
                    marginRight: "1.2em",
                  }}
                >
                  Terms &amp; Condition
                </div>
              </Col>
              <Col flex="fill">
                <div
                  style={{
                    font: "24px Poppins-Medium",
                    color: "black",
                    lineHeight: "26px",
                    marginTop: "1.2em",
                  }}
                >
                  Privacy Policy
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={1} md={2} />
          <Col
            span={24}
            style={{
              position: "fixed",
              bottom: "0px",
              right: "50px",
              padding: "50px 0px",
            }}
          >
            <Button
              size="large"
              shape="circle"
              icon={<SnippetsOutlined />}
              style={{}}
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  dbAuth: state.auth,
});

const mapDispatchToProps = {
  initDBLoad,
  updateLanguage,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(HomePage);
