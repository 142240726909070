import React, { Component, Fragment } from "react";
import { Button, Col, Row, Progress } from "antd";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withFirebase } from "react-redux-firebase";

class LessonAchievementCompleted extends Component {
  state = {
    dbAuth: this.props.dbAuth,
  };

  async componentDidMount() {
    const { match, achievement, dbAuth } = this.props;
    let courseId = match.params.courseId;
    let topicId = match.params.topicId;

    let totalToComplete =
      achievement.currentWeekAchievement.lesson.totalToComplete;
    let totalCompleted = Object.entries(
      dbAuth.progressInfo.achievement.lesson
    ).length;
    let lessonToComplete = totalToComplete - totalCompleted;

    this.setState({
      totalToComplete,
      totalCompleted,
      lessonToComplete,
      courseId,
      topicId,
    });
  }

  componentDidUpdate(prevProps) {}

  onNextButtonClick = () => {
    const { courseId, topicId } = this.state;
    this.props.history.push(
      `/user-dashboard/courses/${courseId}/topics/${topicId}`
    );
  };

  render() {
    const { totalCompleted, totalToComplete, lessonToComplete } = this.state;
    return (
      <Fragment>
        <Row>
          <Col xs={1} sm={1} md={2} lg={4} xl={5} xxl={6} />
          <Col
            xs={0}
            sm={0}
            md={20}
            lg={16}
            xl={14}
            xxl={12}
            style={{
              textAlign: "center",
              marginTop: "32px",
              minHeight: "850px",
            }}
          >
            <br />
            <div
              style={{
                fontFamily: "Poppins-Bold",
                fontSize: "34px",
                color: "#3276F5",
                padding: "16px 48px",
                marginTop: "16px",
              }}
            >
              Lesson Completed!
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <img
              src="/assets/achievement-trophy.svg"
              alt=""
              style={{ width: "300px", height: "300px" }}
            />
            <br />
            <br />
            <Progress
              percent={(totalCompleted / totalToComplete) * 100}
              status="normal"
              showInfo={false}
              strokeColor="#FF9F29"
              strokeWidth="50px"
            />
            <br />
            <div
              style={{
                fontFamily: "Poppins-Regular",
                fontSize: "18px",
                padding: "16px 48px",
                marginTop: "16px",
              }}
            >
              {lessonToComplete === 1
                ? `Complete ${lessonToComplete} more lesson to complete your weekly goal!`
                : lessonToComplete > 0
                ? `Complete ${lessonToComplete} more lessons to complete your weekly goal!`
                : `You have completed your weekly goal for this week in lessons!`}
            </div>
          </Col>
          <Col
            xs={22}
            sm={22}
            md={0}
            lg={0}
            xl={0}
            xxl={0}
            style={{
              textAlign: "center",
              marginTop: "32px",
              minHeight: "550px",
            }}
          >
            <div
              style={{
                fontFamily: "Poppins-Bold",
                fontSize: "28px",
                color: "#3276F5",
                padding: "16px 48px",
              }}
            >
              Lesson Completed!
            </div>
            <br />
            <img
              src="/assets/achievement-trophy.svg"
              alt=""
              style={{ width: "200px", height: "200px" }}
            />
            <br />
            <br />
            <Progress
              percent={(totalCompleted / totalToComplete) * 100}
              status="normal"
              showInfo={false}
              strokeColor="#FF9F29"
              strokeWidth="30px"
            />
            <br />
            <div
              style={{
                fontFamily: "Poppins-Regular",
                fontSize: "18px",
                padding: "16px 48px",
                marginTop: "16px",
              }}
            >
              {lessonToComplete === 1
                ? `Complete ${lessonToComplete} more lesson to complete your weekly goal!`
                : lessonToComplete > 0
                ? `Complete ${lessonToComplete} more lessons to complete your weekly goal!`
                : `You have completed your weekly goal for this week in lessons!`}
            </div>
          </Col>
          {/* <Col
            span={24}
            style={{
              textAlign: "center",
              borderTop: "4px solid #F1F1F1"
            }}
          >
            <Button
              type="primary"
              style={{
                margin: "24px 0px",
                borderRadius: "24px",
                height: "auto",
                fontFamily: "Poppins-Bold",
                fontSize: "23px",
                width: "250px",
                position:"sticky",
                bottom:"0",
                boxShadow: "0px 6px 0  #2A3580"
              }}
              onClick={this.onNextButtonClick}
            >
              Back To Course
            </Button>
          </Col> */}
        </Row>
        <Row>
          <Col
            span={24}
            style={{
              textAlign: "center",
              borderTop: "4px solid #F1F1F1",
            }}
            className="footer"
          >
            <Button
              type="primary"
              style={{
                margin: "24px 0px",
                borderRadius: "24px",
                height: "auto",
                fontFamily: "Poppins-Bold",
                fontSize: "23px",
                width: "250px",
                position: "sticky",
                bottom: "0",
                boxShadow: "0px 6px 0  #2A3580",
              }}
              onClick={this.onNextButtonClick}
            >
              Back To Course
            </Button>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  dbAuth: state.auth,
  achievement: state.achievement,
});

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, null)
)(LessonAchievementCompleted);
