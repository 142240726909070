import React, { Component, Fragment } from "react";
import "./ExamPage.css";
import UserDashboardNavBar from "../../UserDashboardNavBar/UserDashboardNavBar";
import UserDashboardNavBottomBar from "../../UserDashboardNavBar/UserDashboardNavBottomBar";
import { Row, Col, Progress } from "antd";
import WeeklyGoal from "../../WeeklyGoal/WeeklyGoal";
import BottomBar from "../../../BottomBar/BottomBar";
import RetakeExamModal from "../../../modals/RetakeExamModal";
import AllTestsPassedModal from "../../../modals/AllTestsPassedModal";
import SingleTestPassedModal from "../../../modals/SingleTestPassedModal";
import TimeRunningOutModal from "../../../modals/TimeRunningOutModal";
import { getNativeLangTransText } from "../../../SharedUtils";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  loadAllExamLevels,
  resetAllExamLevels,
} from "../../../course/courseActions";
import { withRouter } from "react-router";
import { confirmAlert } from "react-confirm-alert";
import _ from "lodash";
import LoadingComponent from "../../../app/common/loading/LoadingComponent";

class ExamPage extends Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.props.loadAllExamLevels();
  }

  componentDidUpdate(prevProps) {
    const { course } = this.props;
    if (
      !_.isEqual(prevProps.course.examInfoObj, course.examInfoObj) ||
      (course.examInfoObj !== null && this.state.isLoading)
    ) {
      this.setState({
        isLoading: false,
      });
    }
  }

  componentWillUnmount() {
    this.props.resetAllExamLevels();
  }

  onExamLevelLabelClick = (examId) => {
    const { dbAuth, translation, configuration } = this.props;
    // if (dbAuth.userInfo.verified)
      this.props.history.push(`/user-dashboard/exam/${examId}`);
    // else {
    //   confirmAlert({
    //     title: getNativeLangTransText(
    //       dbAuth.userInfo.nativeLanguage,
    //       configuration.projectUI.account_not_verified,
    //       translation.translationObj
    //     ).text,
    //     message: getNativeLangTransText(
    //       dbAuth.userInfo.nativeLanguage,
    //       configuration.projectUI.please_verify_your_details,
    //       translation.translationObj
    //     ).text,
    //     buttons: [
    //       {
    //         label: getNativeLangTransText(
    //           dbAuth.userInfo.nativeLanguage,
    //           configuration.projectUI.ok,
    //           translation.translationObj
    //         ).text,
    //         onClick: () => this.onVerifyClick(),
    //       },
    //     ],
    //   });
    // }
  };

  onVerifyClick = () => {
    this.props.history.push(`/user-dashboard/profile`);
  };

  render() {
    const { isLoading } = this.state;
    const {
      course,
      onHandleResetLabelClick,
      dbAuth,
      translation,
      configuration,
    } = this.props;

    if (isLoading) return <LoadingComponent />;

    return (
      <Fragment>
        <RetakeExamModal />
        <AllTestsPassedModal />
        <SingleTestPassedModal />
        <TimeRunningOutModal />
        <UserDashboardNavBar
          onHandleResetLabelClick={onHandleResetLabelClick}
        />
        <Row>
          <Col className="page-container" span={24}>
            <Row style={{ marginBottom: "16px" }}>
              <Col offset={1} span={22} className="content-wrap">
                <Row>
                  <Col xs={2} sm={4} md={1} lg={2} xl={5} xxl={6} />
                  <Col xs={20} sm={16} md={11} lg={10} xl={6} xxl={5}>
                    <Row>
                      <Col span={24}>
                        <Row align="middle">
                          <Col flex="80px">
                            <img
                              src="/assets/owl-watching-icon.svg"
                              alt=""
                              style={{ width: "100%" }}
                            />
                          </Col>
                          <Col flex="auto" style={{ marginLeft: "8px" }}>
                            <div
                              style={{
                                fontFamily: "Poppins-Bold",
                                fontSize: "35px",
                                lineHeight: "normal",
                              }}
                            >
                              {
                                getNativeLangTransText(
                                  dbAuth.userInfo.nativeLanguage,
                                  configuration.projectUI.exam,
                                  translation.translationObj
                                ).text
                              }
                            </div>
                            <div
                              style={{
                                fontFamily: "Poppins-Regular",
                                fontSize: "23px",
                                lineHeight: "normal",
                              }}
                            >
                              {
                                getNativeLangTransText(
                                  dbAuth.userInfo.nativeLanguage,
                                  configuration.projectUI
                                    .aim_for_the_certificates,
                                  translation.translationObj
                                ).text
                              }
                            </div>
                          </Col>
                        </Row>
                      </Col>

                      <Col
                        span={24}
                        style={{ marginTop: "32px", textAlign: "center" }}
                      >
                        {/* {console.log(course.examInfoObj.examInfoList)} */}
                        {course.examInfoObj.examInfoList.map((item, idx) => {
                          if (dbAuth.progressInfo.exams) {
                            if (
                              Object.keys(dbAuth.progressInfo.exams).includes(
                                item.docId
                              )
                            ) {
                              for (const [topic, object] of Object.entries(
                                dbAuth.progressInfo.exams
                              )) {
                                var completedTest = 0;
                                if (topic === item.docId) {
                                  for (const mark of Object.values(object)) {
                                    if (mark > 49) {
                                      completedTest += 1;
                                    }
                                  }
                                  return (
                                    <Fragment key={idx}>
                                      <div
                                        style={{
                                          boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                                          borderRadius: "16px",
                                          padding: "16px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          this.onExamLevelLabelClick(item.docId)
                                        }
                                      >
                                        <div
                                          style={{
                                            fontFamily: "Poppins-Regular",
                                            fontSize: "20px",
                                            lineHeight: "normal",
                                          }}
                                        >
                                          {
                                            getNativeLangTransText(
                                              dbAuth.userInfo.nativeLanguage,
                                              configuration.projectUI.level,
                                              translation.translationObj
                                            ).text
                                          }{" "}
                                          {idx + 1}
                                        </div>
                                        {completedTest === item.tests.length ? (
                                          <div
                                            style={{
                                              fontFamily: "Poppins-Regular",
                                              fontSize: "14px",
                                              lineHeight: "normal",
                                            }}
                                          >
                                            {
                                              getNativeLangTransText(
                                                dbAuth.userInfo.nativeLanguage,
                                                configuration.projectUI
                                                  .completed,
                                                translation.translationObj
                                              ).text
                                            }
                                          </div>
                                        ) : (
                                          <Progress
                                            percent={
                                              (completedTest /
                                                item.tests.length) *
                                              100
                                            }
                                            status="normal"
                                            showInfo={false}
                                            strokeColor="#82ABF7"
                                          />
                                        )}
                                        {completedTest === item.tests.length ? (
                                          <div
                                            style={{
                                              fontFamily: "Poppins-Bold",
                                              fontSize: "18px",
                                              color: "#82ABF7",
                                            }}
                                          >
                                            View
                                          </div>
                                        ) : (
                                          <div
                                            style={{
                                              fontFamily: "Poppins-Regular",
                                              fontSize: "14px",
                                              lineHeight: "normal",
                                            }}
                                          >
                                            {getNativeLangTransText(
                                              dbAuth.userInfo.nativeLanguage,
                                              configuration.projectUI
                                                .completed_exams,
                                              translation.translationObj
                                            )
                                              .text.replace("%a", completedTest)
                                              .replace("%b", item.tests.length)}
                                          </div>
                                        )}
                                      </div>
                                      <br />
                                    </Fragment>
                                  );
                                }
                              }
                            } else {
                              return (
                                <Fragment key={idx}>
                                  <div
                                    style={{
                                      boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                                      borderRadius: "16px",
                                      padding: "16px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      this.onExamLevelLabelClick(item.docId)
                                    }
                                  >
                                    <div
                                      style={{
                                        fontFamily: "Poppins-Regular",
                                        fontSize: "20px",
                                        lineHeight: "normal",
                                      }}
                                    >
                                      {
                                        getNativeLangTransText(
                                          dbAuth.userInfo.nativeLanguage,
                                          configuration.projectUI.level,
                                          translation.translationObj
                                        ).text
                                      }{" "}
                                      {idx + 1}
                                    </div>
                                    <Progress
                                      percent={0}
                                      status="normal"
                                      showInfo={false}
                                      strokeColor="#82ABF7"
                                    />
                                    <div
                                      style={{
                                        fontFamily: "Poppins-Regular",
                                        fontSize: "14px",
                                        lineHeight: "normal",
                                      }}
                                    >
                                      {getNativeLangTransText(
                                        dbAuth.userInfo.nativeLanguage,
                                        configuration.projectUI.completed_exams,
                                        translation.translationObj
                                      )
                                        .text.replace("%a", 0)
                                        .replace("%b", item.tests.length)}
                                    </div>
                                  </div>
                                  <br />
                                </Fragment>
                              );
                            }
                          } else {
                            return (
                              <Fragment key={idx}>
                                <div
                                  style={{
                                    boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                                    borderRadius: "16px",
                                    padding: "16px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.onExamLevelLabelClick(item.docId)
                                  }
                                >
                                  <div
                                    style={{
                                      fontFamily: "Poppins-Regular",
                                      fontSize: "20px",
                                      lineHeight: "normal",
                                    }}
                                  >
                                    {
                                      getNativeLangTransText(
                                        dbAuth.userInfo.nativeLanguage,
                                        configuration.projectUI.level,
                                        translation.translationObj
                                      ).text
                                    }{" "}
                                    {idx + 1}
                                  </div>
                                  <Progress
                                    percent={0}
                                    status="normal"
                                    showInfo={false}
                                    strokeColor="#82ABF7"
                                  />
                                  <div
                                    style={{
                                      fontFamily: "Poppins-Regular",
                                      fontSize: "14px",
                                      lineHeight: "normal",
                                    }}
                                  >
                                    {getNativeLangTransText(
                                      dbAuth.userInfo.nativeLanguage,
                                      configuration.projectUI.completed_exams,
                                      translation.translationObj
                                    )
                                      .text.replace("%a", 0)
                                      .replace("%b", item.tests.length)}
                                  </div>
                                </div>
                                <br />
                              </Fragment>
                            );
                          }
                        })}
                      </Col>
                      <Col span={8} />
                    </Row>
                    {/* <ExamOverview /> */}
                    {/* <ExamLevelDetails /> */}
                  </Col>

                  <Col xs={3} sm={5} md={1} lg={1} xl={1} xxl={1} />
                  <Col xs={18} sm={14} md={10} lg={9} xl={7} xxl={6}>
                    <WeeklyGoal />
                  </Col>
                </Row>
              </Col>
              <Col span={6} />
            </Row>
            <Row>
              <Col
                xs={0}
                sm={0}
                md={24}
                lg={24}
                xl={24}
                className="footer-bottom"
              >
                <BottomBar />
              </Col>
              <Col
                xs={0}
                sm={24}
                md={0}
                lg={0}
                xl={0}
                style={{ marginBottom: "100px" }}
              >
                <BottomBar />
              </Col>
              <Col
                xs={24}
                sm={0}
                md={0}
                lg={0}
                xl={0}
                style={{ marginBottom: "70px" }}
              >
                <BottomBar />
              </Col>
            </Row>
            <Row>
              <Col
                xs={24}
                sm={24}
                md={0}
                lg={0}
                xl={0}
                style={{
                  textAlign: "center",
                  borderTop: "4px solid #F1F1F1",
                }}
                className="footer"
              >
                <UserDashboardNavBottomBar
                  onHandleResetLabelClick={onHandleResetLabelClick}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  course: state.course,
  dbAuth: state.auth,
  translation: state.translation,
  configuration: state.configuration,
});

const mapDispatchToProps = {
  loadAllExamLevels,
  resetAllExamLevels,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ExamPage);
