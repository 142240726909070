import React, { Component, Fragment } from "react";
import UserDashboardNavBar from "../../UserDashboardNavBar/UserDashboardNavBar";
import UserDashboardNavBottomBar from "../../UserDashboardNavBar/UserDashboardNavBottomBar";
import { Row, Col, Form, Input, Button, notification } from "antd";
import BottomBar from "../../../BottomBar/BottomBar";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";
import { withFirebase } from "react-redux-firebase";
import {
  LoadingOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import { sendFeedback } from "../../../feedback/FeedbackAction";

class GiveUsFeedBackPage extends Component {
  state = { reset: false };

  form = React.createRef();

  handleSubmit = () => {
    const { dbAuth } = this.props;
    this.form.current.validateFields().then((values) => {
      notification.open({
        key,
        message: "Sending feedback",
        duration: 0,
        icon: <LoadingOutlined />,
      });
      console.log(values.feedback, dbAuth.userInfo.email);
      this.props.sendFeedback(values.feedback, dbAuth.userInfo.email);
      this.setState({
        reset: true,
      });
    });
  };

  componentDidUpdate() {
    const { feedback } = this.props;
    // console.log(this.props)
    if (!feedback.loading && feedback.feedbackSuccess && this.state.reset) {
      notification.open({
        key,
        message: "Success",
        description: "Successfully sent a feedback",
        icon: (
          <CheckCircleTwoTone
            twoToneColor="
        #52c41a"
          />
        ),
      });
      this.form.current.resetFields();
      this.setState({
        reset: false,
      });
    } else if (
      !feedback.loading &&
      feedback.feedbackFailure &&
      this.state.reset
    ) {
      notification.open({
        key,
        message: "Failed",
        description: feedback.errMsg,
        duration: 3,
        icon: (
          <CloseCircleTwoTone
            twoToneColor="
        #ff5255"
          />
        ),
      });
      this.form.current.resetFields();
      this.setState({
        reset: false,
      });
    }
  }

  render() {
    const { onHandleResetLabelClick, feedback } = this.props;
    return (
      <Fragment>
        <UserDashboardNavBar
          onHandleResetLabelClick={onHandleResetLabelClick}
        />
        <Row style={{ marginBottom: "16px" }}>
          <Col offset={6} span={12}>
            <div
              style={{
                fontFamily: "Poppins-Bold",
                fontSize: "35px",
                lineHeight: "normal",
              }}
            >
              Give Us Feedback
            </div>
            <br />
            <br />
            <div
              style={{
                fontFamily: "Poppins-Bold",
                fontSize: "23px",
                lineHeight: "normal",
              }}
            >
              How are we doing?
            </div>
            <br />
            <div
              style={{
                fontFamily: "Poppins-Regular",
                fontSize: "23px",
                lineHeight: "normal",
              }}
            >
              We’re always working to improve the app experience, so we’d love
              to hear what’s working and how we can do better.
            </div>
            <br />
            <Form
              ref={this.form}
              autoComplete="off"
              style={{ textAlign: "center" }}
              onFinish={this.handleSubmit}
            >
              <Form.Item
                name="feedback"
                rules={[
                  {
                    required: true,
                    message: "Empty message!",
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="Type your message here"
                  autoSize={{ minRows: 8, maxRows: 8 }}
                  maxLength={1000}
                />
              </Form.Item>
              <Button
                type="primary"
                style={{
                  borderRadius: "24px",
                  padding: "12px",
                  height: "auto",
                  fontFamily: "Poppins-Bold",
                  fontSize: "23px",
                  color: "white",
                  border: "none",
                  width: "200px",
                  boxShadow: "0px 6px 0  #2A3580",
                }}
                htmlType="submit"
                disabled={feedback.loading}
              >
                Submit
              </Button>
            </Form>
          </Col>
          <Col span={6} />
        </Row>
        <Row>
          <Col xs={0} sm={0} md={24} lg={24} xl={24}>
            <BottomBar />
          </Col>
          <Col
            xs={0}
            sm={24}
            md={0}
            lg={0}
            xl={0}
            style={{ marginBottom: "100px" }}
          >
            <BottomBar />
          </Col>
          <Col
            xs={24}
            sm={0}
            md={0}
            lg={0}
            xl={0}
            style={{ marginBottom: "70px" }}
          >
            <BottomBar />
          </Col>
        </Row>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={0}
            lg={0}
            xl={0}
            style={{
              textAlign: "center",
              borderTop: "4px solid #F1F1F1",
            }}
            className="footer"
          >
            <UserDashboardNavBottomBar
              onHandleResetLabelClick={onHandleResetLabelClick}
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const key = "feedbackNotification";

const mapStateToProps = (state) => ({
  dbAuth: state.auth,
  feedback: state.feedback,
});
const mapDispatchToProps = {
  sendFeedback,
};
export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(GiveUsFeedBackPage);
