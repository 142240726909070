import React, { Component, Fragment } from "react";
import "./BottomBar.css";
import { Row, Col, Divider } from "antd";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getNativeLangTransText } from "../SharedUtils";

class BottomBar extends Component {
  render() {
    const { translation, dbAuth, configuration } = this.props;
    return (
      <Fragment>
        <Row>
          <Col span={24} style={{ background: "#F2F2F2", textAlign: "center" }}>
            {/* <img
              src="/assets/facebook-icon.svg"
              alt=""
              style={{ margin: "16px" }}
            />
            <img
              src="/assets/pinterest-icon.svg"
              alt=""
              style={{ margin: "16px" }}
            />
            <img
              src="/assets/instagram-icon.svg"
              alt=""
              style={{ margin: "16px" }}
            /> */}
            <Col
              span={24}
              style={{
                fontFamily: "Poppins-Regular",
                fontSize: "20px",
                textAlign: "center",
                margin: "8px 0px",
              }}
            >
              @InfiniJunction Sdn Bhd
            </Col>
          </Col>
          {/* <Col
            span={24}
            style={{
              fontFamily: "Poppins-Regular",
              fontSize: "20px",
              textAlign: "center",
              margin: "8px 0px"
            }}
          >
            @InfiniJunction Sdn Bhd
          </Col> */}

          <Col
            span={24}
            style={{
              fontFamily: "Poppins-Regular",
              fontSize: "20px",
              textAlign: "center",
              margin: "8px 0px",
            }}
          >
           
            <Link to={`/privacy-policy`}>
              {dbAuth.userInfo
                ? getNativeLangTransText(
                    dbAuth.userInfo.nativeLanguage,
                    configuration.projectUI.privacy_policy,
                    translation.translationObj
                  ).text
                : "Privacy Policy"}
            </Link>
            <Divider type="vertical" style={{ backgroundColor: "black" }} />
            <Link to={`/terms-condition`}>
              {dbAuth.userInfo
                ? getNativeLangTransText(
                    dbAuth.userInfo.nativeLanguage,
                    configuration.projectUI.terms_conditions,
                    translation.translationObj
                  ).text
                : "Terms and Conditions "}
            </Link>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  translation: state.translation,
  dbAuth: state.auth,
  configuration: state.configuration,
});

export default compose(connect(mapStateToProps))(BottomBar);
