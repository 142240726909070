import {
  FEEDBACK_START,
  FEEDBACK_SUCCESS,
  FEEDBACK_FAILURE
} from "./FeedbackConstants";

export const sendFeedback = (feedback, email) => {
  return async (dispatch, getState, { getFirebase }) => {
    dispatch({ type: FEEDBACK_START });
    const db =  getFirebase().firestore();

    db.collection("feedbacks").doc()
      .set({createdAt: new Date(), message: feedback, email:email})
      .then(() => {
        dispatch({ type: FEEDBACK_SUCCESS });
      })
      .catch(err => {
        console.log(err);
        dispatch({
          type: FEEDBACK_FAILURE,
          payload: {
            errMsg:
              "Error occured while sending feeback. Please try again later."
          }
        });
      });
  };
};
