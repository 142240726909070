import { LOAD_ACHIEVEMENT_DATA } from "./achievementConstant";
import { loadUserInfo } from "../auth/authActions";
export const loadAchievementsData = (userAchievementProgress, userData) => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();
    try {
      // console.log("start")
      let achievementSnap = await db.collection("achievements").get();
      //create own internal map ...
      let videoPath = [];
      let currentWeekAchievement = {};

      achievementSnap.forEach(async achievementDoc => {
        let achievement = achievementDoc.data();
        if (userAchievementProgress) {
          let currentId = 1;

          if (userAchievementProgress.currentAchievementId)
            currentId = userAchievementProgress.currentAchievementId.replace(
              "A",
              ""
            );

          let currentUserAchivementIdInInt = parseInt(currentId);
          if (currentUserAchivementIdInInt >= achievement.order) {
            videoPath.push(achievement.video.path);
          }

          if (userAchievementProgress.currentAchievementCompleted) {
            let userDate = new Date(userData.createdAt.seconds * 1000);
            console.log(userDate);
            let currentDate = new Date();
            let totalWeeks = weeks_between(currentDate, userDate);

            var nextAchievement = 0;
            if (totalWeeks > currentUserAchivementIdInInt) {
              nextAchievement = currentUserAchivementIdInInt + 1;
            } else {
              nextAchievement = currentUserAchivementIdInInt;
            }

            if (nextAchievement > achievementSnap.size) {
              await db
                .doc(`users/${getState().firebase.auth.uid}/progress/progress`)
                .set(
                  {
                    achievement: (userAchievementProgress.allCompleted = true)
                  },
                  { merge: true }
                );
              currentWeekAchievement = {
                ...achievementSnap[achievementSnap.size - 1].data(),
                docId: achievementDoc.id
              };
            } else {
              if (achievement.order === nextAchievement) {
                let updatedAt = new Date();
                let newUserAchievement = {
                  updatedAt,
                  currentAchievementId: `A${achievement.order}`,
                  lesson: {},
                  quiz: {},
                  currentAchievementCompleted: false,
                  videoWatched: false
                };
                await db
                  .doc(
                    `users/${getState().firebase.auth.uid}/progress/progress`
                  )
                  .set({ achievement: newUserAchievement }, { merge: true });
                await dispatch(loadUserInfo(userData.docId));
                currentWeekAchievement = {
                  ...achievement,
                  docId: achievementDoc.id
                };
              }
            }
          } else {
            if (achievement.order === currentUserAchivementIdInInt) {
              currentWeekAchievement = {
                ...achievement,
                docId: achievementDoc.id
              };
            }
          }
        } else {
          if (achievement.order === 1) {
            currentWeekAchievement = {
              ...achievement,
              docId: achievementDoc.id
            };
            videoPath.push(achievement.video.path);
          }
        }
      });
      //   console.log(achievementObject)
      dispatch({
        type: LOAD_ACHIEVEMENT_DATA,
        payload: { videoPath, currentWeekAchievement }
      });
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
};

function weeks_between(date1, date2) {
  // The number of milliseconds in one week
  var ONE_WEEK = 1000 * 60 * 60 * 24 * 7;
  // Convert both dates to milliseconds
  var date1_ms = date1.getTime();
  var date2_ms = date2.getTime();
  // Calculate the difference in milliseconds
  var difference_ms = Math.abs(date1_ms - date2_ms);
  // Convert back to weeks and return hole weeks
  return Math.floor(difference_ms / ONE_WEEK);
}
