import React, { Component, Fragment } from "react";
import "./ContentType5LessonPage.css";
import { _saveProgress, lessonPageInit } from "../../../../SharedUtils";
import { operationTypeEnum } from "../../../../OperationTypeConstant";
import _ from "lodash";
import { Button, Col, Row, Progress, Spin } from "antd";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  updateLessonProgress,
  updateLessonAchievementProgress,
} from "../../../../auth/authActions";
import SelfRecording from "../../../SelfRecording/SelfRecording";
import { withRouter } from "react-router";
import { withFirebase } from "react-redux-firebase";
import { getNativeLangTransText } from "../../../../SharedUtils";

let audioElement = new Audio();
let voiceoverBlobUrlList = [];

class ContentType5LessonPage extends Component {
  constructor(props) {
    super(props);
    this._saveProgress = _saveProgress.bind(this);
  }

  state = {
    targetedLessonList: [],
    currentStepNum: 0,
    isAudioDownloadedLocally: false,
    clickDisabled: false,
    isAllLessonsCompleted: false,
    achievement: this.props.achievement,
    dbAuth: this.props.dbAuth,
  };

  async componentDidMount() {
    const { course, match, dbAuth, firebase } = this.props;
    const storageRef = firebase.storage().ref();

    let courseId = match.params.courseId;
    let topicId = match.params.topicId;
    let subTopicId = match.params.subTopicId;
    let groupId = match.params.groupId;

    let { first, second, third } = lessonPageInit(
      course,
      dbAuth,
      courseId,
      topicId,
      subTopicId,
      groupId
    );

    //load image
    let imageUrlPromises = [];
    course.lessonInfoObj.lessonList.forEach((item) => {
      imageUrlPromises.push(storageRef.child(item.imagePath).getDownloadURL());
    });

    let imageUrls = await Promise.all(imageUrlPromises);

    first.forEach((item, idx) => {
      item.imageUrl = imageUrls[idx];
    });

    this.setState({
      targetedLessonList: first,
      currentStepNum: second,
      isAllLessonsCompleted: third,
      courseId,
      topicId,
      subTopicId,
      groupId,
    });
  }

  componentWillUnmount() {
    audioElement.removeEventListener("ended", this.playNextAudio);
  }

  componentDidUpdate(prevProps) {
    const { courseId, topicId, subTopicId, groupId, achievement, completed } =
      this.state;
    const { operation, dbAuth } = this.props;
    // console.log(this.state);
    if (
      !_.isEqual(prevProps.operation.isProcessing, operation.isProcessing) &&
      !operation.isProcessing &&
      dbAuth.progressInfo
    ) {
      if (
        operation.operationType === operationTypeEnum.NO_OPS ||
        (prevProps.operation.operationType ===
          operationTypeEnum.UPDATE_LESSON_PROGRESS &&
          operation.operationType === operationTypeEnum.NO_OPS)
      ) {
        if (
          dbAuth.progressInfo.achievement &&
          dbAuth.progressInfo.achievement.lesson
        ) {
          let totaltoCompelete =
            achievement.currentWeekAchievement.lesson.totalToComplete;
          let totalCompleted = Object.entries(
            dbAuth.progressInfo.achievement.lesson
          ).length;
          if (totaltoCompelete >= totalCompleted && completed) {
            this.props.history.push(
              `/user-dashboard/courses/${courseId}/topics/${topicId}/subTopics/${subTopicId}/lessons/${groupId}/lesson_achievement`
            );
            let buttonSound = new Audio("/assets/Lesson_done.mp3");
            buttonSound.play();
          } else {
            this.props.history.push(
              `/user-dashboard/courses/${courseId}/topics/${topicId}`
            );
          }
        } else {
          this.props.history.push(
            `/user-dashboard/courses/${courseId}/topics/${topicId}`
          );
        }
      }
    }
  }

  onPreviousButtonClick = () => {
    let selectButtonSound = new Audio("/assets/Select_sound03.mp3");
    selectButtonSound.play();
    window.scrollTo(0, 0);

    audioElement.currentTime = 0;
    audioElement.pause();

    this.setState((prevState) => ({
      currentStepNum: prevState.currentStepNum - 1,
      isAudioDownloadedLocally: false,
    }));
  };

  onNextButtonClick = () => {
    let selectButtonSound = new Audio("/assets/Select_sound03.mp3");
    selectButtonSound.play();
    const { targetedLessonList, currentStepNum } = this.state;
    if (currentStepNum === targetedLessonList.length - 1) {
      //Note : below equivalent to currentStepNumber + 1 === targetedLessonList.length
      //save full progress here...
      // console.log(this.state)
      this._saveProgress();
    } else {
      window.scrollTo(0, 0);

      audioElement.currentTime = 0;
      audioElement.pause();

      this.setState((prevState) => ({
        currentStepNum: prevState.currentStepNum + 1,
        isAudioDownloadedLocally: false,
      }));
    }
  };

  onUpButtonClick = () => {
    //reset audio to prevent audio resume in next re-entrance
    audioElement.currentTime = 0;
    audioElement.pause();

    this._saveProgress();
  };

  _isConsonantOrVowelPhonic = (phonicGroupCharacter, text) => {
    let isPhonic = true;

    let vowelList = ["a", "e", "i", "o", "u"];
    //if false, it's consonant
    if (!vowelList.some((item) => item === phonicGroupCharacter)) {
      //check if "consonant" phonic contains ANY vowel, if yes, ignore that...
      vowelList.forEach((vowel) => {
        if (text.includes(vowel)) {
          isPhonic = false;
        }
      });
    }
    return isPhonic;
  };

  onHandleSoundIconClick = (clickedIdx) => {
    const { targetedLessonList, currentStepNum, isAudioDownloadedLocally } =
      this.state;
    const { firebase } = this.props;
    const storageRef = firebase.storage().ref();

    if (
      audioElement.currentTime === 0 ||
      audioElement.currentTime === audioElement.duration
    ) {
      if (isAudioDownloadedLocally) {
        this.setState(
          {
            clickDisabled: true,
          },
          () => {
            this.setState(
              {
                clickDisabled: false,
              },
              () => {
                audioElement.src = voiceoverBlobUrlList[clickedIdx];
                audioElement.play();
              }
            );
          }
        );
      } else {
        this.setState(
          {
            clickDisabled: true,
          },
          async () => {
            let voiceoverUrlPromises = [];

            targetedLessonList[currentStepNum].contents.forEach((item) => {
              if (item.voiceover.length > 0) {
                voiceoverUrlPromises.push(
                  storageRef.child(item.voiceover).getDownloadURL()
                );
              } else {
                //hack with dummy promises
                voiceoverUrlPromises.push(
                  new Promise((resolve, _) => {
                    resolve("N/A");
                  })
                );
              }
            });

            let voiceoverUrls = await Promise.all(voiceoverUrlPromises);

            //download blob at once
            let voiceoverDownloadPromises = [];

            voiceoverUrls.forEach((vUrl) => {
              if (vUrl !== "N/A") {
                voiceoverDownloadPromises.push(
                  new Promise((resolve, reject) => {
                    let xhr = new XMLHttpRequest();
                    xhr.responseType = "blob";
                    xhr.onload = function (event) {
                      if (xhr.status === 200) {
                        resolve(xhr.response);
                      } else {
                        reject(xhr.status);
                      }
                    };
                    xhr.open("GET", vUrl);
                    xhr.send();
                  })
                );
              } else {
                //dummy promise again
                voiceoverDownloadPromises.push(
                  new Promise((resolve, _) => {
                    resolve("N/A");
                  })
                );
              }
            });

            voiceoverBlobUrlList.length = 0;

            let downloadedBlobs = await Promise.all(voiceoverDownloadPromises);
            downloadedBlobs.forEach((blob, idx) => {
              if (blob !== "N/A") {
                voiceoverBlobUrlList.push(URL.createObjectURL(blob));
              } else {
                voiceoverBlobUrlList.push("N/A");
              }
            });

            this.setState(
              {
                isAudioDownloadedLocally: true,
                clickDisabled: false,
              },
              () => {
                if (voiceoverBlobUrlList[clickedIdx] !== "N/A") {
                  audioElement.src = voiceoverBlobUrlList[clickedIdx];
                  audioElement.play();
                } else {
                  //ignore intentionally
                }
              }
            );
          }
        );
      }
    }
  };

  onHandlePauseIconClick = () => {
    audioElement.pause();
  };

  render() {
    const { subTopicId, targetedLessonList, currentStepNum, clickDisabled } =
      this.state;
    const { operation, dbAuth, translation, configuration } = this.props;

    if (targetedLessonList.length === 0) return null;

    return (
      <Fragment>
        <Spin
          tip="Saving lesson progress..."
          spinning={operation.isProcessing}
          size="large"
        >
          <Row>
            <Col
              offset={6}
              span={12}
              style={{
                textAlign: "center",
                marginTop: "32px",
                // minHeight: "850px"
              }}
            >
              <div style={{ display: "flex" }}>
                {/* <Link
                to={`/user-dashboard/courses/${courseId}/topics/${topicId}/subTopics`}
              >
                <img
                  src="/assets/back-arrow-icon.svg"
                  alt=""
                  style={{ marginRight: "32px", cursor: "pointer" }}
                />
              </Link> */}
                <img
                  src="/assets/back-arrow-icon.svg"
                  alt=""
                  style={{ marginRight: "32px", cursor: "pointer" }}
                  onClick={this.onUpButtonClick}
                />

                <Progress
                  percent={
                    ((currentStepNum + 1) / targetedLessonList.length) * 100
                  }
                  status="normal"
                  showInfo={false}
                  strokeColor={{ "0%": "#82ABF7", "100%": "#4252BE" }}
                  strokeWidth="50px"
                />
              </div>

              <Col xs={0} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <img
                  src={targetedLessonList[currentStepNum].imageUrl}
                  alt=""
                  style={{
                    width: "290px",
                    height: "250px",
                    marginTop: "40px",
                    marginBottom: "90px",
                  }}
                />
              </Col>
              <Col xs={24} sm={0} md={0} lg={0} xl={0} xxl={0}>
                <img
                  src={targetedLessonList[currentStepNum].imageUrl}
                  alt=""
                  style={{
                    width: "100%",
                    marginTop: "40px",
                    marginBottom: "40px",
                  }}
                />
              </Col>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  //marginBottom: currentStepNum === 0 ? "120px" : "240px"
                }}
              >
                {targetedLessonList[currentStepNum].contents.map(
                  (item, idx) => {
                    if (
                      targetedLessonList[currentStepNum].contents.length > 1 &&
                      item.text.includes(subTopicId.substring(1, 2), 0) &&
                      this._isConsonantOrVowelPhonic(
                        subTopicId.substring(1, 2),
                        item.text
                      )
                    ) {
                      return (
                        <div key={idx}>
                          <img
                            src="/assets/sound-icon.svg"
                            alt=""
                            onClick={
                              clickDisabled
                                ? null
                                : () => this.onHandleSoundIconClick(idx)
                            }
                            className="sound-button"
                          />
                          <div
                            style={{
                              cursor: "pointer",
                              fontFamily: "Poppins-Regular",
                              fontSize: "23px",
                              background: "#F0A6B6",
                              padding: "16px 48px",
                              marginTop: "16px",
                              marginBottom: "20px",
                            }}
                            onClick={
                              clickDisabled
                                ? null
                                : () => this.onHandleSoundIconClick(idx)
                            }
                          >
                            {item.text}
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div key={idx}>
                          <img
                            src="/assets/sound-icon.svg"
                            alt=""
                            onClick={
                              clickDisabled
                                ? null
                                : () => this.onHandleSoundIconClick(idx)
                            }
                            className="sound-button"
                          />
                          <div
                            style={{
                              fontFamily: "Poppins-Regular",
                              fontSize: "23px",
                              background: "#F3C9D2",
                              padding: "16px 48px",
                              marginTop: "16px",
                            }}
                            onClick={
                              clickDisabled
                                ? null
                                : () => this.onHandleSoundIconClick(idx)
                            }
                          >
                            {item.text}
                          </div>
                        </div>
                      );
                    }
                  }
                )}
              </div>
            </Col>
            <Col span={6} />
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={0}
              style={{
                textAlign: "center",
                marginTop: "40px",
                marginBottom: "140px",
              }}
            >
              <SelfRecording currentStepNum={currentStepNum} />
            </Col>
            <Col
              span={24}
              style={{
                textAlign: "center",
              }}
              className="footer"
            >
              <Col
                xs={0}
                sm={0}
                md={0}
                lg={0}
                xl={0}
                xxl={24}
                style={{ paddingBottom: "24px" }}
              >
                <SelfRecording currentStepNum={currentStepNum} />
              </Col>
              <Col
                xs={0}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                xxl={24}
                style={{ borderTop: "4px solid #F1F1F1" }}
              >
                {currentStepNum === 0 ? null : (
                  <Button
                    type="primary"
                    style={{
                      margin: "24px 10px",
                      borderRadius: "24px",
                      height: "auto",
                      fontFamily: "Poppins-Bold",
                      fontSize: "23px",
                      width: "250px",
                      boxShadow: "0px 6px 0  #2A3580",
                    }}
                    onClick={this.onPreviousButtonClick}
                  >
                    {
                      getNativeLangTransText(
                        dbAuth.userInfo.nativeLanguage,
                        configuration.projectUI.previous,
                        translation.translationObj
                      ).text
                    }
                  </Button>
                )}

                <Button
                  type="primary"
                  style={{
                    margin: "24px 0px",
                    borderRadius: "24px",
                    height: "auto",
                    fontFamily: "Poppins-Bold",
                    fontSize: "23px",
                    width: "250px",
                    boxShadow: "0px 6px 0  #2A3580",
                  }}
                  onClick={this.onNextButtonClick}
                >
                  {
                    getNativeLangTransText(
                      dbAuth.userInfo.nativeLanguage,
                      configuration.projectUI.next,
                      translation.translationObj
                    ).text
                  }
                </Button>
              </Col>
              <Col
                xs={24}
                sm={0}
                md={0}
                lg={0}
                xl={0}
                xxl={0}
                style={{ borderTop: "4px solid #F1F1F1" }}
              >
                {currentStepNum === 0 ? null : (
                  <Button
                    type="primary"
                    style={{
                      margin: "24px 10px",
                      borderRadius: "24px",
                      height: "auto",
                      fontFamily: "Poppins-Bold",
                      fontSize: "18px",
                      width: "120px",
                      boxShadow: "0px 6px 0  #2A3580",
                    }}
                    onClick={this.onPreviousButtonClick}
                  >
                    {
                      getNativeLangTransText(
                        dbAuth.userInfo.nativeLanguage,
                        configuration.projectUI.previous,
                        translation.translationObj
                      ).text
                    }
                  </Button>
                )}

                <Button
                  type="primary"
                  style={{
                    margin: "24px 0px",
                    borderRadius: "24px",
                    height: "auto",
                    fontFamily: "Poppins-Bold",
                    fontSize: "18px",
                    width: "120px",
                    boxShadow: "0px 6px 0  #2A3580",
                  }}
                  onClick={this.onNextButtonClick}
                >
                  {
                    getNativeLangTransText(
                      dbAuth.userInfo.nativeLanguage,
                      configuration.projectUI.next,
                      translation.translationObj
                    ).text
                  }
                </Button>
              </Col>
            </Col>
          </Row>
        </Spin>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  course: state.course,
  dbAuth: state.auth,
  operation: state.operation,
  achievement: state.achievement,
  translation: state.translation,
  configuration: state.configuration,
});

const mapDispatchToProps = {
  updateLessonProgress,
  updateLessonAchievementProgress,
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(ContentType5LessonPage);
