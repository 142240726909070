import React, { Component, Fragment } from "react";
import "./ContentType4LessonPage.css";
import {
  _saveProgress,
  lessonPageInit,
  getEnText,
  getNativeLangTransText,
} from "../../../../SharedUtils";
import { operationTypeEnum } from "../../../../OperationTypeConstant";
import _ from "lodash";
import { Row, Col, Progress, Button, Spin } from "antd";
import SelfRecording from "../../../SelfRecording/SelfRecording";
import {
  updateLessonProgress,
  updateLessonAchievementProgress,
} from "../../../../auth/authActions";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withFirebase } from "react-redux-firebase";

let audioElement = new Audio();

class ContentType4LessonPage extends Component {
  constructor(props) {
    super(props);
    this._saveProgress = _saveProgress.bind(this);
  }

  state = {
    targetedLessonList: [],
    currentStepNum: 0,
    isPlayingAudio: false,
    isAudioDownloadedLocally: false,
    clickDisabled: false,
    isAllLessonsCompleted: false,
  };

  async componentDidMount() {
    const { course, match, dbAuth, firebase } = this.props;
    const storageRef = firebase.storage().ref();

    let courseId = match.params.courseId;
    let topicId = match.params.topicId;
    let subTopicId = match.params.subTopicId;
    let groupId = match.params.groupId;

    let { first, second, third } = lessonPageInit(
      course,
      dbAuth,
      courseId,
      topicId,
      subTopicId,
      groupId
    );

    //load image
    let imageUrlPromises = [];
    course.lessonInfoObj.lessonList.forEach((item) => {
      imageUrlPromises.push(storageRef.child(item.imagePath).getDownloadURL());
    });

    let imageUrls = await Promise.all(imageUrlPromises);

    first.forEach((item, idx) => {
      item.imageUrl = imageUrls[idx];
    });

    this.setState({
      targetedLessonList: first,
      currentStepNum: second,
      isAllLessonsCompleted: third,
      courseId,
      topicId,
      subTopicId,
      groupId,
    });
  }

  componentWillUnmount() {
    audioElement.removeEventListener("ended", this.onAudioEnded);
  }

  componentDidUpdate(prevProps) {
    const { courseId, topicId, subTopicId, groupId, completed } = this.state;
    const { operation, dbAuth, achievement } = this.props;
    if (
      !_.isEqual(prevProps.operation.isProcessing, operation.isProcessing) &&
      !operation.isProcessing
    ) {
      if (
        operation.operationType === operationTypeEnum.NO_OPS ||
        (prevProps.operation.operationType ===
          operationTypeEnum.UPDATE_LESSON_PROGRESS &&
          operation.operationType === operationTypeEnum.NO_OPS)
      ) {
        if (
          dbAuth.progressInfo.achievement &&
          dbAuth.progressInfo.achievement.lesson
        ) {
          let totaltoCompelete =
            achievement.currentWeekAchievement.lesson.totalToComplete;
          let totalCompleted = Object.entries(
            dbAuth.progressInfo.achievement.lesson
          ).length;
          if (totaltoCompelete >= totalCompleted && completed) {
            this.props.history.push(
              `/user-dashboard/courses/${courseId}/topics/${topicId}/subTopics/${subTopicId}/lessons/${groupId}/lesson_achievement`
            );
            let buttonSound = new Audio("/assets/Lesson_done.mp3");
            buttonSound.play();
          } else {
            this.props.history.push(
              `/user-dashboard/courses/${courseId}/topics/${topicId}`
            );
          }
        } else {
          this.props.history.push(
            `/user-dashboard/courses/${courseId}/topics/${topicId}`
          );
        }
      }
    }
  }

  onPreviousButtonClick = () => {
    let selectButtonSound = new Audio("/assets/Select_sound03.mp3");
    selectButtonSound.play();

    window.scrollTo(0, 0);

    audioElement.currentTime = 0;
    audioElement.pause();

    this.setState((prevState) => ({
      currentStepNum: prevState.currentStepNum - 1,
      isPlayingAudio: false,
      isAudioDownloadedLocally: false,
    }));
  };

  onNextButtonClick = () => {
    let selectButtonSound = new Audio("/assets/Select_sound03.mp3");
    selectButtonSound.play();
    const { targetedLessonList, currentStepNum } = this.state;
    if (currentStepNum === targetedLessonList.length - 1) {
      //Note : below equivalent to currentStepNumber + 1 === targetedLessonList.length
      //save full progress here...
      this._saveProgress();
    } else {
      window.scrollTo(0, 0);

      audioElement.currentTime = 0;
      audioElement.pause();

      this.setState((prevState) => ({
        currentStepNum: prevState.currentStepNum + 1,
        isPlayingAudio: false,
        isAudioDownloadedLocally: false,
      }));
    }
  };

  onUpButtonClick = () => {
    //reset audio to prevent audio resume in next re-entrance
    audioElement.currentTime = 0;
    audioElement.pause();

    this._saveProgress();
  };

  onHandleSoundIconClick = () => {
    const { targetedLessonList, currentStepNum, isAudioDownloadedLocally } =
      this.state;
    const { firebase } = this.props;
    const storageRef = firebase.storage().ref();

    if (
      audioElement.currentTime === 0 ||
      audioElement.currentTime === audioElement.duration
    ) {
      if (isAudioDownloadedLocally) {
        this.setState(
          {
            clickDisabled: true,
          },
          () => {
            this.setState(
              {
                isPlayingAudio: true,
                clickDisabled: false,
              },
              () => {
                audioElement.play();
              }
            );
          }
        );
      } else {
        this.setState(
          {
            clickDisabled: true,
          },
          async () => {
            //get voice over url
            if (targetedLessonList[currentStepNum].voiceover.length > 0) {
              let vUrl = await storageRef
                .child(targetedLessonList[currentStepNum].voiceover)
                .getDownloadURL();

              let downloadPromise = new Promise((resolve, reject) => {
                let xhr = new XMLHttpRequest();
                xhr.responseType = "blob";
                xhr.onload = function (event) {
                  if (xhr.status === 200) {
                    resolve(xhr.response);
                  } else {
                    reject(xhr.status);
                  }
                };
                xhr.open("GET", vUrl);
                xhr.send();
              });

              let downloadedBlob = await downloadPromise;

              audioElement.addEventListener("ended", this.onAudioEnded);
              this.setState(
                {
                  isAudioDownloadedLocally: true,
                  isPlayingAudio: true,
                  clickDisabled: false,
                },
                () => {
                  audioElement.src = URL.createObjectURL(downloadedBlob);
                  audioElement.play();
                }
              );
            }
          }
        );
      }
    } else {
      //resume playing

      audioElement.play();
      this.setState({
        isPlayingAudio: true,
      });
    }
  };

  onAudioEnded = () => {
    this.setState({
      isPlayingAudio: false,
    });
  };

  onHandlePauseIconClick = () => {
    audioElement.pause();
    this.setState({
      isPlayingAudio: false,
    });
  };

  render() {
    const {
      targetedLessonList,
      currentStepNum,
      isPlayingAudio,
      clickDisabled,
    } = this.state;
    const { dbAuth, translation, operation, configuration } = this.props;

    if (targetedLessonList.length === 0) return null;
    return (
      <Fragment>
        <Spin
          tip="Saving lesson progress..."
          spinning={operation.isProcessing}
          size="large"
        >
          <Row>
            <Col
              offset={6}
              span={12}
              style={{
                textAlign: "center",
                marginTop: "32px",
                // minHeight: "850px"
              }}
            >
              <div style={{ display: "flex" }}>
                <img
                  src="/assets/back-arrow-icon.svg"
                  alt=""
                  style={{ marginRight: "32px", cursor: "pointer" }}
                  onClick={this.onUpButtonClick}
                />

                <Progress
                  percent={
                    ((currentStepNum + 1) / targetedLessonList.length) * 100
                  }
                  status="normal"
                  showInfo={false}
                  strokeColor={{ "0%": "#82ABF7", "100%": "#4252BE" }}
                  strokeWidth="50px"
                />
              </div>

              <br />
              <br />
              <br />
              <Col xs={0} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <img
                  src={targetedLessonList[currentStepNum].imageUrl}
                  alt=""
                  style={{ width: "290px", height: "250px" }}
                />
              </Col>
              <Col xs={24} sm={0} md={0} lg={0} xl={0} xxl={0}>
                <img
                  src={targetedLessonList[currentStepNum].imageUrl}
                  alt=""
                  style={{ width: "100%" }}
                />
              </Col>

              <br />
              <br />
              <br />
              {isPlayingAudio ? (
                <Row>
                  <Col xs={0} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <img
                      src="/assets/pause-icon.svg"
                      alt=""
                      onClick={this.onHandlePauseIconClick}
                      style={{
                        cursor: "pointer",
                        width: "65px",
                        height: "65px",
                      }}
                    />
                  </Col>
                  <Col xs={24} sm={0} md={0} lg={0} xl={0} xxl={0}>
                    <img
                      src="/assets/pause-icon.svg"
                      alt=""
                      onClick={this.onHandlePauseIconClick}
                      style={{ cursor: "pointer", width: "30%" }}
                    />
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col xs={0} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <img
                      src="/assets/sound-icon.svg"
                      alt=""
                      onClick={
                        clickDisabled ? null : this.onHandleSoundIconClick
                      }
                      style={{
                        cursor: "pointer",
                        width: "65px",
                        height: "65px",
                      }}
                    />
                  </Col>
                  <Col xs={24} sm={0} md={0} lg={0} xl={0} xxl={0}>
                    <img
                      src="/assets/sound-icon.svg"
                      alt=""
                      onClick={
                        clickDisabled ? null : this.onHandleSoundIconClick
                      }
                      style={{ cursor: "pointer", width: "30%" }}
                    />
                  </Col>
                </Row>
              )}

              <br />
              <br />
              <div
                style={{
                  fontSize: "23px",
                }}
              >
                {getEnText(
                  targetedLessonList[currentStepNum].sentence,
                  translation.translationObj
                ).isHtml ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: getEnText(
                        targetedLessonList[currentStepNum].sentence,
                        translation.translationObj
                      ).text,
                    }}
                  />
                ) : (
                  <span style={{ fontFamily: "Poppins-Regular" }}>
                    {
                      getEnText(
                        targetedLessonList[currentStepNum].sentence,
                        translation.translationObj
                      ).text
                    }
                  </span>
                )}
              </div>
              {dbAuth.userInfo.nativeLanguage !== "en" && (
                <div
                  style={{
                    fontStyle: "italic",
                    fontSize: "23px",
                  }}
                >
                  {getNativeLangTransText(
                    dbAuth.userInfo.nativeLanguage,
                    targetedLessonList[currentStepNum].sentence,
                    translation.translationObj
                  ).isHtml ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          targetedLessonList[currentStepNum].sentence,
                          translation.translationObj
                        ).text,
                      }}
                    />
                  ) : (
                    <span style={{ fontFamily: "Poppins-Regular" }}>
                      {
                        getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          targetedLessonList[currentStepNum].sentence,
                          translation.translationObj
                        ).text
                      }
                    </span>
                  )}
                </div>
              )}
            </Col>
            <Col span={6} />

            <Col
              xs={24}
              sm={0}
              md={0}
              lg={0}
              xl={0}
              xxl={0}
              style={{
                textAlign: "center",
                marginTop: "40px",
                marginBottom: "140px",
              }}
            >
              <SelfRecording currentStepNum={currentStepNum} />
            </Col>
            <Col
              xs={0}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={0}
              style={{
                textAlign: "center",
                marginTop: "40px",
                //marginBottom: currentStepNum === 0 ? "120px" : "240px"
              }}
            >
              <SelfRecording currentStepNum={currentStepNum} />
            </Col>
            <Col span={24} className="footer">
              <Col
                xs={0}
                sm={0}
                md={0}
                lg={0}
                xl={0}
                xxl={24}
                style={{ paddingBottom: "24px" }}
              >
                <SelfRecording currentStepNum={currentStepNum} />
              </Col>
              <Col
                xs={0}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                xxl={24}
                style={{ borderTop: "4px solid #F1F1F1" }}
              >
                {currentStepNum === 0 ? null : (
                  <Button
                    type="primary"
                    style={{
                      margin: "24px 10px",
                      borderRadius: "24px",
                      height: "auto",
                      fontFamily: "Poppins-Bold",
                      fontSize: "23px",
                      width: "250px",
                      boxShadow: "0px 6px 0  #2A3580",
                    }}
                    onClick={this.onPreviousButtonClick}
                  >
                    {
                      getNativeLangTransText(
                        dbAuth.userInfo.nativeLanguage,
                        configuration.projectUI.previous,
                        translation.translationObj
                      ).text
                    }
                  </Button>
                )}

                <Button
                  type="primary"
                  style={{
                    margin: "24px 0px",
                    borderRadius: "24px",
                    height: "auto",
                    fontFamily: "Poppins-Bold",
                    fontSize: "23px",
                    width: "250px",
                    boxShadow: "0px 6px 0  #2A3580",
                  }}
                  onClick={this.onNextButtonClick}
                >
                  {
                    getNativeLangTransText(
                      dbAuth.userInfo.nativeLanguage,
                      configuration.projectUI.next,
                      translation.translationObj
                    ).text
                  }
                </Button>
              </Col>
              <Col
                xs={24}
                sm={0}
                md={0}
                lg={0}
                xl={0}
                xxl={0}
                style={{ borderTop: "4px solid #F1F1F1" }}
              >
                {currentStepNum === 0 ? null : (
                  <Button
                    type="primary"
                    style={{
                      margin: "24px 10px",
                      borderRadius: "24px",
                      height: "auto",
                      fontFamily: "Poppins-Bold",
                      fontSize: "18px",
                      width: "120px",
                      boxShadow: "0px 6px 0  #2A3580",
                    }}
                    onClick={this.onPreviousButtonClick}
                  >
                    {
                      getNativeLangTransText(
                        dbAuth.userInfo.nativeLanguage,
                        configuration.projectUI.previous,
                        translation.translationObj
                      ).text
                    }
                  </Button>
                )}

                <Button
                  type="primary"
                  style={{
                    margin: "24px 0px",
                    borderRadius: "24px",
                    height: "auto",
                    fontFamily: "Poppins-Bold",
                    fontSize: "18px",
                    width: "120px",
                    boxShadow: "0px 6px 0  #2A3580",
                  }}
                  onClick={this.onNextButtonClick}
                >
                  {
                    getNativeLangTransText(
                      dbAuth.userInfo.nativeLanguage,
                      configuration.projectUI.next,
                      translation.translationObj
                    ).text
                  }
                </Button>
              </Col>
            </Col>
          </Row>
        </Spin>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  course: state.course,
  translation: state.translation,
  dbAuth: state.auth,
  operation: state.operation,
  achievement: state.achievement,
  configuration: state.configuration,
});

const mapDispatchToProps = {
  updateLessonProgress,
  updateLessonAchievementProgress,
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(ContentType4LessonPage);
