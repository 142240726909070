import React, { Component } from "react";
import "./LessonPage.css";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  loadTargetedCourseLesson,
  resetTargetedCourseLesson
} from "../../../course/courseActions";
import LoadingComponent from "../../../app/common/loading/LoadingComponent";
import _ from "lodash";
import ContentType2LessonPage from "./ContentType2LessonPage/ContentType2LessonPage";
import ContentType1LessonPage from "./ContentType1LessonPage/ContentType1LessonPage";
import ContentType4LessonPage from "./ContentType4LessonPage/ContentType4LessonPage";
import ContentType5LessonPage from "./ContentType5LessonPage/ContentType5LessonPage";
import ContentType6LessonPage from "./ContentType6LessonPage/ContentType6LessonPage";
import ContentType7LessonPage from "./ContentType7LessonPage/ContentType7LessonPage";
import ContentType3LessonPage from "./ContentType3LessonPage/ContentType3LessonPage";

class LessonPage extends Component {
  state = {
    isLoading: true,
    contentType: -1
  };

  componentDidMount() {
    const { match } = this.props;
    let courseId = match.params.courseId;
    let topicId = match.params.topicId;
    let subTopicId = match.params.subTopicId;
    let groupId = match.params.groupId;

    this.props.loadTargetedCourseLesson(courseId, topicId, subTopicId, groupId);
  }

  async componentDidUpdate(prevProps) {
    const { course } = this.props;

    if (
      !_.isEqual(prevProps.course.lessonInfoObj, course.lessonInfoObj) &&
      course.lessonInfoObj !== null
    ) {
      this.setState({
        isLoading: false,
        contentType: course.lessonInfoObj.subTopicInfoObj.contentType
      });
    }
  }

  componentWillUnmount() {
    this.props.resetTargetedCourseLesson();
  }

  render() {
    const { isLoading, contentType } = this.state;

    if (isLoading) return <LoadingComponent />;

    if (contentType === 1) return <ContentType1LessonPage />;
    if (contentType === 2) return <ContentType2LessonPage />;
    if (contentType === 3) return <ContentType3LessonPage />;
    if (contentType === 4) return <ContentType4LessonPage />;
    if (contentType === 5) return <ContentType5LessonPage />;
    if (contentType === 6) return <ContentType6LessonPage />;
    if (contentType === 7) return <ContentType7LessonPage />;

    // if (match.params.topicId === "quick_phonics")
    //   return <QuickPhonicsLessonPage />;

    // if (match.params.topicId === "sir2000")
    //   return <SIR2000LessonPage enableQuestionPage={true} />;

    // if (
    //   (match.params.topicId === "introduction_to_english" &&
    //     match.params.subTopicId !== "44_basic_sounds") ||
    //   match.params.topicId === "oral_english" ||
    //   match.params.topicId === "expressions_english_ag" ||
    //   match.params.topicId === "expressions_english_hn" ||
    //   match.params.topicId === "expressions_english_oz" ||
    //   match.params.topicId === "common_words_in_newspapers"
    // )
    //   return <IntroductionToEnglishLessonPage />;

    // if (
    //   match.params.topicId === "introduction_to_english" &&
    //   match.params.subTopicId === "44_basic_sounds"
    // )
    //   return <SIR2000LessonPage enableQuestionPage={false} />;

    // if (match.params.topicId === "first_steps_to_english")
    //   return <FirstStepsToEnglishLessonPage />;

    // if (
    //   match.params.topicId === "communication_skills" ||
    //   match.params.topicId === "short_conversations" ||
    //   match.params.topicId === "survival_english" ||
    //   match.params.topicId === "trip_usa_part1" ||
    //   match.params.topicId === "trip_usa_part2"
    // )
    //   return <ConversationLessonPage />;

    // if (match.params.topicId === "idioms_and_expressions")
    //   return <IdiomAndExpressionLessonPage />;

    // if (match.params.topicId === "grammar") return <GrammarLessonPage />;

    return null;
  }
}

const mapStateToProps = state => ({
  course: state.course
});

const mapDispatchToProps = {
  loadTargetedCourseLesson,
  resetTargetedCourseLesson
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(LessonPage);
