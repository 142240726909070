import React, { Component, Fragment } from "react";
import "./ContentType8Page.css";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  questionPageInit,
  getEnText,
  _saveQuizProgress,
} from "../../../../SharedUtils";
import { withFirebase } from "react-redux-firebase";
import {
  updateQuizProgress,
  updateQuizAchievementProgress,
} from "../../../../auth/authActions";
import { Spin, Button, Col, Row, Progress } from "antd";
import _ from "lodash";
import { operationTypeEnum } from "../../../../OperationTypeConstant";
import { getNativeLangTransText } from "../../../../SharedUtils";

class ContentType8Page extends Component {
  constructor(props) {
    super(props);
    this._saveQuizProgress = _saveQuizProgress.bind(this);
  }

  state = {
    targetedQuestionList: [],
    currentStepNum: 0,
    isAllQuestionsCompleted: false,
    questionImageUrls: [],
    quizTestObj: {
      tappedAnswerIdx: -1,
      isAnswerRevealed: false,
    },
  };

  async componentDidMount() {
    const { course, match, dbAuth, firebase } = this.props;
    const storageRef = firebase.storage().ref();

    let quizId = match.params.quizId;
    let topicId = match.params.topicId;
    let groupId = match.params.groupId;

    let { first, second, third } = questionPageInit(
      course,
      dbAuth,
      quizId,
      topicId,
      groupId
    );

    //load image
    let imageUrlPromises = [];

    course.quizQuestionInfoObj.questionGroupObj.questions.forEach((item) => {
      if (item.imagePath.length > 0) {
        imageUrlPromises.push(
          storageRef.child(item.imagePath).getDownloadURL()
        );
      } else {
        //dummy promise
        imageUrlPromises.push(
          new Promise((resolve, _) => {
            resolve(null);
          })
        );
      }
    });

    let questionImageUrls = await Promise.all(imageUrlPromises);

    this.setState({
      targetedQuestionList: first,
      currentStepNum: second,
      isAllQuestionsCompleted: third,
      questionImageUrls,
      quizId,
      topicId,
      groupId,
    });
  }

  componentDidUpdate(prevProps) {
    const { quizId, topicId, groupId, completed } = this.state;
    const { operation, dbAuth, achievement } = this.props;
    if (
      !_.isEqual(prevProps.operation.isProcessing, operation.isProcessing) &&
      !operation.isProcessing
    ) {
      if (
        operation.operationType === operationTypeEnum.NO_OPS ||
        (prevProps.operation.operationType ===
          operationTypeEnum.UPDATE_QUIZ_PROGRESS &&
          operation.operationType === operationTypeEnum.NO_OPS)
      ) {
        if (
          dbAuth.progressInfo.achievement &&
          dbAuth.progressInfo.achievement.quiz
        ) {
          let totaltoCompelete =
            achievement.currentWeekAchievement.quiz.totalToComplete;
          let totalCompleted = Object.entries(
            dbAuth.progressInfo.achievement.quiz
          ).length;
          if (totaltoCompelete >= totalCompleted && completed) {
            console.log("successfully done");
            this.props.history.push(
              `/user-dashboard/quiz/all-quizzes/${quizId}/topics/${topicId}/groups/${groupId}/quiz_achievement`
            );
            let buttonSound = new Audio("/assets/Lesson_done.mp3");
            buttonSound.play();
          } else {
            this.props.history.push(
              `/user-dashboard/quiz/all-quizzes/${quizId}/topics/${topicId}`
            );
          }
        } else {
          this.props.history.push(
            `/user-dashboard/quiz/all-quizzes/${quizId}/topics/${topicId}`
          );
        }
      }
    }
  }

  onNextButtonClick = () => {
    let buttonSound = new Audio("/assets/Select_sound03.mp3");
    buttonSound.play();
    const { targetedQuestionList, currentStepNum } = this.state;
    if (currentStepNum === targetedQuestionList.length - 1) {
      //Note : below equivalent to currentStepNumber + 1 === targetedQuestionList.length
      //save full progress here...
      this._saveQuizProgress();
    } else {
      window.scrollTo(0, 0);

      this.setState((prevState) => ({
        currentStepNum: prevState.currentStepNum + 1,
        quizTestObj: {
          tappedAnswerIdx: -1,
          isAnswerRevealed: false,
        },
      }));
    }
  };

  captureTappedAnswerIdx = (idx) => {
    this.setState((prevState) => ({
      quizTestObj: {
        ...prevState.quizTestObj,
        tappedAnswerIdx: idx,
      },
    }));
  };

  onCheckAnswerButtonClick = () => {
    const { quizTestObj, targetedQuestionList, currentStepNum } = this.state;
    if (
      quizTestObj.tappedAnswerIdx !==
      targetedQuestionList[currentStepNum].answer
    ) {
      let buttonSound = new Audio("/assets/Wrong_answer01.mp3");
      buttonSound.play();
    } else {
      let buttonSound = new Audio("/assets/Correct_answer.mp3");
      buttonSound.play();
    }
    this.setState((prevState) => ({
      quizTestObj: {
        ...prevState.quizTestObj,
        isAnswerRevealed: true,
      },
    }));
  };

  onUpButtonClick = async () => {
    this._saveQuizProgress();
  };

  render() {
    const {
      targetedQuestionList,
      currentStepNum,
      questionImageUrls,
      quizTestObj,
    } = this.state;
    const { operation, translation, configuration, dbAuth } = this.props;
    if (targetedQuestionList.length === 0) return null;

    return (
      <Fragment>
        <Spin
          tip="Saving quiz progress..."
          spinning={operation.isProcessing}
          size="large"
        >
          <Row>
            <Col xs={1} sm={2} md={3} lg={4} xl={6} xxl={6} />
            <Col
              xs={0}
              sm={0}
              md={0}
              lg={16}
              xl={12}
              xxl={12}
              style={{
                textAlign: "center",
                marginTop: "32px",
                // minHeight: "950px"
              }}
            >
              <div style={{ display: "flex" }}>
                <img
                  src="/assets/back-arrow-icon.svg"
                  alt=""
                  style={{ marginRight: "32px", cursor: "pointer" }}
                  onClick={this.onUpButtonClick}
                />
                <Progress
                  percent={
                    ((currentStepNum + 1) / targetedQuestionList.length) * 100
                  }
                  status="normal"
                  showInfo={false}
                  strokeColor={{ "0%": "#82ABF7", "100%": "#4252BE" }}
                  strokeWidth="50px"
                />
              </div>
              <br />
              <br />
              {questionImageUrls[currentStepNum] && (
                <img
                  src={questionImageUrls[currentStepNum]}
                  alt=""
                  style={{ width: "150px", height: "150px" }}
                />
              )}
              {!targetedQuestionList[currentStepNum].description.includes(
                "<img",
                0
              ) && (
                <div
                  style={{
                    fontSize: "23px",
                  }}

                  // dangerouslySetInnerHTML={{
                  //   __html: targetedQuizQuestionList[currentStepNum].description
                  // }}
                >
                  {getEnText(
                    targetedQuestionList[currentStepNum].description,
                    translation.translationObj
                  ).isHtml ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: getEnText(
                          targetedQuestionList[currentStepNum].description,
                          translation.translationObj
                        ).text,
                      }}
                    />
                  ) : (
                    <span style={{ fontFamily: "Poppins-Regular" }}>
                      {
                        getEnText(
                          targetedQuestionList[currentStepNum].description,
                          translation.translationObj
                        ).text
                      }
                    </span>
                  )}
                </div>
              )}
              <div
                style={{
                  fontSize: "23px",
                }}
                // dangerouslySetInnerHTML={{
                //   __html: targetedQuizQuestionList[currentStepNum].question
                // }}
              >
                {getEnText(
                  targetedQuestionList[currentStepNum].question,
                  translation.translationObj
                ).isHtml ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: getEnText(
                        targetedQuestionList[currentStepNum].question,
                        translation.translationObj
                      ).text,
                    }}
                  />
                ) : (
                  <span style={{ fontFamily: "Poppins-Regular" }}>
                    {
                      getEnText(
                        targetedQuestionList[currentStepNum].question,
                        translation.translationObj
                      ).text
                    }
                  </span>
                )}
              </div>
              <Row>
                {targetedQuestionList[currentStepNum].choices.map(
                  (item, idx) => (
                    <Fragment key={idx}>
                      <Col
                        className={
                          "contentType8-quiz-test-mode" +
                          (quizTestObj.tappedAnswerIdx === idx
                            ? " selected"
                            : " not-selected") +
                          (quizTestObj.isAnswerRevealed
                            ? idx ===
                              targetedQuestionList[currentStepNum].answer
                              ? " correct"
                              : " not-correct"
                            : "")
                        }
                        offset={7}
                        span={10}
                        style={{
                          fontFamily: "Poppins-Bold",
                          fontSize: "20px",
                          color: "#4252BE",
                          borderRadius: "24px",
                          border: "1px solid #4252BE",
                          marginTop: "16px",
                          padding: "8px",
                          cursor: "pointer",
                          // background:
                          //   targetedQuestionList[currentStepNum].answer ===
                          //   idx
                          //     ? "#D0F8CF"
                          //     : "white"
                        }}
                        onClick={
                          quizTestObj.isAnswerRevealed
                            ? null
                            : () => this.captureTappedAnswerIdx(idx)
                        }
                      >
                        <div style={{ display: "flex" }}>
                          <img
                            src={
                              quizTestObj.isAnswerRevealed
                                ? idx ===
                                  targetedQuestionList[currentStepNum].answer
                                  ? "/assets/checked-circle-icon.svg"
                                  : "/assets/clear-circle-icon.svg"
                                : "/assets/checked-circle-icon.svg"
                            }
                            alt=""
                            style={{
                              // display:
                              //   targetedQuizQuestionList[currentStepNum]
                              //     .answer === idx
                              //     ? "block"
                              //     : "none",
                              display: quizTestObj.isAnswerRevealed
                                ? idx ===
                                    targetedQuestionList[currentStepNum]
                                      .answer ||
                                  idx === quizTestObj.tappedAnswerIdx
                                  ? "block"
                                  : "none"
                                : "none",
                              position: "absolute",
                              top: "50%",
                              left: "24px",
                              transform: "translate(-50%,-50%)",
                            }}
                          />

                          <span
                            style={{
                              padding: "0px 28px",
                              width: "100%",
                            }}
                          >
                            {item}
                          </span>
                        </div>
                      </Col>
                      <Col span={7} style={{ marginTop: "16px" }} />
                    </Fragment>
                  )
                )}
              </Row>
              <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
              <br /> <br /> <br />
            </Col>

            <Col
              xs={0}
              sm={20}
              md={18}
              lg={0}
              xl={0}
              xxl={0}
              style={{
                textAlign: "center",
                marginTop: "32px",
                // minHeight: "850px"
              }}
            >
              <div style={{ display: "flex" }}>
                <img
                  src="/assets/back-arrow-icon.svg"
                  alt=""
                  style={{
                    marginRight: "32px",
                    cursor: "pointer",
                    width: "23px",
                  }}
                  onClick={this.onUpButtonClick}
                />
                <Progress
                  percent={
                    ((currentStepNum + 1) / targetedQuestionList.length) * 100
                  }
                  status="normal"
                  showInfo={false}
                  strokeColor={{ "0%": "#82ABF7", "100%": "#4252BE" }}
                  strokeWidth="40px"
                />
              </div>
              <br />
              <br />
              {questionImageUrls[currentStepNum] && (
                <img
                  src={questionImageUrls[currentStepNum]}
                  alt=""
                  style={{ width: "150px", height: "150px" }}
                />
              )}
              {!targetedQuestionList[currentStepNum].description.includes(
                "<img",
                0
              ) && (
                <div
                  style={{
                    fontSize: "20px",
                  }}

                  // dangerouslySetInnerHTML={{
                  //   __html: targetedQuizQuestionList[currentStepNum].description
                  // }}
                >
                  {getEnText(
                    targetedQuestionList[currentStepNum].description,
                    translation.translationObj
                  ).isHtml ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: getEnText(
                          targetedQuestionList[currentStepNum].description,
                          translation.translationObj
                        ).text,
                      }}
                    />
                  ) : (
                    <span style={{ fontFamily: "Poppins-Regular" }}>
                      {
                        getEnText(
                          targetedQuestionList[currentStepNum].description,
                          translation.translationObj
                        ).text
                      }
                    </span>
                  )}
                </div>
              )}
              <div
                style={{
                  fontSize: "20px",
                }}
                // dangerouslySetInnerHTML={{
                //   __html: targetedQuizQuestionList[currentStepNum].question
                // }}
              >
                {getEnText(
                  targetedQuestionList[currentStepNum].question,
                  translation.translationObj
                ).isHtml ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: getEnText(
                        targetedQuestionList[currentStepNum].question,
                        translation.translationObj
                      ).text,
                    }}
                  />
                ) : (
                  <span style={{ fontFamily: "Poppins-Regular" }}>
                    {
                      getEnText(
                        targetedQuestionList[currentStepNum].question,
                        translation.translationObj
                      ).text
                    }
                  </span>
                )}
              </div>
              <Row>
                {targetedQuestionList[currentStepNum].choices.map(
                  (item, idx) => (
                    <Fragment key={idx}>
                      <Col
                        className={
                          "contentType8-quiz-test-mode" +
                          (quizTestObj.tappedAnswerIdx === idx
                            ? " selected"
                            : " not-selected") +
                          (quizTestObj.isAnswerRevealed
                            ? idx ===
                              targetedQuestionList[currentStepNum].answer
                              ? " correct"
                              : " not-correct"
                            : "")
                        }
                        offset={4}
                        span={16}
                        style={{
                          fontFamily: "Poppins-Bold",
                          fontSize: "18px",
                          color: "#4252BE",
                          borderRadius: "24px",
                          border: "1px solid #4252BE",
                          marginTop: "16px",
                          padding: "8px",
                          cursor: "pointer",
                          // background:
                          //   targetedQuestionList[currentStepNum].answer ===
                          //   idx
                          //     ? "#D0F8CF"
                          //     : "white"
                        }}
                        onClick={
                          quizTestObj.isAnswerRevealed
                            ? null
                            : () => this.captureTappedAnswerIdx(idx)
                        }
                      >
                        <div style={{ display: "flex" }}>
                          <img
                            src={
                              quizTestObj.isAnswerRevealed
                                ? idx ===
                                  targetedQuestionList[currentStepNum].answer
                                  ? "/assets/checked-circle-icon.svg"
                                  : "/assets/clear-circle-icon.svg"
                                : "/assets/checked-circle-icon.svg"
                            }
                            alt=""
                            style={{
                              // display:
                              //   targetedQuizQuestionList[currentStepNum]
                              //     .answer === idx
                              //     ? "block"
                              //     : "none",
                              display: quizTestObj.isAnswerRevealed
                                ? idx ===
                                    targetedQuestionList[currentStepNum]
                                      .answer ||
                                  idx === quizTestObj.tappedAnswerIdx
                                  ? "block"
                                  : "none"
                                : "none",
                              position: "absolute",
                              top: "50%",
                              left: "24px",
                              transform: "translate(-50%,-50%)",
                            }}
                          />

                          <span
                            style={{
                              padding: "0px 28px",
                              width: "100%",
                            }}
                          >
                            {item}
                          </span>
                        </div>
                      </Col>
                      <Col span={7} style={{ marginTop: "16px" }} />
                    </Fragment>
                  )
                )}
              </Row>
              <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
              <br /> <br /> <br />
            </Col>

            <Col
              xs={22}
              sm={0}
              md={0}
              lg={0}
              xl={0}
              xxl={0}
              style={{
                textAlign: "center",
                marginTop: "32px",
                // minHeight: "850px"
              }}
            >
              <div style={{ display: "flex" }}>
                <img
                  src="/assets/back-arrow-icon.svg"
                  alt=""
                  style={{
                    marginRight: "32px",
                    cursor: "pointer",
                    width: "16px",
                  }}
                  onClick={this.onUpButtonClick}
                />
                <Progress
                  percent={
                    ((currentStepNum + 1) / targetedQuestionList.length) * 100
                  }
                  status="normal"
                  showInfo={false}
                  strokeColor={{ "0%": "#82ABF7", "100%": "#4252BE" }}
                  strokeWidth="30px"
                />
              </div>
              <br />
              {questionImageUrls[currentStepNum] && (
                <img
                  src={questionImageUrls[currentStepNum]}
                  alt=""
                  style={{ width: "100px", height: "100px" }}
                />
              )}
              {!targetedQuestionList[currentStepNum].description.includes(
                "<img",
                0
              ) && (
                <div
                  style={{
                    fontSize: "16px",
                  }}

                  // dangerouslySetInnerHTML={{
                  //   __html: targetedQuizQuestionList[currentStepNum].description
                  // }}
                >
                  {getEnText(
                    targetedQuestionList[currentStepNum].description,
                    translation.translationObj
                  ).isHtml ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: getEnText(
                          targetedQuestionList[currentStepNum].description,
                          translation.translationObj
                        ).text,
                      }}
                    />
                  ) : (
                    <span style={{ fontFamily: "Poppins-Regular" }}>
                      {
                        getEnText(
                          targetedQuestionList[currentStepNum].description,
                          translation.translationObj
                        ).text
                      }
                    </span>
                  )}
                </div>
              )}
              <div
                style={{
                  fontSize: "16px",
                }}
                // dangerouslySetInnerHTML={{
                //   __html: targetedQuizQuestionList[currentStepNum].question
                // }}
              >
                {getEnText(
                  targetedQuestionList[currentStepNum].question,
                  translation.translationObj
                ).isHtml ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: getEnText(
                        targetedQuestionList[currentStepNum].question,
                        translation.translationObj
                      ).text,
                    }}
                  />
                ) : (
                  <span style={{ fontFamily: "Poppins-Regular" }}>
                    {
                      getEnText(
                        targetedQuestionList[currentStepNum].question,
                        translation.translationObj
                      ).text
                    }
                  </span>
                )}
              </div>
              <Row>
                {targetedQuestionList[currentStepNum].choices.map(
                  (item, idx) => (
                    <Fragment key={idx}>
                      <Col
                        className={
                          "contentType8-quiz-test-mode" +
                          (quizTestObj.tappedAnswerIdx === idx
                            ? " selected"
                            : " not-selected") +
                          (quizTestObj.isAnswerRevealed
                            ? idx ===
                              targetedQuestionList[currentStepNum].answer
                              ? " correct"
                              : " not-correct"
                            : "")
                        }
                        offset={2}
                        span={20}
                        style={{
                          fontFamily: "Poppins-Bold",
                          fontSize: "16px",
                          color: "#4252BE",
                          borderRadius: "24px",
                          border: "1px solid #4252BE",
                          marginTop: "16px",
                          padding: "8px",
                          cursor: "pointer",
                          // background:
                          //   targetedQuestionList[currentStepNum].answer ===
                          //   idx
                          //     ? "#D0F8CF"
                          //     : "white"
                        }}
                        onClick={
                          quizTestObj.isAnswerRevealed
                            ? null
                            : () => this.captureTappedAnswerIdx(idx)
                        }
                      >
                        <div style={{ display: "flex" }}>
                          <img
                            src={
                              quizTestObj.isAnswerRevealed
                                ? idx ===
                                  targetedQuestionList[currentStepNum].answer
                                  ? "/assets/checked-circle-icon.svg"
                                  : "/assets/clear-circle-icon.svg"
                                : "/assets/checked-circle-icon.svg"
                            }
                            alt=""
                            style={{
                              // display:
                              //   targetedQuizQuestionList[currentStepNum]
                              //     .answer === idx
                              //     ? "block"
                              //     : "none",
                              display: quizTestObj.isAnswerRevealed
                                ? idx ===
                                    targetedQuestionList[currentStepNum]
                                      .answer ||
                                  idx === quizTestObj.tappedAnswerIdx
                                  ? "block"
                                  : "none"
                                : "none",
                              position: "absolute",
                              top: "50%",
                              left: "24px",
                              transform: "translate(-50%,-50%)",
                            }}
                          />

                          <span
                            style={{
                              padding: "0px 28px",
                              width: "100%",
                            }}
                          >
                            {item}
                          </span>
                        </div>
                      </Col>
                      <Col span={7} style={{ marginTop: "16px" }} />
                    </Fragment>
                  )
                )}
              </Row>
              <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
              <br /> <br /> <br />
            </Col>

            <Col
              span={24}
              className="footer"
              style={{
                borderTop: "4px solid #F1F1F1",
              }}
            >
              {quizTestObj.isAnswerRevealed &&
                quizTestObj.tappedAnswerIdx ===
                  targetedQuestionList[currentStepNum].answer && (
                  <div
                    style={{
                      position: "absolute",
                      top: "-173px",
                      left: 0,
                      width: "100%",
                      height: "170px",
                      textAlign: "left",
                      background: "#E7FAE7",
                    }}
                  >
                    <img
                      src="/assets/owl-watching-icon.svg"
                      alt=""
                      style={{
                        position: "absolute",
                        top: "-40px",
                        right: 0,
                        paddingRight: "20px",
                      }}
                    />
                    <div style={{ display: "flex" }}>
                      <img
                        src="/assets/checked-circle-icon.svg"
                        alt=""
                        style={{ margin: "16px", width: "32px" }}
                      />
                      <div
                        style={{
                          fontFamily: "Poppins-Bold",
                          fontSize: "30px",
                          color: "#049F00",
                          lineHeight: "normal",
                          margin: "16px",
                        }}
                      >
                       {
                    getNativeLangTransText(
                      dbAuth.userInfo.nativeLanguage,
                      configuration.projectUI.correct_answer,
                      translation.translationObj
                    ).text
                  }
                      </div>
                    </div>
                    <div
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "25px",
                        color: "#049F00",
                        margin: "16px",
                      }}
                    >
                      {
                    getNativeLangTransText(
                      dbAuth.userInfo.nativeLanguage,
                      configuration.projectUI.click_next_to_proceed_to_the_next_question,
                      translation.translationObj
                    ).text
                  }
                    </div>
                  </div>
                )}

              {quizTestObj.isAnswerRevealed &&
                quizTestObj.tappedAnswerIdx !==
                  targetedQuestionList[currentStepNum].answer && (
                  <div
                    style={{
                      position: "absolute",
                      top: "-173px",
                      left: 0,
                      width: "100%",
                      height: "170px",
                      textAlign: "left",
                      background: "#FFF7F7",
                    }}
                  >
                    <img
                      src="/assets/owl-watching-icon.svg"
                      alt=""
                      style={{
                        position: "absolute",
                        top: "-40px",
                        right: 0,
                        paddingRight: "20px",
                      }}
                    />
                    <div style={{ display: "flex" }}>
                      <img
                        src="/assets/clear-circle-icon.svg"
                        alt=""
                        style={{ margin: "16px", width: "32px" }}
                      />

                      <div
                        style={{
                          fontFamily: "Poppins-Bold",
                          fontSize: "30px",
                          color: "#ED1C24",
                          lineHeight: "normal",
                          margin: "16px",
                        }}
                      >
                        {
                          getNativeLangTransText(
                            dbAuth.userInfo.nativeLanguage,
                            configuration.projectUI.wrong_answer,
                            translation.translationObj
                          ).text
                        }
                      </div>
                    </div>
                    <div
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "25px",
                        color: "#ED1C24",
                        margin: "16px",
                      }}
                    >
                     {
                          getNativeLangTransText(
                            dbAuth.userInfo.nativeLanguage,
                            configuration.projectUI.please_refer_to_the_above_for_the_correct_answer,
                            translation.translationObj
                          ).text
                        }
                    </div>
                  </div>
                )}

              {quizTestObj.isAnswerRevealed ? (
                <Button
                  type="primary"
                  style={{
                    margin: "24px 0px",
                    borderRadius: "24px",
                    height: "auto",
                    fontFamily: "Poppins-Bold",
                    fontSize: "23px",
                    width: "250px",
                    boxShadow: "0px 6px 0 #2A3580",
                  }}
                  onClick={this.onNextButtonClick}
                >
                  {
                    getNativeLangTransText(
                      dbAuth.userInfo.nativeLanguage,
                      configuration.projectUI.next,
                      translation.translationObj
                    ).text
                  }
                </Button>
              ) : (
                <Button
                  disabled={quizTestObj.tappedAnswerIdx === -1}
                  type="primary"
                  style={{
                    margin: "24px 0px",
                    borderRadius: "24px",
                    height: "auto",
                    fontFamily: "Poppins-Bold",
                    fontSize: "23px",
                    width: "250px",
                    boxShadow:
                      quizTestObj.tappedAnswerIdx === -1
                        ? "0px 6px 0 #D4D4D4"
                        : "0px 6px 0 #2A3580",
                  }}
                  // disabled={quizTestObj.tappedAnswerIdx === -1}
                  onClick={this.onCheckAnswerButtonClick}
                >
                  {
                    getNativeLangTransText(
                      dbAuth.userInfo.nativeLanguage,
                      configuration.projectUI.check,
                      translation.translationObj
                    ).text
                  }
                </Button>
              )}
            </Col>
          </Row>
        </Spin>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  course: state.course,
  translation: state.translation,
  dbAuth: state.auth,
  operation: state.operation,
  achievement: state.achievement,
  configuration: state.configuration,
});

const mapDispatchToProps = {
  updateQuizProgress,
  updateQuizAchievementProgress,
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(ContentType8Page);
