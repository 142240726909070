import { LOAD_ALL_LANGUAGE_INFO } from "./generalConstant";

export const loadAllLanguageInfo = () => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();
    try {
      let supportedLanguagesDoc = await db
        .collection("configuration")
        .doc("supported_languages")
        .get();

      //console.log(supportedLanguagesDoc.data().languageList)
      let languageList = supportedLanguagesDoc.data().languageList;

      const firebase = getFirebase();
      const storageRef = firebase.storage().ref();

      languageList.forEach(async (language) => {
        language.imageUrl = await Promise.resolve(
          storageRef
            .child(language.imagePath)
            .getDownloadURL()
        );
      });

      let projectUIDoc = await db
        .collection("configuration")
        .doc("project_UI")
        .get();
      let projectUI = projectUIDoc.data();

      let dataCollectionsDoc = await db
        .collection("data_collections")
        .doc("general")
        .get();

      let dataInfoObj = dataCollectionsDoc.data();

      dispatch({
        type: LOAD_ALL_LANGUAGE_INFO,
        payload: {
          languageList,
          projectUI,
          dataInfoObj
        },
      });
    } catch (err) {
      console.log(err);
    }
  };
};
