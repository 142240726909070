import React, { Component, Fragment } from "react";
import "./ThisWeekPage.css";
import { Row, Col } from "antd";
import WeeklyGoal from "../../WeeklyGoal/WeeklyGoal";
import BottomBar from "../../../BottomBar/BottomBar";
import UserDashboardNavBar from "../../UserDashboardNavBar/UserDashboardNavBar";
import UserDashboardNavBottomBar from "../../UserDashboardNavBar/UserDashboardNavBottomBar";
import ReactPlayer from "react-player";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";
import { withFirebase } from "react-redux-firebase";
import { courseColorMap } from "../../../SharedConstant";
import LoadingComponent from "../../../app/common/loading/LoadingComponent";

class ThisWeekPage extends Component {
  state = {
    loading: true,
    dbAuth: this.props.dbAuth,
    course: this.props.course,
    translation: this.props.translation,
    achievement: this.props.achievement,
  };
  async componentDidMount() {
    const { achievement } = this.state;
    const { firebase } = this.props;
    const storageRef = firebase.storage().ref();

    let video = await Promise.resolve(
      storageRef
        .child(achievement.currentWeekAchievement.video.path)
        .getDownloadURL()
    );

    this.setState({
      loading: false,
      loadedAchievement: achievement,
      video: video,
    });
  }

  onProgress = (progress) => {
    if (progress.played === 1) {
      console.log(progress.played);
    }
  };

  render() {
    const { loadedAchievement, loading, video, course, translation } =
      this.state;
    const { onHandleResetLabelClick } = this.props;
    if (loading) return <LoadingComponent />;
    return (
      <Fragment>
        <UserDashboardNavBar
          onHandleResetLabelClick={onHandleResetLabelClick}
        />
        <Row style={{ marginBottom: "16px" }}>
          <Col offset={6} span={12}>
            <Row>
              <Col span={12}>
                <Row>
                  <Col span={24}>
                    <div
                      style={{
                        fontFamily: "Poppins-Bold",
                        fontSize: "35px",
                        lineHeight: "normal"
                      }}
                    >
                      {loadedAchievement.currentWeekAchievement.video.name}
                    </div>
                    <div
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "23px"
                      }}
                    >
                      Watch the weekly video!
                    </div>

                    <br />

                    <div
                      style={{
                        position: "relative",
                        paddingTop: "56.25%"
                      }}
                    >
                      <ReactPlayer
                        url={video}
                        style={{ position: "absolute", top: 0, left: 0 }}
                        controls
                        width="100%"
                        height="100%"
                        onProgress={this.onProgress}
                      />
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <a
                        href="/user-dashboard/this-week/previous-video"
                        style={{
                          fontFamily: "Poppins-Bold",
                          fontSize: "25px",
                          color: "#4252BE"
                        }}
                      >
                        Browse all previous videos
                      </a>
                    </div>

                    <br />
                    <div
                      style={{
                        fontFamily: "Poppins-Bold",
                        fontSize: "35px",
                      }}
                    >
                      Recommended
                    </div>
                    <Row>
                      {loadedAchievement.currentWeekAchievement.lesson.recommended.map(
                        (item, idx) => {
                          let topicName = "";
                          // console.log(course.courseInfoObj)
                          for (
                            var i = 0;
                            i < course.courseInfoObj.length;
                            i++
                          ) {
                            if (
                              course.courseInfoObj[i].docId === item.courseId
                            ) {
                              for (
                                var x = 0;
                                x < course.courseInfoObj[i].topics.length;
                                x++
                              ) {
                                if (
                                  course.courseInfoObj[i].topics[x]
                                    .topicName === item.topicId
                                ) {
                                  if (
                                    translation.translationObj.en[
                                      course.courseInfoObj[i].topics[x].name
                                    ].newText === ""
                                  )
                                    topicName =
                                      translation.translationObj.en[
                                        course.courseInfoObj[i].topics[x].name
                                      ].text;
                                  else
                                    topicName =
                                      translation.translationObj.en[
                                        course.courseInfoObj[i].topics[x].name
                                      ].newText;
                                }
                              }
                            }
                          }
                          return (
                            <Col
                              span={16}
                              style={{ marginTop: "24px" }}
                              key={idx}
                            >
                              <div
                                style={{
                                  boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                                  borderRadius: "24px",
                                  border: "1px solid rgba(112,112,112,0.4)",
                                  fontFamily: "Poppins-Regular",
                                  fontSize: "23px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  padding: "40px",
                                  cursor: "pointer",
                                  backgroundColor: courseColorMap.get(
                                    item.courseId
                                  ).hexColorCode,
                                }}
                                onClick={() =>
                                  this.props.history.push(
                                    `/user-dashboard/courses/${item.courseId}/topics/${item.topicId}`
                                  )
                                }
                              >
                                {topicName}
                              </div>
                            </Col>
                          );
                        }
                      )}

                      {/* <Col span={16} style={{ marginTop: "24px" }}>
                        <div
                          style={{
                            boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                            borderRadius: "24px",
                            border: "1px solid rgba(112,112,112,0.4)",
                            fontFamily: "Poppins-Regular",
                            fontSize: "23px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "40px",
                            cursor: "pointer"
                          }}
                          onClick={() =>
                            this.props.history.push(
                              "/user-dashboard/courses/beginner/topics/sir2000"
                            )
                          }
                        >
                          S.I.R 2000
                        </div>
                      </Col>
                      <Col span={8} /> */}
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col offset={1} span={11}>
                <WeeklyGoal />
              </Col>
            </Row>
          </Col>
          <Col span={6} />
        </Row>

        <Row>
          <Col xs={0} sm={0} md={24} lg={24} xl={24}>
            <BottomBar />
          </Col>
          <Col
            xs={0}
            sm={24}
            md={0}
            lg={0}
            xl={0}
            style={{ marginBottom: "100px" }}
          >
            <BottomBar />
          </Col>
          <Col
            xs={24}
            sm={0}
            md={0}
            lg={0}
            xl={0}
            style={{ marginBottom: "70px" }}
          >
            <BottomBar />
          </Col>
        </Row>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={0}
            lg={0}
            xl={0}
            style={{
              textAlign: "center",
              borderTop: "4px solid #F1F1F1",
            }}
            className="footer"
          >
            <UserDashboardNavBottomBar
              onHandleResetLabelClick={onHandleResetLabelClick}
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  dbAuth: state.auth,
  achievement: state.achievement,
  course: state.course,
  translation: state.translation,
});

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, null)
)(ThisWeekPage);
