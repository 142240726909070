import React, { Component, Fragment } from "react";
import "./SelfRecording.css";
import AudioAnalyser from "./AudioAnalyser";
import { getNativeLangTransText } from "../../SharedUtils";
import { compose } from "redux";
import { connect } from "react-redux";

let mediaRecorder;
let chunks = [];

class SelfRecording extends Component {
  state = {
    hideRecording: false, //mainly to hide safari(for now)
    isRecording: false,
    mediaStream: null,
    recordedAudioBlob: null,
    savedCurrentStepNum: null,
  };

  componentDidMount() {
    this.setState({
      //hideRecording: this.isSafari()
    });
  }

  componentDidUpdate() {
    const { savedCurrentStepNum } = this.state;
    const { currentStepNum } = this.props;

    if (savedCurrentStepNum !== currentStepNum) {
      //force reset
      this.setState({
        isRecording: false,
        mediaStream: null,
        recordedAudioBlob: null,
        savedCurrentStepNum: currentStepNum,
      });
    }
  }

  //https://stackoverflow.com/questions/7944460/detect-safari-browser
  isSafari() {
    var ua = window.navigator.userAgent;
    var iOS = !!ua.match(/iP(ad|od|hone)/i);
    var hasSafariInUa = !!ua.match(/Safari/i);
    var noOtherBrowsersInUa = !ua.match(
      /Chrome|CriOS|OPiOS|mercury|FxiOS|Firefox/i
    );
    var result = false;
    if (iOS) {
      //detecting Safari in IOS mobile browsers
      var webkit = !!ua.match(/WebKit/i);
      result = webkit && hasSafariInUa && noOtherBrowsersInUa;
    } else if (window.safari !== undefined) {
      //detecting Safari in Desktop Browsers
      result = true;
    } else {
      // detecting Safari in other platforms
      result = hasSafariInUa && noOtherBrowsersInUa;
    }
    return result;
  }

  onMediaRecorderStop = () => {
    // const blob = new Blob(chunks, { type: "audio/ogg; codecs=opus" });
    let blob = new Blob(chunks, { type: "audio/mp3" });
    chunks = [];

    this.state.mediaStream.getTracks().forEach((track) => track.stop());

    this.setState({
      isRecording: false,
      mediaStream: null,
      recordedAudioBlob: window.URL.createObjectURL(blob),
    });
  };

  startRecording = async () => {
    try {
      //recorder init
      let mediaStream = await navigator.mediaDevices.getUserMedia(
        // constraints - only audio needed for this app
        {
          audio: true,
        }
      );
      mediaRecorder = new MediaRecorder(mediaStream);

      //setup few listeners
      mediaRecorder.ondataavailable = function (e) {
        chunks.push(e.data);
      };
      mediaRecorder.addEventListener("stop", this.onMediaRecorderStop);

      //after init,start recording
      mediaRecorder.start();

      this.setState({
        isRecording: true,
        mediaStream,
      });
    } catch (err) {
      console.log(err);
    }
  };

  stopRecording = () => {
    mediaRecorder.stop();
  };

  render() {
    const { hideRecording, mediaStream, isRecording, recordedAudioBlob } =
      this.state;
    const { dbAuth, translation, configuration } = this.props;
    return (
      <Fragment>
        <div
          style={{
            // position: "absolute",
            // top: "-110px",
            // left: "50%",
            // transform: "translate(-50%,-50%)",
            display: hideRecording ? "none" : "block",
          }}
        >
          <div
            style={{ cursor: "pointer" }}
            className={
              "self-recording" + (isRecording ? " not-recording" : " recording")
            }
            onClick={this.startRecording}
          >
            <img src="/assets/microphone-icon.svg" alt="" />
            <div
              style={{
                fontFamily: "Poppins-Bold",
                fontSize: "20px",
                color: "#4252BE",
              }}
            >
              {
                getNativeLangTransText(
                  dbAuth.userInfo.nativeLanguage,
                  configuration.projectUI.tap_here_to_start_recording,
                  translation.translationObj
                ).text
              }
            </div>
          </div>

          <div
            className={
              "self-recording" + (isRecording ? " recording" : " not-recording")
            }
            style={{
              fontFamily: "Poppins-Bold",
              fontSize: "22px",
              color: "#4252BE",
              cursor: "pointer",
            }}
            onClick={this.stopRecording}
          >
            {mediaStream && <AudioAnalyser mediaStream={mediaStream} />}
            <br />
            {
              getNativeLangTransText(
                dbAuth.userInfo.nativeLanguage,
                configuration.projectUI.stop,
                translation.translationObj
              ).text
            }
          </div>

          {recordedAudioBlob && !isRecording && (
            <audio controls controlsList="nodownload" src={recordedAudioBlob} />
          )}
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  dbAuth: state.auth,
  configuration: state.configuration,
  translation: state.translation,
});
export default compose(connect(mapStateToProps))(SelfRecording);
