import React, { Component, Fragment } from "react";
import "./LessonGroupPage.css";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  loadTargetedCourseLessonGroup,
  resetTargetedCourseLessonGroup,
} from "../../../course/courseActions";
import BottomBar from "../../../BottomBar/BottomBar";
import { Col, Row, Breadcrumb } from "antd";
import WeeklyGoal from "../../WeeklyGoal/WeeklyGoal";
import UserDashboardNavBar from "../../UserDashboardNavBar/UserDashboardNavBar";
import UserDashboardNavBottomBar from "../../UserDashboardNavBar/UserDashboardNavBottomBar";
import LoadingComponent from "../../../app/common/loading/LoadingComponent";
import { courseStylingMap, courseColorMap } from "../../../SharedConstant";
import { Link } from "react-router-dom";
import _ from "lodash";
import { withFirebase } from "react-redux-firebase";
import { getEnText, getNativeLangTransText } from "../../../SharedUtils";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
//import "react-circular-progressbar/dist/styles.css"; //put in under App.jsx for consistency

class LessonGroupPage extends Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    const { match } = this.props;
    let courseId = match.params.courseId;
    let topicId = match.params.topicId;
    let subTopicId = match.params.subTopicId;

    this.setState(
      {
        courseId,
        topicId,
        subTopicId,
      },
      () =>
        this.props.loadTargetedCourseLessonGroup(courseId, topicId, subTopicId)
    );
  }

  componentWillUnmount() {
    this.props.resetTargetedCourseLessonGroup();
  }

  async componentDidUpdate(prevProps) {
    const { courseId, topicId, subTopicId } = this.state;
    const { course, dbAuth, history, firebase } = this.props;
    const storageRef = firebase.storage().ref();
    if (
      !_.isEqual(
        prevProps.course.lessonGroupInfoObj,
        course.lessonGroupInfoObj
      ) &&
      course.lessonGroupInfoObj !== null
    ) {
      if (course.lessonGroupInfoObj.lessonGroupInfoList.length === 1) {
        history.push(
          `/user-dashboard/courses/${courseId}/topics/${topicId}/subTopics/${subTopicId}/lessons/${course.lessonGroupInfoObj.lessonGroupInfoList[0].docId}`
        );
      } else {
        //track progress here - start with lessons
        let progressObj = {
          courseObj: {},
          //achivement:{},
        };

        //use docId as identifier to track all possible scenes progress(if any)
        course.lessonGroupInfoObj.lessonGroupInfoList.forEach((item) => {
          let targetedLessonId = `${topicId}-${subTopicId}-${item.docId}`;
          let lessonIdList = null;
          if (
            dbAuth.progressInfo.lessons &&
            dbAuth.progressInfo.lessons[courseId] &&
            dbAuth.progressInfo.lessons[courseId][targetedLessonId]
          ) {
            lessonIdList =
              dbAuth.progressInfo.lessons[courseId][targetedLessonId];
          }

          if (lessonIdList) {
            let remainingLessonList = [];

            //check completion rate
            remainingLessonList = item.lessons.filter(
              (item) => !lessonIdList.includes(item.lessonId)
            );

            if (remainingLessonList.length === 0) {
              progressObj.courseObj[item.docId] = 100;
            } else {
              progressObj.courseObj[item.docId] =
                ((item.lessons.length - remainingLessonList.length) /
                  item.lessons.length) *
                100;
            }
          } else {
            progressObj.courseObj[item.docId] = 0;
          }
        });

        let imageUrlPromises = [];

        course.lessonGroupInfoObj.lessonGroupInfoList.forEach((item) => {
          if (item.imagePath && item.imagePath.length > 0) {
            imageUrlPromises.push(
              storageRef.child(item.imagePath).getDownloadURL()
            );
          }
        });

        let iconImageUrl = await Promise.all(imageUrlPromises);

        this.setState({
          isLoading: false,
          iconImageUrl,
          progressObj,
        });
      }
    }
  }
  onCourseClick = () => {
    this.props.history.push(`/user-dashboard/courses`);
  };

  onCourseTopicClick = () => {
    const { courseId } = this.state;
    this.props.history.push(`/user-dashboard/courses/${courseId}`);
  };

  render() {
    const {
      isLoading,
      courseId,
      topicId,
      subTopicId,
      iconImageUrl,
      progressObj,
    } = this.state;
    const {
      course,
      translation,
      dbAuth,
      onHandleResetLabelClick,
      configuration,
    } = this.props;

    if (isLoading) return <LoadingComponent />;
    return (
      <Fragment>
        <UserDashboardNavBar
          onHandleResetLabelClick={onHandleResetLabelClick}
        />
        <Row>
          <Col className="page-container" span={24}>
            <Row style={{ marginBottom: "16px" }}>
              <Col xs={2} sm={4} md={1} lg={2} xl={4} xxl={4} />
              <Col
                xs={20}
                sm={16}
                md={22}
                lg={20}
                xl={16}
                xxl={16}
                className="content-wrap"
              >
                <Breadcrumb
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "22px",
                    marginBottom: "16px",
                  }}
                >
                  <Breadcrumb.Item onClick={this.onCourseClick}>
                    {
                      getNativeLangTransText(
                        dbAuth.userInfo.nativeLanguage,
                        configuration.projectUI.course,
                        translation.translationObj
                      ).text
                    }
                  </Breadcrumb.Item>
                  <Breadcrumb.Item onClick={this.onCourseTopicClick}>
                    {courseColorMap.get(courseId).label === "White"
                      ? getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          configuration.projectUI.white,
                          translation.translationObj
                        ).text
                      : courseColorMap.get(courseId).label === "Yellow"
                      ? getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          configuration.projectUI.yellow,
                          translation.translationObj
                        ).text
                      : courseColorMap.get(courseId).label === "Green"
                      ? getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          configuration.projectUI.green,
                          translation.translationObj
                        ).text
                      : getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          configuration.projectUI.blue,
                          translation.translationObj
                        ).text}
                  </Breadcrumb.Item>

                  <Breadcrumb.Item
                    href={`/user-dashboard/courses/${courseId}/topics/${topicId}`}
                  >
                    {getNativeLangTransText(
                      dbAuth.userInfo.nativeLanguage,
                      course.lessonGroupInfoObj.topicInfoObj.name,
                      translation.translationObj
                    ).text.length > 0
                      ? getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          course.lessonGroupInfoObj.topicInfoObj.name,
                          translation.translationObj
                        ).text
                      : getEnText(
                          course.lessonGroupInfoObj.topicInfoObj.name,
                          translation.translationObj
                        ).text}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    {getNativeLangTransText(
                      dbAuth.userInfo.nativeLanguage,
                      course.lessonGroupInfoObj.subTopicInfoObj.name,
                      translation.translationObj
                    ).text.length > 0
                      ? getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          course.lessonGroupInfoObj.subTopicInfoObj.name,
                          translation.translationObj
                        ).text
                      : getEnText(
                          course.lessonGroupInfoObj.subTopicInfoObj.name,
                          translation.translationObj
                        ).text}
                  </Breadcrumb.Item>
                </Breadcrumb>
                <Row>
                  <Col xs={0} sm={0} md={14} lg={14} xl={14} xxl={14}>
                    <Row>
                      <Col span={24} style={{ marginTop: "16px" }}>
                        <Row>
                          <Col span={24}>
                            <Link
                              style={{
                                fontFamily: "Poppins-Bold",
                                fontSize: "22px",
                                color: "#4252BE",
                                cursor: "pointer",
                              }}
                              to={`/user-dashboard/courses/${courseId}/topics/${topicId}`}
                            >
                              {"< " +
                                getNativeLangTransText(
                                  dbAuth.userInfo.nativeLanguage,
                                  configuration.projectUI.back,
                                  translation.translationObj
                                ).text}
                            </Link>
                          </Col>
                          <Col span={24} style={{ marginTop: "16px" }}>
                            {getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              course.lessonGroupInfoObj.subTopicInfoObj.name,
                              translation.translationObj
                            ).text.length > 0 && (
                              <div
                                style={{
                                  fontFamily: "Poppins-Bold",
                                  fontSize: "35px",
                                  lineHeight: "normal",
                                  whiteSpace: "normal",
                                  overflowWrap: "break-word",
                                }}
                              >
                                {
                                  getNativeLangTransText(
                                    dbAuth.userInfo.nativeLanguage,
                                    course.lessonGroupInfoObj.subTopicInfoObj
                                      .name,
                                    translation.translationObj
                                  ).text
                                }
                              </div>
                            )}

                            {getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              course.lessonGroupInfoObj.subTopicInfoObj
                                .description,
                              translation.translationObj
                            ).text.length > 0 && (
                              <div
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "23px",
                                  lineHeight: "normal",
                                  whiteSpace: "normal",
                                  overflowWrap: "break-word",
                                }}
                              >
                                {
                                  getNativeLangTransText(
                                    dbAuth.userInfo.nativeLanguage,
                                    course.lessonGroupInfoObj.subTopicInfoObj
                                      .description,
                                    translation.translationObj
                                  ).text
                                }
                              </div>
                            )}

                            {/* Style Code 0 */}
                            {courseStylingMap.get(courseId) &&
                              courseStylingMap.get(courseId)[topicId]
                                .groupStyleCode === 0 && (
                                <Row
                                  gutter={[16, 32]}
                                  style={{ marginTop: "16px" }}
                                >
                                  {course.lessonGroupInfoObj.lessonGroupInfoList.map(
                                    (lessonGroupInfo, idx) => (
                                      <Col span={8} key={idx}>
                                        <div style={{ width: "90px" }}>
                                          <Link
                                            to={{
                                              pathname: `/user-dashboard/courses/${courseId}/topics/${topicId}/subTopics/${subTopicId}/lessons/${lessonGroupInfo.docId}`,
                                            }}
                                          >
                                            <CircularProgressbarWithChildren
                                              value={0}
                                              strokeWidth={4}
                                              styles={{
                                                //path: { stroke: "#00FF00" },
                                                //path: { stroke: "red" },
                                                path: { stroke: "transparent" },
                                                trail: {
                                                  stroke: "transparent",
                                                },
                                              }}
                                            >
                                              <div style={{ width: "92%" }}>
                                                <CircularProgressbar
                                                  strokeWidth={4}
                                                  background={true}
                                                  value={
                                                    progressObj.courseObj[
                                                      lessonGroupInfo.docId
                                                    ]
                                                  }
                                                  //  backgroundPadding={20}
                                                  text={lessonGroupInfo.name}
                                                  styles={{
                                                    path: { stroke: "#00FF00" },
                                                    //path: { stroke: "transparent" },
                                                    trail: {
                                                      stroke:
                                                        "rgb(211,211,211)",
                                                      // stroke: "transparent",
                                                    },
                                                    text: {
                                                      fontFamily:
                                                        "Poppins-Bold",
                                                      fontSize: "20px",
                                                      fill: "black",
                                                    },
                                                    // background: {
                                                    //   fill: "#7EC4FB"
                                                    // }
                                                    background: {
                                                      fill: courseColorMap.get(
                                                        courseId
                                                      ).hexColorCode,
                                                    },
                                                  }}
                                                />
                                              </div>
                                            </CircularProgressbarWithChildren>
                                          </Link>
                                        </div>

                                        {/* <Link
                                      to={{
                                        pathname: `/user-dashboard/courses/${courseId}/topics/${topicId}/subTopics/${subTopicId}/lessons/${lessonGroupInfo.docId}`
                                      }}
                                      style={{ color: "black" }}
                                    >
                                      <div
                                        style={{
                                          cursor: "pointer",
                                          fontFamily: "Poppins-Bold",
                                          fontSize: "20px",
                                          borderRadius: "50%",
                                          width: "80px",
                                          lineHeight: "80px",
                                          border:
                                            "1px solid rgba(112, 112, 112, 0.4)",
                                          boxShadow:
                                            "0 0 5px rgba(0, 0, 0, 0.14)",
                                          textAlign: "center",
                                          background: courseColorMap.get(
                                            courseId
                                          ).hexColorCode
                                        }}
                                      >
                                        {lessonGroupInfo.name}
                                      </div>
                                    </Link> */}
                                      </Col>
                                    )
                                  )}
                                </Row>
                              )}

                            {/* Style Code 1 */}
                            {courseStylingMap.get(courseId) &&
                              courseStylingMap.get(courseId)[topicId]
                                .groupStyleCode === 1 && (
                                <Row>
                                  {course.lessonGroupInfoObj.lessonGroupInfoList.map(
                                    (lessonGroupInfo, idx) => (
                                      <Col
                                        span={24}
                                        key={idx}
                                        style={{ marginTop: "24px" }}
                                      >
                                        {/* {console.log(
                                      progressObj.courseObj[
                                        lessonGroupInfo.docId
                                      ]
                                    )} */}
                                        <Link
                                          to={`/user-dashboard/courses/${courseId}/topics/${topicId}/subTopics/${subTopicId}/lessons/${lessonGroupInfo.docId}`}
                                          style={{
                                            color: "black",
                                            boxShadow:
                                              "0 0 5px rgba(0,0,0,0.14)",
                                            borderRadius: "24px",
                                            border:
                                              progressObj.courseObj[
                                                lessonGroupInfo.docId
                                              ] === 100
                                                ? "3px solid green"
                                                : "1px solid rgba(112,112,112,0.4)",
                                            fontFamily: "Poppins-Regular",
                                            fontSize: "23px",
                                            display: "flex",
                                            alignItems: "center",
                                            padding: "8px 24px",
                                            background:
                                              courseColorMap.get(courseId)
                                                .hexColorCode,
                                          }}
                                        >
                                          {iconImageUrl.length > 0 && (
                                            <img
                                              src={iconImageUrl[idx]}
                                              alt=""
                                            />
                                          )}
                                          {getNativeLangTransText(
                                            dbAuth.userInfo.nativeLanguage,
                                            lessonGroupInfo.lessons[0].title,
                                            translation.translationObj
                                          ).text.length > 0 ? (
                                            <div style={{ marginLeft: "16px" }}>
                                              {
                                                getNativeLangTransText(
                                                  dbAuth.userInfo
                                                    .nativeLanguage,
                                                  lessonGroupInfo.lessons[0]
                                                    .title,
                                                  translation.translationObj
                                                ).text
                                              }
                                            </div>
                                          ) : (
                                            <div style={{ marginLeft: "16px" }}>
                                              {lessonGroupInfo.name}
                                            </div>
                                          )}
                                        </Link>
                                      </Col>
                                    )
                                  )}
                                </Row>
                              )}

                            {/* Style Code 2 */}
                            {courseStylingMap.get(courseId) &&
                              courseStylingMap.get(courseId)[topicId]
                                .groupStyleCode === 2 && (
                                <Row>
                                  {course.lessonGroupInfoObj.lessonGroupInfoList.map(
                                    (lessonGroupInfo, idx) => (
                                      <Col
                                        span={24}
                                        key={idx}
                                        style={{ marginTop: "24px" }}
                                      >
                                        <Link
                                          to={`/user-dashboard/courses/${courseId}/topics/${topicId}/subTopics/${subTopicId}/lessons/${lessonGroupInfo.docId}`}
                                          style={{
                                            color: "black",
                                            boxShadow:
                                              "0 0 5px rgba(0,0,0,0.14)",
                                            borderRadius: "24px",
                                            border:
                                              progressObj.courseObj[
                                                lessonGroupInfo.docId
                                              ] === 100
                                                ? "3px solid green"
                                                : "1px solid rgba(112,112,112,0.4)",
                                            fontFamily: "Poppins-Regular",
                                            fontSize: "23px",
                                            display: "flex",
                                            alignItems: "center",
                                            padding: "8px 24px",
                                            background:
                                              courseColorMap.get(courseId)
                                                .hexColorCode,
                                          }}
                                        >
                                          {iconImageUrl.length > 0 && (
                                            <img
                                              src={iconImageUrl[idx]}
                                              alt=""
                                            />
                                          )}
                                          {getNativeLangTransText(
                                            dbAuth.userInfo.nativeLanguage,
                                            lessonGroupInfo.lessons[0].title,
                                            translation.translationObj
                                          ).text.length > 0 ? (
                                            <div style={{ marginLeft: "16px" }}>
                                              {
                                                getNativeLangTransText(
                                                  dbAuth.userInfo
                                                    .nativeLanguage,
                                                  lessonGroupInfo.lessons[0]
                                                    .title,
                                                  translation.translationObj
                                                ).text
                                              }
                                            </div>
                                          ) : (
                                            <div style={{ marginLeft: "16px" }}>
                                              {lessonGroupInfo.name}
                                            </div>
                                          )}
                                        </Link>
                                      </Col>
                                    )
                                  )}
                                </Row>
                              )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
                    <Row>
                      <Col span={24} style={{ marginTop: "16px" }}>
                        <Row>
                          <Col span={24}>
                            <Link
                              style={{
                                fontFamily: "Poppins-Bold",
                                fontSize: "20px",
                                color: "#4252BE",
                                cursor: "pointer",
                              }}
                              to={`/user-dashboard/courses/${courseId}/topics/${topicId}`}
                            >
                              {"< " +
                                getNativeLangTransText(
                                  dbAuth.userInfo.nativeLanguage,
                                  configuration.projectUI.back,
                                  translation.translationObj
                                ).text}
                            </Link>
                          </Col>
                          <Col span={24} style={{ marginTop: "16px" }}>
                            {getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              course.lessonGroupInfoObj.subTopicInfoObj.name,
                              translation.translationObj
                            ).text.length > 0 && (
                              <div
                                style={{
                                  fontFamily: "Poppins-Bold",
                                  fontSize: "32px",
                                  lineHeight: "normal",
                                  whiteSpace: "normal",
                                  overflowWrap: "break-word",
                                }}
                              >
                                {
                                  getNativeLangTransText(
                                    dbAuth.userInfo.nativeLanguage,
                                    course.lessonGroupInfoObj.subTopicInfoObj
                                      .name,
                                    translation.translationObj
                                  ).text
                                }
                              </div>
                            )}

                            {getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              course.lessonGroupInfoObj.subTopicInfoObj
                                .description,
                              translation.translationObj
                            ).text.length > 0 && (
                              <div
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "22px",
                                  lineHeight: "normal",
                                  whiteSpace: "normal",
                                  overflowWrap: "break-word",
                                }}
                              >
                                {
                                  getNativeLangTransText(
                                    dbAuth.userInfo.nativeLanguage,
                                    course.lessonGroupInfoObj.subTopicInfoObj
                                      .description,
                                    translation.translationObj
                                  ).text
                                }
                              </div>
                            )}

                            {/* Style Code 0 */}
                            {courseStylingMap.get(courseId) &&
                              courseStylingMap.get(courseId)[topicId]
                                .groupStyleCode === 0 && (
                                <Row
                                  gutter={[16, 32]}
                                  style={{ marginTop: "16px" }}
                                >
                                  {course.lessonGroupInfoObj.lessonGroupInfoList.map(
                                    (lessonGroupInfo, idx) => (
                                      <Col span={8} key={idx}>
                                        <div style={{ width: "90px" }}>
                                          <Link
                                            to={{
                                              pathname: `/user-dashboard/courses/${courseId}/topics/${topicId}/subTopics/${subTopicId}/lessons/${lessonGroupInfo.docId}`,
                                            }}
                                          >
                                            <CircularProgressbarWithChildren
                                              value={0}
                                              strokeWidth={4}
                                              styles={{
                                                //path: { stroke: "#00FF00" },
                                                //path: { stroke: "red" },
                                                path: { stroke: "transparent" },
                                                trail: {
                                                  stroke: "transparent",
                                                },
                                              }}
                                            >
                                              <div style={{ width: "92%" }}>
                                                <CircularProgressbar
                                                  strokeWidth={4}
                                                  background={true}
                                                  value={
                                                    progressObj.courseObj[
                                                      lessonGroupInfo.docId
                                                    ]
                                                  }
                                                  //  backgroundPadding={20}
                                                  text={lessonGroupInfo.name}
                                                  styles={{
                                                    path: { stroke: "#00FF00" },
                                                    //path: { stroke: "transparent" },
                                                    trail: {
                                                      stroke:
                                                        "rgb(211,211,211)",
                                                      // stroke: "transparent",
                                                    },
                                                    text: {
                                                      fontFamily:
                                                        "Poppins-Bold",
                                                      fontSize: "20px",
                                                      fill: "black",
                                                    },
                                                    // background: {
                                                    //   fill: "#7EC4FB"
                                                    // }
                                                    background: {
                                                      fill: courseColorMap.get(
                                                        courseId
                                                      ).hexColorCode,
                                                    },
                                                  }}
                                                />
                                              </div>
                                            </CircularProgressbarWithChildren>
                                          </Link>
                                        </div>
                                      </Col>
                                    )
                                  )}
                                </Row>
                              )}

                            {/* Style Code 1 */}
                            {courseStylingMap.get(courseId) &&
                              courseStylingMap.get(courseId)[topicId]
                                .groupStyleCode === 1 && (
                                <Row>
                                  {course.lessonGroupInfoObj.lessonGroupInfoList.map(
                                    (lessonGroupInfo, idx) => (
                                      <Col
                                        span={24}
                                        key={idx}
                                        style={{ marginTop: "24px" }}
                                      >
                                        {/* {console.log(
                                      progressObj.courseObj[
                                        lessonGroupInfo.docId
                                      ]
                                    )} */}
                                        <Link
                                          to={`/user-dashboard/courses/${courseId}/topics/${topicId}/subTopics/${subTopicId}/lessons/${lessonGroupInfo.docId}`}
                                          style={{
                                            color: "black",
                                            boxShadow:
                                              "0 0 5px rgba(0,0,0,0.14)",
                                            borderRadius: "24px",
                                            border:
                                              progressObj.courseObj[
                                                lessonGroupInfo.docId
                                              ] === 100
                                                ? "3px solid green"
                                                : "1px solid rgba(112,112,112,0.4)",
                                            fontFamily: "Poppins-Regular",
                                            fontSize: "20px",
                                            display: "flex",
                                            alignItems: "center",
                                            padding: "8px 24px",
                                            background:
                                              courseColorMap.get(courseId)
                                                .hexColorCode,
                                          }}
                                        >
                                          {iconImageUrl.length > 0 && (
                                            <img
                                              src={iconImageUrl[idx]}
                                              alt=""
                                            />
                                          )}
                                          {getNativeLangTransText(
                                            dbAuth.userInfo.nativeLanguage,
                                            lessonGroupInfo.lessons[0].title,
                                            translation.translationObj
                                          ).text.length > 0 ? (
                                            <div style={{ marginLeft: "16px" }}>
                                              {
                                                getNativeLangTransText(
                                                  dbAuth.userInfo
                                                    .nativeLanguage,
                                                  lessonGroupInfo.lessons[0]
                                                    .title,
                                                  translation.translationObj
                                                ).text
                                              }
                                            </div>
                                          ) : (
                                            <div style={{ marginLeft: "16px" }}>
                                              {lessonGroupInfo.name}
                                            </div>
                                          )}
                                        </Link>
                                      </Col>
                                    )
                                  )}
                                </Row>
                              )}

                            {/* Style Code 2 */}
                            {courseStylingMap.get(courseId) &&
                              courseStylingMap.get(courseId)[topicId]
                                .groupStyleCode === 2 && (
                                <Row>
                                  {course.lessonGroupInfoObj.lessonGroupInfoList.map(
                                    (lessonGroupInfo, idx) => (
                                      <Col
                                        span={24}
                                        key={idx}
                                        style={{ marginTop: "24px" }}
                                      >
                                        <Link
                                          to={`/user-dashboard/courses/${courseId}/topics/${topicId}/subTopics/${subTopicId}/lessons/${lessonGroupInfo.docId}`}
                                          style={{
                                            color: "black",
                                            boxShadow:
                                              "0 0 5px rgba(0,0,0,0.14)",
                                            borderRadius: "24px",
                                            border:
                                              progressObj.courseObj[
                                                lessonGroupInfo.docId
                                              ] === 100
                                                ? "3px solid green"
                                                : "1px solid rgba(112,112,112,0.4)",
                                            fontFamily: "Poppins-Regular",
                                            fontSize: "20px",
                                            display: "flex",
                                            alignItems: "center",
                                            padding: "8px 24px",
                                            background:
                                              courseColorMap.get(courseId)
                                                .hexColorCode,
                                          }}
                                        >
                                          {iconImageUrl.length > 0 && (
                                            <img
                                              src={iconImageUrl[idx]}
                                              alt=""
                                            />
                                          )}
                                          {getNativeLangTransText(
                                            dbAuth.userInfo.nativeLanguage,
                                            lessonGroupInfo.lessons[0].title,
                                            translation.translationObj
                                          ).text.length > 0 ? (
                                            <div style={{ marginLeft: "16px" }}>
                                              {
                                                getNativeLangTransText(
                                                  dbAuth.userInfo
                                                    .nativeLanguage,
                                                  lessonGroupInfo.lessons[0]
                                                    .title,
                                                  translation.translationObj
                                                ).text
                                              }
                                            </div>
                                          ) : (
                                            <div style={{ marginLeft: "16px" }}>
                                              {lessonGroupInfo.name}
                                            </div>
                                          )}
                                        </Link>
                                      </Col>
                                    )
                                  )}
                                </Row>
                              )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>

                  <Col offset={1} xs={0} sm={0} md={9} lg={9} xl={9} xxl={9}>
                    <WeeklyGoal />
                  </Col>
                </Row>
              </Col>
              <Col xs={3} sm={5} md={0} lg={0} xl={0} xxl={0} />
              <Col xs={18} sm={14} md={0} lg={0} xl={0} xxl={0}>
                <WeeklyGoal />
              </Col>
            </Row>
            <Row>
              <Col
                xs={0}
                sm={0}
                md={24}
                lg={24}
                xl={24}
                className="footer-bottom"
              >
                <BottomBar />
              </Col>
              <Col
                xs={0}
                sm={24}
                md={0}
                lg={0}
                xl={0}
                style={{ marginBottom: "100px" }}
              >
                <BottomBar />
              </Col>
              <Col
                xs={24}
                sm={0}
                md={0}
                lg={0}
                xl={0}
                style={{ marginBottom: "70px" }}
              >
                <BottomBar />
              </Col>
            </Row>
            <Row>
              <Col
                xs={24}
                sm={24}
                md={0}
                lg={0}
                xl={0}
                style={{
                  textAlign: "center",
                  borderTop: "4px solid #F1F1F1",
                }}
                className="footer"
              >
                <UserDashboardNavBottomBar
                  onHandleResetLabelClick={onHandleResetLabelClick}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  course: state.course,
  translation: state.translation,
  dbAuth: state.auth,
  configuration: state.configuration,
});

const mapDispatchToProps = {
  loadTargetedCourseLessonGroup,
  resetTargetedCourseLessonGroup,
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(LessonGroupPage);
