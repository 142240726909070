import React, { Component, Fragment } from "react";
import { Row, Col, Button, Collapse, Form, Input, message } from "antd";
import "../../HomePage.less";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";
import { withFirebase } from "react-redux-firebase";
import { resetEmailPassword } from "../../../auth/authActions";

// import Bowser from "bowser"
const { Panel } = Collapse;

class ForgotPassword extends Component {
  state = { isResetMailSent: false, isResetting: false };

  onresetClick = (values) => {
    this.setState(
      {
        isResetting: true,
      },
      async () => {
        let errorObj = await this.props.resetEmailPassword(values.emailToReset);
        if (errorObj) {
          message.error(errorObj.code + ":" + errorObj.message);
          this.setState({
            isResetting: false,
          });
        } else {
          this.setState({
            isResetting: false,
            isResetMailSent: true,
          });
        }
      }
    );
  };

  onBackToLogin = () => {
    window.history.back();
  };

  render() {
    const { dbAuth } = this.props;
    const { isResetMailSent, isResetting } = this.state;
    return (
      <Fragment>
        {isResetMailSent ? (
          <Row style={{ margin: "30px" }}>
            <Col
              xs={0}
              md={24}
              style={{
                textAlign: "center",
                position: "fixed",
                top: "50%",
                left: "50%",
                marginTop: "-220px",
                marginLeft: "-200px",
                borderRadius: "10px",
                padding: "30px",
                boxShadow:
                  "0px 1px 2px 0px #f2f2f2, 1px 2px 4px 0px #f2f2f2, 2px 4px 8px 0px #f2f2f2, 2px 4px 16px 0px #f2f2f2",
              }}
            >
              <img
                src="/assets/Forgot-Password/Successful-Icon.svg"
                alt="successful"
              />
              <div
                style={{
                  font: "24px Poppins-Bold",
                  margin: "40px 0px 0px 0px",
                  color: "black",
                }}
              >
                Password has been reset
              </div>
              <div
                style={{
                  font: "24px Poppins-Bold",
                  margin: "0px 0px 40px 0px",
                  color: "black",
                }}
              >
                successfully!
              </div>
              <Button
                size="large"
                style={{
                  font: "18px Poppins-Bold",
                  color: "white",
                  backgroundColor: "#48ca5e",
                  border: "3px solid #48ca5e",
                  borderRadius: "15px",
                  width: "100%",
                  height: "50px",
                }}
                onClick={this.onBackToLogin}
              >
                Back to Login
              </Button>
            </Col>
            <Col
              xs={24}
              md={0}
              style={{
                textAlign: "center",
                borderRadius: "10px",
                padding: "30px",
                boxShadow:
                  "0px 1px 2px 0px #f2f2f2, 1px 2px 4px 0px #f2f2f2, 2px 4px 8px 0px #f2f2f2, 2px 4px 16px 0px #f2f2f2",
              }}
            >
              <img
                src="/assets/Forgot-Password/Successful-Icon.svg"
                alt="successful"
              />
              <div
                style={{
                  font: "24px Poppins-Bold",
                  margin: "40px 0px 0px 0px",
                  color: "black",
                }}
              >
                Password has been reset
              </div>
              <div
                style={{
                  font: "24px Poppins-Bold",
                  margin: "0px 0px 40px 0px",
                  color: "black",
                }}
              >
                successfully!
              </div>
              <Button
                size="large"
                style={{
                  font: "18px Poppins-Bold",
                  color: "white",
                  backgroundColor: "#48ca5e",
                  border: "3px solid #48ca5e",
                  borderRadius: "15px",
                  width: "100%",
                  height: "50px",
                }}
                onClick={this.onBackToLogin}
              >
                Back to Login
              </Button>
            </Col>
          </Row>
        ) : (
          <Row style={{ margin: "30px" }}>
            <Col
              xs={0}
              md={24}
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                marginTop: "-220px",
                marginLeft: "-230px",
                borderRadius: "10px",
                padding: "30px 60px 30px 60px",
                boxShadow:
                  "0px 1px 2px 0px #f2f2f2, 1px 2px 4px 0px #f2f2f2, 2px 4px 8px 0px #f2f2f2, 2px 4px 16px 0px #f2f2f2",
              }}
            >
              <img
                src="/assets/Forgot-Password/Back-Button.svg"
                alt="Back"
                onClick={this.onBackToLogin}
              />
              <div
                style={{
                  font: "34px Poppins-Bold",
                  margin: "40px 00px",
                  color: "black",
                }}
              >
                Forgot Password?
              </div>

              <Form
                layout="vertical"
                autoComplete="off"
                onFinish={this.onresetClick}
              >
                <div
                  style={{
                    font: "18px Poppins-Normal",
                    margin: "20px 0px",
                    color: "black",
                  }}
                >
                  Please enter your email to reset your password
                </div>

                <Form.Item
                  name="emailToReset"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not a valid email!",
                    },
                    {
                      required: true,
                      message: "Please enter a valid email address",
                    },
                  ]}
                >
                  <Input
                    placeholder="Please enter your email to reset your password"
                    style={{ width: "100%" }}
                  />
                </Form.Item>

                <Button
                  size="large"
                  htmlType="submit"
                  style={{
                    font: "18px Poppins-Bold",
                    color: "white",
                    backgroundColor: "#48ca5e",
                    border: "3px solid #48ca5e",
                    borderRadius: "15px",
                    width: "100%",
                    height: "50px",
                  }}
                  loading={isResetting}
                >
                  Send reset Link
                </Button>
              </Form>
            </Col>
            <Col
              xs={24}
              md={0}
              style={{
                borderRadius: "10px",
                padding: "30px",
                boxShadow:
                  "0px 1px 2px 0px #f2f2f2, 1px 2px 4px 0px #f2f2f2, 2px 4px 8px 0px #f2f2f2, 2px 4px 16px 0px #f2f2f2",
              }}
            >
              <img
                src="/assets/Forgot-Password/Back-Button.svg"
                alt="Back"
                onClick={this.onBackToLogin}
              />
              <div
                style={{
                  font: "34px Poppins-Bold",
                  margin: "40px 00px",
                  color: "black",
                }}
              >
                Forgot Password?
              </div>

              <Form
                layout="vertical"
                autoComplete="off"
                onFinish={this.onresetClick}
              >
                <div
                  style={{
                    font: "18px Poppins-Normal",
                    margin: "20px 0px",
                    color: "black",
                  }}
                >
                  Please enter your email to reset your password
                </div>

                <Form.Item
                  name="emailToReset"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not a valid email!",
                    },
                    {
                      required: true,
                      message: "Please enter a valid email address",
                    },
                  ]}
                >
                  <Input
                    placeholder="Please enter your email to reset your password"
                    style={{ width: "100%" }}
                  />
                </Form.Item>

                <Button
                  size="large"
                  htmlType="submit"
                  style={{
                    font: "18px Poppins-Bold",
                    color: "white",
                    backgroundColor: "#48ca5e",
                    border: "3px solid #48ca5e",
                    borderRadius: "15px",
                    width: "100%",
                    height: "50px",
                  }}
                  loading={isResetting}
                >
                  Send reset Link
                </Button>
              </Form>
            </Col>
          </Row>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  contactUs: state.contactUs,
  dbAuth: state.auth,
});
const mapDispatchToProps = {
  resetEmailPassword,
};
export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(ForgotPassword);
