import React, { Component, Fragment } from "react";
import { Button, Col, Input, message, Row, Form, Select } from "antd";
import { compose } from "redux";
import { connect } from "react-redux";
import { withFirebase } from "react-redux-firebase";
import { withRouter } from "react-router";
import {
  signUpWithEmail,
  initDBLoad,
  signInWithGoogle,
  signInWithFacebook,
  storeEcodeForUse,
  signInWithApple,
  updateUserNewEcode,
} from "../../../auth/authActions";
import LoadingComponent from "../../../app/common/loading/LoadingComponent";
import "../../HomePage.less";
import _ from "lodash";

class Ecode extends Component {
  state = {
    isLoading: false,
    isEmailLoading: false,
    isFacebookLoading: false,
    ecodeArray: [],
    ecodeError: "",
    isNewUser: false,
    ecodeData: {},
  };

  onPasswordChange = (e, inputField) => {
    const { confirmPassword } = this.state;
    let regex = "^(?=.*[A-Za-z])(?=.*[0-9])(?=.{6,})";
    let inputVal = e.target.value;
    if (inputVal.match(regex)) {
      this.setState({
        [inputField]: inputVal,
      });
    } else {
      this.setState({
        [inputField]: inputVal,
      });
    }

    if (confirmPassword != undefined) {
      if (inputVal == confirmPassword) {
        this.setState({
          [inputField]: inputVal,
          confirmPassErr: "",
        });
      } else {
        this.setState({
          [inputField]: inputVal,
          confirmPassErr: "Password not same",
        });
      }
    }
  };

  onConfirmPasswordChange = (e, inputField) => {
    const { password } = this.state;
    let inputVal = e.target.value;
    if (inputVal == password) {
      this.setState({
        [inputField]: inputVal,
        confirmPassErr: "",
      });
    } else {
      this.setState({
        [inputField]: inputVal,
        confirmPassErr: "Password not same",
      });
    }
  };

  showLogin = () => {
    this.props.history.push("/login");
  };

  onEcodeContinueButtonClick = async () => {
    const { ecodeArray, showRegister } = this.state;
    let error = false;

    if (ecodeArray.length !== 5) {
      this.setState({ ecodeError: "Please enter a valid E-code" });
      error = true;
    } else {
      for (let i = 0; i < ecodeArray.length; i++) {
        if (ecodeArray[i])
          if (i === 0 && ecodeArray[i].length !== 3) {
            this.setState({ ecodeError: "Please enter a valid E-code" });
            error = true;
          } else if (i !== 0 && ecodeArray[i].length !== 4) {
            this.setState({ ecodeError: "Please enter a valid E-code" });
            error = true;
          }
      }
    }
    if (!error) {
      let ecode = ecodeArray.join("-").toLocaleUpperCase();
      //console.log(ecodeArray, ecode);
      this.setState({ ecodeError: "" });
      let ecodeDoc = await this.props.storeEcodeForUse(ecode);

      //console.log(showRegister, this.props.auth.uid !== undefined);
      if (ecodeDoc.createdAt !== undefined) {
        if (!showRegister && this.props.auth.uid !== undefined) {
          this.onEcodeContinueWithUserAuthClick(ecodeDoc);
        } else
          this.setState({
            ecodeData: ecodeDoc,
            isNewUser: true,
          });
      } else {
        // console.log(ecodeDoc)
        message.error(ecodeDoc.code + " : " + ecodeDoc.message);
      }
    }
  };

  onEcodeContinueWithUserAuthClick = async (ecodeData) => {
    const { updateUserNewEcode, history, initDBLoad, auth } = this.props;

    let result = await updateUserNewEcode(auth, ecodeData);
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        if (result && result.code === "Note") {
          message.info(result.code + " : " + result.message);
          await initDBLoad(this.props.auth.uid);
          history.push("/user-dashboard");
        } else if (result) {
          this.setState({
            isLoading: false,
          });
          if (result.code === "Expired E-Code") {
            this.setState({
              panelId: 4,
              showRegister: false,
            });
            window.scrollTo(0, 0);
          }
          message.error(result.code + " : " + result.message);
        } else {
          await initDBLoad(this.props.auth.uid);
          history.push("/user-dashboard");
        }
      }
    );
  };

  onHandleSignUpFormFinish = async (e) => {
    const { signUpWithEmail, history, initDBLoad } = this.props;
    this.setState(
      {
        isEmailLoading: true,
      },
      async () => {
        if (!_.isEmpty(this.state.ecodeData)) {
          let errorObj = await signUpWithEmail(e, this.state.ecodeData);

          if (errorObj) {
            message.error(errorObj.code + ":" + errorObj.message);
            this.setState({
              isEmailLoading: false,
            });
          } else {
            await initDBLoad(this.props.auth.uid);
            history.push("/user-dashboard");
          }
        } else {
          this.setState({
            isEmailLoading: false,
          });
        }
      }
    );
  };

  onGoogleButtonClick = async (e) => {
    const { ecodeData } = this.state;
    const { signInWithGoogle, history, initDBLoad } = this.props;

    e.preventDefault();

    let result = await signInWithGoogle(ecodeData);
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        if (result && result.code === "Note") {
          message.info(result.code + " : " + result.message);
          await initDBLoad(this.props.auth.uid);
          history.push("/user-dashboard");
        } else if (result) {
          //console.log(result);
          //await this.props.logout();
          this.setState({
            isLoading: false,
          });
          message.error(result.code + " : " + result.message);
          if (result.code === "Expired E-Code") {
            this.setState({
              panelId: 4,
              showRegister: false,
            });
            window.scrollTo(0, 0);
          }
        } else {
          await initDBLoad(this.props.auth.uid);
          history.push("/user-dashboard");
        }
      }
    );
  };

  onFacebookButtonClick = async (e) => {
    const { ecodeData } = this.state;
    const { signInWithFacebook, history, initDBLoad } = this.props;

    e.preventDefault();

    let result = await signInWithFacebook(ecodeData);
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        if (result && result.code === "Note") {
          message.info(result.code + " : " + result.message);
          await initDBLoad(this.props.auth.uid);
          history.push("/user-dashboard");
        } else if (result) {
          //await this.props.logout();
          this.setState({
            isLoading: false,
          });
          if (result.code === "Expired E-Code") {
            this.setState({
              panelId: 4,
              showRegister: false,
            });
            window.scrollTo(0, 0);
          }
          message.error(result.code + " : " + result.message);
        } else {
          await initDBLoad(this.props.auth.uid);
          history.push("/user-dashboard");
        }
      }
    );
  };

  onAppleButtonClick = async (e) => {
    console.log("here");
    const { ecodeData } = this.state;
    const { signInWithApple, history, initDBLoad } = this.props;

    e.preventDefault();

    let result = await signInWithApple(ecodeData);
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        if (result && result.code === "Note") {
          message.info(result.code + " : " + result.message);
          await initDBLoad(this.props.auth.uid);
          history.push("/user-dashboard");
        } else if (result) {
          //await this.props.logout();
          this.setState({
            isLoading: false,
          });
          message.error(result.code + " : " + result.message);
        } else {
          await initDBLoad(this.props.auth.uid);
          history.push("/user-dashboard");
        }
      }
    );
  };

  onEcodeChange = (e, num) => {
    let ecArray = this.state.ecodeArray;
    //console.log(ecArray, e.target.value.length);
    if (e.target.value.includes("-") && num === 0) {
      ecArray = e.target.value.split("-");
      ecArray[0] = ecArray[0].substring(0, 3);
      ecArray[1] = ecArray[1].substring(0, 4);
      ecArray[2] = ecArray[2].substring(0, 4);
      ecArray[3] = ecArray[3].substring(0, 4);
      ecArray[4] = ecArray[4].substring(0, 4);
      //console.log(ecArray);
    } else {
      if (num === 0 && e.target.value.length <= 3)
        ecArray[num] = e.target.value;
      else if (num > 0 && e.target.value.length <= 4)
        ecArray[num] = e.target.value;
    }

    this.setState({ ecodeArray: ecArray });
  };

  render() {
    const { ecodeArray, ecodeError, isNewUser, isEmailLoading, isLoading } =
      this.state;
    if (isLoading) return <LoadingComponent />;
    return (
      <Fragment>
        {isNewUser ? (
          <Row>
            <Col
              xs={24}
              xl={0}
              flex="auto"
              style={{
                backgroundColor: "#e4ffe9",
                padding: "80px",
              }}
            >
              <div
                style={{
                  font: "40px Poppins-Bold",
                  color: "black",
                  margin: "50px 0px 10px 0px",
                }}
              >
                Fun, engaging and interactive activities
              </div>
              <div
                style={{
                  font: "16px Poppins-Normal",
                  color: "grey",
                }}
              >
                Student will learn, play and practise their English skills with
                our highly interactive lessons that use immersive audio that
                stimulate the senses to enhance your learning.
              </div>
              <Row
                style={{
                  backgroundColor: "white",
                  margin: "20px 0px",
                  borderRadius: "10px",
                  alignItems: "center",
                  border: "1px solid #48ca5e",
                }}
              >
                <Col span={4} style={{ margin: "20px", flex: "none" }}>
                  <img
                    src="/assets/Register/Why-ESL/Icon-1.svg"
                    alt="Landing Page Row 1"
                    style={{}}
                  />
                </Col>
                <Col span={17}>
                  <Row>
                    <Col>
                      <div
                        style={{
                          font: "18px Poppins-Bold",
                          color: "black",
                        }}
                      >
                        Independent learning method
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row
                style={{
                  backgroundColor: "white",
                  margin: "20px 0px",
                  borderRadius: "10px",
                  alignItems: "center",
                  border: "1px solid #48ca5e",
                }}
              >
                <Col span={4} style={{ margin: "20px", flex: "none" }}>
                  <img
                    src="/assets/Register/Why-ESL/Icon-2.svg"
                    alt="Landing Page Row 1"
                    style={{}}
                  />
                </Col>
                <Col span={17}>
                  <Row>
                    <Col>
                      <div
                        style={{
                          font: "18px Poppins-Bold",
                          color: "black",
                        }}
                      >
                        Student-centered and user-friendly
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row
                style={{
                  backgroundColor: "white",
                  margin: "20px 0px",
                  borderRadius: "10px",
                  alignItems: "center",
                  border: "1px solid #48ca5e",
                }}
              >
                <Col span={4} style={{ margin: "20px", flex: "none" }}>
                  <img
                    src="/assets/Register/Why-ESL/Icon-3.svg"
                    alt="Landing Page Row 1"
                    style={{}}
                  />
                </Col>
                <Col span={17}>
                  <Row>
                    <Col>
                      <div
                        style={{
                          font: "18px Poppins-Bold",
                          color: "black",
                        }}
                      >
                        Flexible leaning schedule
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row
                style={{
                  backgroundColor: "white",
                  margin: "20px 0px",
                  borderRadius: "10px",
                  alignItems: "center",
                  border: "1px solid #48ca5e",
                }}
              >
                <Col span={4} style={{ margin: "20px", flex: "none" }}>
                  <img
                    src="/assets/Register/Why-ESL/Icon-4.svg"
                    alt="Landing Page Row 1"
                    style={{}}
                  />
                </Col>
                <Col span={17}>
                  <Row>
                    <Col>
                      <div
                        style={{
                          font: "18px Poppins-Bold",
                          color: "black",
                        }}
                      >
                        Tons of Quizzes and Exercises
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row
                style={{
                  backgroundColor: "white",
                  margin: "20px 0px",
                  borderRadius: "10px",
                  alignItems: "center",
                  border: "1px solid #48ca5e",
                }}
              >
                <Col span={4} style={{ margin: "20px", flex: "none" }}>
                  <img
                    src="/assets/Register/Why-ESL/Icon-5.svg"
                    alt="Landing Page Row 1"
                    style={{}}
                  />
                </Col>
                <Col span={17}>
                  <Row>
                    <Col>
                      <div
                        style={{
                          font: "18px Poppins-Bold",
                          color: "black",
                        }}
                      >
                        Four level of exams
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={0} md={24} xl={15} style={{ padding: "120px" }}>
              <Col
                span={24}
                style={{
                  borderRadius: "10px",
                  padding: "80px",
                  boxShadow:
                    "0px 1px 2px 0px #f2f2f2, 1px 2px 4px 0px #f2f2f2, 2px 4px 8px 0px #f2f2f2, 2px 4px 16px 0px #f2f2f2",
                }}
              >
                <img
                  src="/assets/home-page-logo.png"
                  alt="Landing Page Row 1"
                  style={{ width: "250px" }}
                />
                <div
                  style={{
                    font: "40px Poppins-Bold",
                    color: "black",
                    margin: "60px 0px 40px 0px",
                  }}
                >
                  Please provide these info
                </div>
                <Form
                  ref={this.form}
                  onFinish={this.onHandleSignUpFormFinish}
                  initialValues={{
                    name: this.state.name,
                    email: this.state.email,
                    mobileNumber: this.state.mobileNumber,
                    address: this.state.address,
                    birthday: this.state.birthday,
                  }}
                >
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your full name",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Name"
                      style={{
                        height: "50px",
                        borderRadius: "5px",
                        borderColor: "#ddedf9",
                        padding: "0px 30px",
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "The input is not a valid email!",
                      },
                      {
                        required: true,
                        message: "Please enter a valid email address",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Email Address"
                      style={{
                        height: "50px",
                        borderRadius: "5px",
                        borderColor: "#ddedf9",
                        padding: "0px 30px",
                      }}
                    />
                  </Form.Item>
                  <Row>
                    <Col span={12} style={{ padding: "0px 5px 0px 0px" }}>
                      <Form.Item
                        name="password"
                        onChange={(e) => this.onPasswordChange(e, "password")}
                        rules={[
                          {
                            pattern: new RegExp(
                              "^(?=.*[A-Za-z])(?=.*[0-9])(?=.{6,})"
                            ),
                            message:
                          "Password must contain at least a number and 6 character long",
                          },
                          {
                            required: true,
                            message: "Please enter your password",
                          },
                        ]}
                      >
                        <Input.Password
                          size="large"
                          placeholder="Password"
                          style={{
                            height: "50px",
                            borderRadius: "5px",
                            borderColor: "#ddedf9",
                            padding: "0px 30px",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} style={{ padding: "0px 0px 0px 5px" }}>
                      <Form.Item
                        name="confrimPassword"
                        onChange={(e) =>
                          this.onConfirmPasswordChange(e, "confirmPassword")
                        }
                        rules={[
                          {
                            pattern: new RegExp(
                              "^(?=.*[A-Za-z])(?=.*[0-9])(?=.{6,})"
                            ),
                            message:
                            "Password must contain at least a number and 6 character long",
                          },
                          {
                            required: true,
                            message: "Please enter your password",
                          },
                        ]}
                      >
                        <Input.Password
                          size="large"
                          placeholder="Confirm Password"
                          style={{
                            height: "50px",
                            borderRadius: "5px",
                            borderColor: "#ddedf9",
                            padding: "0px 30px",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="gender"
                        rules={[
                          {
                            required: true,
                            message: "Please state your gender",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Gender"
                          className="sign-up-gender-select-new"
                          getPopupContainer={(trigger) => trigger.parentNode}
                          size="large"
                          style={{ border: "transparent" }}
                        >
                          <Select.Option
                            className="sign-up-gender-select-new"
                            value="Male"
                          >
                            Male
                          </Select.Option>
                          <Select.Option
                            className="sign-up-gender-select-new"
                            value="Female"
                          >
                            Female
                          </Select.Option>
                          <Select.Option
                            className="sign-up-gender-select-new"
                            value="Prefer not to say"
                          >
                            Prefer not to say
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Button
                    size="large"
                    htmlType="submit"
                    style={{
                      font: "18px Poppins-Bold",
                      color: "white",
                      backgroundColor: "#48ca5e",
                      border: "3px solid #48ca5e",
                      borderRadius: "15px",
                      width: "100%",
                      height: "50px",
                    }}
                    loading={this.state.isEmailLoading}
                  >
                    Register
                  </Button>
                </Form>
                <Row style={{ paddingTop: "20px" }}>
                  <Col span={12}>
                    <Button
                      size="large"
                      htmlType="submit"
                      style={{
                        font: "18px Poppins-Bold",
                        color: "white",
                        backgroundColor: "white",
                        border: "1px solid black",
                        borderRadius: "15px",
                        width: "100%",
                        height: "50px",
                      }}
                      onClick={this.onGoogleButtonClick}
                      disabled={isLoading || isEmailLoading}
                    >
                      <img src="/assets/Login/Google-Icon.svg" alt="Google" />
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      size="large"
                      htmlType="submit"
                      style={{
                        font: "18px Poppins-Bold",
                        color: "white",
                        backgroundColor: "#1977f2",
                        border: "3px solid #1977f2",
                        borderRadius: "15px",
                        width: "96%",
                        height: "50px",
                        margin: "0px 5px",
                      }}
                      onClick={this.onFacebookButtonClick}
                      disabled={isLoading || isEmailLoading}
                    >
                      <img
                        src="/assets/Login/Facebook-Icon.svg"
                        alt="Facebook"
                      />
                    </Button>
                  </Col>
                  {/* <Col span={8}>
                    <Button
                      size="large"
                      htmlType="submit"
                      style={{
                        font: "18px Poppins-Bold",
                        color: "white",
                        backgroundColor: "black",
                        border: "3px solid black",
                        borderRadius: "15px",
                        width: "100%",
                        height: "50px",
                      }}
                      onClick={this.onAppleButtonClick}
                      disabled={isLoading || isEmailLoading}
                    >
                      <img src="/assets/Login/Apple-Icon.svg" alt="Apple" />
                    </Button>
                  </Col> */}
                </Row>
                <br />
                <div
                  className="sign-up-description"
                  style={{ textAlign: "center" }}
                >
                  Already have an account?{" "}
                  <u
                    onClick={this.showLogin}
                    style={{ cursor: "pointer", color: "#48ca5e" }}
                  >
                    Login
                  </u>
                </div>
              </Col>
            </Col>
            <Col xs={24} md={0} style={{ padding: "30px" }}>
              <Col
                span={24}
                style={{
                  borderRadius: "10px",
                  padding: "20px",
                  boxShadow:
                    "0px 1px 2px 0px #f2f2f2, 1px 2px 4px 0px #f2f2f2, 2px 4px 8px 0px #f2f2f2, 2px 4px 16px 0px #f2f2f2",
                }}
              >
                <img
                  src="/assets/home-page-logo.png"
                  alt="Landing Page Row 1"
                  style={{ width: "150px" }}
                />
                <div
                  style={{
                    font: "30px Poppins-Bold",
                    color: "black",
                    margin: "20px 0px 41px 0px",
                  }}
                >
                  Please provide these info
                </div>
                <Form
                  ref={this.form}
                  onFinish={this.onHandleSignUpFormFinish}
                  initialValues={{
                    name: this.state.name,
                    email: this.state.email,
                    mobileNumber: this.state.mobileNumber,
                    address: this.state.address,
                    birthday: this.state.birthday,
                  }}
                >
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your full name",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Name"
                      style={{
                        height: "50px",
                        borderRadius: "5px",
                        borderColor: "#ddedf9",
                        padding: "0px 30px",
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "The input is not a valid email!",
                      },
                      {
                        required: true,
                        message: "Please enter a valid email address",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Email Address"
                      style={{
                        height: "50px",
                        borderRadius: "5px",
                        borderColor: "#ddedf9",
                        padding: "0px 30px",
                      }}
                    />
                  </Form.Item>
                  <Row>
                    <Col span={12} style={{ padding: "0px 5px 0px 0px" }}>
                      <Form.Item
                        name="password"
                        onChange={(e) => this.onPasswordChange(e, "password")}
                        rules={[
                          {
                            pattern: new RegExp(
                              "^(?=.*[A-Za-z])(?=.*[0-9])(?=.{6,})"
                            ),
                            message:
                          "Password must contain at least a number and 6 character long",
                          },
                          {
                            required: true,
                            message: "Please enter your password",
                          },
                        ]}
                      >
                        <Input.Password
                          size="large"
                          placeholder="Password"
                          style={{
                            height: "50px",
                            borderRadius: "5px",
                            borderColor: "#ddedf9",
                            padding: "0px 30px",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} style={{ padding: "0px 0px 0px 5px" }}>
                      <Form.Item
                        name="confrimPassword"
                        onChange={(e) =>
                          this.onConfirmPasswordChange(e, "confirmPassword")
                        }
                        rules={[
                          {
                            pattern: new RegExp(
                              "^(?=.*[A-Za-z])(?=.*[0-9])(?=.{6,})"
                            ),
                            message:
                            "Password must contain at least a number and 6 character long",
                          },
                          {
                            required: true,
                            message: "Please enter your password",
                          },
                        ]}
                      >
                        <Input.Password
                          size="large"
                          placeholder="Confirm Password"
                          style={{
                            height: "50px",
                            borderRadius: "5px",
                            borderColor: "#ddedf9",
                            padding: "0px 30px",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="gender"
                        rules={[
                          {
                            required: true,
                            message: "Please state your gender",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Gender"
                          className="sign-up-gender-select-new"
                          getPopupContainer={(trigger) => trigger.parentNode}
                          size="large"
                          style={{ border: "transparent" }}
                        >
                          <Select.Option
                            className="sign-up-gender-select-new"
                            value="Male"
                          >
                            Male
                          </Select.Option>
                          <Select.Option
                            className="sign-up-gender-select-new"
                            value="Female"
                          >
                            Female
                          </Select.Option>
                          <Select.Option
                            className="sign-up-gender-select-new"
                            value="Prefer not to say"
                          >
                            Prefer not to say
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Button
                    size="large"
                    htmlType="submit"
                    style={{
                      font: "18px Poppins-Bold",
                      color: "white",
                      backgroundColor: "#48ca5e",
                      border: "3px solid #48ca5e",
                      borderRadius: "15px",
                      width: "100%",
                      height: "50px",
                    }}
                    loading={this.state.isEmailLoading}
                  >
                    Register
                  </Button>
                </Form>
                <Row style={{ paddingTop: "20px" }}>
                  <Col span={24}>
                    <Button
                      size="large"
                      htmlType="submit"
                      style={{
                        font: "18px Poppins-Bold",
                        color: "white",
                        backgroundColor: "white",
                        border: "1px solid black",
                        borderRadius: "15px",
                        width: "100%",
                        height: "50px",
                      }}
                      onClick={this.onGoogleButtonClick}
                      disabled={isLoading || isEmailLoading}
                    >
                      <img src="/assets/Login/Google-Icon.svg" alt="Google" />
                    </Button>
                  </Col>
                  <Col span={24}>
                    <Button
                      size="large"
                      htmlType="submit"
                      style={{
                        font: "18px Poppins-Bold",
                        color: "white",
                        backgroundColor: "#1977f2",
                        border: "3px solid #1977f2",
                        borderRadius: "15px",
                        width: "100%",
                        height: "50px",
                        margin: "15px 0px",
                      }}
                      onClick={this.onFacebookButtonClick}
                      disabled={isLoading || isEmailLoading}
                    >
                      <img
                        src="/assets/Login/Facebook-Icon.svg"
                        alt="Facebook"
                      />
                    </Button>
                  </Col>
                  {/* <Col span={24}>
                    <Button
                      size="large"
                      htmlType="submit"
                      style={{
                        font: "18px Poppins-Bold",
                        color: "white",
                        backgroundColor: "black",
                        border: "3px solid black",
                        borderRadius: "15px",
                        width: "100%",
                        height: "50px",
                      }}
                      onClick={this.onAppleButtonClick}
                      disabled={isLoading || isEmailLoading}
                    >
                      <img src="/assets/Login/Apple-Icon.svg" alt="Apple" />
                    </Button>
                  </Col> */}
                </Row>
                <br />
                <div
                  className="sign-up-description"
                  style={{ textAlign: "center" }}
                >
                  Already have an account?{" "}
                  <u
                    onClick={this.showLogin}
                    style={{ cursor: "pointer", color: "#48ca5e" }}
                  >
                    Login
                  </u>
                </div>
              </Col>
            </Col>
            <Col
              xs={0}
              xl={9}
              flex="auto"
              style={{
                backgroundColor: "#e4ffe9",
              }}
            >
              <div
                style={{
                  font: "50px Poppins-Bold",
                  color: "black",
                  margin: "120px 80px 60px 80px",
                }}
              >
                Fun, engaging and interactive activities
              </div>
              <div
                style={{
                  font: "16px Poppins-Normal",
                  color: "grey",
                  margin: "0px 80px 60px 80px",
                }}
              >
                Student will learn, play and practise their English skills with
                our highly interactive lessons that use immersive audio that
                stimulate the senses to enhance your learning.
              </div>
              <Row
                style={{
                  backgroundColor: "white",
                  margin: "20px 60px",
                  borderRadius: "10px",
                  alignItems: "center",
                  border: "1px solid #48ca5e",
                }}
              >
                <Col span={4} style={{ margin: "20px", flex: "none" }}>
                  <img
                    src="/assets/Register/Why-ESL/Icon-1.svg"
                    alt="Landing Page Row 1"
                  />
                </Col>
                <Col span={19}>
                  <Row>
                    <Col>
                      <div
                        style={{
                          font: "18px Poppins-Bold",
                          color: "black",
                        }}
                      >
                        Independent learning method
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col></Col>
                  </Row>
                </Col>
              </Row>
              <Row
                style={{
                  backgroundColor: "white",
                  margin: "20px 60px",
                  borderRadius: "10px",
                  alignItems: "center",
                  border: "1px solid #48ca5e",
                }}
              >
                <Col span={4} style={{ margin: "20px", flex: "none" }}>
                  <img
                    src="/assets/Register/Why-ESL/Icon-2.svg"
                    alt="Landing Page Row 1"
                    style={{}}
                  />
                </Col>
                <Col span={19}>
                  <Row>
                    <Col>
                      <div
                        style={{
                          font: "18px Poppins-Bold",
                          color: "black",
                        }}
                      >
                        Student-centered and user-friendly
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row
                style={{
                  backgroundColor: "white",
                  margin: "20px 60px",
                  borderRadius: "10px",
                  alignItems: "center",
                  border: "1px solid #48ca5e",
                }}
              >
                <Col span={4} style={{ margin: "20px", flex: "none" }}>
                  <img
                    src="/assets/Register/Why-ESL/Icon-3.svg"
                    alt="Landing Page Row 1"
                    style={{}}
                  />
                </Col>
                <Col span={19}>
                  <Row>
                    <Col>
                      <div
                        style={{
                          font: "18px Poppins-Bold",
                          color: "black",
                        }}
                      >
                        Flexible leaning schedule
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row
                style={{
                  backgroundColor: "white",
                  margin: "20px 60px",
                  borderRadius: "10px",
                  alignItems: "center",
                  border: "1px solid #48ca5e",
                }}
              >
                <Col span={4} style={{ margin: "20px", flex: "none" }}>
                  <img
                    src="/assets/Register/Why-ESL/Icon-4.svg"
                    alt="Landing Page Row 1"
                    style={{}}
                  />
                </Col>
                <Col span={19}>
                  <Row>
                    <Col>
                      <div
                        style={{
                          font: "18px Poppins-Bold",
                          color: "black",
                        }}
                      >
                        Tons of Quizzes and Exercises
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row
                style={{
                  backgroundColor: "white",
                  margin: "20px 60px",
                  borderRadius: "10px",
                  alignItems: "center",
                  border: "1px solid #48ca5e",
                }}
              >
                <Col span={4} style={{ margin: "20px", flex: "none" }}>
                  <img
                    src="/assets/Register/Why-ESL/Icon-5.svg"
                    alt="Landing Page Row 1"
                    style={{}}
                  />
                </Col>
                <Col span={19}>
                  <Row>
                    <Col>
                      <div
                        style={{
                          font: "18px Poppins-Bold",
                          color: "black",
                        }}
                      >
                        Four level of exams
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col
              xs={24}
              xl={0}
              flex="auto"
              style={{
                backgroundColor: "#e4ffe9",
                padding: "80px",
              }}
            >
              <div
                style={{
                  font: "40px Poppins-Bold",
                  color: "black",
                  margin: "50px 0px 10px 0px",
                }}
              >
                Fun, engaging and interactive activities
              </div>
              <div
                style={{
                  font: "16px Poppins-Normal",
                  color: "grey",
                }}
              >
                Student will learn, play and practise their English skills with
                our highly interactive lessons that use immersive audio that
                stimulate the senses to enhance your learning.
              </div>
              <Row
                style={{
                  backgroundColor: "white",
                  margin: "20px 0px",
                  borderRadius: "10px",
                  alignItems: "center",
                  border: "1px solid #48ca5e",
                }}
              >
                <Col span={3} style={{ margin: "15px", flex: "none" }}>
                  <img
                    src="/assets/Register/Why-ESL/Icon-1.svg"
                    alt="Landing Page Row 1"
                    style={{}}
                  />
                </Col>
                <Col span={17}>
                  <Row>
                    <Col>
                      <div
                        style={{
                          font: "18px Poppins-Bold",
                          color: "black",
                        }}
                      >
                        Independent learning method
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row
                style={{
                  backgroundColor: "white",
                  margin: "20px 0px",
                  borderRadius: "10px",
                  alignItems: "center",
                  border: "1px solid #48ca5e",
                }}
              >
                <Col span={3} style={{ margin: "15px", flex: "none" }}>
                  <img
                    src="/assets/Register/Why-ESL/Icon-2.svg"
                    alt="Landing Page Row 1"
                    style={{}}
                  />
                </Col>
                <Col span={17}>
                  <Row>
                    <Col>
                      <div
                        style={{
                          font: "18px Poppins-Bold",
                          color: "black",
                        }}
                      >
                        Student-centered and user-friendly
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row
                style={{
                  backgroundColor: "white",
                  margin: "20px 0px",
                  borderRadius: "10px",
                  alignItems: "center",
                  border: "1px solid #48ca5e",
                }}
              >
                <Col span={3} style={{ margin: "15px", flex: "none" }}>
                  <img
                    src="/assets/Register/Why-ESL/Icon-3.svg"
                    alt="Landing Page Row 1"
                    style={{}}
                  />
                </Col>
                <Col span={17}>
                  <Row>
                    <Col>
                      <div
                        style={{
                          font: "18px Poppins-Bold",
                          color: "black",
                        }}
                      >
                        Flexible leaning schedule
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row
                style={{
                  backgroundColor: "white",
                  margin: "20px 0px",
                  borderRadius: "10px",
                  alignItems: "center",
                  border: "1px solid #48ca5e",
                }}
              >
                <Col span={3} style={{ margin: "15px", flex: "none" }}>
                  <img
                    src="/assets/Register/Why-ESL/Icon-4.svg"
                    alt="Landing Page Row 1"
                    style={{}}
                  />
                </Col>
                <Col span={17}>
                  <Row>
                    <Col>
                      <div
                        style={{
                          font: "18px Poppins-Bold",
                          color: "black",
                        }}
                      >
                        Tons of Quizzes and Exercises
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row
                style={{
                  backgroundColor: "white",
                  margin: "20px 0px",
                  borderRadius: "10px",
                  alignItems: "center",
                  border: "1px solid #48ca5e",
                }}
              >
                <Col span={3} style={{ margin: "15px", flex: "none" }}>
                  <img
                    src="/assets/Register/Why-ESL/Icon-5.svg"
                    alt="Landing Page Row 1"
                    style={{}}
                  />
                </Col>
                <Col span={17}>
                  <Row>
                    <Col>
                      <div
                        style={{
                          font: "18px Poppins-Bold",
                          color: "black",
                        }}
                      >
                        Four level of exams
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={0} md={24} xl={15} style={{ padding: "120px" }}>
              <Col
                span={24}
                style={{
                  borderRadius: "10px",
                  padding: "80px",
                  boxShadow:
                    "0px 1px 2px 0px #f2f2f2, 1px 2px 4px 0px #f2f2f2, 2px 4px 8px 0px #f2f2f2, 2px 4px 16px 0px #f2f2f2",
                }}
              >
                <img
                  src="/assets/home-page-logo.png"
                  alt="Landing Page Row 1"
                  style={{ width: "250px" }}
                />
                <div
                  style={{
                    font: "40px Poppins-Bold",
                    color: "black",
                    margin: "60px 0px 20px 0px",
                  }}
                >
                  Register now and start your journey with ESl now!
                </div>

                <div
                  style={{
                    font: "20px Poppins-Bold",
                    color: "black",
                    margin: "40px 0px 20px 0px",
                  }}
                >
                  Enter Your E-Code
                </div>

                <div
                  style={{
                    font: "18px",
                    color: "black",
                    margin: "0px 0px 40px 0px",
                  }}
                >
                  Enter the E-Code recieved via your email to activate your
                  account.
                </div>

                <Input
                  name="1"
                  type="text"
                  style={{
                    width: "15%",
                    fontFamily: "Poppins-Regular",
                    fontSize: "25px",
                    border: "1px solid rgba(112,112,112,0.4)",
                    padding: "8px",
                    borderRadius: "16px",
                    outline: "none",
                    alignItems: "center",
                  }}
                  value={ecodeArray[0]}
                  size="large"
                  placeholder="XXX"
                  //maxLength={3}
                  onChange={(e) => this.onEcodeChange(e, 0)}
                />
                <span> - </span>
                <Input
                  name="2"
                  type="text"
                  style={{
                    width: "15%",
                    fontFamily: "Poppins-Regular",
                    fontSize: "25px",
                    border: "1px solid rgba(112,112,112,0.4)",
                    padding: "8px",
                    borderRadius: "16px",
                    outline: "none",
                    alignItems: "center",
                  }}
                  value={ecodeArray[1]}
                  size="large"
                  placeholder="XXXX"
                  maxLength={4}
                  onChange={(e) => this.onEcodeChange(e, 1)}
                />
                <span> - </span>
                <Input
                  name="3"
                  type="text"
                  style={{
                    width: "15%",
                    fontFamily: "Poppins-Regular",
                    fontSize: "25px",
                    border: "1px solid rgba(112,112,112,0.4)",
                    padding: "8px",
                    borderRadius: "16px",
                    outline: "none",
                    alignItems: "center",
                  }}
                  value={ecodeArray[2]}
                  size="large"
                  placeholder="XXXX"
                  maxLength={4}
                  onChange={(e) => this.onEcodeChange(e, 2)}
                />
                <span> - </span>
                <Input
                  name="4"
                  type="text"
                  style={{
                    width: "15%",
                    fontFamily: "Poppins-Regular",
                    fontSize: "25px",
                    border: "1px solid rgba(112,112,112,0.4)",
                    padding: "8px",
                    borderRadius: "16px",
                    outline: "none",
                    alignItems: "center",
                  }}
                  value={ecodeArray[3]}
                  size="large"
                  placeholder="XXXX"
                  maxLength={4}
                  onChange={(e) => this.onEcodeChange(e, 3)}
                />
                <span> - </span>
                <Input
                  name="5"
                  type="text"
                  style={{
                    width: "15%",
                    fontFamily: "Poppins-Regular",
                    fontSize: "25px",
                    border: "1px solid rgba(112,112,112,0.4)",
                    padding: "8px",
                    borderRadius: "16px",
                    outline: "none",
                    alignItems: "center",
                  }}
                  value={ecodeArray[4]}
                  size="large"
                  placeholder="XXXX"
                  maxLength={4}
                  onChange={(e) => this.onEcodeChange(e, 4)}
                />
                <br />
                <span
                  style={{
                    color: "red",
                  }}
                >
                  {ecodeError}
                </span>
                <br />
                <Button
                  size="large"
                  htmlType="submit"
                  style={{
                    font: "18px Poppins-Bold",
                    color: "white",
                    backgroundColor: "#48ca5e",
                    border: "3px solid #48ca5e",
                    borderRadius: "15px",
                    width: "100%",
                    height: "50px",
                  }}
                  loading={this.state.isLoading}
                  onClick={this.onEcodeContinueButtonClick}
                >
                  Continue
                </Button>
                <br />

                <br />
                <div
                  className="sign-up-description"
                  style={{ textAlign: "center" }}
                >
                  Already have an account?{" "}
                  <u
                    onClick={this.showLogin}
                    style={{ cursor: "pointer", color: "#48ca5e" }}
                  >
                    Login
                  </u>
                </div>
              </Col>
            </Col>
            <Col xs={24} md={0} xl={0} style={{ padding: "30px" }}>
              <Col
                span={24}
                style={{
                  borderRadius: "10px",
                  padding: "20px",
                  boxShadow:
                    "0px 1px 2px 0px #f2f2f2, 1px 2px 4px 0px #f2f2f2, 2px 4px 8px 0px #f2f2f2, 2px 4px 16px 0px #f2f2f2",
                }}
              >
                <img
                  src="/assets/home-page-logo.png"
                  alt="Landing Page Row 1"
                  style={{ width: "150px" }}
                />
                <div
                  style={{
                    font: "30px Poppins-Bold",
                    color: "black",
                    margin: "20px 0px 10px 0px",
                  }}
                >
                  Register now and start your journey with ESl now!
                </div>

                <div
                  style={{
                    font: "20px Poppins-Bold",
                    color: "black",
                    margin: "20px 0px 10px 0px",
                  }}
                >
                  Enter Your E-Code
                </div>

                <div
                  style={{
                    font: "18px",
                    color: "black",
                    margin: "0px 0px 20px 0px",
                  }}
                >
                  Enter the E-Code recieved via your email to activate your
                  account.
                </div>

                <Input
                  name="1"
                  type="text"
                  style={{
                    width: "16%",
                    fontFamily: "Poppins-Regular",
                    fontSize: "14px",
                    border: "1px solid rgba(112,112,112,0.4)",
                    padding: "8px",
                    borderRadius: "16px",
                    outline: "none",
                    alignItems: "center",
                  }}
                  value={ecodeArray[0]}
                  size="large"
                  placeholder="XXX"
                  //maxLength={3}
                  onChange={(e) => this.onEcodeChange(e, 0)}
                />
                <span> - </span>
                <Input
                  name="2"
                  type="text"
                  style={{
                    width: "16%",
                    fontFamily: "Poppins-Regular",
                    fontSize: "14px",
                    border: "1px solid rgba(112,112,112,0.4)",
                    padding: "8px",
                    borderRadius: "16px",
                    outline: "none",
                    alignItems: "center",
                  }}
                  value={ecodeArray[1]}
                  size="large"
                  placeholder="XXXX"
                  maxLength={4}
                  onChange={(e) => this.onEcodeChange(e, 1)}
                />
                <span> - </span>
                <Input
                  name="3"
                  type="text"
                  style={{
                    width: "16%",
                    fontFamily: "Poppins-Regular",
                    fontSize: "14px",
                    border: "1px solid rgba(112,112,112,0.4)",
                    padding: "8px",
                    borderRadius: "16px",
                    outline: "none",
                    alignItems: "center",
                  }}
                  value={ecodeArray[2]}
                  size="large"
                  placeholder="XXXX"
                  maxLength={4}
                  onChange={(e) => this.onEcodeChange(e, 2)}
                />
                <span> - </span>
                <Input
                  name="4"
                  type="text"
                  style={{
                    width: "16%",
                    fontFamily: "Poppins-Regular",
                    fontSize: "14px",
                    border: "1px solid rgba(112,112,112,0.4)",
                    padding: "8px",
                    borderRadius: "16px",
                    outline: "none",
                    alignItems: "center",
                  }}
                  value={ecodeArray[3]}
                  size="large"
                  placeholder="XXXX"
                  maxLength={4}
                  onChange={(e) => this.onEcodeChange(e, 3)}
                />
                <span> - </span>
                <Input
                  name="5"
                  type="text"
                  style={{
                    width: "16%",
                    fontFamily: "Poppins-Regular",
                    fontSize: "14px",
                    border: "1px solid rgba(112,112,112,0.4)",
                    padding: "8px",
                    borderRadius: "16px",
                    outline: "none",
                    alignItems: "center",
                  }}
                  value={ecodeArray[4]}
                  size="large"
                  placeholder="XXXX"
                  maxLength={4}
                  onChange={(e) => this.onEcodeChange(e, 4)}
                />
                <br />
                <span
                  style={{
                    color: "red",
                  }}
                >
                  {ecodeError}
                </span>
                <br />
                <Button
                  size="large"
                  htmlType="submit"
                  style={{
                    font: "18px Poppins-Bold",
                    color: "white",
                    backgroundColor: "#48ca5e",
                    border: "3px solid #48ca5e",
                    borderRadius: "15px",
                    width: "100%",
                    height: "50px",
                  }}
                  loading={this.state.isLoading}
                  onClick={this.onEcodeContinueButtonClick}
                >
                  Continue
                </Button>
                <br />

                <br />
                <div
                  className="sign-up-description"
                  style={{ textAlign: "center" }}
                >
                  Already have an account?{" "}
                  <u
                    onClick={this.showLogin}
                    style={{ cursor: "pointer", color: "#48ca5e" }}
                  >
                    Login
                  </u>
                </div>
              </Col>
            </Col>
            <Col
              xs={0}
              xl={9}
              flex="auto"
              style={{
                backgroundColor: "#e4ffe9",
                backgroundSize: "100% 100%",
              }}
            >
              <div
                style={{
                  font: "40px Poppins-Bold",
                  color: "black",
                  margin: "60px 60px 60px 60px",
                }}
              >
                Fun, engaging and interactive activities
              </div>
              <div
                style={{
                  font: "16px Poppins-Normal",
                  color: "grey",
                  margin: "0px 80px 30px 60px",
                }}
              >
                Student will learn, play and practise their English skills with
                our highly interactive lessons that use immersive audio that
                stimulate the senses to enhance your learning.
              </div>
              <Row
                style={{
                  backgroundColor: "white",
                  margin: "20px 60px",
                  borderRadius: "10px",
                  alignItems: "center",
                  border: "1px solid #48ca5e",
                }}
              >
                <Col span={4} style={{ margin: "20px", flex: "none" }}>
                  <img
                    src="/assets/Register/Why-ESL/Icon-1.svg"
                    alt="Landing Page Row 1"
                  />
                </Col>
                <Col span={19}>
                  <Row>
                    <Col>
                      <div
                        style={{
                          font: "18px Poppins-Bold",
                          color: "black",
                        }}
                      >
                        Independent learning method
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col></Col>
                  </Row>
                </Col>
              </Row>
              <Row
                style={{
                  backgroundColor: "white",
                  margin: "20px 60px",
                  borderRadius: "10px",
                  alignItems: "center",
                  border: "1px solid #48ca5e",
                }}
              >
                <Col span={4} style={{ margin: "20px", flex: "none" }}>
                  <img
                    src="/assets/Register/Why-ESL/Icon-2.svg"
                    alt="Landing Page Row 1"
                    style={{}}
                  />
                </Col>
                <Col span={19}>
                  <Row>
                    <Col>
                      <div
                        style={{
                          font: "18px Poppins-Bold",
                          color: "black",
                        }}
                      >
                        Student-centered and user-friendly
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row
                style={{
                  backgroundColor: "white",
                  margin: "20px 60px",
                  borderRadius: "10px",
                  alignItems: "center",
                  border: "1px solid #48ca5e",
                }}
              >
                <Col span={4} style={{ margin: "20px", flex: "none" }}>
                  <img
                    src="/assets/Register/Why-ESL/Icon-3.svg"
                    alt="Landing Page Row 1"
                    style={{}}
                  />
                </Col>
                <Col span={19}>
                  <Row>
                    <Col>
                      <div
                        style={{
                          font: "18px Poppins-Bold",
                          color: "black",
                        }}
                      >
                        Flexible leaning schedule
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row
                style={{
                  backgroundColor: "white",
                  margin: "20px 60px",
                  borderRadius: "10px",
                  alignItems: "center",
                  border: "1px solid #48ca5e",
                }}
              >
                <Col span={4} style={{ margin: "20px", flex: "none" }}>
                  <img
                    src="/assets/Register/Why-ESL/Icon-4.svg"
                    alt="Landing Page Row 1"
                    style={{}}
                  />
                </Col>
                <Col span={19}>
                  <Row>
                    <Col>
                      <div
                        style={{
                          font: "18px Poppins-Bold",
                          color: "black",
                        }}
                      >
                        Tons of Quizzes and Exercises
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row
                style={{
                  backgroundColor: "white",
                  margin: "20px 60px",
                  borderRadius: "10px",
                  alignItems: "center",
                  border: "1px solid #48ca5e",
                }}
              >
                <Col span={4} style={{ margin: "20px", flex: "none" }}>
                  <img
                    src="/assets/Register/Why-ESL/Icon-5.svg"
                    alt="Landing Page Row 1"
                    style={{}}
                  />
                </Col>
                <Col span={19}>
                  <Row>
                    <Col>
                      <div
                        style={{
                          font: "18px Poppins-Bold",
                          color: "black",
                        }}
                      >
                        Four level of exams
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  dbAuth: state.auth,
});

const mapDispatchToProps = {
  signUpWithEmail,
  initDBLoad,
  signInWithGoogle,
  signInWithFacebook,
  storeEcodeForUse,
  signInWithApple,
  updateUserNewEcode,
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(Ecode);
