import { Button, Col, Modal, Row } from "antd";
import React, { Fragment } from "react";

const EditEcodeUserDetails = ({
  isVisible,
  onCancel,
  onDelete,
  clickedRecord,
  onInputChange,
  email
}) => {
  return (
    <Fragment>
      <Modal
        visible={isVisible}
        onCancel={onCancel}
        closeIcon={
          <img
            src="/assets/close-black-icon.svg"
            alt=""
            style={{ width: "24px", height: "24px" }}
          />
        }
        footer={null}
        width={500}
      >
        <div
          style={{
            fontFamily: "Poppins-Bold",
            fontSize: "1.3rem",
            paddingLeft: "1rem"
          }}
        >
          Delete user
        </div>

        <Row
          style={{
            fontFamily: "Poppins-Regular",
            fontSize: "1rem",
            padding: "1rem"
          }}
        >
          <Col span={24}>
            <div
              style={{
                border: "1px solid rgba(112,112,112,0.4)",
                padding: "0.5rem 1rem",
                borderRadius: "1rem",
                marginTop: "1rem"
              }}
            >
              <span
                style={{
                  fontSize: "0.8rem",

                  color: "#757575"
                }}
              >
                Enter User Email to Delete
              </span>

              <input
                placeholder={clickedRecord.userEmail}
                style={{
                  width: "100%",
                  fontSize: "1rem",
                  border: "none",
                  outline: "none"
                }}
                onChange={e => onInputChange(e, "email")}
              />
            </div>
            <br />
            <div
              style={{
                backgroundColor: "red",
                borderRadius: "1rem",
                display: "flex",
                padding: "2rem",
                justifyContent: "space-between"
              }}
            >
              <img
                src="/assets/alert-icon.svg"
                alt=""
                style={{ marginRight: "1rem" }}
              />
              <span style={{ color: "white" }}>
                Warning: The user will be removed from database and may not be
                able to be recovered
              </span>
            </div>
          </Col>
        </Row>

        <Row
          justify="end"
          style={{
            backgroundColor: "#F8F8F9",
            borderBottomRightRadius: "24px",
            borderBottomLeftRadius: "24px",
            padding: "1rem"
          }}
        >
          <Col>
            <div
              style={{
                padding: "0.4rem 2rem",
                fontFamily: "Poppins-Bold",
                fontSize: "1rem",
                cursor: "pointer"
              }}
              onClick={onCancel}
            >
              CANCEL
            </div>
          </Col>
          <Col>
            {email === clickedRecord.userEmail ? (
              <Button
                style={{
                  borderRadius: "2rem",
                  padding: "0.4rem 2rem",
                  fontFamily: "Poppins-Bold",
                  fontSize: "1rem",
                  height: "auto",
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                  backgroundColor: "red",
                  color: "white",
                  borderColor: "red",
                  marginLeft: "2rem"
                }}
                onClick={onDelete}
              >
                DELETE
              </Button>
            ) : (
              <Button
                style={{
                  borderRadius: "2rem",
                  padding: "0.4rem 2rem",
                  fontFamily: "Poppins-Bold",
                  fontSize: "1rem",
                  height: "auto",
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                  marginLeft: "2rem"
                }}
                onClick={onDelete}
                disabled={email !== clickedRecord.userEmail}
              >
                DELETE
              </Button>
            )}
          </Col>
        </Row>
      </Modal>
    </Fragment>
  );
};

export default EditEcodeUserDetails;
