import { LOAD_STATISTICS_DATA } from "./statisticsConstant";


export const loadStatisticsData = () => {

    return async (dispatch, getState, { getFirebase }) => {
        const db = getFirebase().firestore();
        try {

            let statisticsDocSnap = await db.doc("statistics/general").get();

            //create own internal map ...
            let statisticsObj = {}

            if (statisticsDocSnap.exists) {
                statisticsObj = { ...statisticsDocSnap.data(), docId: statisticsDocSnap.id }
            }

            dispatch({ type: LOAD_STATISTICS_DATA, payload: { statisticsObj } })

        } catch (err) {
            console.log(err)
            throw (err)
        }
    }
}
