import React, { Component, Fragment } from "react";
import { Menu } from "antd";
import { updateUserLanguageInfo } from "./auth/authActions";
import { connect } from "react-redux";

class LanguageSelectionComponent extends Component {
  onLanguageMenuClick = async (e) => {
    if (this.props.dbAuth.userInfo)
      this.props.updateUserLanguageInfo(
        e.key,
        this.props.dbAuth.nativeLanguage
      );
  };

  render() {
    return (
      <Fragment>
        <Menu
          style={{
            fontFamily: "Poppins-Regular",
            fontSize: "16px",
            borderRight: "none",
          }}
          onClick={(e) => this.onLanguageMenuClick(e)}
        >
          {this.props.configuration.languageList.map((language, idx) => (
            <Menu.Item key={language.languageCode}>
              <img
                src={language.imageUrl}
                alt=""
                style={{ width: "50px", height: "25px" }}
              />
              {language.languageName}
            </Menu.Item>
          ))}
        </Menu>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  dbAuth: state.auth,
  operation: state.operation,
  configuration: state.configuration,
});
const mapDispatchToProps = {
  updateUserLanguageInfo,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LanguageSelectionComponent);
