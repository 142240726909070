import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  loadTargetedQuizGroupQuestions,
  resetTargetedQuizGroupQuestions
} from "../../../course/courseActions";
import ContentType8Page from "../../CourseNav/LessonPage/ContentType8Page/ContentType8Page";
import ContentType9Page from "../../CourseNav/LessonPage/ContentType9Page/ContentType9Page";

class QuizQuestionPage extends Component {
  state = {
    isLoading: true,
    contentType: -1
  };

  componentDidMount() {
    const { match } = this.props;
    let quizId = match.params.quizId;
    let topicId = match.params.topicId;
    let groupId = match.params.groupId;

    this.props.loadTargetedQuizGroupQuestions(quizId, topicId, groupId);
  }

  async componentDidUpdate(prevProps) {
    const { course } = this.props;

    if (
      !_.isEqual(
        prevProps.course.quizQuestionInfoObj,
        course.quizQuestionInfoObj
      ) &&
      course.quizQuestionInfoObj !== null
    ) {
      this.setState({
        isLoading: false,
        contentType: course.quizQuestionInfoObj.topicInfoObj.contentType
      });
    }
  }

  componentWillUnmount() {
    this.props.resetTargetedQuizGroupQuestions();
  }

  render() {
    const { contentType } = this.state;

    if (contentType === 8) return <ContentType8Page />;
    if (contentType === 9) return <ContentType9Page />;
    // if (match.params.topicId !== "grammar")
    //   return <MultiChoiceSelectionQuestionPage />;

    // if (match.params.topicId === "grammar")
    //   return <FillInTheBlankQuestionPage />;

    return null;
  }
}

const mapStateToProps = state => ({
  course: state.course
});

const mapDispatchToProps = {
  loadTargetedQuizGroupQuestions,
  resetTargetedQuizGroupQuestions
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(QuizQuestionPage);
