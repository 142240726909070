
export const courseColorMap = new Map([
    ["beginner", { hexColorCode: "#FFFFFF", label: "White" }],
    ["intermediate", { hexColorCode: "#FFF173", label: "Yellow" }],
    ["advanced", { hexColorCode: "#9ED65D", label: "Green" }],
    ["expert", { hexColorCode: "#7EC4FB", label: "Blue" }],
])

/*
styleCode : 0, circle
styleCode : 1, rectangular (default)
styleCode : 2, square
 */
export const courseStylingMap = new Map([
    ["beginner", {
        quick_phonics: { subTopicStyleCode: 0, groupStyleCode: -1 },
        sir2000: { subTopicStyleCode: 1, groupStyleCode: 0 },
        introduction_to_english: { subTopicStyleCode: 1, groupStyleCode: -1 },
        first_steps_to_english: { subTopicStyleCode: 1, groupStyleCode: -1 },
    }],
    ["intermediate", {
        short_conversations: { subTopicStyleCode: 2, groupStyleCode: 1 },
        communication_skills: { subTopicStyleCode: 2, groupStyleCode: 1 },
        survival_english: { subTopicStyleCode: 0, groupStyleCode: -1 },
        oral_english: { subTopicStyleCode: 1, groupStyleCode: -1 },
    }],
    ["advanced", {
        idioms_and_expressions: { subTopicStyleCode: 0, groupStyleCode: -1 },
        trip_usa_part1: { subTopicStyleCode: 0, groupStyleCode: -1 },
        trip_usa_part2: { subTopicStyleCode: 0, groupStyleCode: -1 },
        grammar: { subTopicStyleCode: 1, groupStyleCode: 0 },

    }],
    ["expert", {
        expressions_english_ag: { subTopicStyleCode: 2, groupStyleCode: 1 },
        expressions_english_hn: { subTopicStyleCode: 2, groupStyleCode: 1 },
        expressions_english_oz: { subTopicStyleCode: 2, groupStyleCode: 1 },
        common_words_in_newspapers: { subTopicStyleCode: 2, groupStyleCode: 0 }
    }],
])

export const quizStylingMap = new Map([
    ["beginner", {
        quick_phonics: { groupStyleCode: 0 },
        sir2000: { groupStyleCode: 0 },
        introduction_to_english: { groupStyleCode: 0 },
        first_steps_to_english: { groupStyleCode: 0 },
    }],
    ["intermediate", {
        short_conversations: { groupStyleCode: 0 },
        communication_skills: { groupStyleCode: 0 },
        survival_english: { groupStyleCode: 0 },
        oral_english: { groupStyleCode: 0 },
    }],
    ["advanced", {
        idioms_and_expressions: { groupStyleCode: 0 },
        trip_usa_part1: { groupStyleCode: 0 },
        trip_usa_part2: { groupStyleCode: 0 },
        grammar: { groupStyleCode: 1 },

    }],
    ["expert", {
        expressions_english_ag: { groupStyleCode: 0 },
        expressions_english_hn: { groupStyleCode: 0 },
        expressions_english_oz: { groupStyleCode: 0 },
        common_words_in_newspapers: { groupStyleCode: 0 }
    }],
])