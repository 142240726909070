import React, { Component, Fragment } from "react";
import BottomBar from "../../../BottomBar/BottomBar";
import { Col, Row, Breadcrumb } from "antd";
import WeeklyGoal from "../../WeeklyGoal/WeeklyGoal";
import UserDashboardNavBar from "../../UserDashboardNavBar/UserDashboardNavBar";
import UserDashboardNavBottomBar from "../../UserDashboardNavBar/UserDashboardNavBottomBar";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  loadTargetedCourseSubTopics,
  resetTargetedCourseSubTopics,
} from "../../../course/courseActions";
import { withRouter } from "react-router";
import LoadingComponent from "../../../app/common/loading/LoadingComponent";
import _ from "lodash";
import { courseStylingMap, courseColorMap } from "../../../SharedConstant";
import { Link } from "react-router-dom";
import { withFirebase } from "react-redux-firebase";
import { getEnText, getNativeLangTransText } from "../../../SharedUtils";
import {
  CircularProgressbarWithChildren,
  CircularProgressbar,
} from "react-circular-progressbar";

class SubTopicPage extends Component {
  state = {
    isLoading: true,
    iconImageUrl: [],
  };

  componentDidMount() {
    const { match } = this.props;
    let courseId = match.params.courseId;
    let topicId = match.params.topicId;

    this.props.loadTargetedCourseSubTopics(courseId, topicId);
    this.setState({
      courseId,
      topicId,
    });
  }

  componentWillUnmount() {
    this.props.resetTargetedCourseSubTopics();
  }

  async componentDidUpdate(prevProps) {
    const { courseId, topicId } = this.state;
    const { course, firebase, dbAuth, statistics } = this.props;
    const storageRef = firebase.storage().ref();
    if (
      !_.isEqual(prevProps.course.subTopicInfoObj, course.subTopicInfoObj) &&
      course.subTopicInfoObj !== null
    ) {
      //track progress here
      let progressObj = {
        //achivement:{},
      };

      //add up total lessons for all subTopics of current topic,(relying statistics information)

      course.subTopicInfoObj.subTopicInfoList.forEach((item) => {
        let subTopicId = item.docId;

        Object.keys(statistics.statisticsObj.lessons[courseId]).forEach(
          (lessonId) => {
            if (lessonId.includes(`${topicId}-${subTopicId}`, 0)) {
              if (
                typeof progressObj[`${topicId}-${subTopicId}`] === "undefined"
              ) {
                progressObj[`${topicId}-${subTopicId}`] = {};
              }

              if (
                typeof progressObj[`${topicId}-${subTopicId}`].totalLessons ===
                "undefined"
              ) {
                progressObj[`${topicId}-${subTopicId}`].totalLessons =
                  statistics.statisticsObj.lessons[courseId][lessonId];
              } else {
                progressObj[`${topicId}-${subTopicId}`].totalLessons +=
                  statistics.statisticsObj.lessons[courseId][lessonId];
              }
            }
          }
        );
      });

      //retrieve own progress
      if (
        dbAuth.progressInfo.lessons &&
        dbAuth.progressInfo.lessons[courseId]
      ) {
        course.subTopicInfoObj.subTopicInfoList.forEach((item) => {
          let subTopicId = item.docId;

          Object.keys(dbAuth.progressInfo.lessons[courseId]).forEach(
            (lessonId) => {
              if (lessonId.includes(`${topicId}-${subTopicId}`, 0)) {
                if (
                  typeof progressObj[`${topicId}-${subTopicId}`]
                    .totalCompleted === "undefined"
                ) {
                  progressObj[`${topicId}-${subTopicId}`].totalCompleted =
                    dbAuth.progressInfo.lessons[courseId][lessonId].length;
                } else {
                  progressObj[`${topicId}-${subTopicId}`].totalCompleted +=
                    dbAuth.progressInfo.lessons[courseId][lessonId].length;
                }
              } else {
                //ignore intentionally
              }
            }
          );
        });

        //init remaining
        Object.keys(progressObj).forEach((item) => {
          if (typeof progressObj[item].totalCompleted === "undefined") {
            progressObj[item].totalCompleted = 0;
          }
        });
      } else {
        //default init if no progress saved at all
        course.subTopicInfoObj.subTopicInfoList.forEach((item) => {
          let subTopicId = item.docId;
          //console.log(item, topicId);
          progressObj[`${topicId}-${subTopicId}`].totalCompleted = 0;
        });
      }

      let imageUrlPromises = [];
      course.subTopicInfoObj.subTopicInfoList.forEach((item) => {
        if (item.imagePath && item.imagePath.length > 0) {
          imageUrlPromises.push(
            storageRef.child(item.imagePath).getDownloadURL()
          );
        }
      });

      let iconImageUrl = await Promise.all(imageUrlPromises);

      this.setState({
        isLoading: false,
        progressObj,
        iconImageUrl,
      });
    }
  }

  onCourseClick = () => {
    this.props.history.push(`/user-dashboard/courses`);
  };

  onCourseTopicClick = () => {
    const { courseId } = this.state;
    this.props.history.push(`/user-dashboard/courses/${courseId}`);
  };

  render() {
    const { isLoading, courseId, topicId, iconImageUrl, progressObj } =
      this.state;
    const {
      course,
      translation,
      dbAuth,
      onHandleResetLabelClick,
      configuration,
    } = this.props;

    if (isLoading) return <LoadingComponent />;
    return (
      <Fragment>
        <UserDashboardNavBar
          onHandleResetLabelClick={onHandleResetLabelClick}
        />
        <Row>
          <Col className="page-container" span={24}>
            <Row style={{ marginBottom: "16px" }}>
              <Col xs={2} sm={4} md={1} lg={2} xl={4} xxl={4} />
              <Col
                xs={20}
                sm={16}
                md={22}
                lg={20}
                xl={16}
                xxl={16}
                className="content-wrap"
              >
                <Breadcrumb
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "22px",
                  }}
                >
                  <Breadcrumb.Item onClick={this.onCourseClick}>
                    {
                      getNativeLangTransText(
                        dbAuth.userInfo.nativeLanguage,
                        configuration.projectUI.course,
                        translation.translationObj
                      ).text
                    }
                  </Breadcrumb.Item>
                  <Breadcrumb.Item onClick={this.onCourseTopicClick}>
                    {courseColorMap.get(courseId).label === "White"
                      ? getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          configuration.projectUI.white,
                          translation.translationObj
                        ).text
                      : courseColorMap.get(courseId).label === "Yellow"
                      ? getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          configuration.projectUI.yellow,
                          translation.translationObj
                        ).text
                      : courseColorMap.get(courseId).label === "Green"
                      ? getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          configuration.projectUI.green,
                          translation.translationObj
                        ).text
                      : getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          configuration.projectUI.blue,
                          translation.translationObj
                        ).text}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    {getNativeLangTransText(
                      dbAuth.userInfo.nativeLanguage,
                      course.subTopicInfoObj.topicInfoObj.name,
                      translation.translationObj
                    ).text.length > 0
                      ? getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          course.subTopicInfoObj.topicInfoObj.name,
                          translation.translationObj
                        ).text
                      : getEnText(
                          course.subTopicInfoObj.topicInfoObj.name,
                          translation.translationObj
                        ).text}
                  </Breadcrumb.Item>
                </Breadcrumb>
                <Row>
                  <Col xs={0} sm={0} md={14} lg={14} xl={14} xxl={14}>
                    <Row>
                      <Col span={24} style={{ marginTop: "16px" }}>
                        <Row>
                          <Col span={24}>
                            <Link
                              style={{
                                fontFamily: "Poppins-Bold",
                                fontSize: "22px",
                                color: "#4252BE",
                                cursor: "pointer",
                              }}
                              to={`/user-dashboard/courses/${courseId}`}
                            >
                              {"< " +
                                getNativeLangTransText(
                                  dbAuth.userInfo.nativeLanguage,
                                  configuration.projectUI.back,
                                  translation.translationObj
                                ).text}
                            </Link>
                            {/* <div
                          style={{
                            fontFamily: "Poppins-Bold",
                            fontSize: "35px",
                            lineHeight: "normal",
                            whiteSpace: "normal",
                            overflowWrap: "break-word"
                          }}
                        >
                          {
                            getEnText(
                              course.subTopicInfoObj.topicInfoObj.name,
                              translation.translationObj
                            ).text
                          }
                        </div>
                        <br />
                        <div
                          style={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "23px",
                            // overflowY: "scroll",
                            // height: "200px",
                            lineHeight: "normal",
                            whiteSpace: "normal",
                            overflowWrap: "break-word"
                          }}
                        >
                          {
                            getEnText(
                              course.subTopicInfoObj.topicInfoObj.description,
                              translation.translationObj
                            ).text
                          }
                        </div> */}
                          </Col>
                          <Col span={24} style={{ marginTop: "16px" }}>
                            {getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              course.subTopicInfoObj.topicInfoObj.name,
                              translation.translationObj
                            ).text.length > 0 && (
                              <div
                                style={{
                                  fontFamily: "Poppins-Bold",
                                  fontSize: "35px",
                                  lineHeight: "normal",
                                  whiteSpace: "normal",
                                  overflowWrap: "break-word",
                                }}
                              >
                                {
                                  getNativeLangTransText(
                                    dbAuth.userInfo.nativeLanguage,
                                    course.subTopicInfoObj.topicInfoObj.name,
                                    translation.translationObj
                                  ).text
                                }
                              </div>
                            )}

                            {getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              course.subTopicInfoObj.topicInfoObj.description,
                              translation.translationObj
                            ).text.length > 0 && (
                              <div
                                style={{
                                  fontFamily: "Poppins-Regular",
                                  fontSize: "23px",
                                  lineHeight: "normal",
                                  whiteSpace: "normal",
                                  overflowWrap: "break-word",
                                }}
                              >
                                {
                                  getNativeLangTransText(
                                    dbAuth.userInfo.nativeLanguage,
                                    course.subTopicInfoObj.topicInfoObj
                                      .description,
                                    translation.translationObj
                                  ).text
                                }
                              </div>
                            )}

                            {/* Style Code 0 - circle */}
                            {courseStylingMap.get(courseId) &&
                              courseStylingMap.get(courseId)[
                                course.subTopicInfoObj.topicInfoObj.docId
                              ].subTopicStyleCode === 0 && (
                                <Row
                                  gutter={[0, 32]}
                                  style={{ marginTop: "16px" }}
                                >
                                  {course.subTopicInfoObj.subTopicInfoList.map(
                                    (subTopicInfo, idx) => (
                                      <Col span={8} key={idx}>
                                        <div style={{ width: "90px" }}>
                                          <Link
                                            to={`/user-dashboard/courses/${courseId}/topics/${topicId}/subTopics/${subTopicInfo.docId}`}
                                          >
                                            <CircularProgressbarWithChildren
                                              value={0}
                                              strokeWidth={4}
                                              styles={{
                                                path: { stroke: "transparent" },
                                                trail: {
                                                  stroke: "transparent",
                                                },
                                              }}
                                            >
                                              <div style={{ width: "92%" }}>
                                                <CircularProgressbar
                                                  strokeWidth={4}
                                                  background={true}
                                                  value={
                                                    (progressObj[
                                                      `${topicId}-${subTopicInfo.docId}`
                                                    ].totalCompleted /
                                                      progressObj[
                                                        `${topicId}-${subTopicInfo.docId}`
                                                      ].totalLessons) *
                                                    100
                                                  }
                                                  // value={
                                                  //   progressObj.courseObj[
                                                  //     lessonGroupInfo.docId
                                                  //   ]
                                                  // }
                                                  text={
                                                    getEnText(
                                                      subTopicInfo.name,
                                                      translation.translationObj
                                                    ).text
                                                  }
                                                  styles={{
                                                    path: { stroke: "#00FF00" },

                                                    trail: {
                                                      stroke:
                                                        "rgb(211,211,211)",
                                                    },
                                                    text: {
                                                      fontFamily:
                                                        "Poppins-Bold",
                                                      fontSize: "20px",
                                                      fill: "black",
                                                    },
                                                    background: {
                                                      fill: courseColorMap.get(
                                                        courseId
                                                      ).hexColorCode,
                                                    },
                                                  }}
                                                />
                                              </div>
                                            </CircularProgressbarWithChildren>
                                          </Link>
                                        </div>

                                        {/* <Link
                                      to={`/user-dashboard/courses/${courseId}/topics/${topicId}/subTopics/${subTopicInfo.docId}`}
                                      style={{ color: "black" }}
                                    >
                                      <div
                                        style={{
                                          cursor: "pointer",
                                          fontFamily: "Poppins-Bold",
                                          fontSize: "20px",
                                          borderRadius: "50%",
                                          width: "80px",
                                          lineHeight: "80px",
                                          border:
                                            "1px solid rgba(112, 112, 112, 0.4)",
                                          boxShadow:
                                            "0 0 5px rgba(0, 0, 0, 0.14)",
                                          textAlign: "center",
                                          background: courseColorMap.get(
                                            courseId
                                          ).hexColorCode
                                        }}
                                      >
                                        {
                                          getEnText(
                                            subTopicInfo.name,
                                            translation.translationObj
                                          ).text
                                        }
                                      </div>
                                    </Link> */}
                                      </Col>
                                    )
                                  )}
                                </Row>
                              )}

                            {/* Style Code 1  -> long subtopic */}
                            {courseStylingMap.get(courseId) &&
                              courseStylingMap.get(courseId)[
                                course.subTopicInfoObj.topicInfoObj.docId
                              ].subTopicStyleCode === 1 && (
                                <Row gutter={[16, 0]}>
                                  {course.subTopicInfoObj.subTopicInfoList.map(
                                    (subTopicInfo, idx) => (
                                      <Col
                                        span={12}
                                        key={idx}
                                        style={{ marginTop: "24px" }}
                                      >
                                        <Link
                                          to={`/user-dashboard/courses/${courseId}/topics/${topicId}/subTopics/${subTopicInfo.docId}`}
                                          style={{
                                            color: "black",
                                            boxShadow:
                                              "0 0 5px rgba(0,0,0,0.14)",
                                            borderRadius: "24px",
                                            border:
                                              (progressObj[
                                                `${topicId}-${subTopicInfo.docId}`
                                              ].totalCompleted /
                                                progressObj[
                                                  `${topicId}-${subTopicInfo.docId}`
                                                ].totalLessons) *
                                                100 ===
                                              100
                                                ? "3px solid green"
                                                : "1px solid rgba(112,112,112,0.4)",
                                            // "4px solid rgba(112,112,112,0.4)",
                                            fontFamily: "Poppins-Bold",
                                            fontSize: "21px",
                                            display:
                                              iconImageUrl.length > 0
                                                ? "flex"
                                                : "block",
                                            padding: "8px 24px",
                                            background:
                                              courseColorMap.get(courseId)
                                                .hexColorCode,
                                            cursor: "pointer",
                                            whiteSpace: "normal",
                                            overflowWrap: "break-word",
                                          }}
                                        >
                                          {iconImageUrl.length > 0 && (
                                            <img
                                              src={iconImageUrl[idx]}
                                              alt=""
                                              style={{
                                                height: "25px",
                                                alignSelf: "center",
                                              }}
                                            />
                                          )}
                                          <div
                                            style={{
                                              marginLeft:
                                                iconImageUrl.length > 0
                                                  ? "16px"
                                                  : "0px",
                                              fontFamily: "Poppins-Bold",
                                            }}
                                          >
                                            {getNativeLangTransText(
                                              dbAuth.userInfo.nativeLanguage,
                                              subTopicInfo.name,
                                              translation.translationObj
                                            ).text.length > 0
                                              ? getNativeLangTransText(
                                                  dbAuth.userInfo
                                                    .nativeLanguage,
                                                  subTopicInfo.name,
                                                  translation.translationObj
                                                ).text
                                              : getEnText(
                                                  subTopicInfo.name,
                                                  translation.translationObj
                                                ).text}
                                          </div>
                                        </Link>
                                      </Col>
                                    )
                                  )}
                                </Row>
                              )}

                            {/* Style Code 2 - two box in a page*/}

                            {courseStylingMap.get(courseId) &&
                              courseStylingMap.get(courseId)[
                                course.subTopicInfoObj.topicInfoObj.docId
                              ].subTopicStyleCode === 2 && (
                                <Row
                                  gutter={[60, 60]}
                                  style={{
                                    textAlign: "center",
                                    paddingTop: "18px",
                                  }}
                                >
                                  <br />
                                  {course.subTopicInfoObj.subTopicInfoList.map(
                                    (subTopicInfo, idx) => (
                                      <Col span={12} key={idx}>
                                        <Link
                                          to={`/user-dashboard/courses/${courseId}/topics/${topicId}/subTopics/${subTopicInfo.docId}`}
                                          style={{
                                            color: "black",
                                            cursor: "pointer",
                                            boxShadow:
                                              "0 0 5px rgba(0, 0, 0, 0.14)",
                                            borderRadius: "24px",
                                            fontFamily: "Poppins-Bold",
                                            fontSize: "23px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            height: "250px",
                                            background:
                                              courseColorMap.get(courseId)
                                                .hexColorCode,
                                          }}
                                        >
                                          {getNativeLangTransText(
                                            dbAuth.userInfo.nativeLanguage,
                                            subTopicInfo.name,
                                            translation.translationObj
                                          ).text.length > 0
                                            ? getNativeLangTransText(
                                                dbAuth.userInfo.nativeLanguage,
                                                subTopicInfo.name,
                                                translation.translationObj
                                              ).text
                                            : getEnText(
                                                subTopicInfo.name,
                                                translation.translationObj
                                              ).text}
                                        </Link>
                                      </Col>
                                    )
                                  )}
                                </Row>
                              )}

                            {/* <Row>
                          <Col span={24} style={{ marginTop: "24px" }}>
                            <div
                              className="sir2000topics-container"
                              style={{
                                boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                                borderRadius: "24px",
                                border: "1px solid rgba(112,112,112,0.4)",
                                fontFamily: "Poppins-Regular",
                                fontSize: "25px",
                                display: "flex",
                                alignItems: "center",
                                padding: "8px 24px"
                              }}
                            >
                              <img src="/assets/kindergarten-icon.svg" alt="" />
                              <div style={{ marginLeft: "16px" }}>
                                The 500 Most Commonly Used Words
                              </div>
                            </div>
                          </Col>
                          <Col span={24} style={{ marginTop: "24px" }}>
                            <div
                              className="sir2000topics-container"
                              style={{
                                boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                                borderRadius: "24px",
                                border: "1px solid rgba(112,112,112,0.4)",
                                fontFamily: "Poppins-Regular",
                                fontSize: "25px",
                                display: "flex",
                                alignItems: "center",
                                padding: "8px 24px"
                              }}
                            >
                              <img src="/assets/boy-icon.svg" alt="" />
                              <div style={{ marginLeft: "16px" }}>
                                Operation - 100 Words
                              </div>
                            </div>
                          </Col>
                          <Col span={24} style={{ marginTop: "24px" }}>
                            <div
                              className="sir2000topics-container"
                              style={{
                                boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                                borderRadius: "24px",
                                border: "1px solid rgba(112,112,112,0.4)",
                                fontFamily: "Poppins-Regular",
                                fontSize: "25px",
                                display: "flex",
                                alignItems: "center",
                                padding: "8px 24px"
                              }}
                            >
                              <img src="/assets/chair-icon.svg" alt="" />
                              <div style={{ marginLeft: "16px" }}>
                                Things - 400 General Words
                              </div>
                            </div>
                          </Col>
                          <Col span={24} style={{ marginTop: "24px" }}>
                            <div
                              className="sir2000topics-container"
                              style={{
                                boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                                borderRadius: "24px",
                                border: "1px solid rgba(112,112,112,0.4)",
                                fontFamily: "Poppins-Regular",
                                fontSize: "25px",
                                display: "flex",
                                alignItems: "center",
                                padding: "8px 24px"
                              }}
                            >
                              <img src="/assets/fairytale-icon.svg" alt="" />
                              <div style={{ marginLeft: "16px" }}>
                                Things - 200 Picturable
                              </div>
                            </div>
                          </Col>
                          <Col span={24} style={{ marginTop: "24px" }}>
                            <div
                              className="sir2000topics-container"
                              style={{
                                boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                                borderRadius: "24px",
                                border: "1px solid rgba(112,112,112,0.4)",
                                fontFamily: "Poppins-Regular",
                                fontSize: "25px",
                                display: "flex",
                                alignItems: "center",
                                padding: "8px 24px"
                              }}
                            >
                              <img src="/assets/pencil-icon.svg" alt="" />
                              <div style={{ marginLeft: "16px" }}>
                                Qualities - 100 General
                              </div>
                            </div>
                          </Col>
                          <Col span={24} style={{ marginTop: "24px" }}>
                            <div
                              className="sir2000topics-container"
                              style={{
                                boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                                borderRadius: "24px",
                                border: "1px solid rgba(112,112,112,0.4)",
                                fontFamily: "Poppins-Regular",
                                fontSize: "25px",
                                display: "flex",
                                alignItems: "center",
                                padding: "8px 24px"
                              }}
                            >
                              <img src="/assets/swing-icon.svg" alt="" />
                              <div style={{ marginLeft: "16px" }}>
                                Qualities - 50 Opposites
                              </div>
                            </div>
                          </Col>
                          <Col span={24} style={{ marginTop: "24px" }}>
                            <div
                              className="sir2000topics-container"
                              style={{
                                boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                                borderRadius: "24px",
                                border: "1px solid rgba(112,112,112,0.4)",
                                fontFamily: "Poppins-Regular",
                                fontSize: "25px",
                                display: "flex",
                                alignItems: "center",
                                padding: "8px 24px"
                              }}
                            >
                              <img src="/assets/cubes-icon.svg" alt="" />
                              <div style={{ marginLeft: "16px" }}>
                                Word List - Alphabetic
                              </div>
                            </div>
                          </Col>
                        </Row> */}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
                    <Row>
                      <Col span={24} style={{ marginTop: "16px" }}>
                        <Row>
                          <Col span={24}>
                            <Link
                              style={{
                                fontFamily: "Poppins-Bold",
                                fontSize: "19px",
                                color: "#4252BE",
                                cursor: "pointer",
                              }}
                              to={`/user-dashboard/courses/${courseId}`}
                            >
                              {"< " +
                                getNativeLangTransText(
                                  dbAuth.userInfo.nativeLanguage,
                                  configuration.projectUI.back,
                                  translation.translationObj
                                ).text}
                            </Link>
                            {/* <div
                          style={{
                            fontFamily: "Poppins-Bold",
                            fontSize: "28px",
                            lineHeight: "normal",
                            whiteSpace: "normal",
                            overflowWrap: "break-word",
                          }}
                        >
                          {
                            getEnText(
                              course.subTopicInfoObj.topicInfoObj.name,
                              translation.translationObj
                            ).text
                          }
                        </div>
                        <br />
                        <div
                          style={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "20px",
                            // overflowY: "scroll",
                            // height: "200px",
                            lineHeight: "normal",
                            whiteSpace: "normal",
                            overflowWrap: "break-word",
                          }}
                        >
                          {
                            getEnText(
                              course.subTopicInfoObj.topicInfoObj.description,
                              translation.translationObj
                            ).text
                          }
                        </div> */}
                          </Col>
                          <Col span={24} style={{ marginTop: "16px" }}>
                            {console.log(
                              course.subTopicInfoObj.topicInfoObj.name
                            )}
                            {getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              course.subTopicInfoObj.topicInfoObj.name,
                              translation.translationObj
                            ).text.length > 0 && (
                              <div
                                style={{
                                  fontFamily: "Poppins-Bold",
                                  fontSize: "28px",
                                  lineHeight: "normal",
                                  whiteSpace: "normal",
                                  overflowWrap: "break-word",
                                }}
                              >
                                {
                                  getNativeLangTransText(
                                    dbAuth.userInfo.nativeLanguage,
                                    course.subTopicInfoObj.topicInfoObj.name,
                                    translation.translationObj
                                  ).text
                                }
                              </div>
                            )}

                            {getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              course.subTopicInfoObj.topicInfoObj.description,
                              translation.translationObj
                            ).text.length > 0 && (
                              <div
                                style={{
                                  fontFamily: "Poppins-Regular",
                                  fontSize: "20px",
                                  lineHeight: "normal",
                                  whiteSpace: "normal",
                                  overflowWrap: "break-word",
                                }}
                              >
                                {
                                  getNativeLangTransText(
                                    dbAuth.userInfo.nativeLanguage,
                                    course.subTopicInfoObj.topicInfoObj
                                      .description,
                                    translation.translationObj
                                  ).text
                                }
                              </div>
                            )}

                            {/* Style Code 0 */}
                            {courseStylingMap.get(courseId) &&
                              courseStylingMap.get(courseId)[
                                course.subTopicInfoObj.topicInfoObj.docId
                              ].subTopicStyleCode === 0 && (
                                <Row
                                  gutter={[0, 32]}
                                  style={{ marginTop: "16px" }}
                                >
                                  {course.subTopicInfoObj.subTopicInfoList.map(
                                    (subTopicInfo, idx) => (
                                      <Col span={8} key={idx}>
                                        <div style={{ width: "90px" }}>
                                          <Link
                                            to={`/user-dashboard/courses/${courseId}/topics/${topicId}/subTopics/${subTopicInfo.docId}`}
                                          >
                                            <CircularProgressbarWithChildren
                                              value={0}
                                              strokeWidth={4}
                                              styles={{
                                                path: { stroke: "transparent" },
                                                trail: {
                                                  stroke: "transparent",
                                                },
                                              }}
                                            >
                                              <div style={{ width: "92%" }}>
                                                <CircularProgressbar
                                                  strokeWidth={4}
                                                  background={true}
                                                  value={
                                                    (progressObj[
                                                      `${topicId}-${subTopicInfo.docId}`
                                                    ].totalCompleted /
                                                      progressObj[
                                                        `${topicId}-${subTopicInfo.docId}`
                                                      ].totalLessons) *
                                                    100
                                                  }
                                                  text={
                                                    getEnText(
                                                      subTopicInfo.name,
                                                      translation.translationObj
                                                    ).text
                                                  }
                                                  styles={{
                                                    path: { stroke: "#00FF00" },

                                                    trail: {
                                                      stroke:
                                                        "rgb(211,211,211)",
                                                    },
                                                    text: {
                                                      fontFamily:
                                                        "Poppins-Bold",
                                                      fontSize: "20px",
                                                      fill: "black",
                                                    },
                                                    background: {
                                                      fill: courseColorMap.get(
                                                        courseId
                                                      ).hexColorCode,
                                                    },
                                                  }}
                                                />
                                              </div>
                                            </CircularProgressbarWithChildren>
                                          </Link>
                                        </div>
                                      </Col>
                                    )
                                  )}
                                </Row>
                              )}

                            {/* Style Code 1 */}

                            {courseStylingMap.get(courseId) &&
                              courseStylingMap.get(courseId)[
                                course.subTopicInfoObj.topicInfoObj.docId
                              ].subTopicStyleCode === 1 && (
                                <Row>
                                  {course.subTopicInfoObj.subTopicInfoList.map(
                                    (subTopicInfo, idx) => (
                                      <Col
                                        span={24}
                                        key={idx}
                                        style={{ marginTop: "24px" }}
                                      >
                                        <Link
                                          to={`/user-dashboard/courses/${courseId}/topics/${topicId}/subTopics/${subTopicInfo.docId}`}
                                          style={{
                                            color: "black",
                                            boxShadow:
                                              "0 0 5px rgba(0,0,0,0.14)",
                                            borderRadius: "24px",
                                            border:
                                              (progressObj[
                                                `${topicId}-${subTopicInfo.docId}`
                                              ].totalCompleted /
                                                progressObj[
                                                  `${topicId}-${subTopicInfo.docId}`
                                                ].totalLessons) *
                                                100 ===
                                              100
                                                ? "3px solid green"
                                                : "1px solid rgba(112,112,112,0.4)",
                                            // "4px solid rgba(112,112,112,0.4)",
                                            fontFamily: "Poppins-Regular",
                                            fontSize: "20px",
                                            display:
                                              iconImageUrl.length > 0
                                                ? "flex"
                                                : "block",
                                            padding: "8px 24px",
                                            background:
                                              courseColorMap.get(courseId)
                                                .hexColorCode,
                                            cursor: "pointer",
                                            whiteSpace: "normal",
                                            overflowWrap:
                                              iconImageUrl.length > 0
                                                ? null
                                                : "break-word",
                                          }}
                                        >
                                          {iconImageUrl.length > 0 && (
                                            <img
                                              src={iconImageUrl[idx]}
                                              alt=""
                                              style={{
                                                height: "25px",
                                                alignSelf: "center",
                                              }}
                                            />
                                          )}
                                          <div
                                            style={{
                                              marginLeft:
                                                iconImageUrl.length > 0
                                                  ? "16px"
                                                  : "0px",
                                              fontFamily: "Poppins-Bold",
                                            }}
                                          >
                                            {getNativeLangTransText(
                                              dbAuth.userInfo.nativeLanguage,
                                              subTopicInfo.name,
                                              translation.translationObj
                                            ).text.length > 0
                                              ? getNativeLangTransText(
                                                  dbAuth.userInfo
                                                    .nativeLanguage,
                                                  subTopicInfo.name,
                                                  translation.translationObj
                                                ).text
                                              : getEnText(
                                                  subTopicInfo.name,
                                                  translation.translationObj
                                                ).text}
                                          </div>
                                        </Link>
                                      </Col>
                                    )
                                  )}
                                </Row>
                              )}

                            {/* Style Code 2 */}
                            {courseStylingMap.get(courseId) &&
                              courseStylingMap.get(courseId)[
                                course.subTopicInfoObj.topicInfoObj.docId
                              ].subTopicStyleCode === 2 && (
                                <Row
                                  gutter={[16, 16]}
                                  style={{ textAlign: "center" }}
                                >
                                  {course.subTopicInfoObj.subTopicInfoList.map(
                                    (subTopicInfo, idx) => (
                                      <Col span={12} key={idx}>
                                        <Link
                                          to={`/user-dashboard/courses/${courseId}/topics/${topicId}/subTopics/${subTopicInfo.docId}`}
                                          style={{
                                            color: "black",
                                            cursor: "pointer",
                                            boxShadow:
                                              "0 0 5px rgba(0, 0, 0, 0.14)",
                                            borderRadius: "24px",
                                            fontFamily: "Poppins-Bold",
                                            fontSize: "20px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            height: "200px",
                                            background:
                                              courseColorMap.get(courseId)
                                                .hexColorCode,
                                          }}
                                        >
                                          {getNativeLangTransText(
                                            dbAuth.userInfo.nativeLanguage,
                                            subTopicInfo.name,
                                            translation.translationObj
                                          ).text.length > 0
                                            ? getNativeLangTransText(
                                                dbAuth.userInfo.nativeLanguage,
                                                subTopicInfo.name,
                                                translation.translationObj
                                              ).text
                                            : getEnText(
                                                subTopicInfo.name,
                                                translation.translationObj
                                              ).text}
                                        </Link>
                                      </Col>
                                    )
                                  )}
                                </Row>
                              )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <br />
                  </Col>

                  {/* <Col offset={1} span={11}> */}
                  <Col offset={1} xs={0} sm={0} md={9} lg={9} xl={9} xxl={9}>
                    <WeeklyGoal />
                  </Col>
                </Row>
              </Col>
              <Col xs={3} sm={5} md={0} lg={0} xl={0} xxl={0} />
              <Col xs={18} sm={14} md={0} lg={0} xl={0} xxl={0}>
                <WeeklyGoal />
              </Col>
            </Row>
            <Row>
              <Col
                xs={0}
                sm={0}
                md={24}
                lg={24}
                xl={24}
                className="footer-bottom"
              >
                <BottomBar />
              </Col>
              <Col
                xs={0}
                sm={24}
                md={0}
                lg={0}
                xl={0}
                style={{ marginBottom: "100px" }}
              >
                <BottomBar />
              </Col>
              <Col
                xs={24}
                sm={0}
                md={0}
                lg={0}
                xl={0}
                style={{ marginBottom: "70px" }}
              >
                <BottomBar />
              </Col>
            </Row>
            <Row>
              <Col
                xs={24}
                sm={24}
                md={0}
                lg={0}
                xl={0}
                style={{
                  textAlign: "center",
                  borderTop: "4px solid #F1F1F1",
                }}
                className="footer"
              >
                <UserDashboardNavBottomBar
                  onHandleResetLabelClick={onHandleResetLabelClick}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  course: state.course,
  translation: state.translation,
  dbAuth: state.auth,
  statistics: state.statistics,
  configuration: state.configuration,
});

const mapDispatchToProps = {
  loadTargetedCourseSubTopics,
  resetTargetedCourseSubTopics,
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(SubTopicPage);
