import React, { Fragment } from "react";
import "./App.less";
import { Switch, Route } from "react-router-dom";
import HomePage from "./HomePage/HomePageNew";
import Login from "./HomePage/components/Login/LoginNew";
import ForgotPassword from "./HomePage/components/Login/ForgetPassword";
import Ecode from "./HomePage/components/EnterYourECode/Ecode";
import UserDashboard from "./UserDashboard/UserDashboard";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import "react-circular-progressbar/dist/styles.css";
import TermsAndCondition from "./PrivacyPolicy/TermConstions";
//import ConsoleDashboard from './adminConsole/ConsoleDashboard';
//import AdminHomePage from './adminConsole/HomePage';

function App() {
  return (
    <Fragment>
      <Switch>
        {/* <Route path="/exam-scene" component={ExamScene} />*/}
        <Route path="/user-dashboard" component={UserDashboard} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/terms-condition" component={TermsAndCondition} />
        <Route path="/register" component={Ecode} />
        <Route path="/login/forgotpassword" component={ForgotPassword} />
        <Route path="/login" component={Login} />
        
        
        <Route path="/" component={HomePage} />
      </Switch>
    </Fragment>
  );
}

export default App;
