import { createReducer } from "../app/common/utils/reducerUtils";
import { LOAD_TRANSLATION_DATA, RESET_TRANSLATION_DATA } from "./translationConstant";

const initialState = {
    translationObj: null
}

const loadTranslationData = (state, payload) => {
    return {
        ...state,
        translationObj: payload.translationObj
    }
}

const resetTranslationData = (state) => {
    return {
        ...state,
        translationObj: null
    }
}

export default createReducer(initialState, {
    [LOAD_TRANSLATION_DATA]: loadTranslationData,
    [RESET_TRANSLATION_DATA]: resetTranslationData

});