export const LOAD_USER_DATA = "LOAD_USER_DATA";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const UPDATE_USER_LANGUAGE = "UPDATE_USER_LANGUAGE";
export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";
export const RESET_USER_DATA = "RESET_USER_DATA";
export const UPDATE_LESSON_PROGRESS = "UPDATE_LESSON_PROGRESS";
export const UPDATE_QUIZ_PROGRESS = "UPDATE_QUIZ_PROGRESS";
export const UPDATE_EXAM_PROGRESS = "UPDATE_EXAM_PROGRESS";
export const UPDATE_LESSON_ACHIEVEMENT_PROGRESS = "UPDATE_LESSON_ACHIEVEMENT_PROGRESS";
export const UPDATE_QUIZ_ACHIEVEMENT_PROGRESS ="UPDATE_QUIZ_ACHIEVEMENT_PROGRESS";
export const PROGRESS_RESET_START = "PROGRESS_START";
export const PROGRESS_RESET_SUCCESS = "PROGRESS_SUCCESS";
export const PROGRESS_RESET_FAILURE = "PROGRESS_FAILURE";
