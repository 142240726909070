import React, { Component, Fragment } from "react";
import { Redirect, Route, Switch } from "react-router";
import ThisWeekPage from "./ThisWeek/ThisWeekPage/ThisWeekPage";
import ExamPage from "./Exam/ExamPage/ExamPage";
import CoursePage from "./CourseNav/CoursePage/CoursePage";
import LessonAchievementCompleted from "./CourseNav/LessonAchievementCompleted";
import QuizAchievementCompleted from "./CourseNav/QuizAchievementCompleted";
import TopicPage from "./CourseNav/TopicPage/TopicPage";
import SubTopicPage from "./CourseNav/SubTopicPage/SubTopicPage";
import LessonGroupPage from "./CourseNav/LessonGroupPage/LessonGroupPage";
import LessonPage from "./CourseNav/LessonPage/LessonPage";
import AllQuizzesPage from "./Quiz/AllQuizzesPage/AllQuizzesPage";
import QuizTopicPage from "./Quiz/QuizTopicPage/QuizTopicPage";
import QuizGroupPage from "./Quiz/QuizGroupPage/QuizGroupPage";
import QuizQuestionPage from "./Quiz/QuizQuestionPage/QuizQuestionPage";
import ExamLevelDetailsPage from "./Exam/ExamLevelDetailsPage/ExamLevelDetailsPage";
import ExamQuestionPage from "./Exam/ExamQuestionPage/ExamQuestionPage";
import ExamCert from "./Exam/ExamCert";
import StatPage from "./Stat/StatPage/StatPage";
import PreviousVideoPage from "./ThisWeek/PreviousVideoPage/PreviousVideoPage";
import EditProfilePage from "./Profile/EditProfilePage/EditProfilePage";
import GiveUsFeedBackPage from "./Profile/GiveUsFeedBackPage/GiveUsFeedBackPage";
import ResetProgressModal from "../modals/ResetProgressModal";
import GenerateECodesPage from "../admin/GenerateECodesPage";
import ECodeDetailsPage from "../admin/ECodeDetailsPage";
import { connect } from "react-redux";
import { message, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { logout } from "../auth/authActions";

class UserDashboard extends Component {
  state = {
    isResetProgressModalVisible: false,
    reset: false,
  };

  onHandleResetLabelClick = () => {
    this.setState({
      isResetProgressModalVisible: true,
    });
  };
  onHandleResetProgressYesButtonClick = () => {
    const { auth } = this.props;
    notification.open({
      key,
      message: "Resetting Progress",
      duration: 0,
      icon: <LoadingOutlined />,
    });
    console.log(auth);
    this.props.resetProgress(auth.uid);
    this.setState({
      isResetProgressModalVisible: false,
      reset: true,
      // isLoading: true
    });
  };

  onHandleResetProgressNoButtonClick = () => {
    this.setState({
      isResetProgressModalVisible: false,
    });
  };

  // componentDidUpdate() {
  //   const{dbAuth} = this.props
  //   if (dbAuth){
  //     // console.log(this.props)
  //     if (
  //       !dbAuth.loading &&
  //       dbAuth.progressResetSuccess &&
  //       this.state.reset
  //     ) {
  //       notification.open({
  //         key,
  //         message: "Success",
  //         description: "Successfully reseted your progress",
  //         icon: (
  //           <CheckCircleTwoTone
  //             twoToneColor="
  //         #52c41a"
  //           />
  //         )
  //       });
  //       this.setState({
  //         reset: false
  //       });
  //     } else if (
  //       !dbAuth.loading &&
  //       dbAuth.progressResetFailure &&
  //       this.state.reset
  //     ) {
  //       notification.open({
  //         key,
  //         message: "Failed",
  //         description: dbAuth.errMsg,
  //         duration: 3,
  //         icon: (
  //           <CloseCircleTwoTone
  //             twoToneColor="
  //         #ff5255"
  //           />
  //         )
  //       });
  //       this.form.current.resetFields();
  //       this.setState({
  //         reset: false
  //       });
  //     }
  //   }

  // }

  expired = async () => {
    message.error(
      "Expired E-Code : Your account's E-Code has expired. Please use a new E-Code."
    );
    await this.props.logout();
    this.props.history.push(`/`);
  };

  render() {
    const { isResetProgressModalVisible } = this.state;
    const { auth, dbAuth } = this.props;

    const isAuthenticated = auth.uid && !auth.isAnonymous;
    //console.log(dbAuth.userInfo.admin);
    if (!isAuthenticated) return <Redirect to="/" />;
    else if (
      !dbAuth.userInfo.admin &&
      dbAuth.userInfo.subscription &&
      dbAuth.userInfo.subscription.endDate.seconds * 1000 < new Date().getTime()
    ) {
      this.expired();
      return null;
    }

    return (
      <Fragment>
        <ResetProgressModal
          isVisible={isResetProgressModalVisible}
          onCancel={this.onHandleResetProgressNoButtonClick}
          onYes={this.onHandleResetProgressYesButtonClick}
        />

        <Switch>
          <Redirect exact from="/user-dashboard" to="/user-dashboard/courses" />
          <Route
            path="/user-dashboard/courses/:courseId/topics/:topicId/subTopics/:subTopicId/lessons/:groupId/lesson_achievement"
            render={(props) => (
              <LessonAchievementCompleted
                {...props}
                onHandleResetLabelClick={this.onHandleResetLabelClick}
              />
            )}
          />
          <Route
            path="/user-dashboard/quiz/all-quizzes/:quizId/topics/:topicId/groups/:groupId/quiz_achievement"
            render={(props) => (
              <QuizAchievementCompleted
                {...props}
                onHandleResetLabelClick={this.onHandleResetLabelClick}
              />
            )}
          />
          <Route
            path="/user-dashboard/courses/:courseId/topics/:topicId/subTopics/:subTopicId/lessons/:groupId"
            component={LessonPage}
          />
          <Route
            path="/user-dashboard/courses/:courseId/topics/:topicId/subTopics/:subTopicId"
            render={(props) => (
              <LessonGroupPage
                {...props}
                onHandleResetLabelClick={this.onHandleResetLabelClick}
              />
            )}
          />
          <Route
            path="/user-dashboard/courses/:courseId/topics/:topicId"
            render={(props) => (
              <SubTopicPage
                {...props}
                onHandleResetLabelClick={this.onHandleResetLabelClick}
              />
            )}
          />
          <Route
            path="/user-dashboard/courses/:courseId"
            render={(props) => (
              <TopicPage
                {...props}
                onHandleResetLabelClick={this.onHandleResetLabelClick}
              />
            )}
          />
          <Route
            path="/user-dashboard/courses"
            render={(props) => (
              <CoursePage
                {...props}
                onHandleResetLabelClick={this.onHandleResetLabelClick}
              />
            )}
          />
          <Route
            path="/user-dashboard/this-week/previous-video"
            render={(props) => (
              <PreviousVideoPage
                {...props}
                onHandleResetLabelClick={this.onHandleResetLabelClick}
              />
            )}
          />
          <Route
            path="/user-dashboard/this-week"
            render={(props) => (
              <ThisWeekPage
                {...props}
                onHandleResetLabelClick={this.onHandleResetLabelClick}
              />
            )}
          />
          <Route
            path="/user-dashboard/quiz/all-quizzes/:quizId/topics/:topicId/groups/:groupId"
            component={QuizQuestionPage}
          />
          <Route
            path="/user-dashboard/quiz/all-quizzes/:quizId/topics/:topicId"
            render={(props) => (
              <QuizGroupPage
                {...props}
                onHandleResetLabelClick={this.onHandleResetLabelClick}
              />
            )}
          />
          <Route
            path="/user-dashboard/quiz/all-quizzes/:quizId"
            render={(props) => (
              <QuizTopicPage
                {...props}
                onHandleResetLabelClick={this.onHandleResetLabelClick}
              />
            )}
          />
          <Route
            path="/user-dashboard/quiz/all-quizzes"
            render={(props) => (
              <AllQuizzesPage
                {...props}
                onHandleResetLabelClick={this.onHandleResetLabelClick}
              />
            )}
          />
          {/* <Route path="/user-dashboard/quiz" render={(props)=><QuizPage  {...props} onHandleResetLabelClick={this.onHandleResetLabelClick}/>}/> */}
          <Route
            path="/user-dashboard/quiz"
            render={(props) => (
              <AllQuizzesPage
                {...props}
                onHandleResetLabelClick={this.onHandleResetLabelClick}
              />
            )}
          />

          <Route
            path="/user-dashboard/exam/:examId/groups/:groupId"
            component={ExamQuestionPage}
          />

          <Route
            path="/user-dashboard/exam/:examId/certificate"
            component={ExamCert}
          />

          <Route
            path="/user-dashboard/exam/:examId"
            render={(props) => (
              <ExamLevelDetailsPage
                {...props}
                onHandleResetLabelClick={this.onHandleResetLabelClick}
              />
            )}
          />
          <Route
            path="/user-dashboard/exam"
            render={(props) => (
              <ExamPage
                {...props}
                onHandleResetLabelClick={this.onHandleResetLabelClick}
              />
            )}
          />
          <Route
            path="/user-dashboard/stats"
            render={(props) => (
              <StatPage
                {...props}
                onHandleResetLabelClick={this.onHandleResetLabelClick}
              />
            )}
          />
          <Route
            path="/user-dashboard/profile"
            render={(props) => (
              <EditProfilePage
                {...props}
                onHandleResetLabelClick={this.onHandleResetLabelClick}
              />
            )}
          />
          <Route
            path="/user-dashboard/give-us-feedback"
            render={(props) => (
              <GiveUsFeedBackPage
                {...props}
                onHandleResetLabelClick={this.onHandleResetLabelClick}
              />
            )}
          />
          <Route
            path="/user-dashboard/admin/:eCodeBundleId"
            render={(props) => (
              <ECodeDetailsPage
                {...props}
                onHandleResetLabelClick={this.onHandleResetLabelClick}
              />
            )}
          />
          <Route
            path="/user-dashboard/admin"
            render={(props) => (
              <GenerateECodesPage
                {...props}
                onHandleResetLabelClick={this.onHandleResetLabelClick}
              />
            )}
          />
          {/* <Route path="/user-dashboard/terms_and_condition" render={(props)=><TermsAndCondition  {...props} onHandleResetLabelClick={this.onHandleResetLabelClick}/>} /> */}
        </Switch>
      </Fragment>
    );
  }
}
const key = "resetProgress";

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  dbAuth: state.auth,
});
const mapDispatchToProps = {
  logout,
};
export default connect(mapStateToProps, mapDispatchToProps)(UserDashboard);
