import { combineReducers } from "redux";
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";
import courseReducer from "../../course/courseReducer";
import translationReducer from "../../translation/translationReducer";
import authReducer from "../../auth/authReducer";
import operationReducer from "../../operation/operationReducer";
import statisticsReducer from "../../statistics/statisticsReducer";
import achievementReducer from "../../achievement/achievementReducer";
import feedbackReducer from "../../feedback/FeedbackReducer";
import SubscribeReducer from "../../Subscribe/SubscribeReducer";
import languageReducer from "../../general/generalReducer";
import ecodeReducer from "../../admin/ecodeReducer";

const rootReducer = combineReducers({
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  auth: authReducer,
  course: courseReducer,
  translation: translationReducer,
  operation: operationReducer,
  statistics: statisticsReducer,
  achievement: achievementReducer,
  feedback: feedbackReducer,
  subscribe: SubscribeReducer,
  configuration: languageReducer,
  ecodesRecords: ecodeReducer,
});

export default rootReducer;
