import React, { Component, Fragment } from "react";
import "./UserDashboardNavBar.css";
import { Popover, Menu, Row, Col } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import LanguageSelectionComponent from "../../LanguageSelectionComponent";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";
import { withFirebase } from "react-redux-firebase";
import { logout } from "../../auth/authActions";
import { getNativeLangTransText } from "../../SharedUtils";

class UserDashboardNavBar extends Component {
  state = {
    thisWeekMenuObj: {
      selected: false,
      exactMatch: false,
    },
    courseMenuObj: {
      selected: false,
      exactMatch: false,
    },
    quizMenuObj: {
      selected: false,
      exactMatch: false,
    },
    examMenuObj: {
      selected: false,
      exactMatch: false,
    },
    statsMenuObj: {
      selected: false,
      exactMatch: false,
    },
    // loading : false
  };

  componentDidMount() {
    const { location } = this.props;
    this.setState({
      thisWeekMenuObj: {
        selected: location.pathname.match(/this-week/g) !== null,
        exactMatch: location.pathname.match(/this-week$/g) !== null,
      },

      courseMenuObj: {
        selected: location.pathname.match(/courses/g) !== null,
        exactMatch: location.pathname.match(/courses$/g) !== null,
      },
      quizMenuObj: {
        selected: location.pathname.match(/quiz/g) !== null,
        exactMatch: location.pathname.match(/quiz$/g) !== null,
      },

      examMenuObj: {
        selected: location.pathname.match(/exam/g) !== null,
        exactMatch: location.pathname.match(/exam$/g) !== null,
      },

      statsMenuObj: {
        selected: location.pathname.match(/stats/g) !== null,
        exactMatch: location.pathname.match(/stats$/g) !== null,
      },
      // emptyUser : dbAuth
    });
  }

  // async componentDidUpdate(prevProps) {
  //   const { dbAuth } = this.props;
  //   if (!this.props.loading ){
  //     this.setState({
  //       dbAuth: dbAuth,
  //       loading : true
  //   })
  //   }

  // }

  onProfileMenuClick = async (e, onHandleResetLabelClick) => {
    if (e.key === "/user-dashboard/profile") {
      this.props.history.push("/user-dashboard/profile");
    } else if (e.key === "/user-dashboard/give-us-feedback") {
      this.props.history.push("/user-dashboard/give-us-feedback");
      // } else if (e.key === "/user-dashboard/terms_and_condition") {
      //   this.props.history.push("/user-dashboard/terms_and_condition");
    } else if (e.key === "resetProgress") {
      onHandleResetLabelClick();
    } else if (e.key === "signOut") {
      await this.props.logout();
      this.props.history.push("/");
    }
  };

  render() {
    const {
      thisWeekMenuObj,
      courseMenuObj,
      quizMenuObj,
      examMenuObj,
      statsMenuObj,
    } = this.state;

    // console.log(dbAuth);
    const {
      onHandleResetLabelClick,
      location,
      dbAuth,
      configuration,
      translation,
    } = this.props;

    const ProfileMenu = ({ onHandleResetLabelClick, location }) => {
      return (
        <Menu
          style={{
            fontFamily: "Poppins-Regular",
            fontSize: "16px",
            borderRight: "none",
          }}
          onClick={(e) => this.onProfileMenuClick(e, onHandleResetLabelClick)}
          selectedKeys={[location.pathname]}
        >
          <Menu.Item key="/user-dashboard/profile">
            {
              getNativeLangTransText(
                dbAuth.userInfo.nativeLanguage,
                configuration.projectUI.profile,
                translation.translationObj
              ).text
            }
          </Menu.Item>
          {/* <Menu.Item key="/user-dashboard/give-us-feedback">
            Give us Feedback
          </Menu.Item> */}
          {/* <Menu.Item key="/user-dashboard/terms_and_condition">
            Terms and Condition
          </Menu.Item> */}

          {/* <Menu.Item key="resetProgress">Reset Progress</Menu.Item> */}

          <Menu.Item key="signOut">
            {
              getNativeLangTransText(
                dbAuth.userInfo.nativeLanguage,
                configuration.projectUI.sign_out,
                translation.translationObj
              ).text
            }
          </Menu.Item>
        </Menu>
      );
    };

    return (
      <Fragment>
        <Row className="user-dashboard-nav-bar-ul-container">
          <Col span={1} />
          <Col
            xs={0}
            sm={0}
            md={0}
            lg={0}
            xl={3}
            style={{ textAlign: "center" }}
          >
            <img
              width="220px"
              src="/assets/home-page-logo-esl.svg"
              alt=""
              style={{ marginBottom: "10px" }}
            />
          </Col>
          {/* <Col
            xs={2}
            sm={2}
            md={2}
            lg={0}
            xl={0}
            style={{ textAlign: "center" }}
          >
            <img
              width="180px"
              src="/assets/home-page-logo-esl.svg"
              alt=""
              style={{ marginBottom: "10px" }}
            />
          </Col> */}
          <Col
            xs={0}
            sm={4}
            md={2}
            lg={2}
            xl={0}
            style={{ textAlign: "center" }}
          >
            <img
              width="90px"
              src="/assets/home-page-logo-small.svg"
              alt=""
              style={{ marginBottom: "10px" }}
            />
          </Col>
          {/* <Col
            xs={0}
            sm={0}
            md={0}
            lg={3}
            xl={3}
            style={{ textAlign: "center" }}
          >
            <a
              href="/user-dashboard/this-week"
              disabled={thisWeekMenuObj.exactMatch}
            >
              <img
                src={
                  thisWeekMenuObj.selected
                    ? "/assets/calendar-icon-selected.svg"
                    : "/assets/calendar-icon.svg"
                }
                alt=""
                style={{ width: "40px", height: "40px" }}
              />
              <div
                style={{
                  fontFamily: "Poppins-Bold",
                  fontSize: "23px",
                  color: thisWeekMenuObj.selected ? "#4252BE" : "#707070"
                }}
              >
                This week
              </div>
            </a>
          </Col> */}
          <Col
            xs={0}
            sm={0}
            md={3}
            lg={3}
            xl={3}
            style={{ textAlign: "center" }}
          >
            <a
              href="/user-dashboard/courses"
              disabled={courseMenuObj.exactMatch}
            >
              <img
                src={
                  courseMenuObj.selected
                    ? "/assets/book-icon-selected.svg"
                    : "/assets/book-icon.svg"
                }
                alt=""
                style={{ width: "40px", height: "40px", alignSelf: "center" }}
              />
              <div
                style={{
                  fontFamily: "Poppins-Bold",
                  fontSize: "23px",
                  color: courseMenuObj.selected ? "#4252BE" : "#707070",
                }}
              >
                {
                  getNativeLangTransText(
                    dbAuth.userInfo.nativeLanguage,
                    configuration.projectUI.course,
                    translation.translationObj
                  ).text
                }
              </div>
            </a>
          </Col>
          <Col
            xs={0}
            sm={4}
            md={0}
            lg={0}
            xl={0}
            style={{ textAlign: "center" }}
          >
            <a
              href="/user-dashboard/courses"
              disabled={courseMenuObj.exactMatch}
            >
              <img
                src={
                  courseMenuObj.selected
                    ? "/assets/book-icon-selected.svg"
                    : "/assets/book-icon.svg"
                }
                alt=""
                style={{ width: "40px", height: "40px", alignSelf: "center" }}
              />
              <div
                style={{
                  fontFamily: "Poppins-Bold",
                  fontSize: "23px",
                  color: courseMenuObj.selected ? "#4252BE" : "#707070",
                }}
              >
                {
                  getNativeLangTransText(
                    dbAuth.userInfo.nativeLanguage,
                    configuration.projectUI.course,
                    translation.translationObj
                  ).text
                }
              </div>
            </a>
          </Col>

          <Col
            xs={0}
            sm={0}
            md={3}
            lg={3}
            xl={3}
            style={{ textAlign: "center" }}
          >
            <a href="/user-dashboard/quiz" disabled={quizMenuObj.exactMatch}>
              <img
                src={
                  quizMenuObj.selected
                    ? "/assets/checklist-icon-selected.svg"
                    : "/assets/checklist-icon.svg"
                }
                alt=""
                style={{ width: "40px", height: "40px" }}
              />
              <div
                style={{
                  fontFamily: "Poppins-Bold",
                  fontSize: "23px",
                  color: quizMenuObj.selected ? "#4252BE" : "#707070",
                }}
              >
                {
                  getNativeLangTransText(
                    dbAuth.userInfo.nativeLanguage,
                    configuration.projectUI.quiz,
                    translation.translationObj
                  ).text
                }
              </div>
            </a>
          </Col>
          <Col
            xs={0}
            sm={4}
            md={0}
            lg={0}
            xl={0}
            style={{ textAlign: "center" }}
          >
            <a href="/user-dashboard/quiz" disabled={quizMenuObj.exactMatch}>
              <img
                src={
                  quizMenuObj.selected
                    ? "/assets/checklist-icon-selected.svg"
                    : "/assets/checklist-icon.svg"
                }
                alt=""
                style={{ width: "40px", height: "40px" }}
              />
              <div
                style={{
                  fontFamily: "Poppins-Bold",
                  fontSize: "23px",
                  color: quizMenuObj.selected ? "#4252BE" : "#707070",
                }}
              >
                {
                  getNativeLangTransText(
                    dbAuth.userInfo.nativeLanguage,
                    configuration.projectUI.quiz,
                    translation.translationObj
                  ).text
                }
              </div>
            </a>
          </Col>
          <Col
            xs={0}
            sm={0}
            md={3}
            lg={3}
            xl={3}
            style={{ textAlign: "center" }}
          >
            <a href="/user-dashboard/exam" disabled={examMenuObj.exactMatch}>
              <img
                src={
                  examMenuObj.selected
                    ? "/assets/exam-icon-selected.svg"
                    : "/assets/exam-icon.svg"
                }
                alt=""
                style={{ width: "40px", height: "40px" }}
              />
              <div
                style={{
                  fontFamily: "Poppins-Bold",
                  fontSize: "23px",
                  color: examMenuObj.selected ? "#4252BE" : "#707070",
                }}
              >
                {
                  getNativeLangTransText(
                    dbAuth.userInfo.nativeLanguage,
                    configuration.projectUI.exam,
                    translation.translationObj
                  ).text
                }
              </div>
            </a>
          </Col>
          <Col
            xs={0}
            sm={4}
            md={0}
            lg={0}
            xl={0}
            style={{ textAlign: "center" }}
          >
            <a href="/user-dashboard/exam" disabled={examMenuObj.exactMatch}>
              <img
                src={
                  examMenuObj.selected
                    ? "/assets/exam-icon-selected.svg"
                    : "/assets/exam-icon.svg"
                }
                alt=""
                style={{ width: "40px", height: "40px" }}
              />
              <div
                style={{
                  fontFamily: "Poppins-Bold",
                  fontSize: "23px",
                  color: examMenuObj.selected ? "#4252BE" : "#707070",
                }}
              >
                {
                  getNativeLangTransText(
                    dbAuth.userInfo.nativeLanguage,
                    configuration.projectUI.exam,
                    translation.translationObj
                  ).text
                }
              </div>
            </a>
          </Col>

          <Col
            xs={0}
            sm={0}
            md={3}
            lg={3}
            xl={3}
            style={{ textAlign: "center" }}
          >
            <a href="/user-dashboard/stats" disabled={statsMenuObj.exactMatch}>
              <img
                src={
                  statsMenuObj.selected
                    ? "/assets/graph-icon-selected.svg"
                    : "/assets/graph-icon.svg"
                }
                alt=""
                style={{ width: "40px", height: "40px" }}
              />
              <div
                style={{
                  fontFamily: "Poppins-Bold",
                  fontSize: "23px",
                  color: statsMenuObj.selected ? "#4252BE" : "#707070",
                }}
              >
                {
                  getNativeLangTransText(
                    dbAuth.userInfo.nativeLanguage,
                    configuration.projectUI.stats,
                    translation.translationObj
                  ).text
                }
              </div>
            </a>
          </Col>
          <Col offset={1} xs={0} sm={0} md={3} lg={3} xl={3}>
            <Popover
              overlayClassName="home-page-popover-container-wrapper"
              content={<LanguageSelectionComponent />}
              trigger="click"
            >
              <div
                style={{
                  display: "flex",
                  cursor: "pointer",
                }}
              >
                <span
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "23px",
                    color: "#707070",
                  }}
                >
                  {
                    this.props.configuration.languageList.find(
                      (language) =>
                        language.languageCode === dbAuth.userInfo.nativeLanguage
                    ).languageName
                  }
                </span>
                &nbsp;&nbsp;
                <img src="/assets/down-ui-icon.svg" alt="" width="20px" />
              </div>
            </Popover>
          </Col>
          {/* xs */}
          <Col
            xs={5}
            sm={0}
            md={0}
            lg={0}
            xl={0}
            style={{ textAlign: "center", paddingBottom: "10px" }}
          >
            <a
              href="/user-dashboard/courses"
              disabled={courseMenuObj.exactMatch}
            >
              <img
                src={
                  courseMenuObj.selected
                    ? "/assets/book-icon-selected.svg"
                    : "/assets/book-icon.svg"
                }
                alt=""
                style={{ width: "35px", height: "35px" }}
              />
            </a>
          </Col>
          <Col
            xs={6}
            sm={0}
            md={0}
            lg={0}
            xl={0}
            style={{ textAlign: "center", paddingBottom: "10px" }}
          >
            <a href="/user-dashboard/quiz" disabled={quizMenuObj.exactMatch}>
              <img
                src={
                  quizMenuObj.selected
                    ? "/assets/checklist-icon-selected.svg"
                    : "/assets/checklist-icon.svg"
                }
                alt=""
                style={{ width: "35px", height: "35px" }}
              />
            </a>
          </Col>
          <Col
            xs={6}
            sm={0}
            md={0}
            lg={0}
            xl={0}
            style={{ textAlign: "center", paddingBottom: "10px" }}
          >
            <a href="/user-dashboard/exam" disabled={examMenuObj.exactMatch}>
              <img
                src={
                  examMenuObj.selected
                    ? "/assets/exam-icon-selected.svg"
                    : "/assets/exam-icon.svg"
                }
                alt=""
                style={{ width: "35px", height: "35px" }}
              />
            </a>
          </Col>
          <Col
            xs={0}
            sm={4}
            md={3}
            lg={3}
            xl={3}
            style={{ textAlign: "center" }}
          >
            <Popover
              overlayClassName="home-page-popover-container-wrapper"
              content={
                <ProfileMenu
                  location={location}
                  onHandleResetLabelClick={onHandleResetLabelClick}
                />
              }
              trigger="click"
            >
              <div
                style={{
                  display: "flex",
                  cursor: "pointer",
                  padding: "0px 40px",
                }}
              >
                <span
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "23px",
                    color: "#707070",
                  }}
                >
                  {dbAuth.userInfo.name}
                </span>
                &nbsp;&nbsp;
                <img src="/assets/down-ui-icon.svg" alt="" width="20px" />
              </div>
            </Popover>
          </Col>
          <Col
            xs={5}
            sm={0}
            md={0}
            lg={0}
            xl={0}
            style={{ textAlign: "center" }}
          >
            <Popover
              overlayClassName="home-page-popover-container-wrapper"
              content={
                <ProfileMenu
                  location={location}
                  onHandleResetLabelClick={onHandleResetLabelClick}
                />
              }
              trigger="click"
            >
              <div
                style={{
                  display: "flex",
                  cursor: "pointer",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "23px",
                    color: "#707070",
                  }}
                >
                  <MenuOutlined />
                </span>
              </div>
            </Popover>
          </Col>

          {/* <Col
            xs={{ span: 3, offset: 17 }}
            sm={{ span: 3, offset: 17 }}
            md={0}
            lg={0}
            xl={0}
            align="right"
          >
            <Popover
              placement="bottomRight"
              trigger="click"
              content={
                <Menu
                  selectedKeys={[location.pathname]}
                  mode="inline"
                  style={{
                    borderRight: "none",
                    background: "transparent",
                    borderBottom: "none"
                  }}
                >
                  <Menu.Item key="/user-dashboard/this-week">
                    <Link to="/user-dashboard/this-week">This week</Link>
                  </Menu.Item>
                  <Menu.Item key="/user-dashboard/courses">
                    <Link to="/user-dashboard/courses">Course</Link>
                  </Menu.Item>
                  <Menu.Item key="/user-dashboard/quiz">
                    <Link to="/user-dashboard/quiz">Quiz</Link>
                  </Menu.Item>
                  <Menu.Item key="/user-dashboard/exam">
                    <Link to="/user-dashboard/exam">Exam</Link>
                  </Menu.Item>
                  <Menu.Item key="/user-dashboard/stats">
                    <Link to="/user-dashboard/stats">Statistics</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Popover
                      overlayClassName="home-page-popover-container-wrapper"
                      content={<LanguageSelectionComponent />}
                      trigger="click"
                    >
                      <div
                        style={{
                          display: "flex",
                          cursor: "pointer"
                        }}
                      >
                        <span
                        >
                          {dbAuth.userInfo.nativeLanguage === "en"
                            ? "English"
                            : dbAuth.userInfo.nativeLanguage === "ms"
                            ? "Malay"
                            : dbAuth.userInfo.nativeLanguage === "cs"
                            ? "简体中文"
                            : dbAuth.userInfo.nativeLanguage === "ct"
                            ? "繁體中文"
                            : dbAuth.userInfo.nativeLanguage === "in"
                            ? "Bahasa Indonesia"
                            : "ไทย"}
                        </span>
                        &nbsp;&nbsp;
                        <img
                          src="/assets/down-ui-icon.svg"
                          alt=""
                          width="12px"
                        />
                      </div>
                    </Popover>
                  </Menu.Item>
                  <Menu.Item>
                    <Popover
                      overlayClassName="home-page-popover-container-wrapper"
                      content={
                        <ProfileMenu
                          location={location}
                          onHandleResetLabelClick={onHandleResetLabelClick}
                        />
                      }
                      trigger="click"
                    >
                      <div
                        style={{
                          display: "flex",
                          cursor: "pointer"
                        }}
                      >
                        <span>{dbAuth.userInfo.name}</span>
                        &nbsp;&nbsp;
                        <img
                          src="/assets/down-ui-icon.svg"
                          alt=""
                          width="12px"
                        />
                      </div>
                    </Popover>
                  </Menu.Item>
                </Menu>
              }
            >
              <MenuOutlined />
            </Popover>
          </Col> */}
          <Col span={1} />
        </Row>
        {/* <ul className="user-dashboard-nav-bar-ul-container">
          <li>
            <img
            width="80px"
              src="/assets/home-page-logo-esl.svg"
              alt=""
              style={{ marginBottom: "10px" }}
            />
          </li>
          <li>
            <a
              href="/user-dashboard/this-week"
              disabled={thisWeekMenuObj.exactMatch}
            >
              <img
                src={
                  thisWeekMenuObj.selected
                    ? "/assets/calendar-icon-selected.svg"
                    : "/assets/calendar-icon.svg"
                }
                alt=""
                style={{ width: "40px", height: "40px" }}
              />
              <div
                style={{
                  fontFamily: "Poppins-Bold",
                  fontSize: "23px",
                  color: thisWeekMenuObj.selected ? "#4252BE" : "#707070"
                }}
              >
                This week
              </div>
            </a>
          </li>
          <li>
            <a
              href="/user-dashboard/courses"
              disabled={courseMenuObj.exactMatch}
            >
              <img
                src={
                  courseMenuObj.selected
                    ? "/assets/book-icon-selected.svg"
                    : "/assets/book-icon.svg"
                }
                alt=""
                style={{ width: "40px", height: "40px" }}
              />
              <div
                style={{
                  fontFamily: "Poppins-Bold",
                  fontSize: "23px",
                  color: courseMenuObj.selected ? "#4252BE" : "#707070"
                }}
              >
                Course
              </div>
            </a>
          </li>
          <li>
            <a href="/user-dashboard/quiz" disabled={quizMenuObj.exactMatch}>
              <img
                src={
                  quizMenuObj.selected
                    ? "/assets/checklist-icon-selected.svg"
                    : "/assets/checklist-icon.svg"
                }
                alt=""
                style={{ width: "40px", height: "40px" }}
              />
              <div
                style={{
                  fontFamily: "Poppins-Bold",
                  fontSize: "23px",
                  color: quizMenuObj.selected ? "#4252BE" : "#707070"
                }}
              >
                Quiz
              </div>
            </a>
          </li>
          <li>
            <a href="/user-dashboard/exam" disabled={examMenuObj.exactMatch}>
              <img
                src={
                  examMenuObj.selected
                    ? "/assets/exam-icon-selected.svg"
                    : "/assets/exam-icon.svg"
                }
                alt=""
                style={{ width: "40px", height: "40px" }}
              />
              <div
                style={{
                  fontFamily: "Poppins-Bold",
                  fontSize: "23px",
                  color: examMenuObj.selected ? "#4252BE" : "#707070"
                }}
              >
                Exam
              </div>
            </a>
          </li>
          <li>
            <a href="/user-dashboard/stats" disabled={statsMenuObj.exactMatch}>
              <img
                src={
                  statsMenuObj.selected
                    ? "/assets/graph-icon-selected.svg"
                    : "/assets/graph-icon.svg"
                }
                alt=""
                style={{ width: "40px", height: "40px" }}
              />
              <div
                style={{
                  fontFamily: "Poppins-Bold",
                  fontSize: "23px",
                  color: statsMenuObj.selected ? "#4252BE" : "#707070"
                }}
              >
                Stats
              </div>
            </a>
          </li>
          <li>
            <Popover
              overlayClassName="home-page-popover-container-wrapper"
              content={<LanguageSelectionComponent />}
              trigger="click"
            >
              <div
                style={{
                  display: "flex",
                  cursor: "pointer",
                  padding: "0px 40px"
                }}
              >
                <span
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "23px",
                    color: "#707070"
                  }}
                >
                  {dbAuth.userInfo.nativeLanguage === "en"
                    ? "English"
                    : dbAuth.userInfo.nativeLanguage === "ms"
                    ? "Malay"
                    : dbAuth.userInfo.nativeLanguage === "cs"
                    ? "简体中文"
                    : dbAuth.userInfo.nativeLanguage === "ct"
                    ? "繁體中文"
                    : dbAuth.userInfo.nativeLanguage === "in"
                    ? "Bahasa Indonesia"
                    : "ไทย"}
                </span>
                &nbsp;&nbsp;
                <img src="/assets/down-ui-icon.svg" alt="" width="20px" />
              </div>
            </Popover>
          </li>
          <li>
            <Popover
              overlayClassName="home-page-popover-container-wrapper"
              content={
                <ProfileMenu
                  location={location}
                  onHandleResetLabelClick={onHandleResetLabelClick}
                />
              }
              trigger="click"
            >
              <div
                style={{
                  display: "flex",
                  cursor: "pointer",
                  padding: "0px 40px"
                }}
              >
                <span
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "23px",
                    color: "#707070"
                  }}
                >
                  {dbAuth.userInfo.name}
                </span>
                &nbsp;&nbsp;
                <img src="/assets/down-ui-icon.svg" alt="" width="20px" />
              </div>
            </Popover>
          </li>
        </ul> */}

        {/* <Menu
          mode="horizontal"
          style={{
            borderBottom: "none",
            textAlign: "center",
            fontFamily: "Poppins-Bold",
             fontSize: "25px",
          }}
        >
          <Menu.Item
            icon={
              <img
                src="/assets/calendar-icon.svg"
                alt=""
                style={{ margin: "0px 80px" }}
              />
            }
          >
            <div>This Week</div>
          </Menu.Item>
          <Menu.Item
            icon={
              <img
                src="/assets/book-icon.svg"
                alt=""
                style={{ margin: "0px 80px" }}
              />
            }
          >
            <div>Lesson</div>
          </Menu.Item>
          <Menu.Item
            icon={
              <img
                src="/assets/checklist-icon.svg"
                alt=""
                style={{ margin: "0px 80px" }}
              />
            }
          >
            <div>Quiz</div>
          </Menu.Item>
          <Menu.Item
            icon={
              <img
                src="/assets/exam-icon.svg"
                alt=""
                style={{ margin: "0px 80px" }}
              />
            }
          >
            <div>Exam</div>
          </Menu.Item>
          <Menu.Item
            icon={
              <img
                src="/assets/graph-icon.svg"
                alt=""
                style={{ margin: "0px 80px" }}
              />
            }
          >
            <div>Stats</div>
          </Menu.Item>
        </Menu> */}
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  dbAuth: state.auth,
  configuration: state.configuration,
  translation: state.translation,
});

const mapDispatchToProps = {
  logout,
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(UserDashboardNavBar);
