import React, { Fragment } from "react";
import { Modal, Row, Col } from "antd";

const SingleTestPassedModal = ({ isVisible, onCancel }) => {
  return (
    <Fragment>
      <Modal
        visible={isVisible}
        onCancel={onCancel}
        closable={false}
        footer={null}
        width={400}
      >
        <Row style={{ margin: "0 36px 24px 36px", textAlign: "center" }}>
          <Col span={24}>
            <div style={{ fontFamily: "Poppins-Bold", fontSize: "25px" }}>
              Congratulation! You passed Test 1 with a score of 87%
            </div>
            <br />
            <img src="/assets/fun-icon.svg" alt="" style={{ width: "90%" }} />
          </Col>
        </Row>

        <div
          style={{
            fontFamily: "Poppins-Bold",
            fontSize: "25px",
            color: "#4252BE",
            padding: "8px",
            borderTop: "1px solid rgba(112,112,112,0.4)",
            marginTop: "24px",
            width: "100%",
            textAlign: "center"
          }}
        >
          Continue
        </div>
      </Modal>
    </Fragment>
  );
};

export default SingleTestPassedModal;
