import { createReducer } from "../app/common/utils/reducerUtils";
import { LOAD_ACHIEVEMENT_DATA } from "./achievementConstant";


const initialState = {
    videoPath: null,
    currentWeekAchievement: null
}

const loadAchievementData = (state, payload) => {
    return {
        ...state,
        // achievementObj: payload.achievementObject
        videoPath: payload.videoPath,
        currentWeekAchievement: payload.currentWeekAchievement 
    }
}


export default createReducer(initialState, {
    [LOAD_ACHIEVEMENT_DATA]: loadAchievementData

});