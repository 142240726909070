import { Breadcrumb, Col, Input, Row, Table, notification } from "antd";
import React, { Component, Fragment } from "react";
import { loadAllECodes, deleteECodeUser } from "./ecodeActions";
import {
  LoadingOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import LoadingComponent from "../app/common/loading/LoadingComponent";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import EditEcodeUserDetails from "./EditEcodeUserDetails";

class ECodeDetailsPage extends Component {
  state = {
    isEditEcodeUserDetailsVisible: false,
    isLoading: true,
    reset: false,
    clickedRecord: {},
    filter: "",
  };

  async componentDidMount() {
    const { match, configuration } = this.props;
    let eCodeBundleId = match.params.eCodeBundleId;
    this.props.loadAllECodes(configuration.idsInfoObj);
    this.setState({
      eCodeBundleId,
    });
  }

  async componentDidUpdate(prevProps) {
    const { ecodesRecords, configuration } = this.props;
    const { eCodeBundleId } = this.state;
    if (
      !_.isEqual(
        prevProps.ecodesRecords.ecodeGenerationInfoObj,
        ecodesRecords.ecodeGenerationInfoObj
      ) ||
      (ecodesRecords.ecodeGenerationInfoObj !== null && this.state.isLoading)
    ) {
      let loadedECodeDetailsData = [];
      // console.log(ecodesRecords.ecodeGenerationInfoObj)
      if (ecodesRecords.ecodeGenerationInfoObj) {
        let projectName;
        ecodesRecords.ecodeGenerationInfoObj.forEach((ecodesRecord) => {
          if (ecodesRecord.docId === eCodeBundleId) {
            projectName = ecodesRecord.projectName;
            ecodesRecord.ecodeList.forEach((ecode, idx) => {
              let duration = ecode.durationInDays;
              if (ecode.activationDate) {
                var activationDate = new Date(
                  ecode.activationDate.seconds * 1000
                ).getTime();
                var validDate = new Date(
                  activationDate + ecode.durationInDays * 24 * 60 * 60 * 1000
                ).getTime();
                if (new Date().getTime() < validDate) {
                  var Difference_In_Time = validDate - new Date().getTime();
                  let times = 1000 * 3600 * 24;
                  duration = Difference_In_Time / times;
                } else {
                  duration = 0;
                }
                console.log(duration);
              }
              loadedECodeDetailsData.push({
                key: idx,
                eCode: ecode.code,
                userEmail: ecode.user.email === "" ? "-" : ecode.user.email,
                userId: ecode.user.id,
                durationLeft: parseFloat(duration).toFixed(0),
                status: ecode.status,
                projectCode: ecode.projectCode,
                docId: ecode.docId,
                createdAt: ecode.createdAt,
              });
            });
          }
        });
        // console.log(loadedECodeData);
        this.setState({
          isLoading: false,
          loadedECodeDetailsData,
          projectName,
        });
      }
    }
    if (
      !ecodesRecords.loading &&
      ecodesRecords.ecodeUserDeleteSuccess &&
      this.state.reset
    ) {
      notification.open({
        key,
        message: "Success",
        description: "Successfully deleted user",
        icon: (
          <CheckCircleTwoTone
            twoToneColor="
        #52c41a"
          />
        ),
      });
      this.props.loadAllECodes(configuration.idsInfoObj);
      this.setState({
        isEditEcodeUserDetailsVisible: false,
        reset: false,
        isLoading: true,
      });
    } else if (
      !ecodesRecords.loading &&
      ecodesRecords.ecodeUserDeleteFailure &&
      this.state.reset
    ) {
      notification.open({
        key,
        message: "Failed",
        description: ecodesRecords.errMsg,
        duration: 3,
        icon: (
          <CloseCircleTwoTone
            twoToneColor="
        #ff5255"
          />
        ),
      });
      this.setState({
        reset: false,
      });
    }
  }

  onHandleECodeDetailsPencilIconClick = (record) => {
    // console.log(record);
    this.setState({
      isEditEcodeUserDetailsVisible: true,
      clickedRecord: record,
    });
  };

  onHandleEditEcodeUserDetailsCancelButtonClick = () => {
    this.setState({ isEditEcodeUserDetailsVisible: false });
  };

  onHandleEditEcodeUserDetailsDeleteButtonClick = () => {
    const { clickedRecord } = this.state;

    notification.open({
      key,
      message: "Deleting User",
      duration: 0,
      icon: <LoadingOutlined />,
    });
    this.props.deleteECodeUser(clickedRecord);
    this.setState({
      reset: true,
    });
  };

  onInputChange = (e, inputField) => {
    let inputVal = e.target.value;
    this.setState({
      [inputField]: inputVal,
    });
  };

  handleSearchChange = (event) => {
    this.setState({ filter: event.target.value });
  };

  render() {
    const {
      isLoading,
      loadedECodeDetailsData,
      projectName,
      isEditEcodeUserDetailsVisible,
      clickedRecord,
      email,
      filter,
    } = this.state;
    if (isLoading) return <LoadingComponent />;

    // console.log(loadedECodeDetailsData)
    const lowercasedFilter = filter.toLowerCase();
    const filteredData = loadedECodeDetailsData.filter((item) => {
      return Object.keys(item).some(
        (key) =>
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(lowercasedFilter)
      );
    });

    const eCodeDetailsColumns = [
      {
        title: "E-Code",
        dataIndex: "eCode",
        key: "eCode",
      },
      {
        title: "Duration Left(Days)",
        dataIndex: "durationLeft",
        key: "durationLeft",
      },
      {
        title: "Assigned User",
        dataIndex: "userEmail",
        key: "userEmail",
      },
      {
        title: "",
        dataIndex: "editAction",
        key: "editAction",
        render: (_, record) => (
          <Fragment>
            {record.durationLeft > 0 && record.status === "Used" && (
              <div style={{ textAlign: "end", marginRight: "2rem" }}>
                <img
                  src="/assets/pencil-icon.svg"
                  alt=""
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    this.onHandleECodeDetailsPencilIconClick(record)
                  }
                />
              </div>
            )}
          </Fragment>
        ),
      },
    ];
    return (
      <Fragment>
        <EditEcodeUserDetails
          isVisible={isEditEcodeUserDetailsVisible}
          onCancel={this.onHandleEditEcodeUserDetailsCancelButtonClick}
          onDelete={this.onHandleEditEcodeUserDetailsDeleteButtonClick}
          clickedRecord={clickedRecord}
          onInputChange={this.onInputChange}
          email={email}
          // onHandleProjectSelectionMenuClick={
          //   this.onHandleProjectSelectionMenuClick
          // }
          // newEcodesInfoObj={newEcodesInfoObj}
        />
        <Row align="bottom" justify="space-between" style={{ padding: "20px" }}>
          <Col
            style={{
              fontFamily: "Poppins-Bold",
              fontSize: "1.8rem",
            }}
          >
            E-Codes - {projectName}
          </Col>
        </Row>
        <Row align="middle" style={{ padding: "20px" }}>
          <Col flex="auto">
            <Input
              placeholder="Search"
              onChange={this.handleSearchChange}
              prefix={
                <img
                  src="/assets/search-icon.svg"
                  alt=""
                  style={{
                    width: "1rem",
                    height: "1rem",
                    marginRight: "0.5rem",
                  }}
                />
              }
              size="large"
              style={{ borderRadius: "24px", width: "20rem" }}
            />
          </Col>
          <Col span={24}>
            <Table
              dataSource={filteredData}
              columns={eCodeDetailsColumns}
              pagination={{
                showSizeChanger: false,
                pageSize: 10, //TODO
                position: ["bottomCenter"],
              }}
              footer={() => <span>Total E-codes: {filteredData.length} </span>}
              style={{ marginTop: "2rem" }}
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}
const key = "changeEcodeNotification";

const mapStateToProps = (state) => ({
  ecodesRecords: state.ecodesRecords,
  configuration: state.configuration,
});
const mapDispatchToProps = {
  loadAllECodes,
  deleteECodeUser,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ECodeDetailsPage);
