import { createReducer } from "../app/common/utils/reducerUtils";
import { LOAD_STATISTICS_DATA, RESET_STATISTICS_DATA } from "./statisticsConstant";


const initialState = {
    statisticsObj: null
}

const loadStatisticsData = (state, payload) => {
    return {
        ...state,
        statisticsObj: payload.statisticsObj
    }
}

const resetStatisticsData = (state) => {
    return {
        ...state,
        statisticsObj: null
    }
}

export default createReducer(initialState, {
    [LOAD_STATISTICS_DATA]: loadStatisticsData,
    [RESET_STATISTICS_DATA]: resetStatisticsData

});