import React, { Fragment } from "react";
import { Modal, Row, Col } from "antd";

const ResetProgressModal = ({ isVisible, onCancel , onYes}) => {
  return (
    <Fragment>
      <Modal
        visible={isVisible}
        onCancel={onCancel}
        closeIcon={<img src="/assets/blue-close-icon.svg" alt="" />}
        footer={null}
        width={400}
      >
        <Row style={{ margin: "24px" }}>
          <Col span={24} style={{ textAlign: "center" }}>
            <div style={{ fontFamily: "Poppins-Bold", fontSize: "23px" }}>
              Are you sure you want to reset your progress?
            </div>
            <br />
            <div style={{ fontFamily: "Poppins-Regular", fontSize: "23px" }}>
              Note that you will lose your achievements, exam certificates and
              all saved progress.
            </div>
          </Col>
        </Row>

        <Row
          style={{
            borderTop: "1px solid rgba(112,112,112,0.4)",
            textAlign: "center"
          }}
        >
          <Col
            span={12}
            style={{
              fontFamily: "Poppins-Bold",
              fontSize: "23px",
              color: "#4252BE",
              padding: "8px",
              borderRight: "1px solid rgba(112,112,112,0.4)",
              cursor: "pointer"
            }}
            onClick={onYes}
          >
            Yes
          </Col>
          <Col
            span={12}
            style={{
              fontFamily: "Poppins-Bold",
              fontSize: "23px",
              color: "#4252BE",
              padding: "8px",
              cursor: "pointer"
            }}
            onClick={onCancel}
          >
            No
          </Col>
        </Row>
      </Modal>
    </Fragment>
  );
};

export default ResetProgressModal;
