import React, { Component } from "react";
import "./PrivacyPolicy.css";
import { Row, Col, Typography } from "antd";
const { Text } = Typography;

export default class TermsAndCondition extends Component {
  render() {
    window.scrollTo(0, 0);
    return (
      <Row
        type="flex"
        align="middle"
        style={{
          backgroundColor: "#F2F2F2",
          padding: "40px 0px 40px 0px"
        }}
      >
        <Col xs={1} sm={1} md={2} lg={4} xl={4}></Col>
        <Col
          xs={22}
          sm={22}
          md={20}
          lg={16}
          xl={16}
          style={{ backgroundColor: "White" }}
        >
          <Row type="flex" align="middle">
            <Col style={{ padding: "30px" }}>
              <div
                className="terms-head"
                style={{ fontSize: "35px", marginBottom: "20px" }}
              >
                Terms and Conditions
              </div>
              <div
                style={{
                  fontSize: "18px",
                  marginBottom: "20px",
                  color: "#3C3C3C"
                }}
              >
                Last updated: February 02, 2021 <br /> <br />
                Please read these terms and conditions carefully before using
                Our Service.
                <br />
                <br />
                Interpretation and Definitions
                <br />
                <br />
              </div>
              <div
                className="terms-head"
                style={{
                  fontSize: "25px",
                  marginBottom: "20px",
                  color: "#3C3C3C"
                }}
              >
                Interpretation
              </div>
              <div
                style={{
                  fontSize: "18px",
                  marginBottom: "20px",
                  color: "#3C3C3C"
                }}
              >
                The words of which the initial letter is capitalized have
                meanings defined under the following conditions. The following
                definitions shall have the same meaning regardless of whether
                they appear in singular or in plural.
              </div>
              <div
                className="terms-head"
                style={{
                  fontSize: "25px",
                  marginBottom: "20px",
                  color: "#3C3C3C"
                }}
              >
                Definitions
              </div>
              <div
                style={{
                  fontSize: "18px",
                  marginBottom: "20px",
                  color: "#3C3C3C"
                }}
              >
                For the purposes of these Terms and Conditions:
                <br />
                <br />
                <Text
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "18px",
                    color: "#3C3C3C"
                  }}
                >
                  Application{" "}
                </Text>
                means the software program provided by the Company downloaded by
                You on any electronic device, named ESL
                <br />
                <br />
                <Text
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "18px",
                    color: "#3C3C3C"
                  }}
                >
                  Application Store{" "}
                </Text>
                means the digital distribution service operated and developed by
                Apple Inc. (Apple App Store) or Google Inc. (Google Play Store)
                in which the Application has been downloaded.
                <br />
                <br />
                <Text
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "18px",
                    color: "#3C3C3C"
                  }}
                >
                  Affiliate{" "}
                </Text>
                means an entity that controls, is controlled by or is under
                common control with a party, where "control" means ownership of
                50% or more of the shares, equity interest or other securities
                entitled to vote for election of directors or other managing
                authority.
                <br />
                <br />
                <Text
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "18px",
                    color: "#3C3C3C"
                  }}
                >
                  Account{" "}
                </Text>
                means a unique account created for You to access our Service or
                parts of our Service.
                <br />
                <br />
                <Text
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "18px",
                    color: "#3C3C3C"
                  }}
                >
                  Country{" "}
                </Text>
                refers to: Malaysia
                <br />
                <br />
                <Text
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "18px",
                    color: "#3C3C3C"
                  }}
                >
                  Company{" "}
                </Text>
                (referred to as either "the Company", "We", "Us" or "Our" in
                this Agreement) refers to InfiniJunction Sdn Bhd.
                <br />
                <br />
                <Text
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "18px",
                    color: "#3C3C3C"
                  }}
                >
                  Device{" "}
                </Text>
                means any device that can access the Service such as a computer,
                a cellphone or a digital tablet.
                <br />
                <br />
                <Text
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "18px",
                    color: "#3C3C3C"
                  }}
                >
                  Free Trial{" "}
                </Text>
                refers to a limited period of time that may be free when
                purchasing a Subscription.
                <br />
                <br />
                <Text
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "18px",
                    color: "#3C3C3C"
                  }}
                >
                  In-app Purchase{" "}
                </Text>
                refers to the purchase of a product, item, service or
                Subscription made through the Application and subject to these
                Terms and Conditions and/or the Application Store's own terms
                and conditions.
                <br />
                <br />
                <Text
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "18px",
                    color: "#3C3C3C"
                  }}
                >
                  Service{" "}
                </Text>
                refers to the Application or the Website or both.
                <br />
                <br />
                <Text
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "18px",
                    color: "#3C3C3C"
                  }}
                >
                  Subscriptions{" "}
                </Text>
                refer to the services or access to the Service offered on a
                subscription basis by the Company to You.
                <br />
                <br />
                <Text
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "18px",
                    color: "#3C3C3C"
                  }}
                >
                  Terms and Conditions{" "}
                </Text>
                (also referred as "Terms") mean these Terms and Conditions that
                form the entire agreement between You and the Company regarding
                the use of the Service.
                <br />
                <br />
                <Text
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "18px",
                    color: "#3C3C3C"
                  }}
                >
                  Third-party Social Media Service{" "}
                </Text>
                means any services or content (including data, information,
                products or services) provided by a third-party that may be
                displayed, included or made available by the Service.
                <br />
                <br />
                <Text
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "18px",
                    color: "#3C3C3C"
                  }}
                >
                  Website{" "}
                </Text>
                refers to ESL, accessible from{" "}
                <a href="https://esl.my/">https://esl.my/</a>
                <br />
                <br />
                <Text
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "18px",
                    color: "#3C3C3C"
                  }}
                >
                  You{" "}
                </Text>
                means the individual accessing or using the Service, or the
                company, or other legal entity on behalf of which such
                individual is accessing or using the Service, as applicable.
              </div>

              <div
                className="terms-head"
                style={{ fontSize: "35px", marginBottom: "20px" }}
              >
                Acknowledgment
              </div>
              <div
                style={{
                  fontSize: "18px",
                  marginBottom: "20px",
                  color: "#3C3C3C"
                }}
              >
                These are the Terms and Conditions governing the use of this
                Service and the agreement that operates between You and the
                Company. These Terms and Conditions set out the rights and
                obligations of all users regarding the use of the Service.{" "}
                <br />
                <br />
                Your access to and use of the Service is conditioned on Your
                acceptance of and compliance with these Terms and Conditions.
                These Terms and Conditions apply to all visitors, users and
                others who access or use the Service.
                <br />
                <br />
                By accessing or using the Service You agree to be bound by these
                Terms and Conditions. If You disagree with any part of these
                Terms and Conditions then You may not access the Service.
                <br />
                <br />
                You represent that you are over the age of 18. The Company does
                not permit those under 18 to use the Service without the
                authorization from You.
                <br />
                <br />
                Your access to and use of the Service is also conditioned on
                Your acceptance of and compliance with the Privacy Policy of the
                Company. Our Privacy Policy describes Our policies and
                procedures on the collection, use and disclosure of Your
                personal information when You use the Application or the Website
                and tells You about Your privacy rights and how the law protects
                You. Please read Our Privacy Policy carefully before using Our
                Service.
                <br />
              </div>
              <div
                className="terms-head"
                style={{ fontSize: "35px", marginBottom: "20px" }}
              >
                Subscriptions
              </div>

              <div
                className="terms-head"
                style={{
                  fontSize: "25px",
                  marginBottom: "20px",
                  color: "#3C3C3C"
                }}
              >
                Subscription period
              </div>
              <div
                style={{
                  fontSize: "18px",
                  marginBottom: "20px",
                  color: "#3C3C3C"
                }}
              >
                The Service or some parts of the Service are available only with
                a paid Subscription. You will be billed in advance on a
                recurring and periodic basis (such as daily, weekly, monthly or
                annually), depending on the type of Subscription plan you select
                when purchasing the Subscription.
                <br />
                <br />
                At the end of each period, Your Subscription will automatically
                renew under the exact same conditions unless You cancel it or
                the Company cancels it.
              </div>

              <div
                className="terms-head"
                style={{
                  fontSize: "25px",
                  marginBottom: "20px",
                  color: "#3C3C3C"
                }}
              >
                Subscription cancellations
              </div>
              <div
                style={{
                  fontSize: "18px",
                  marginBottom: "20px",
                  color: "#3C3C3C"
                }}
              >
                You may cancel Your Subscription renewal either through Your
                Account settings page or by contacting the Company. You will not
                receive a refund for the fees You already paid for Your current
                Subscription period and You will be able to access the Service
                until the end of Your current Subscription period.
                <br />
                <br />
                If the Subscription has been made through an In-app Purchase,
                You can cancel the renewal of Your Subscription with the
                Application Store.
              </div>

              <div
                className="terms-head"
                style={{
                  fontSize: "25px",
                  marginBottom: "20px",
                  color: "#3C3C3C"
                }}
              >
                Billing
              </div>
              <div
                style={{
                  fontSize: "18px",
                  marginBottom: "20px",
                  color: "#3C3C3C"
                }}
              >
                You shall provide the Company with accurate and complete billing
                information including full name, address, state, zip code,
                telephone number, and a valid payment method information.
                <br />
                <br />
                Should automatic billing fail to occur for any reason, the
                Company will issue an electronic invoice indicating that you
                must proceed manually, within a certain deadline date, with the
                full payment corresponding to the billing period as indicated on
                the invoice.
                <br />
                <br />
                If the Subscription has been made through an In-app Purchase,
                all billing is handled by the Application Store and is governed
                by the Application Store's own terms and conditions.
              </div>

              <div
                className="terms-head"
                style={{
                  fontSize: "25px",
                  marginBottom: "20px",
                  color: "#3C3C3C"
                }}
              >
                Fee Changes
              </div>
              <div
                style={{
                  fontSize: "18px",
                  marginBottom: "20px",
                  color: "#3C3C3C"
                }}
              >
                The Company, in its sole discretion and at any time, may modify
                the Subscription fees. Any Subscription fee change will become
                effective at the end of the then-current Subscription period.
                <br />
                <br />
                The Company will provide You with reasonable prior notice of any
                change in Subscription fees to give You an opportunity to
                terminate Your Subscription before such change becomes
                effective.
                <br />
                <br />
                Your continued use of the Service after the Subscription fee
                change comes into effect constitutes Your agreement to pay the
                modified Subscription fee amount.
              </div>

              <div
                className="terms-head"
                style={{
                  fontSize: "25px",
                  marginBottom: "20px",
                  color: "#3C3C3C"
                }}
              >
                Refunds
              </div>
              <div
                style={{
                  fontSize: "18px",
                  marginBottom: "20px",
                  color: "#3C3C3C"
                }}
              >
                Except when required by law, paid Subscription fees are
                non-refundable.
                <br />
                <br />
                Certain refund requests for Subscriptions may be considered by
                the Company on a case-by-case basis and granted at the sole
                discretion of the Company.
                <br />
                <br />
                If the Subscription has been made through an In-app purchase,
                the Application Store's refund policy will apply. If You wish to
                request a refund, You may do so by contacting the Application
                Store directly.
              </div>

              <div
                className="terms-head"
                style={{
                  fontSize: "25px",
                  marginBottom: "20px",
                  color: "#3C3C3C"
                }}
              >
                Free Trial
              </div>
              <div
                style={{
                  fontSize: "18px",
                  marginBottom: "20px",
                  color: "#3C3C3C"
                }}
              >
                The Company may, at its sole discretion, offer a Subscription
                with a Free Trial for a limited period of time.
                <br />
                <br />
                You may be required to enter Your billing information in order
                to sign up for the Free Trial.
                <br />
                <br />
                If You do enter Your billing information when signing up for a
                Free Trial, You will not be charged by the Company until the
                Free Trial has expired. On the last day of the Free Trial
                period, unless You cancelled Your Subscription, You will be
                automatically charged the applicable Subscription fees for the
                type of Subscription You have selected.
                <br />
                <br />
                At any time and without notice, the Company reserves the right
                to (i) modify the terms and conditions of the Free Trial offer,
                or (ii) cancel such Free Trial offer.
                <br />
                <br />
                In-app Purchases
                <br />
                <br />
                The Application may include In-app Purchases that allow you to
                buy products, services or Subscriptions.
                <br />
                <br />
                More information about how you may be able to manage In-app
                Purchases using your Device may be set out in the Application
                Store's own terms and conditions or in your Device's Help
                settings.
                <br />
                <br />
                In-app Purchases can only be consumed within the Application. If
                you make a In-app Purchase, that In-app Purchase cannot be
                cancelled after you have initiated its download. In-app
                Purchases cannot be redeemed for cash or other consideration or
                otherwise transferred.
                <br />
                <br />
                If any In-app Purchase is not successfully downloaded or does
                not work once it has been successfully downloaded, we will,
                after becoming aware of the fault or being notified to the fault
                by You, investigate the reason for the fault. We will act
                reasonably in deciding whether to provide You with a replacement
                In-app Purchase or issue You with a patch to repair the fault.
                In no event will We charge You to replace or repair the In-app
                Purchase. In the unlikely event that we are unable to replace or
                repair the relevant In-app Purchase or are unable to do so
                within a reasonable period of time and without significant
                inconvenience to You, We will authorize the Application Store to
                refund You an amount up to the cost of the relevant In-app
                Purchase. Alternatively, if You wish to request a refund, You
                may do so by contacting the Application Store directly.
                <br />
                <br />
                You acknowledge and agree that all billing and transaction
                processes are handled by the Application Store from where you
                downloaded the Application and are governed by that Application
                Store's own terms and conditions.
                <br />
                <br />
                If you have any payment related issues with In-app Purchases,
                then you need to contact the Application Store directly.
                <br />
                <br />
                User Accounts
                <br />
                <br />
                When You create an account with Us, You must provide Us
                information that is accurate, complete, and current at all
                times. Failure to do so constitutes a breach of the Terms, which
                may result in immediate termination of Your account on Our
                Service.
                <br />
                <br />
                You are responsible for safeguarding the password that You use
                to access the Service and for any activities or actions under
                Your password, whether Your password is with Our Service or a
                Third-Party Social Media Service.
                <br />
                <br />
                You agree not to disclose Your password to any third party. You
                must notify Us immediately upon becoming aware of any breach of
                security or unauthorized use of Your account.
                <br />
                <br />
                You may not use as a username the name of another person or
                entity or that is not lawfully available for use, a name or
                trademark that is subject to any rights of another person or
                entity other than You without appropriate authorization, or a
                name that is otherwise offensive, vulgar or obscene.
                <br />
                <br />
                Intellectual Property
                <br />
                <br />
                The Service and its original content (excluding Content provided
                by You or other users), features and functionality are and will
                remain the exclusive property of the Company and its licensors.
                <br />
                <br />
                The Service is protected by copyright, trademark, and other laws
                of both the Country and foreign countries.
                <br />
                <br />
                Our trademarks and trade dress may not be used in connection
                with any product or service without the prior written consent of
                the Company.
                <br />
                <br />
                Links to Other Websites
                <br />
                <br />
                Our Service may contain links to third-party web sites or
                services that are not owned or controlled by the Company.
                <br />
                <br />
                The Company has no control over, and assumes no responsibility
                for, the content, privacy policies, or practices of any third
                party web sites or services. You further acknowledge and agree
                that the Company shall not be responsible or liable, directly or
                indirectly, for any damage or loss caused or alleged to be
                caused by or in connection with the use of or reliance on any
                such content, goods or services available on or through any such
                web sites or services.
                <br />
                <br />
                We strongly advise You to read the terms and conditions and
                privacy policies of any third-party web sites or services that
                You visit.
                <br />
                <br />
                Termination
                <br />
                <br />
                We may terminate or suspend Your Account immediately, without
                prior notice or liability, for any reason whatsoever, including
                without limitation if You breach these Terms and Conditions.
                <br />
                <br />
                Upon termination, Your right to use the Service will cease
                immediately. If You wish to terminate Your Account, You may
                simply discontinue using the Service.
                <br />
                <br />
                Limitation of Liability
                <br />
                <br />
                Notwithstanding any damages that You might incur, the entire
                liability of the Company and any of its suppliers under any
                provision of this Terms and Your exclusive remedy for all of the
                foregoing shall be limited to the amount actually paid by You
                through the Service or 100 USD if You haven't purchased anything
                through the Service.
                <br />
                <br />
                To the maximum extent permitted by applicable law, in no event
                shall the Company or its suppliers be liable for any special,
                incidental, indirect, or consequential damages whatsoever
                (including, but not limited to, damages for loss of profits,
                loss of data or other information, for business interruption,
                for personal injury, loss of privacy arising out of or in any
                way related to the use of or inability to use the Service,
                third-party software and/or third-party hardware used with the
                Service, or otherwise in connection with any provision of this
                Terms), even if the Company or any supplier has been advised of
                the possibility of such damages and even if the remedy fails of
                its essential purpose.
                <br />
                <br />
                Some states do not allow the exclusion of implied warranties or
                limitation of liability for incidental or consequential damages,
                which means that some of the above limitations may not apply. In
                these states, each party's liability will be limited to the
                greatest extent permitted by law.
                <br />
                <br />
                "AS IS" and "AS AVAILABLE" Disclaimer
                <br />
                <br />
                The Service is provided to You "AS IS" and "AS AVAILABLE" and
                with all faults and defects without warranty of any kind. To the
                maximum extent permitted under applicable law, the Company, on
                its own behalf and on behalf of its Affiliates and its and their
                respective licensors and service providers, expressly disclaims
                all warranties, whether express, implied, statutory or
                otherwise, with respect to the Service, including all implied
                warranties of merchantability, fitness for a particular purpose,
                title and non-infringement, and warranties that may arise out of
                course of dealing, course of performance, usage or trade
                practice. Without limitation to the foregoing, the Company
                provides no warranty or undertaking, and makes no representation
                of any kind that the Service will meet Your requirements,
                achieve any intended results, be compatible or work with any
                other software, applications, systems or services, operate
                without interruption, meet any performance or reliability
                standards or be error free or that any errors or defects can or
                will be corrected.
                <br />
                <br />
                Without limiting the foregoing, neither the Company nor any of
                the company's provider makes any representation or warranty of
                any kind, express or implied: (i) as to the operation or
                availability of the Service, or the information, content, and
                materials or products included thereon; (ii) that the Service
                will be uninterrupted or error-free; (iii) as to the accuracy,
                reliability, or currency of any information or content provided
                through the Service; or (iv) that the Service, its servers, the
                content, or e-mails sent from or on behalf of the Company are
                free of viruses, scripts, trojan horses, worms, malware,
                timebombs or other harmful components.
                <br />
                <br />
                Some jurisdictions do not allow the exclusion of certain types
                of warranties or limitations on applicable statutory rights of a
                consumer, so some or all of the above exclusions and limitations
                may not apply to You. But in such a case the exclusions and
                limitations set forth in this section shall be applied to the
                greatest extent enforceable under applicable law.
                <br />
                <br />
                Governing Law
                <br />
                <br />
                The laws of the Country, excluding its conflicts of law rules,
                shall govern this Terms and Your use of the Service. Your use of
                the Application may also be subject to other local, state,
                national, or international laws.
                <br />
                <br />
                Disputes Resolution
                <br />
                <br />
                If You have any concern or dispute about the Service, You agree
                to first try to resolve the dispute informally by contacting the
                Company.
                <br />
                <br />
                For European Union (EU) Users
                <br />
                <br />
                If You are a European Union consumer, you will benefit from any
                mandatory provisions of the law of the country in which you are
                resident in.
                <br />
                <br />
                United States Federal Government End Use Provisions
                <br />
                <br />
                If You are a U.S. federal government end user, our Service is a
                "Commercial Item" as that term is defined at 48 C.F.R. §2.101.
                <br />
                <br />
                United States Legal Compliance
                <br />
                <br />
                You represent and warrant that (i) You are not located in a
                country that is subject to the United States government embargo,
                or that has been designated by the United States government as a
                "terrorist supporting" country, and (ii) You are not listed on
                any United States government list of prohibited or restricted
                parties.
                <br />
                <br />
                Severability and Waiver
              </div>

              <div
                className="terms-head"
                style={{
                  fontSize: "25px",
                  marginBottom: "20px",
                  color: "#3C3C3C"
                }}
              >
                Severability
              </div>
              <div
                style={{
                  fontSize: "18px",
                  marginBottom: "20px",
                  color: "#3C3C3C"
                }}
              >
                If any provision of these Terms is held to be unenforceable or
                invalid, such provision will be changed and interpreted to
                accomplish the objectives of such provision to the greatest
                extent possible under applicable law and the remaining
                provisions will continue in full force and effect.
              </div>

              <div
                className="terms-head"
                style={{
                  fontSize: "25px",
                  marginBottom: "20px",
                  color: "#3C3C3C"
                }}
              >
                Waiver
              </div>
              <div
                style={{
                  fontSize: "18px",
                  marginBottom: "20px",
                  color: "#3C3C3C"
                }}
              >
                Except as provided herein, the failure to exercise a right or to
                require performance of an obligation under this Terms shall not
                effect a party's ability to exercise such right or require such
                performance at any time thereafter nor shall be the waiver of a
                breach constitute a waiver of any subsequent breach.
                <br />
                <br />
                Translation Interpretation
                <br />
                <br />
                These Terms and Conditions may have been translated if We have
                made them available to You on our Service. You agree that the
                original English text shall prevail in the case of a dispute.
                <br />
                <br />
                Changes to These Terms and Conditions
                <br />
                <br />
                We reserve the right, at Our sole discretion, to modify or
                replace these Terms at any time. If a revision is material We
                will make reasonable efforts to provide at least 30 days' notice
                prior to any new terms taking effect. What constitutes a
                material change will be determined at Our sole discretion.
                <br />
                <br />
                By continuing to access or use Our Service after those revisions
                become effective, You agree to be bound by the revised terms. If
                You do not agree to the new terms, in whole or in part, please
                stop using the website and the Service.
                <br />
                <br />
                Contact Us
                <br />
                <br />
                If you have any questions about these Terms and Conditions, You
                can contact us: <br />
                <br />
                By email: info@infiniJunction.com
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={1} sm={1} md={2} lg={2} xl={2}></Col>
      </Row>
    );
  }
}
