import { Document, Page, pdfjs } from "react-pdf";
import React, { Component, Fragment } from "react";
import "./ExamLevelDetailsPage/ExamLevelDetailsPage.css";
import UserDashboardNavBar from "../../UserDashboard/UserDashboardNavBar/UserDashboardNavBar";
import UserDashboardNavBottomBar from "../../UserDashboard/UserDashboardNavBar/UserDashboardNavBottomBar";
import { Row, Col, Breadcrumb, Button } from "antd";
import BottomBar from "../../BottomBar/BottomBar";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  loadTargetedExamGroups,
  resetTargetedExamGroups,
} from "../../course/courseActions";
import { withRouter } from "react-router";
import _ from "lodash";
import LoadingComponent from "../../app/common/loading/LoadingComponent";
import { getNativeLangTransText } from "../../SharedUtils";
import { Link } from "react-router-dom";
import { withFirebase } from "react-redux-firebase";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class ExamCert extends Component {
  state = {
    isLoading: true,
  };

  // componentWillUnmount() {
  //   this.props.resetTargetedExamGroups();
  // }

  componentDidMount() {
    const { match } = this.props;
    let examId = match.params.examId;
    window.scrollTo(0, 0);
    this.setState(
      {
        examId,
      },
      () => this.props.loadTargetedExamGroups(examId)
    );
  }

  async componentDidUpdate(prevProps) {
    const { examId } = this.state;
    const { course, dbAuth, firebase } = this.props;
    const storageRef = firebase.storage().ref();
    if (
      !_.isEqual(prevProps.course.examGroupInfoObj, course.examGroupInfoObj) ||
      (course.examGroupInfoObj !== null && this.state.isLoading)
    ) {
      if (
        dbAuth &&
        dbAuth.progressInfo &&
        dbAuth.progressInfo.exams &&
        dbAuth.progressInfo.exams[examId] &&
        dbAuth.progressInfo.exams[examId].certificatePath !== undefined
      ) {
        let url = await storageRef
          .child(dbAuth.progressInfo.exams[examId].certificatePath)
          .getDownloadURL();
        //console.log(url);

        this.setState({
          isLoading: false,
          pdfUrl: url,
        });
      } else {
        var generateExamCert = firebase
          .app()
          .functions("asia-east2")
          .httpsCallable("generateExamCert");
        generateExamCert({
          userId: dbAuth.userInfo.docId,
          levelName: examId,
          name: dbAuth.userInfo.name,
        })
          .then(async (result) => {
            console.log(result.data);
            let url = await storageRef
              .child(result.data.fileName)
              .getDownloadURL();
            this.setState({
              isLoading: false,
              pdfUrl: url,
            });
          })
          .catch((error) => {
            // Getting the E rror details.
            console.log(error);
            // ...
          });
      }
    }
  }

  print = async (pdfUrl) => {
    window.open(pdfUrl).print();
  };

  back = () => {
    const { examId } = this.state;
    this.props.history.push(`/user-dashboard/exam/${examId}`);
  };

  render() {
    const { isLoading, examId, pdfUrl } = this.state;
    const {
      course,
      dbAuth,
      onHandleResetLabelClick,
      translation,
      configuration,
    } = this.props;
    const styles = StyleSheet.create({
      page: { backgroundColor: "tomato" },
      section: { textAlign: "center", margin: 30 },
    });
    if (isLoading) return <LoadingComponent />;
    return (
      <Fragment>
        <UserDashboardNavBar
          onHandleResetLabelClick={onHandleResetLabelClick}
        />
        <Row>
          <Col className="page-container" span={24}>
            <Row style={{ marginBottom: "16px" }}>
              <Col xs={2} sm={4} md={2} lg={4} xl={5} xxl={6} />
              <Col
                xs={20}
                sm={16}
                md={20}
                lg={16}
                xl={14}
                xxl={12}
                className="content-wrap"
              >
                <Breadcrumb
                  style={{ fontFamily: "Poppins-Bold", fontSize: "22px" }}
                >
                  <Breadcrumb.Item onClick={this.onExamsClick}>
                    Exams
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    {course.examGroupInfoObj.examInfoObj.name}
                  </Breadcrumb.Item>
                </Breadcrumb>
                <Row>
                  <Col xs={0} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <Row>
                      <Col span={24}>
                        <Link
                          style={{
                            fontFamily: "Poppins-Bold",
                            fontSize: "22px",
                            color: "#4252BE",
                            cursor: "pointer",
                          }}
                          to={"/user-dashboard/exam"}
                        >
                          {"< Back"}
                        </Link>
                        <br />

                        <div className="exam-title">
                          Congratulations! You have passed the Exam!
                        </div>
                        <br />
                        <div className="exam-sub-title">
                          Here is your certificate
                        </div>
                        <br />
                        <Document>
                          <Page size="A4" style={styles.page}>
                            
                          </Page>
                        </Document>
                        {/* <Document file={pdfUrl}>
                          <Page pageNumber={1} />
                        </Document> */}
                        <br />
                        <Button
                          className="exam-continue-button"
                          type="primary"
                          onClick={() => this.print(pdfUrl)}
                          block
                        >
                          PRINT
                        </Button>
                        <br />
                        <br />

                        <Button
                          className="exam-continue-button"
                          onClick={this.back}
                          type="primary"
                          block
                        >
                          DONE
                        </Button>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={24} sm={0} md={0} lg={0} xl={0} xxl={0}>
                    <Row>
                      <Col span={24}>
                        <Link
                          style={{
                            fontFamily: "Poppins-Bold",
                            fontSize: "20px",
                            color: "#4252BE",
                            cursor: "pointer",
                          }}
                          to={"/user-dashboard/exam"}
                        >
                          {"< " +
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.back,
                              translation.translationObj
                            ).text}
                        </Link>
                        <br />
                        <div className="exam-title">
                          Congratulations! You have passed the Exam!
                        </div>
                        <br />
                        <div className="exam-sub-title">
                          Here is your certificate
                        </div>
                        <br />

                        {/* {console.log(pdfUrl)} */}
                        <Document file={pdfUrl}>
                          <Page pageNumber={1} />
                        </Document>
                        <Button
                          className="exam-continue-button"
                          onClick={() => this.print(pdfUrl)}
                          type="primary"
                          block
                        >
                          PRINT
                        </Button>
                        <br />
                        <br />

                        <Button
                          className="exam-continue-button"
                          onClick={this.back}
                          type="primary"
                          block
                        >
                          DONE
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col xs={3} sm={5} md={0} lg={0} xl={0} xxl={0} />
            </Row>
            <Row>
              <Col
                xs={0}
                sm={0}
                md={24}
                lg={24}
                xl={24}
                className="footer-bottom"
              >
                <BottomBar />
              </Col>
              <Col
                xs={0}
                sm={24}
                md={0}
                lg={0}
                xl={0}
                style={{ marginBottom: "100px" }}
              >
                <BottomBar />
              </Col>
              <Col
                xs={24}
                sm={0}
                md={0}
                lg={0}
                xl={0}
                style={{ marginBottom: "70px" }}
              >
                <BottomBar />
              </Col>
            </Row>
            <Row>
              <Col
                xs={24}
                sm={24}
                md={0}
                lg={0}
                xl={0}
                style={{
                  textAlign: "center",
                  borderTop: "4px solid #F1F1F1",
                }}
                className="footer"
              >
                <UserDashboardNavBottomBar
                  onHandleResetLabelClick={onHandleResetLabelClick}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  course: state.course,
  dbAuth: state.auth,
  translation: state.translation,
  configuration: state.configuration,
});

const mapDispatchToProps = {
  loadTargetedExamGroups,
  resetTargetedExamGroups,
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(ExamCert);
