import React, { Component } from "react";
import "./PrivacyPolicy.css";
import { Row, Col } from "antd";

export default class PrivacyPolicy extends Component {
  render() {
    window.scrollTo(0, 0)
    return (
      <Row
        type="flex"
        align="middle"
        style={{
          backgroundColor: "#F2F2F2",
          padding: "40px 0px 40px 0px"
        }}
      >
        <Col xs={1} sm={1} md={2} lg={4} xl={4}></Col>
        <Col xs={22} sm={22} md={20} lg={16} xl={16} style={{backgroundColor:"White"}}>
          <Row type="flex" align="middle">
            <Col style={{padding:"30px"}} >
              <div className="terms-head" style={{ fontSize:"35px" , marginBottom: "20px" }}>
              Privacy Policy
              </div>
              <div className="terms-head" style={{ fontSize:"25px" , marginBottom: "20px", color:"#3C3C3C" }}>
              Overview
              </div>
              <p>This Privacy Policy details the information collected by InfiniJunction Sdn Bhd (“InfiniJunction”, “we,” “us” or “our”) via our mobile applications and website, and our usage of this information.</p>
              <div className="terms-head" style={{ fontSize:"25px" , marginBottom: "20px", color:"#3C3C3C" }}>
              1.0. InfiniJunction does not collect personal information from children through our applications or website
              </div>
              <p>We do not require you to provide any information to download or use our mobile applications, and we do not collect any information about you or your device, excepting</p>

              <p>(i) non-personal information about how the App is used, and for how long, such as the screens viewed and actions taken within the applications, for the specific purpose of improving our applications, and</p>
              <p>(ii) email addresses of parents who register for our newsletter, accessed via a parent-only part of the application. This email address is solely used for newsletter distribution, and is not linked in any way to application usage.</p>

              <div className="terms-head" style={{ fontSize:"25px" , marginBottom: "20px", color:"#3C3C3C" }}>
              2.0. Your email address is only used for our newsletter
              </div>
              <p>If you wish to receive our newsletter, we need to collect and store your email address. Our usage of your email address is governed by this Privacy Policy, and we ensure you that</p>
              <p>(i) your email address is only being collected for the purposes of receiving our newsletter, and is not combined with any other information, except your name if (optionally) provided,</p>
              <p>(ii) you can immediately unsubscribe from our newsletter at any time, and</p>
              <p>(iii) the newsletter is distributed via a third-party platform, and for this reason, the email address needs to be shared with this third-party, for this sole purpose of newsletter distribution.</p>

              <div className="terms-head" style={{ fontSize:"25px" , marginBottom: "20px", color:"#3C3C3C" }}>
              3.0. Support requests can be made via email
              </div>
              <p>You may contact us via email in regards to any general or specific support enquiries. Any information provided in this request, including your email address, will only be used to provide support. Any support requests from individuals identifying themselves as under the age of 13 will only be used to respond to said request, and will be immediately and irretrievably deleted when the request has been resolved.</p>
              <div className="terms-head" style={{ fontSize:"22px" , marginBottom: "20px", color:"#3C3C3C" }}>
              4.0. Information sharing disclosure
              </div>
              <p>Any information received from you, such as your email address or support information, will not be shared with anyone, except for the following disclosures</p>
              <p>(i) your email address is shared with our email service provider, for the purpose of sending the newsletter, and</p>
              <p>(ii) if required, we will share your email address or support request information to protect the security or integrity of InfiniJunction applications and website, in taking precautions against liability, and to comply with judicial or law-enforcement requests.</p>
              
              <div className="terms-head" style={{ fontSize:"22px" , marginBottom: "20px", color:"#3C3C3C" }}>
              5.0. Cookie usage
              </div>
              <p>We may send a cookie to your computer to uniquely identify your browser when you visit our website, to improve our service to you. Most browsers allow you to disable or delete cookies in their settings.</p>

              <div className="terms-head" style={{ fontSize:"22px" , marginBottom: "20px", color:"#3C3C3C" }}>
              6.0. Third-party site links
              </div>
              <p>Our website and Applications may contain links to third-party websites and services for which we claim no responsibility for the privacy practices or content therein. Our Applications only contain links to third-party websites (i.e. social networking websites) within the parent-only sections of the Applications.</p>

              <div className="terms-head" style={{ fontSize:"22px" , marginBottom: "20px", color:"#3C3C3C" }}>
              7.0. Changes to this Privacy Policy
              </div>
              <p>We will only use your information in accordance with the Privacy Policy at the time the information was provided. If there is a change to the Privacy Policy and how your email address is to be used, you will be contacted prior to the change taking effect.</p>

              <div className="terms-head" style={{ fontSize:"22px" , marginBottom: "20px", color:"#3C3C3C" }}>
              8.0. Contact
              </div>
              <p>If you have any queries or concerns in regards to this Privacy Policy, pleas contact us via our website.</p>

            </Col>
          </Row>
        </Col>
        <Col xs={1} sm={1} md={2} lg={2} xl={2}></Col>
  
      </Row>
    );
  }
}
