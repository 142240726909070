import React, { Component, Fragment } from "react";
import "./ContentType3LessonPage.css";
import {
  _saveProgress,
  lessonPageInit,
  getEnText,
  getNativeLangTransText,
} from "../../../../SharedUtils";
import { operationTypeEnum } from "../../../../OperationTypeConstant";
import _ from "lodash";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withFirebase } from "react-redux-firebase";
import SelfRecording from "../../../SelfRecording/SelfRecording";
import {
  updateLessonProgress,
  updateLessonAchievementProgress,
} from "../../../../auth/authActions";
import { Spin, Button, Col, Row } from "antd";

let audioElement = new Audio();
let currentAudioIdx = 0;
let voiceoverBlobUrlList = [];
let opsPaused = false;

class ContentType3LessonPage extends Component {
  constructor(props) {
    super(props);
    this._saveProgress = _saveProgress.bind(this);
    this.setMessageContainerRefCallback = (element, idx) => {
      this[`messageContainer_${idx}_ref`] = element;
    };
  }
  state = {
    targetedLessonList: [],
    currentStepNum: 0,
    highlightedItemIdx: -1,
    isPlayingAudio: false,
    isAudioDownloadedLocally: false,
    clickDisabled: false,
    isAllLessonsCompleted: false,
    saveProgressAllowed: false,
    bottom: false,
  };

  async componentDidMount() {
    const { course, match, dbAuth, firebase } = this.props;
    const { currentStepNum } = this.state;
    const storageRef = firebase.storage().ref();

    let courseId = match.params.courseId;
    let topicId = match.params.topicId;
    let subTopicId = match.params.subTopicId;
    let groupId = match.params.groupId;

    let { first, second, third } = lessonPageInit(
      course,
      dbAuth,
      courseId,
      topicId,
      subTopicId,
      groupId
    );

    //load conversation icon(first conversation)
    let imageUrlPromises = [];

    first[currentStepNum].imagePaths.forEach((item) => {
      imageUrlPromises.push(storageRef.child(item).getDownloadURL());
    });

    let iconImageUrl = await Promise.all(imageUrlPromises);

    this.setState({
      targetedLessonList: first,
      currentStepNum: second,
      isAllLessonsCompleted: third,
      courseId,
      topicId,
      subTopicId,
      groupId,
      iconImageUrl,
    });
  }

  componentWillUnmount() {
    audioElement.removeEventListener("ended", this.playNextAudio);
  }

  async componentDidUpdate(prevProps, prevState) {
    const {
      courseId,
      topicId,
      targetedLessonList,
      currentStepNum,
      highlightedItemIdx,
      isPlayingAudio,
      subTopicId,
      groupId,
      completed,
    } = this.state;
    const { operation, dbAuth, achievement, firebase } = this.props;
    const storageRef = firebase.storage().ref();

    if (
      !_.isEqual(prevProps.operation.isProcessing, operation.isProcessing) &&
      !operation.isProcessing
    ) {
      if (
        operation.operationType === operationTypeEnum.NO_OPS ||
        (prevProps.operation.operationType ===
          operationTypeEnum.UPDATE_LESSON_PROGRESS &&
          operation.operationType === operationTypeEnum.NO_OPS)
      ) {
        if (
          dbAuth.progressInfo.achievement &&
          dbAuth.progressInfo.achievement.lesson
        ) {
          let totaltoCompelete =
            achievement.currentWeekAchievement.lesson.totalToComplete;
          let totalCompleted = Object.entries(
            dbAuth.progressInfo.achievement.lesson
          ).length;
          if (totaltoCompelete >= totalCompleted && completed) {
            this.props.history.push(
              `/user-dashboard/courses/${courseId}/topics/${topicId}/subTopics/${subTopicId}/lessons/${groupId}/lesson_achievement`
            );
            let buttonSound = new Audio("/assets/Lesson_done.mp3");
            buttonSound.play();
          } else {
            this.props.history.push(
              `/user-dashboard/courses/${courseId}/topics/${topicId}`
            );
          }
        } else {
          this.props.history.push(
            `/user-dashboard/courses/${courseId}/topics/${topicId}`
          );
        }
      }
    }

    //NOTE:handle situation if there's more than one conversation lesson
    if (
      !_.isEqual(prevState.currentStepNum, currentStepNum) &&
      currentStepNum !== 0
    ) {
      let imageUrlPromises = [];
      targetedLessonList[currentStepNum].imagePaths.forEach((item) => {
        imageUrlPromises.push(storageRef.child(item).getDownloadURL());
      });

      let iconImageUrl = await Promise.all(imageUrlPromises);

      this.setState({
        iconImageUrl,
      });
    }

    //handle scrolling effect via highlight state change
    if (highlightedItemIdx > -1 && isPlayingAudio) {
      //   this[`messageContainer_${highlightedItemIdx}_ref`].scrollIntoView({
      //     behavior: "smooth",
      //     block: "center"
      //   });//if use scrollable container
      console.log(this[`messageContainer_${highlightedItemIdx}_ref`].offsetTop);
      window.scroll({
        top: this[`messageContainer_${highlightedItemIdx}_ref`].offsetTop - 140,
        behavior: "smooth",
      });
    }
  }

  // handleScroll = (e) => {
  //   //console.log(e.target.scrollHeight, e.target.scrollTop);
  //   const bottom =
  //     e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

  //     this.setState({ bottom: bottom });

  //   //console.log(e.target.clientHeight, e.target.scrollHeight, e.target.scrollTop )
  //   // else if () {
  //   //   this.setState({ bottom: bottom });
  //   // }
  // };

  onNextButtonClick = () => {
    let selectButtonSound = new Audio("/assets/Lesson_done.mp3");
    selectButtonSound.play();
    const {
      targetedLessonList,
      currentStepNum,
      saveProgressAllowed,
      courseId,
      topicId,
    } = this.state;
    if (currentStepNum === targetedLessonList.length - 1) {
      //Note : below equivalent to currentStepNumber + 1 === targetedLessonList.length
      //save full progress here...
      if (saveProgressAllowed) {
        //handle specific for conversation type
        this._saveProgress();
      } else {
        this.props.history.push(
          `/user-dashboard/courses/${courseId}/topics/${topicId}`
        );
      }
    } else {
      window.scrollTo(0, 0);

      audioElement.currentTime = 0;
      audioElement.pause();

      this.setState((prevState) => ({
        currentStepNum: prevState.currentStepNum + 1,
        isPlayingAudio: false,
        isAudioDownloadedLocally: false,
        highlightedItemIdx: -1,
      }));
    }
  };

  onUpButtonClick = () => {
    const { saveProgressAllowed, courseId, topicId } = this.state;
    //reset audio to prevent audio resume in next re-entrance
    audioElement.currentTime = 0;
    audioElement.pause();

    if (saveProgressAllowed) {
      this._saveProgress();
    } else {
      if (topicId === "short_conversations") window.history.back();
      else
        this.props.history.push(
          `/user-dashboard/courses/${courseId}/topics/${topicId}`
        );
    }
  };

  onHandleSoundIconClick = () => {
    currentAudioIdx = 0;
    this.onAudioPlay();
  };

  onAudioPlay = () => {
    const { targetedLessonList, currentStepNum, isAudioDownloadedLocally } =
      this.state;
    const { firebase } = this.props;
    const storageRef = firebase.storage().ref();

    if (
      audioElement.currentTime === 0 ||
      audioElement.currentTime === audioElement.duration
    ) {
      if (isAudioDownloadedLocally) {
        if (opsPaused) {
          opsPaused = false;

          this.setState(
            {
              clickDisabled: true,
            },
            () => {
              this.setState(
                {
                  isPlayingAudio: true,
                  clickDisabled: false,
                },
                () => {
                  this.playNextAudio();
                }
              );
            }
          );
        } else {
          this.setState(
            {
              clickDisabled: true,
            },
            () => {
              this.setState(
                {
                  highlightedItemIdx: currentAudioIdx,
                  isPlayingAudio: true,
                  clickDisabled: false,
                },
                () => {
                  audioElement.src = voiceoverBlobUrlList[currentAudioIdx];
                  audioElement.play();
                }
              );
            }
          );
        }
      } else {
        this.setState(
          {
            clickDisabled: true,
          },
          async () => {
            let voiceoverUrlPromises = [];

            //get voice over url
            targetedLessonList[currentStepNum].contents.forEach((item) => {
              voiceoverUrlPromises.push(
                storageRef.child(item.voiceover).getDownloadURL()
              );
            });

            let voiceoverUrls = await Promise.all(voiceoverUrlPromises);

            //download blob at once
            let voiceoverDownloadPromises = [];

            voiceoverUrls.forEach((vUrl) => {
              voiceoverDownloadPromises.push(
                new Promise((resolve, reject) => {
                  let xhr = new XMLHttpRequest();
                  xhr.responseType = "blob";
                  xhr.onload = function (event) {
                    if (xhr.status === 200) {
                      resolve(xhr.response);
                    } else {
                      reject(xhr.status);
                    }
                  };
                  xhr.open("GET", vUrl);
                  xhr.send();
                })
              );
            });

            //init force reset
            // currentAudioIdx = 0;
            voiceoverBlobUrlList.length = 0;

            let downloadedBlobs = await Promise.all(voiceoverDownloadPromises);
            downloadedBlobs.forEach((blob) => {
              voiceoverBlobUrlList.push(URL.createObjectURL(blob));
            });

            audioElement.addEventListener("ended", this.playNextAudio);

            this.setState(
              {
                highlightedItemIdx: currentAudioIdx,
                isAudioDownloadedLocally: true,
                isPlayingAudio: true,
                clickDisabled: false,
              },
              () => {
                audioElement.src = voiceoverBlobUrlList[currentAudioIdx];
                audioElement.play();
              }
            );
          }
        );
      }
    } else {
      //resume playing
      console.log("line 346");
      audioElement.play();
      this.setState({
        isPlayingAudio: true,
      });
    }
  };

  playNextAudio = () => {
    setTimeout(() => {
      if (
        audioElement.paused &&
        (audioElement.currentTime === 0 ||
          audioElement.currentTime === audioElement.duration) &&
        this.state.isPlayingAudio //access directly from latest state to potential old value
      ) {
        currentAudioIdx++;

        if (currentAudioIdx === voiceoverBlobUrlList.length) {
          //reset
          currentAudioIdx = 0;
          this.setState({
            highlightedItemIdx: -1,
            isPlayingAudio: false,
            saveProgressAllowed: true,
          });
        } else {
          this.setState(
            (prevState) => ({
              highlightedItemIdx: prevState.highlightedItemIdx + 1,
            }),
            () => {
              audioElement.src = voiceoverBlobUrlList[currentAudioIdx];
              audioElement.play();
            }
          );
        }
      } else {
        //NOTE:special flag to resume delayed operation...
        opsPaused = true;
      }
    }, 500);
  };

  onHandlePauseIconClick = () => {
    audioElement.pause();
    audioElement.currentTime = 0;
    this.setState({
      isPlayingAudio: false,
    });
  };

  onHandleMessageClick = (idx) => {
    if (!this.state.isPlayingAudio) {
      currentAudioIdx = idx;
      this.onAudioPlay();
    }
    if (idx === currentAudioIdx) {
      this.onHandlePauseIconClick();
    }
  };

  render() {
    const {
      targetedLessonList,
      currentStepNum,
      isPlayingAudio,
      clickDisabled,
      highlightedItemIdx,
      iconImageUrl,
    } = this.state;
    const { dbAuth, translation, operation } = this.props;

    if (targetedLessonList.length === 0) return null;
    return (
      <Fragment>
        <Spin
          tip="Saving lesson progress..."
          spinning={operation.isProcessing}
          size="large"
        >
          <Row
          // onScroll={this.handleScroll}
          // style={{ overflowY: "scroll", maxHeight: window.innerHeight }}
          >
            <Col xs={3} sm={4} md={5} lg={6} xl={6} xxl={6} />
            <Col
              xs={18}
              sm={16}
              md={14}
              lg={12}
              xl={12}
              xxl={12}
              style={{
                textAlign: "center",
              }}
            >
              <Row className="sound-nav-bar">
                <Col span={3} >
                  <img
                    className="back-button"
                    src="/assets/back-arrow-icon.svg"
                    alt=""
                    style={{ marginRight: "32px", cursor: "pointer" }}
                    onClick={this.onUpButtonClick}
                  />
                </Col>
                {isPlayingAudio ? (
                  <Col span={18}>
                    <img
                      src="/assets/pause-icon.svg"
                      alt=""
                      onClick={this.onHandlePauseIconClick}
                      className="sound-button"
                    />
                  </Col>
                ) : (
                  <Col span={18}>
                    <img
                      src="/assets/sound-icon.svg"
                      alt=""
                      onClick={
                        clickDisabled ? null : this.onHandleSoundIconClick
                      }
                      className="sound-button"
                    />
                  </Col>
                )}
              </Row>
              <Row>
                <Col xs={0} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <div
                    style={{
                      fontSize: "18px",
                      lineHeight: "normal",
                    }}
                  >
                    {getEnText(
                      targetedLessonList[currentStepNum].title,
                      translation.translationObj
                    ).isHtml ? (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: getEnText(
                            targetedLessonList[currentStepNum].title,
                            translation.translationObj
                          ).text,
                        }}
                      />
                    ) : (
                      <span style={{ fontFamily: "Poppins-Bold" }}>
                        {
                          getEnText(
                            targetedLessonList[currentStepNum].title,
                            translation.translationObj
                          ).text
                        }
                      </span>
                    )}
                  </div>
                  {dbAuth.userInfo.nativeLanguage !== "en" && (
                    <div
                      style={{
                        fontStyle: "italic",
                        fontSize: "18px",
                        lineHeight: "normal",
                      }}
                    >
                      {getNativeLangTransText(
                        dbAuth.userInfo.nativeLanguage,
                        targetedLessonList[currentStepNum].title,
                        translation.translationObj
                      ).isHtml ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              targetedLessonList[currentStepNum].title,
                              translation.translationObj
                            ).text,
                          }}
                        />
                      ) : (
                        <span style={{ fontFamily: "Poppins-Regular" }}>
                          {
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              targetedLessonList[currentStepNum].title,
                              translation.translationObj
                            ).text
                          }
                        </span>
                      )}
                    </div>
                  )}
                </Col>
                <Col xs={24} sm={0} md={0} lg={0} xl={0} xxl={0}>
                  <div
                    style={{
                      fontSize: "18px",
                      lineHeight: "normal",
                    }}
                  >
                    {getEnText(
                      targetedLessonList[currentStepNum].title,
                      translation.translationObj
                    ).isHtml ? (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: getEnText(
                            targetedLessonList[currentStepNum].title,
                            translation.translationObj
                          ).text,
                        }}
                      />
                    ) : (
                      <span style={{ fontFamily: "Poppins-Bold" }}>
                        {
                          getEnText(
                            targetedLessonList[currentStepNum].title,
                            translation.translationObj
                          ).text
                        }
                      </span>
                    )}
                  </div>
                  <div
                    style={{
                      fontStyle: "italic",
                      fontSize: "18px",
                      lineHeight: "normal",
                    }}
                  >
                    {dbAuth.userInfo.nativeLanguage !== "en" &&
                    getNativeLangTransText(
                      dbAuth.userInfo.nativeLanguage,
                      targetedLessonList[currentStepNum].title,
                      translation.translationObj
                    ).isHtml ? (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: getNativeLangTransText(
                            dbAuth.userInfo.nativeLanguage,
                            targetedLessonList[currentStepNum].title,
                            translation.translationObj
                          ).text,
                        }}
                      />
                    ) : (
                      <span style={{ fontFamily: "Poppins-Regular" }}>
                        {
                          getNativeLangTransText(
                            dbAuth.userInfo.nativeLanguage,
                            targetedLessonList[currentStepNum].title,
                            translation.translationObj
                          ).text
                        }
                      </span>
                    )}
                  </div>
                </Col>

                <Row
                  align="middle"
                  style={{
                    textAlign: "left",
                    //   maxHeight: "480px",
                    //   overflowY: "scroll"
                  }}
                >
                  {targetedLessonList[0].contents.map((item, idx) => {
                    if (item.imagePathIndex === 0) {
                      return (
                        <Fragment key={idx}>
                          <Col span={4}>
                            <img
                              src={iconImageUrl[item.imagePathIndex]}
                              // src="/assets/person-left-icon.svg"
                              alt=""
                              style={{ width: "95%" }}
                            />
                          </Col>
                          <Col
                            xs={0}
                            sm={20}
                            md={20}
                            lg={20}
                            xl={20}
                            xxl={20}
                            ref={
                              document.documentElement.clientWidth > 575
                                ? (element) =>
                                    this.setMessageContainerRefCallback(
                                      element,
                                      idx
                                    )
                                : null
                            }
                            className={
                              "contentType3" +
                              (highlightedItemIdx === idx
                                ? " highlighted"
                                : " not-highlighted")
                            }
                            style={{
                              marginTop: "10px",
                              borderRadius: "16px",
                              padding: "16px",
                            }}
                            onClick={
                              clickDisabled
                                ? null
                                : () => this.onHandleMessageClick(idx)
                            }
                          >
                            <div
                              style={{
                                fontSize: "18px",
                                lineHeight: "normal",
                              }}
                            >
                              {getEnText(
                                item.sentence,
                                translation.translationObj
                              ).isHtml ? (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: getEnText(
                                      item.sentence,
                                      translation.translationObj
                                    ).text,
                                  }}
                                />
                              ) : (
                                <span style={{ fontFamily: "Poppins-Bold" }}>
                                  {
                                    getEnText(
                                      item.sentence,
                                      translation.translationObj
                                    ).text
                                  }
                                </span>
                              )}
                            </div>

                            {dbAuth.userInfo.nativeLanguage !== "en" && (
                              <span
                                style={{
                                  fontStyle: "italic",
                                  fontSize: "18px",
                                  lineHeight: "normal",
                                }}
                              >
                                {getNativeLangTransText(
                                  dbAuth.userInfo.nativeLanguage,
                                  item.sentence,
                                  translation.translationObj
                                ).isHtml ? (
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: getNativeLangTransText(
                                        dbAuth.userInfo.nativeLanguage,
                                        item.sentence,
                                        translation.translationObj
                                      ).text,
                                    }}
                                  />
                                ) : (
                                  <span
                                    style={{ fontFamily: "Poppins-Regular" }}
                                  >
                                    {
                                      getNativeLangTransText(
                                        dbAuth.userInfo.nativeLanguage,
                                        item.sentence,
                                        translation.translationObj
                                      ).text
                                    }
                                  </span>
                                )}
                              </span>
                            )}
                          </Col>
                          <Col
                            xs={20}
                            sm={0}
                            md={0}
                            lg={0}
                            xl={0}
                            xxl={0}
                            ref={
                              document.documentElement.clientWidth < 576
                                ? (element) =>
                                    this.setMessageContainerRefCallback(
                                      element,
                                      idx
                                    )
                                : null
                            }
                            className={
                              "contentType3" +
                              (highlightedItemIdx === idx
                                ? " highlighted"
                                : " not-highlighted")
                            }
                            style={{
                              marginTop: "10px",
                              borderRadius: "16px",
                              padding: "16px",
                            }}
                            onClick={
                              clickDisabled
                                ? null
                                : () => this.onHandleMessageClick(idx)
                            }
                          >
                            <div
                              style={{
                                fontSize: "14px",
                                lineHeight: "normal",
                              }}
                            >
                              {getEnText(
                                item.sentence,
                                translation.translationObj
                              ).isHtml ? (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: getEnText(
                                      item.sentence,
                                      translation.translationObj
                                    ).text,
                                  }}
                                />
                              ) : (
                                <span style={{ fontFamily: "Poppins-Bold" }}>
                                  {
                                    getEnText(
                                      item.sentence,
                                      translation.translationObj
                                    ).text
                                  }
                                </span>
                              )}
                            </div>
                            <span
                              style={{
                                fontStyle: "italic",
                                fontSize: "14px",
                                lineHeight: "normal",
                              }}
                            >
                              {dbAuth.userInfo.nativeLanguage !== "en" &&
                              getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                item.sentence,
                                translation.translationObj
                              ).isHtml ? (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: getNativeLangTransText(
                                      dbAuth.userInfo.nativeLanguage,
                                      item.sentence,
                                      translation.translationObj
                                    ).text,
                                  }}
                                />
                              ) : (
                                <span style={{ fontFamily: "Poppins-Regular" }}>
                                  {
                                    getNativeLangTransText(
                                      dbAuth.userInfo.nativeLanguage,
                                      item.sentence,
                                      translation.translationObj
                                    ).text
                                  }
                                </span>
                              )}
                            </span>
                          </Col>
                        </Fragment>
                      );
                    } else {
                      return (
                        <Fragment key={idx}>
                          <Col
                            xs={0}
                            sm={20}
                            md={20}
                            lg={20}
                            xl={20}
                            xxl={20}
                            ref={
                              document.documentElement.clientWidth > 575
                                ? (element) =>
                                    this.setMessageContainerRefCallback(
                                      element,
                                      idx
                                    )
                                : null
                            }
                            className={
                              "contentType3" +
                              (highlightedItemIdx === idx
                                ? " highlighted"
                                : " not-highlighted")
                            }
                            style={{
                              marginTop: "10px",
                              borderRadius: "16px",
                              padding: "16px",
                            }}
                            onClick={
                              clickDisabled
                                ? null
                                : () => this.onHandleMessageClick(idx)
                            }
                          >
                            <div
                              style={{
                                fontSize: "18px",
                                lineHeight: "normal",
                              }}
                            >
                              {getEnText(
                                item.sentence,
                                translation.translationObj
                              ).isHtml ? (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: getEnText(
                                      item.sentence,
                                      translation.translationObj
                                    ).text,
                                  }}
                                />
                              ) : (
                                <span style={{ fontFamily: "Poppins-Bold" }}>
                                  {
                                    getEnText(
                                      item.sentence,
                                      translation.translationObj
                                    ).text
                                  }
                                </span>
                              )}
                            </div>
                            {dbAuth.userInfo.nativeLanguage !== "en" && <span
                              style={{
                                fontStyle: "italic",
                                fontSize: "18px",
                                lineHeight: "normal",
                              }}
                            >
                              {
                              getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                item.sentence,
                                translation.translationObj
                              ).isHtml ? (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: getNativeLangTransText(
                                      dbAuth.userInfo.nativeLanguage,
                                      item.sentence,
                                      translation.translationObj
                                    ).text,
                                  }}
                                />
                              ) : (
                                <span style={{ fontFamily: "Poppins-Regular" }}>
                                  {
                                    getNativeLangTransText(
                                      dbAuth.userInfo.nativeLanguage,
                                      item.sentence,
                                      translation.translationObj
                                    ).text
                                  }
                                </span>
                              )}
                            </span>}
                            
                          </Col>
                          <Col
                            xs={20}
                            sm={0}
                            md={0}
                            lg={0}
                            xl={0}
                            xxl={0}
                            ref={
                              document.documentElement.clientWidth < 576
                                ? (element) =>
                                    this.setMessageContainerRefCallback(
                                      element,
                                      idx
                                    )
                                : null
                            }
                            className={
                              "contentType3" +
                              (highlightedItemIdx === idx
                                ? " highlighted"
                                : " not-highlighted")
                            }
                            style={{
                              marginTop: "10px",
                              borderRadius: "16px",
                              padding: "16px",
                            }}
                            onClick={
                              clickDisabled
                                ? null
                                : () => this.onHandleMessageClick(idx)
                            }
                          >
                            <div
                              style={{
                                fontSize: "14px",
                                lineHeight: "normal",
                              }}
                            >
                              {getEnText(
                                item.sentence,
                                translation.translationObj
                              ).isHtml ? (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: getEnText(
                                      item.sentence,
                                      translation.translationObj
                                    ).text,
                                  }}
                                />
                              ) : (
                                <span style={{ fontFamily: "Poppins-Bold" }}>
                                  {
                                    getEnText(
                                      item.sentence,
                                      translation.translationObj
                                    ).text
                                  }
                                </span>
                              )}
                            </div>
                            <span
                              style={{
                                fontStyle: "italic",
                                fontSize: "14px",
                                lineHeight: "normal",
                              }}
                            >
                              {dbAuth.userInfo.nativeLanguage !== "en" &&
                              getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                item.sentence,
                                translation.translationObj
                              ).isHtml ? (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: getNativeLangTransText(
                                      dbAuth.userInfo.nativeLanguage,
                                      item.sentence,
                                      translation.translationObj
                                    ).text,
                                  }}
                                />
                              ) : (
                                <span style={{ fontFamily: "Poppins-Regular" }}>
                                  {
                                    getNativeLangTransText(
                                      dbAuth.userInfo.nativeLanguage,
                                      item.sentence,
                                      translation.translationObj
                                    ).text
                                  }
                                </span>
                              )}
                            </span>
                          </Col>
                          <Col span={4}>
                            <img
                              src={iconImageUrl[item.imagePathIndex]}
                              // src="/assets/person-right-icon.svg"
                              alt=""
                              style={{ width: "95%" }}
                            />
                          </Col>
                        </Fragment>
                      );
                    }
                  })}
                </Row>
              </Row>
            </Col>
            <Col span={6} />

            <Col
              span={24}
              style={{
                textAlign: "center",
                borderTop: "4px solid #F1F1F1",
                backgroundColor: "white",
                position: "sticky",
                bottom: "0",
              }}
            >
              <Col
                span={24}
                style={{
                  textAlign: "center",
                  marginTop: "10px",
                  //marginBottom: currentStepNum === 0 ? "120px" : "240px"
                }}
              >
                <SelfRecording currentStepNum={currentStepNum} />
              </Col>
              {/* {this.state.bottom && (
                <Button
                  type="primary"
                  style={{
                    margin: "24px 0px",
                    borderRadius: "24px",
                    height: "auto",
                    fontFamily: "Poppins-Bold",
                    fontSize: "23px",
                    width: "250px",
                    boxShadow: "0px 6px 0  #2A3580",
                  }}
                  onClick={this.onNextButtonClick}
                >
                  Back
                </Button>
              )} */}
            </Col>
          </Row>
        </Spin>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  course: state.course,
  translation: state.translation,
  dbAuth: state.auth,
  operation: state.operation,
  achievement: state.achievement,
});

const mapDispatchToProps = {
  updateLessonProgress,
  updateLessonAchievementProgress,
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(ContentType3LessonPage);
