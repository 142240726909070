import {
  LOAD_ALL_COURSE,
  LOAD_COURSE_TOPICS,
  RESET_COURSE_TOPICS,
  RESET_COURSE_SUBTOPICS,
  LOAD_COURSE_SUBTOPICS,
  RESET_COURSE_LESSON_GROUPS,
  LOAD_COURSE_LESSON_GROUPS,
  RESET_COURSE_LESSON,
  LOAD_COURSE_LESSON,
  LOAD_QUIZ_TOPICS,
  RESET_QUIZ_TOPICS,
  LOAD_QUIZ_GROUPS,
  RESET_QUIZ_GROUPS,
  LOAD_QUIZ_GROUP_QUESTIONS,
  RESET_QUIZ_GROUP_QUESTIONS,
  RESET_ALL_EXAM_LEVELS,
  LOAD_ALL_EXAM_LEVELS,
  LOAD_EXAM_GROUP_QUESTIONS,
  RESET_EXAM_GROUP_QUESTIONS,
  RESET_TARGETED_EXAM_GROUPS,
  LOAD_TARGETED_EXAM_GROUPS
} from "./courseConstant";

export const loadAllCourse = () => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();
    try {
      // let courseInfoObj = [];
      let courseInfoList = [];
      let courseDocSnap = await db.collection("courses").get();
      let total = 0
      courseDocSnap.forEach(async courseDocSnap => {
        total += 1
        let topicQuerySnap = await db
          .collection(`courses/${courseDocSnap.id}/topics`)
          .get();

        let topicInfoList = [];
        topicQuerySnap.forEach(docSnap => {
          topicInfoList.push({ ...docSnap.data(), docId: docSnap.id });
        });
        courseInfoList.push({
          ...courseDocSnap.data(),
          docId: courseDocSnap.id,
          topics: topicInfoList
        });
        if (courseInfoList.length === total) {
          // console.log(courseInfoList);

          dispatch({ type: LOAD_ALL_COURSE, payload: { courseInfoList } });
        }
      });
    } catch (err) {
      console.log(err);
    }
  };
};

export const loadTargetedCourseTopics = courseId => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();
    try {
      let courseDocSnap = await db.doc(`courses/${courseId}`).get();

      let topicInfoObj = {
        description: "",
        topicInfoList: []
      };

      if (courseDocSnap.exists) {
        topicInfoObj.description = courseDocSnap.data().description;
      }

      //load all topics also
      let topicQuerySnap = await db
        .collection(`courses/${courseId}/topics`).orderBy("topicNumber")
        .get();

      let topicInfoList = [];
      topicQuerySnap.forEach(docSnap => {
        topicInfoList.push({ ...docSnap.data(), docId: docSnap.id });
      });
      topicInfoObj.topicInfoList = topicInfoList;

      dispatch({ type: LOAD_COURSE_TOPICS, payload: { topicInfoObj } });
    } catch (err) {
      console.log(err);
    }
  };
};

export const resetTargetedCourseTopics = () => {
  return async dispatch => {
    try {
      dispatch({ type: RESET_COURSE_TOPICS });
    } catch (err) {
      console.log(err);
    }
  };
};

//== 1

export const loadTargetedCourseSubTopics = (courseId, topicId) => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();
    try {
      let topicDocSnap = await db
        .doc(`courses/${courseId}/topics/${topicId}`)
        .get();

      let subTopicInfoObj = {
        topicInfoObj: {},
        subTopicInfoList: []
      };

      if (topicDocSnap.exists) {
        subTopicInfoObj.topicInfoObj = {
          ...topicDocSnap.data(),
          docId: topicDocSnap.id
        };
      }

      let subTopicQuerySnap = await db
        .collection(`courses/${courseId}/topics/${topicId}/subTopics`).orderBy("subTopicNumber")
        .get();

      let subTopicInfoList = [];
      subTopicQuerySnap.forEach(docSnap => {
        subTopicInfoList.push({ ...docSnap.data(), docId: docSnap.id });
      });
      subTopicInfoObj.subTopicInfoList = subTopicInfoList;

      dispatch({ type: LOAD_COURSE_SUBTOPICS, payload: { subTopicInfoObj } });
    } catch (err) {
      console.log(err);
    }
  };
};

export const resetTargetedCourseSubTopics = () => {
  return async dispatch => {
    try {
      dispatch({ type: RESET_COURSE_SUBTOPICS });
    } catch (err) {
      console.log(err);
    }
  };
};

//== 2

export const loadTargetedCourseLessonGroup = (
  courseId,
  topicId,
  subTopicId
) => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();
    try {
      let lessonGroupInfoObj = {
        topicInfoObj: {},

        subTopicInfoObj: {},
        lessonGroupInfoList: []
      };

      //still fetch topic (for breadcrumb usage)
      let topicDocSnap = await db
        .doc(`courses/${courseId}/topics/${topicId}`)
        .get();

      if (topicDocSnap.exists) {
        lessonGroupInfoObj.topicInfoObj = {
          ...topicDocSnap.data(),
          docId: topicDocSnap.id
        };
      }

      //straight into lesson group
      let lessonGroupQuerySnap = await db
        .collection(
          `courses/${courseId}/topics/${topicId}/subTopics/${subTopicId}/scenes`
        )
        .get();

      let lessonGroupInfoList = [];
      lessonGroupQuerySnap.forEach(docSnap => {
        lessonGroupInfoList.push({ ...docSnap.data(), docId: docSnap.id });
      });

      //sort in asc(TODO:hack)
      lessonGroupInfoList.sort((a, b) => {
        return parseInt(a.name) < parseInt(b.name) ? -1 : 1;
      });
      lessonGroupInfoObj.lessonGroupInfoList = lessonGroupInfoList;

      if (lessonGroupInfoList.length > 1) {
        //found lesson group , now get subTopic description

        let subTopicDocSnap = await db
          .doc(
            `courses/${courseId}/topics/${topicId}/subTopics/${subTopicId}`
          )
          .get();

        if (subTopicDocSnap.exists) {
          lessonGroupInfoObj.subTopicInfoObj = {
            ...subTopicDocSnap.data(),
            docId: subTopicDocSnap.id
          };
        }
      }

      dispatch({
        type: LOAD_COURSE_LESSON_GROUPS,
        payload: { lessonGroupInfoObj }
      });
    } catch (err) {
      console.log(err);
    }
  };
};

export const resetTargetedCourseLessonGroup = () => {
  return async dispatch => {
    try {
      dispatch({ type: RESET_COURSE_LESSON_GROUPS });
    } catch (err) {
      console.log(err);
    }
  };
};

export const loadTargetedCourseLesson = (
  courseId,
  topicId,
  subTopicId,
  groupId
) => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();
    try {
      let lessonInfoObj = {
        subTopicInfoObj: {},
        lessonList: []
      };
      //load content type from subTopic layer...
      let subTopicDocSnap = await db
        .doc(
          `courses/${courseId}/topics/${topicId}/subTopics/${subTopicId}`
        )
        .get();

      if (subTopicDocSnap.exists) {
        lessonInfoObj.subTopicInfoObj = {
          ...subTopicDocSnap.data(),
          docId: subTopicDocSnap.id
        };
      }

      let sceneDocSnap = await db
        .doc(
          `courses/${courseId}/topics/${topicId}/subTopics/${subTopicId}/scenes/${groupId}`
        )
        .get();

      if (sceneDocSnap.exists) {
        lessonInfoObj.lessonList = sceneDocSnap.data().lessons;
      }

      dispatch({ type: LOAD_COURSE_LESSON, payload: { lessonInfoObj } });
    } catch (err) {
      console.log(err);
    }
  };
};

export const resetTargetedCourseLesson = () => {
  return async dispatch => {
    try {
      dispatch({ type: RESET_COURSE_LESSON });
    } catch (err) {
      console.log(err);
    }
  };
};

//quiz related
export const loadTargetedQuizTopics = quizId => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();
    try {
      let quizTopicInfoObj = {};

      //load all topics also
      let topicQuerySnap = await db
        .collection(`quizzes/${quizId}/topics`).orderBy("topicNumber")
        .get();

      let topicInfoList = [];
      topicQuerySnap.forEach(docSnap => {
        topicInfoList.push({ ...docSnap.data(), docId: docSnap.id });
      });

      quizTopicInfoObj.topicInfoList = topicInfoList;

      dispatch({ type: LOAD_QUIZ_TOPICS, payload: { quizTopicInfoObj } });
    } catch (err) {
      console.log(err);
    }
  };
};

export const resetTargetedQuizTopics = () => {
  return async dispatch => {
    try {
      dispatch({ type: RESET_QUIZ_TOPICS });
    } catch (err) {
      console.log(err);
    }
  };
};

export const loadTargetedQuizGroups = (quizId, topicId) => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();
    try {
      let quizGroupInfoObj = {
        topicInfoObj: {},
        quizGroupInfoList: []
      };

      //still fetch topic (for breadcrumb usage)
      let topicDocSnap = await db
        .doc(`quizzes/${quizId}/topics/${topicId}`)
        .get();

      if (topicDocSnap.exists) {
        quizGroupInfoObj.topicInfoObj = {
          ...topicDocSnap.data(),
          docId: topicDocSnap.id
        };
      }

      let quizGroupQuerySnap = await db
        .collection(`quizzes/${quizId}/topics/${topicId}/groups`)
        .get();

      let quizGroupInfoList = [];
      quizGroupQuerySnap.forEach(docSnap => {
        quizGroupInfoList.push({ ...docSnap.data(), docId: docSnap.id });
      });

      // sort in asc(TODO:hack)
      quizGroupInfoList.sort((a, b) => {
        return parseInt(a.name) < parseInt(b.name) ? -1 : 1;
      });

      quizGroupInfoObj.quizGroupInfoList = quizGroupInfoList;

      dispatch({ type: LOAD_QUIZ_GROUPS, payload: { quizGroupInfoObj } });
    } catch (err) {
      console.log(err);
    }
  };
};

export const resetTargetedQuizGroups = () => {
  return async dispatch => {
    try {
      dispatch({ type: RESET_QUIZ_GROUPS });
    } catch (err) {
      console.log(err);
    }
  };
};

export const loadTargetedQuizGroupQuestions = (quizId, topicId, groupId) => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();
    try {
      let quizQuestionInfoObj = {
        topicInfoObj: {},
        questionGroupObj: {}
      };

      //load content type from topic layer...
      let topicDocSnap = await db
        .doc(`quizzes/${quizId}/topics/${topicId}`)
        .get();

      if (topicDocSnap.exists) {
        quizQuestionInfoObj.topicInfoObj = {
          ...topicDocSnap.data(),
          docId: topicDocSnap.id
        };
      }

      let quizGroupDocSnap = await db
        .doc(`quizzes/${quizId}/topics/${topicId}/groups/${groupId}`)
        .get();

      if (quizGroupDocSnap.exists) {
        quizQuestionInfoObj.questionGroupObj = {
          ...quizGroupDocSnap.data(),
          docId: quizGroupDocSnap.id
        };
      }

      dispatch({
        type: LOAD_QUIZ_GROUP_QUESTIONS,
        payload: { quizQuestionInfoObj }
      });
    } catch (err) {
      console.log(err);
    }
  };
};

export const resetTargetedQuizGroupQuestions = () => {
  return async dispatch => {
    try {
      dispatch({ type: RESET_QUIZ_GROUP_QUESTIONS });
    } catch (err) {
      console.log(err);
    }
  };
};

//exam related
export const loadAllExamLevels = () => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();
    try {
      let examInfoObj = {};
      //let examQuerySnap = await db.collection("exams").get();
      let examQuerySnap = await db.collection("exams").get();

      let examInfoList = [];
      examQuerySnap.forEach(async docSnap => {
        let examLevelTests = [];
        let levelSnap = await db
          //.collection(`exams/${docSnap.id}/groups`)
          .collection(`exams/${docSnap.id}/groups`)
          .get();
        levelSnap.forEach(async testDoc => {
          examLevelTests.push(testDoc.data());
        });
        examInfoList.push({
          ...docSnap.data(),
          docId: docSnap.id,
          tests: examLevelTests
        });
        // console.log(examQuerySnap.size);
        if (examInfoList.length === examQuerySnap.size) {
          //   console.log(examInfoList);
          examInfoObj.examInfoList = examInfoList;

          dispatch({ type: LOAD_ALL_EXAM_LEVELS, payload: { examInfoObj } });
        }
      });
      //   console.log(examInfoList);
    } catch (err) {
      console.log(err);
    }
  };
};

export const resetAllExamLevels = () => {
  return async dispatch => {
    try {
      dispatch({ type: RESET_ALL_EXAM_LEVELS });
    } catch (err) {
      console.log(err);
    }
  };
};

export const loadTargetedExamGroups = examId => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();
    try {
      let examGroupInfoObj = {
        examInfoObj: {},
        examGroupInfoList: []
      };

      //still fetch exam (for breadcrumb usage)
      //let examDocSnap = await db.doc(`exams/${examId}`).get();
      let examDocSnap = await db.doc(`exams/${examId}`).get();

      if (examDocSnap.exists) {
        examGroupInfoObj.examInfoObj = {
          ...examDocSnap.data(),
          docId: examDocSnap.id
        };
      }

      let examGroupQuerySnap = await db
        //.collection(`exams/${examId}/groups`)
        .collection(`exams/${examId}/groups`)
        .get();

      let examGroupInfoList = [];

      examGroupQuerySnap.forEach(docSnap => {
        examGroupInfoList.push({ ...docSnap.data(), docId: docSnap.id });
      });

      examGroupInfoObj.examGroupInfoList = examGroupInfoList;

      dispatch({
        type: LOAD_TARGETED_EXAM_GROUPS,
        payload: { examGroupInfoObj }
      });
    } catch (err) {
      console.log(err);
    }
  };
};

export const resetTargetedExamGroups = () => {
  return async dispatch => {
    try {
      dispatch({ type: RESET_TARGETED_EXAM_GROUPS });
    } catch (err) {
      console.log(err);
    }
  };
};

export const loadTargetedExamGroupQuestions = (quizId, groupId) => {
  return async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();
    try {
      // let examGroupDocSnap = await db.doc(`quiz/beginner/topics/introduction_to_english/groups/1`).get();
      let examGroupDocSnap = await db
        //.doc(`exams/${quizId}/groups/${groupId}`)
        .doc(`exams/${quizId}/groups/${groupId}`)
        .get();

      let examQuestionObj = {};
      if (examGroupDocSnap.exists) {
        examQuestionObj = {
          ...examGroupDocSnap.data(),
          docId: examGroupDocSnap.id
        };
      }

      dispatch({
        type: LOAD_EXAM_GROUP_QUESTIONS,
        payload: { examQuestionObj }
      });
    } catch (err) {
      console.log(err);
    }
  };
};

export const resetTargetedExamGroupQuestions = () => {
  return async dispatch => {
    try {
      dispatch({ type: RESET_EXAM_GROUP_QUESTIONS });
    } catch (err) {
      console.log(err);
    }
  };
};
