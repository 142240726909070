import React, { Component, Fragment } from "react";
import "./UserDashboardNavBar.css";
import { Popover, Menu, Row, Col } from "antd";
import LanguageSelectionComponent from "../../LanguageSelectionComponent";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";
import { withFirebase } from "react-redux-firebase";
import { logout } from "../../auth/authActions";
import { getNativeLangTransText } from "../../SharedUtils";

class UserDashboardNavBar extends Component {
  state = {
    thisWeekMenuObj: {
      selected: false,
      exactMatch: false,
    },
    courseMenuObj: {
      selected: false,
      exactMatch: false,
    },
    quizMenuObj: {
      selected: false,
      exactMatch: false,
    },
    examMenuObj: {
      selected: false,
      exactMatch: false,
    },
    statsMenuObj: {
      selected: false,
      exactMatch: false,
    },
    selectedLanguage: {},
    // loading : false
  };

  componentDidMount() {
    const { location } = this.props;
    this.setState({
      thisWeekMenuObj: {
        selected: location.pathname.match(/this-week/g) !== null,
        exactMatch: location.pathname.match(/this-week$/g) !== null,
      },

      statsMenuObj: {
        selected: location.pathname.match(/stats/g) !== null,
        exactMatch: location.pathname.match(/stats$/g) !== null,
      },

      // emptyUser : dbAuth
    });
  }

  onProfileMenuClick = async (e, onHandleResetLabelClick) => {
    if (e.key === "/user-dashboard/profile") {
      this.props.history.push("/user-dashboard/profile");
    } else if (e.key === "/user-dashboard/give-us-feedback") {
      this.props.history.push("/user-dashboard/give-us-feedback");
      // } else if (e.key === "/user-dashboard/terms_and_condition") {
      //   this.props.history.push("/user-dashboard/terms_and_condition");
    } else if (e.key === "resetProgress") {
      onHandleResetLabelClick();
    } else if (e.key === "signOut") {
      await this.props.logout();
      this.props.history.push("/");
    }
  };

  render() {
    const { statsMenuObj } = this.state;

    // console.log(dbAuth);
    const { dbAuth, configuration, translation } = this.props;

    // const ProfileMenu = ({ onHandleResetLabelClick, location }) => {
    //   return (
    //     <Menu
    //       style={{
    //         fontFamily: "Poppins-Regular",
    //         fontSize: "16px",
    //         borderRight: "none",
    //       }}
    //       onClick={(e) => this.onProfileMenuClick(e, onHandleResetLabelClick)}
    //       selectedKeys={[location.pathname]}
    //     >
    //       <Menu.Item key="/user-dashboard/edit-profile">Edit Profile</Menu.Item>
    //       <Menu.Item key="/user-dashboard/give-us-feedback">
    //         Give us Feedback
    //       </Menu.Item>
    //       {/* <Menu.Item key="/user-dashboard/terms_and_condition">
    //         Terms and Condition
    //       </Menu.Item> */}

    //       {/* <Menu.Item key="resetProgress">Reset Progress</Menu.Item> */}

    //       <Menu.Item key="signOut">Sign out</Menu.Item>
    //     </Menu>
    //   );
    // };

    return (
      <Fragment>
        <Row
          className="user-dashboard-nav-bar-ul-container"
          style={{ borderBottom: "none", margin: "16px 0px 0px 0px" }}
        >
          <Col span={1} />

          <Col
            xs={0}
            sm={10}
            md={0}
            lg={0}
            xl={0}
            style={{ textAlign: "center" }}
          >
            <a href="/user-dashboard/stats" disabled={statsMenuObj.exactMatch}>
              <img
                src={
                  statsMenuObj.selected
                    ? "/assets/graph-icon-selected.svg"
                    : "/assets/graph-icon.svg"
                }
                alt=""
                style={{ width: "40px", height: "40px" }}
              />
              <div
                style={{
                  fontFamily: "Poppins-Bold",
                  fontSize: "23px",
                  color: statsMenuObj.selected ? "#4252BE" : "#707070",
                }}
              >
                {
                  getNativeLangTransText(
                    dbAuth.userInfo.nativeLanguage,
                    configuration.projectUI.stats,
                    translation.translationObj
                  ).text
                }
              </div>
            </a>
          </Col>
          <Col
            xs={0}
            sm={10}
            md={0}
            lg={0}
            xl={0}
            style={{ textAlign: "center" }}
          >
            <Popover
              overlayClassName="home-page-popover-container-wrapper"
              content={<LanguageSelectionComponent />}
              trigger="click"
              style={{ textAlign: "center" }}
            >
              <img
                src={
                  this.props.configuration.languageList.find(
                    (language) =>
                      language.languageCode === dbAuth.userInfo.nativeLanguage
                  ).imageUrl
                }
                alt=""
                style={{ width: "40px", height: "40px", cursor: "pointer" }}
              />
              <br />
              <div
                style={{
                  fontFamily: "Poppins-Bold",
                  fontSize: "23px",
                  cursor: "pointer",
                  color: "#707070",
                }}
              >
                {
                  this.props.configuration.languageList.find(
                    (language) =>
                      language.languageCode === dbAuth.userInfo.nativeLanguage
                  ).languageName
                }
              </div>
            </Popover>
          </Col>
          {/* xs */}
          <Col
            xs={10}
            sm={0}
            md={0}
            lg={0}
            xl={0}
            style={{ textAlign: "center", paddingBottom: "10px" }}
          >
            <a href="/user-dashboard/stats" disabled={statsMenuObj.exactMatch}>
              <img
                src={
                  statsMenuObj.selected
                    ? "/assets/graph-icon-selected.svg"
                    : "/assets/graph-icon.svg"
                }
                alt=""
                style={{ width: "35px", height: "35px" }}
              />
            </a>
          </Col>
          <Col
            xs={10}
            sm={0}
            md={0}
            lg={0}
            xl={0}
            style={{ textAlign: "center", paddingBottom: "10px" }}
          >
            <Popover
              overlayClassName="home-page-popover-container-wrapper"
              content={<LanguageSelectionComponent />}
              trigger="click"
              style={{ textAlign: "center" }}
            >
              <img
                src={
                  this.props.configuration.languageList.find(
                    (language) =>
                      language.languageCode === dbAuth.userInfo.nativeLanguage
                  ).imageUrl
                }
                alt=""
                style={{ width: "40px", height: "40px" }}
              />
            </Popover>
          </Col>
          <Col span={1} />
        </Row>
      </Fragment>
    );
  }
}

const list = [
  { name: "item1" },
  { name: "item2" },
  { name: "item3" },
  { name: "item4" },
  { name: "item5" },
  { name: "item6" },
  { name: "item7" },
  { name: "item8" },
  { name: "item9" },
];

const mapStateToProps = (state) => ({
  dbAuth: state.auth,
  configuration: state.configuration,
  translation: state.translation,
});

const mapDispatchToProps = {
  logout,
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(UserDashboardNavBar);
