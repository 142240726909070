import React, { Component, Fragment } from "react";
import "./QuizTopicPage.css";
import BottomBar from "../../../BottomBar/BottomBar";
import { Col, Row, Breadcrumb } from "antd";
import WeeklyGoal from "../../WeeklyGoal/WeeklyGoal";
import UserDashboardNavBar from "../../UserDashboardNavBar/UserDashboardNavBar";
import UserDashboardNavBottomBar from "../../UserDashboardNavBar/UserDashboardNavBottomBar";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  loadTargetedQuizTopics,
  resetTargetedQuizTopics,
} from "../../../course/courseActions";
import LoadingComponent from "../../../app/common/loading/LoadingComponent";
import { getNativeLangTransText } from "../../../SharedUtils";
import _ from "lodash";
import { courseColorMap } from "../../../SharedConstant";

class QuizTopicPage extends Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    const { match } = this.props;
    let quizId = match.params.quizId;

    this.setState(
      {
        quizId, //simply store in var for future use
      },
      () => this.props.loadTargetedQuizTopics(quizId)
    );
  }

  componentWillUnmount() {
    this.props.resetTargetedQuizTopics();
  }

  componentDidUpdate(prevProps) {
    const { course } = this.props;
    if (
      !_.isEqual(prevProps.course.quizTopicInfoObj, course.quizTopicInfoObj) &&
      course.quizTopicInfoObj !== null
    ) {
      this.setState({
        isLoading: false,
      });
    }
  }

  onQuizzesClick = () => {
    this.props.history.push(`/user-dashboard/quiz/all-quizzes`);
  };

  render() {
    const { isLoading, quizId } = this.state;
    const {
      course,
      onHandleResetLabelClick,
      dbAuth,
      translation,
      configuration,
    } = this.props;

    if (isLoading) return <LoadingComponent />;
    return (
      <Fragment>
        <UserDashboardNavBar
          onHandleResetLabelClick={onHandleResetLabelClick}
        />
        <Row>
          <Col className="page-container" span={24}>
            <Row style={{ marginBottom: "16px" }}>
              <Col xs={2} sm={4} md={2} lg={4} xl={5} xxl={6} />
              <Col
                xs={20}
                sm={16}
                md={20}
                lg={16}
                xl={14}
                xxl={12}
                style={{
                  minHeight: window.screen.height - 350,
                }}
                className="content-wrap"
              >
                <Breadcrumb
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "22px",
                  }}
                >
                  <Breadcrumb.Item onClick={this.onQuizzesClick}>
                    Quizzes
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    {courseColorMap.get(quizId).label}
                  </Breadcrumb.Item>
                </Breadcrumb>
                <Row>
                  <Col xs={0} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <Row>
                      <Col span={24}>
                        <Link
                          style={{
                            fontFamily: "Poppins-Bold",
                            fontSize: "22px",
                            color: "#4252BE",
                            cursor: "pointer",
                          }}
                          to={`/user-dashboard/quiz/all-quizzes`}
                        >
                          {"< " +
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.back,
                              translation.translationObj
                            ).text}
                        </Link>
                        <Row align="middle">
                          <Col flex="80px">
                            <img
                              src="/assets/owl-watching-icon.svg"
                              alt=""
                              style={{ width: "100%" }}
                            />
                          </Col>
                          <Col flex="auto" style={{ marginLeft: "8px" }}>
                            <div
                              style={{
                                fontFamily: "Poppins-Bold",
                                fontSize: "35px",
                                lineHeight: "normal",
                              }}
                            >
                              {
                                getNativeLangTransText(
                                  dbAuth.userInfo.nativeLanguage,
                                  configuration.projectUI.choose,
                                  translation.translationObj
                                ).text
                              }
                            </div>
                            <div
                              style={{
                                fontFamily: "Poppins-Regular",
                                fontSize: "23px",
                                lineHeight: "normal",
                              }}
                            >
                              {
                                getNativeLangTransText(
                                  dbAuth.userInfo.nativeLanguage,
                                  configuration.projectUI.the_quizzes,
                                  translation.translationObj
                                ).text
                              }
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24} style={{ marginTop: "16px" }}>
                        <Row style={{ textAlign: "center" }}>
                          {course.quizTopicInfoObj.topicInfoList.map(
                            (topicInfo) => (
                              <Fragment key={topicInfo.docId}>
                                <Col
                                  offset={2}
                                  span={18}
                                  style={{ marginTop: "36px" }}
                                >
                                  <Link
                                    to={`/user-dashboard/quiz/all-quizzes/${quizId}/topics/${topicInfo.docId}`}
                                    style={{
                                      color: "black",
                                      boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                                      borderRadius: "24px",
                                      border: "1px solid rgba(112,112,112,0.4)",
                                      fontFamily: "Poppins-Regular",
                                      fontSize: "23px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      padding: "40px",
                                      background:
                                        courseColorMap.get(quizId).hexColorCode,
                                      cursor: "pointer",
                                    }}
                                  >
                                    {topicInfo.name}
                                  </Link>
                                </Col>
                                <Col span={4} />
                              </Fragment>
                            )
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={24} sm={0} md={0} lg={0} xl={0} xxl={0}>
                    <Row>
                      <Col span={24}>
                        <Link
                          style={{
                            fontFamily: "Poppins-Bold",
                            fontSize: "18px",
                            color: "#4252BE",
                            cursor: "pointer",
                          }}
                          to={`/user-dashboard/quiz/all-quizzes`}
                        >
                          {"< " +
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.back,
                              translation.translationObj
                            ).text}
                        </Link>
                        <Row align="middle">
                          <Col flex="80px">
                            <img
                              src="/assets/owl-watching-icon.svg"
                              alt=""
                              style={{ width: "100%" }}
                            />
                          </Col>
                          <Col flex="auto" style={{ marginLeft: "8px" }}>
                            <div
                              style={{
                                fontFamily: "Poppins-Bold",
                                fontSize: "28px",
                                lineHeight: "normal",
                              }}
                            >
                              {
                                getNativeLangTransText(
                                  dbAuth.userInfo.nativeLanguage,
                                  configuration.projectUI.choose,
                                  translation.translationObj
                                ).text
                              }
                            </div>
                            <div
                              style={{
                                fontFamily: "Poppins-Regular",
                                fontSize: "18px",
                                lineHeight: "normal",
                              }}
                            >
                              {
                                getNativeLangTransText(
                                  dbAuth.userInfo.nativeLanguage,
                                  configuration.projectUI.the_quizzes,
                                  translation.translationObj
                                ).text
                              }
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24} style={{ marginTop: "16px" }}>
                        <Row style={{ textAlign: "center" }}>
                          {course.quizTopicInfoObj.topicInfoList.map(
                            (topicInfo) => (
                              <Fragment key={topicInfo.docId}>
                                <Col
                                  offset={3}
                                  span={18}
                                  style={{ marginTop: "36px" }}
                                >
                                  <Link
                                    to={`/user-dashboard/quiz/all-quizzes/${quizId}/topics/${topicInfo.docId}`}
                                    style={{
                                      color: "black",
                                      boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                                      borderRadius: "24px",
                                      border: "1px solid rgba(112,112,112,0.4)",
                                      fontFamily: "Poppins-Regular",
                                      fontSize: "20px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      padding: "20px",
                                      background:
                                        courseColorMap.get(quizId).hexColorCode,
                                      cursor: "pointer",
                                    }}
                                  >
                                    {topicInfo.name}
                                  </Link>
                                </Col>
                                <Col span={4} />
                              </Fragment>
                            )
                          )}
                        </Row>
                        <br />
                      </Col>
                    </Row>
                  </Col>

                  <Col
                    offset={1}
                    xs={0}
                    sm={0}
                    md={11}
                    lg={11}
                    xl={11}
                    xxl={11}
                  >
                    <WeeklyGoal />
                  </Col>
                </Row>
              </Col>
              <Col xs={3} sm={5} md={0} lg={0} xl={0} xxl={0} />
              <Col xs={18} sm={14} md={0} lg={0} xl={0} xxl={0}>
                <WeeklyGoal />
              </Col>
            </Row>
            <Row>
              <Col
                xs={0}
                sm={0}
                md={24}
                lg={24}
                xl={24}
                className="footer-bottom"
              >
                <BottomBar />
              </Col>
              <Col
                xs={0}
                sm={24}
                md={0}
                lg={0}
                xl={0}
                style={{ marginBottom: "100px" }}
              >
                <BottomBar />
              </Col>
              <Col
                xs={24}
                sm={0}
                md={0}
                lg={0}
                xl={0}
                style={{ marginBottom: "70px" }}
              >
                <BottomBar />
              </Col>
            </Row>
            <Row>
              <Col
                xs={24}
                sm={24}
                md={0}
                lg={0}
                xl={0}
                style={{
                  textAlign: "center",
                  borderTop: "4px solid #F1F1F1",
                }}
                className="footer"
              >
                <UserDashboardNavBottomBar
                  onHandleResetLabelClick={onHandleResetLabelClick}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  course: state.course,
  dbAuth: state.auth,
  translation: state.translation,
  configuration: state.configuration,
});

const mapDispatchToProps = {
  loadTargetedQuizTopics,
  resetTargetedQuizTopics,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(QuizTopicPage);
