import { createReducer } from "../app/common/utils/reducerUtils";
import { LOAD_ALL_COURSE, LOAD_COURSE_TOPICS, RESET_COURSE_TOPICS, LOAD_COURSE_SUBTOPICS, RESET_COURSE_SUBTOPICS, RESET_COURSE_LESSON_GROUPS, LOAD_COURSE_LESSON_GROUPS, LOAD_COURSE_LESSON, RESET_COURSE_LESSON, LOAD_QUIZ_TOPICS, RESET_QUIZ_TOPICS, LOAD_QUIZ_GROUPS, RESET_QUIZ_GROUPS, LOAD_QUIZ_GROUP_QUESTIONS, RESET_QUIZ_GROUP_QUESTIONS, RESET_ALL_EXAM_LEVELS, LOAD_ALL_EXAM_LEVELS, LOAD_EXAM_GROUP_QUESTIONS, RESET_EXAM_GROUP_QUESTIONS, RESET_TARGETED_EXAM_GROUPS, LOAD_TARGETED_EXAM_GROUPS } from "./courseConstant";

const initialState = {
    courseInfoObj: null,
    topicInfoObj: null,
    subTopicInfoObj: null,
    lessonGroupInfoObj: null,

    lessonInfoObj: null,

    quizTopicInfoObj: null,
    quizGroupInfoObj: null,
    quizQuestionInfoObj: null,

    examGroupInfoObj: null,
    examQuestionObj: null
}

const loadAllCourse = (state, payload) => {
    return {
        ...state,
        courseInfoObj: payload.courseInfoList
    }
}

const loadCourseTopics = (state, payload) => {
    return {
        ...state,
        topicInfoObj: payload.topicInfoObj
    }
}


const resetCourseTopics = (state) => {
    return {
        ...state,
        topicInfoObj: null
    }
}

const loadCourseSubTopics = (state, payload) => {
    return {
        ...state,
        subTopicInfoObj: payload.subTopicInfoObj
    }
}


const resetCourseSubTopics = (state) => {
    return {
        ...state,
        subTopicInfoObj: null
    }
}

const loadCourseLessonGroups = (state, payload) => {
    return {
        ...state,
        lessonGroupInfoObj: payload.lessonGroupInfoObj
    }
}


const resetCourseLessonGroups = (state) => {
    return {
        ...state,
        lessonGroupInfoObj: null
    }
}

const loadCourseLesson = (state, payload) => {
    return {
        ...state,
        lessonInfoObj: payload.lessonInfoObj
    }
}


const resetCourseLesson = (state) => {
    return {
        ...state,
        lessonInfoObj: null
    }
}

const loadQuizTopics = (state, payload) => {
    return {
        ...state,
        quizTopicInfoObj: payload.quizTopicInfoObj
    }
}


const resetQuizTopics = (state) => {
    return {
        ...state,
        quizTopicInfoObj: null
    }
}

const loadQuizGroups = (state, payload) => {
    return {
        ...state,
        quizGroupInfoObj: payload.quizGroupInfoObj
    }
}


const resetQuizGroups = (state) => {
    return {
        ...state,
        quizGroupInfoObj: null
    }
}

const loadQuizGroupQuestions = (state, payload) => {
    return {
        ...state,
        quizQuestionInfoObj: payload.quizQuestionInfoObj
    }
}


const resetQuizGroupQuestions = (state) => {
    return {
        ...state,
        quizQuestionInfoObj: null
    }
}

//exam relatd
const loadAllExamLevels = (state, payload) => {
    return {
        ...state,
        examInfoObj: payload.examInfoObj
    }
}


const resetAllExamLevels = (state) => {
    return {
        ...state,
        examInfoObj: null
    }
}

const loadExamGroups = (state, payload) => {
    return {
        ...state,
        examGroupInfoObj: payload.examGroupInfoObj
    }
}


const resetExamGroups = (state) => {
    return {
        ...state,
        examGroupInfoObj: null
    }
}

const loadExamGroupQuestions = (state, payload) => {
    return {
        ...state,
        examQuestionObj: payload.examQuestionObj
    }
}


const resetExamGroupQuestions = (state) => {
    return {
        ...state,
        examQuestionObj: null
    }
}

export default createReducer(initialState, {
    [LOAD_ALL_COURSE]: loadAllCourse,
    [LOAD_COURSE_TOPICS]: loadCourseTopics,
    [RESET_COURSE_TOPICS]: resetCourseTopics,
    [LOAD_COURSE_SUBTOPICS]: loadCourseSubTopics,
    [RESET_COURSE_SUBTOPICS]: resetCourseSubTopics,
    [LOAD_COURSE_LESSON_GROUPS]: loadCourseLessonGroups,
    [RESET_COURSE_LESSON_GROUPS]: resetCourseLessonGroups,
    [LOAD_COURSE_LESSON]: loadCourseLesson,
    [RESET_COURSE_LESSON]: resetCourseLesson,

    [LOAD_QUIZ_TOPICS]: loadQuizTopics,
    [RESET_QUIZ_TOPICS]: resetQuizTopics,
    [LOAD_QUIZ_GROUPS]: loadQuizGroups,
    [RESET_QUIZ_GROUPS]: resetQuizGroups,
    [LOAD_QUIZ_GROUP_QUESTIONS]: loadQuizGroupQuestions,
    [RESET_QUIZ_GROUP_QUESTIONS]: resetQuizGroupQuestions,

    [LOAD_ALL_EXAM_LEVELS]: loadAllExamLevels,
    [RESET_ALL_EXAM_LEVELS]: resetAllExamLevels,

    [LOAD_TARGETED_EXAM_GROUPS]: loadExamGroups,
    [RESET_TARGETED_EXAM_GROUPS]: resetExamGroups,

    [LOAD_EXAM_GROUP_QUESTIONS]: loadExamGroupQuestions,
    [RESET_EXAM_GROUP_QUESTIONS]: resetExamGroupQuestions
});
