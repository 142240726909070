import _ from "lodash";

export const getEnText = (content, translationObj) => {
  //console.log("SSS ",translationObj.en["TXT5594"].text)
  if (content.includes("TXT", 0)) {
    if (translationObj.en[content].newText) {
      return checkIfIsHtml(translationObj.en[content].newText);
    } else {
      return checkIfIsHtml(translationObj.en[content].text);
    }
  } else {
    return checkIfIsHtml(content);
  }
};

export const getNativeLangTransText = (
  languageCode,
  content,
  translationObj
) => {
  // console.log(
  //   languageCode,
  //   content,
  //   translationObj,
  //   translationObj[languageCode][content]
  // );
  if (content.includes("TXT", 0)) {
    return checkIfIsHtml(
      translationObj[languageCode][content].text
        ? translationObj[languageCode][content].text
        : ""
    );
  } else {
    return checkIfIsHtml(content);
  }
};

export const checkIfIsHtml = (content) => {
  if (content.includes("<")) {
    return { text: content, isHtml: true };
  } else {
    return { text: content, isHtml: false };
  }
};

//lesson load related
export const lessonPageInit = (
  course,
  dbAuth,
  courseId,
  topicId,
  subTopicId,
  groupId
) => {
  //retrive any potential progress information
  let targetedLessonId = `${topicId}-${subTopicId}-${groupId}`;

  let targetedLessonList = [];
  let currentStepNum = 0;
  let isAllLessonsCompleted = false;

  if (
    dbAuth.progressInfo.lessons &&
    dbAuth.progressInfo.lessons[courseId] &&
    dbAuth.progressInfo.lessons[courseId][targetedLessonId] &&
    dbAuth.progressInfo.lessons[courseId][targetedLessonId].length > 0
  ) {
    let remainingLessonList = [];
    let validSavedLessonList = [];

    let lessonIdList = dbAuth.progressInfo.lessons[courseId][targetedLessonId];

    //ensuring previously saved lessonId order
    lessonIdList.forEach((lessonId) => {
      let filteredLessonId = course.lessonInfoObj.lessonList.filter(
        (item) => item.lessonId === lessonId
      );

      if (filteredLessonId.length > 0) {
        validSavedLessonList.push(filteredLessonId[0]);
      }
    });
    //==

    remainingLessonList = course.lessonInfoObj.lessonList.filter(
      (item) => !lessonIdList.includes(item.lessonId)
    );

    //check if all lesson completed, if yes then start all over again...
    if (remainingLessonList.length === 0) {
      targetedLessonList = course.lessonInfoObj.lessonList;
      isAllLessonsCompleted = true;
    } else {
      targetedLessonList = [...validSavedLessonList, ...remainingLessonList];
      currentStepNum = validSavedLessonList.length;
    }
  } else {
    targetedLessonList = course.lessonInfoObj.lessonList;
  }

  return {
    first: targetedLessonList,
    second: currentStepNum,
    third: isAllLessonsCompleted,
  };
};

export const _saveProgress = async function () {
  const {
    courseId,
    topicId,
    subTopicId,
    groupId,
    targetedLessonList,
    currentStepNum,
    isAllLessonsCompleted,
  } = this.state;
  const {
    dbAuth,
    updateLessonProgress,
    achievement,
    updateLessonAchievementProgress,
  } = this.props;
  //check latest step number and decide total accessed lessons
  let filteredTargetedLessonList = [];
  let completed = currentStepNum === targetedLessonList.length - 1;
  // console.log(completed)
  this.setState({
    completed,
  });
  if (currentStepNum === targetedLessonList.length - 1) {
    filteredTargetedLessonList = targetedLessonList.slice(
      0,
      currentStepNum + 1
    );
  } else {
    filteredTargetedLessonList = targetedLessonList.slice(0, currentStepNum);
  }

  let lessonIdList = filteredTargetedLessonList.map((item) => item.lessonId);

  //retrieve previously saved lessonId list & ignore save if it's completely identical or nothing to be saved(exp: access 1st lesson and click up buttom)
  let targetedLessonId = `${topicId}-${subTopicId}-${groupId}`;

  let totalToComeplete =
    achievement.currentWeekAchievement.lesson.totalToComplete;
  let totalCompleted =
    dbAuth.progressInfo.achievement && dbAuth.progressInfo.achievement.lesson
      ? Object.entries(dbAuth.progressInfo.achievement.lesson).length
      : 0;
  //console.log(totalToComeplete > totalCompleted)
  // } else if (totalToComeplete > totalCompleted) {
  if (
    ((!dbAuth.progressInfo.lessons ||
      !dbAuth.progressInfo.lessons[courseId] ||
      !dbAuth.progressInfo.lessons[courseId][targetedLessonId]) &&
      lessonIdList.length > 0) ||
    (!isAllLessonsCompleted &&
      dbAuth.progressInfo.lessons &&
      dbAuth.progressInfo.lessons[courseId] &&
      dbAuth.progressInfo.lessons[courseId][targetedLessonId] &&
      !_.isEqual(
        dbAuth.progressInfo.lessons[courseId][targetedLessonId],
        lessonIdList
      ) &&
      lessonIdList.length > 0)
  ) {
    await updateLessonProgress(
      lessonIdList,
      courseId,
      topicId,
      subTopicId,
      groupId,
      completed,
      achievement,
      dbAuth
    );
  } else if (totalToComeplete > totalCompleted && completed) {
    console.log("size not same");
    await updateLessonAchievementProgress(
      courseId,
      topicId,
      subTopicId,
      groupId,
      completed,
      achievement,
      dbAuth
    );
  } else {
    if ((topicId === "sir2000" || courseId === "expert" )&& subTopicId !== "qualities_50_opposites") window.history.back();
    else
      this.props.history.push(
        `/user-dashboard/courses/${courseId}/topics/${topicId}`
      );
  }
};

//quiz load related
export const questionPageInit = (course, dbAuth, quizId, topicId, groupId) => {
  //retrive any potential quiz progress information
  let targetedQuestionId = `${topicId}-${groupId}`;

  let targetedQuestionList = [];
  let currentStepNum = 0;
  let isAllQuestionsCompleted = false;

  if (
    dbAuth.progressInfo.quiz &&
    dbAuth.progressInfo.quiz[quizId] &&
    dbAuth.progressInfo.quiz[quizId][targetedQuestionId] &&
    dbAuth.progressInfo.quiz[quizId][targetedQuestionId].length > 0
  ) {
    let remainingQuestionList = [];
    let validSavedQuestionList = [];

    let questionIdList = dbAuth.progressInfo.quiz[quizId][targetedQuestionId];

    //ensuring previously saved questionId order
    questionIdList.forEach((questionId) => {
      let filteredQuestionId =
        course.quizQuestionInfoObj.questionGroupObj.questions.filter(
          (item) => item.questionId === questionId
        );

      if (filteredQuestionId.length > 0) {
        validSavedQuestionList.push(filteredQuestionId[0]);
      }
    });
    //==

    remainingQuestionList =
      course.quizQuestionInfoObj.questionGroupObj.questions.filter(
        (item) => !questionIdList.includes(item.questionId)
      );

    //check if all questions completed, if yes then start all over again...
    if (remainingQuestionList.length === 0) {
      targetedQuestionList =
        course.quizQuestionInfoObj.questionGroupObj.questions;
      isAllQuestionsCompleted = true;
    } else {
      targetedQuestionList = [
        ...validSavedQuestionList,
        ...remainingQuestionList,
      ];
      currentStepNum = validSavedQuestionList.length;
    }
  } else {
    targetedQuestionList =
      course.quizQuestionInfoObj.questionGroupObj.questions;
  }

  return {
    first: targetedQuestionList,
    second: currentStepNum,
    third: isAllQuestionsCompleted,
  };
};
export const _saveExamProgress = async function () {
  const { examId, currentScore, previousScore, groupId } = this.state;
  console.log(this.state);
  const { updateExamProgress } = this.props;
  if (previousScore < currentScore) {
    console.log(currentScore);
    await updateExamProgress(currentScore, examId, groupId);
  } else {
    console.log("No");
    this.props.history.push(`/user-dashboard/exam/${examId}`);
  }
};

export const _saveQuizProgress = async function () {
  const {
    quizId,
    topicId,
    groupId,
    targetedQuestionList,
    currentStepNum,
    isAllQuestionsCompleted,
  } = this.state;

  const {
    dbAuth,
    updateQuizProgress,
    achievement,
    updateQuizAchievementProgress,
  } = this.props;
  //check latest step number and decide total accessed questions
  let filteredTargetedQuestionList = [];
  let completed = currentStepNum === targetedQuestionList.length - 1;
  console.log(completed);
  this.setState({
    completed,
  });
  if (currentStepNum === targetedQuestionList.length - 1) {
    filteredTargetedQuestionList = targetedQuestionList.slice(
      0,
      currentStepNum + 1
    );
  } else {
    filteredTargetedQuestionList = targetedQuestionList.slice(
      0,
      currentStepNum
    );
  }

  let questionIdList = filteredTargetedQuestionList.map(
    (item) => item.questionId
  );

  //retrieve previously saved questionId list & ignore save if it's completely identical or nothing to be saved(exp: access 1st question and click up button)

  let targetedQuestionId = `${topicId}-${groupId}`;
  let totalToComeplete =
    achievement.currentWeekAchievement.lesson.totalToComplete;
  let totalCompleted =
    dbAuth.progressInfo.achievement && dbAuth.progressInfo.achievement.lesson
      ? Object.entries(dbAuth.progressInfo.achievement.lesson).length
      : 0;

  if (
    ((!dbAuth.progressInfo.quiz ||
      !dbAuth.progressInfo.quiz[quizId] ||
      !dbAuth.progressInfo.quiz[quizId][targetedQuestionId]) &&
      questionIdList.length > 0) ||
    (!isAllQuestionsCompleted &&
      dbAuth.progressInfo.quiz &&
      dbAuth.progressInfo.quiz[quizId] &&
      dbAuth.progressInfo.quiz[quizId][targetedQuestionId] &&
      !_.isEqual(
        dbAuth.progressInfo.quiz[quizId][targetedQuestionId],
        questionIdList
      ) &&
      questionIdList.length > 0)
  ) {
    await updateQuizProgress(
      questionIdList,
      quizId,
      topicId,
      groupId,
      completed,
      achievement,
      dbAuth
    );
  } else if (totalToComeplete > totalCompleted && completed) {
    console.log("size not same");
    await updateQuizAchievementProgress(
      questionIdList,
      quizId,
      topicId,
      groupId,
      completed,
      achievement,
      dbAuth
    );
  } else {
    this.props.history.push(
      `/user-dashboard/quiz/all-quizzes/${quizId}/topics/${topicId}`
    );
  }
};

// export const _playAudio = async function (audioElement, opsPaused, voiceoverBlobUrlList, currentAudioIdx) {
//     const {
//         targetedLessonList,
//         currentStepNum,
//         isAudioDownloadedLocally
//     } = this.state;
//     const { firebase } = this.props;
//     const storageRef = firebase.storage().ref();
//     console.log("PLAY");

//     if (
//         audioElement.currentTime === 0 ||
//         audioElement.currentTime === audioElement.duration
//     ) {
//         if (isAudioDownloadedLocally) {
//             if (opsPaused) {
//                 opsPaused = false;
//                 console.log(
//                     "RESUME FROM PAUSE",
//                     audioElement.currentTime === audioElement.duration
//                 );
//                 this.setState(
//                     {
//                         isPlayingAudio: true
//                     },
//                     () => this.playNextAudio()
//                 );
//             } else {
//                 console.log("REPEAT");
//                 this.setState(
//                     {
//                         clickDisabled: true
//                     },
//                     () => {
//                         if (voiceoverBlobUrlList[currentAudioIdx].type === "MAIN") {
//                             this.setState(
//                                 {
//                                     isPlayingAudio: true,
//                                     clickDisabled: false
//                                 },
//                                 () => {
//                                     audioElement.src =
//                                         voiceoverBlobUrlList[currentAudioIdx].blobUrl;
//                                     audioElement.play();
//                                 }
//                             );
//                         } else {
//                             this.setState(
//                                 {
//                                     highlightedItemIdx: 0,
//                                     isPlayingAudio: true,
//                                     clickDisabled: false
//                                 },
//                                 () => {
//                                     if (
//                                         voiceoverBlobUrlList[currentAudioIdx].blobUrl !== "N/A"
//                                     ) {
//                                         audioElement.src =
//                                             voiceoverBlobUrlList[currentAudioIdx].blobUrl;
//                                         audioElement.play();
//                                     } else {
//                                         setTimeout(() => this.playNextAudio(), 500);
//                                     }
//                                 }
//                             );
//                         }
//                     }
//                 );
//             }
//         } else {
//             //avoid multiple click
//             console.log("INIT");
//             this.setState(
//                 {
//                     clickDisabled: true
//                 },
//                 async () => {
//                     let voiceoverUrlPromises = [];
//                     let voiceoverTypeList = [];

//                     //save main voiceover
//                     if (targetedLessonList[currentStepNum].voiceover.length > 0) {
//                         voiceoverUrlPromises.push(
//                             storageRef
//                                 .child(targetedLessonList[currentStepNum].voiceover)
//                                 .getDownloadURL()
//                         );
//                         voiceoverTypeList.push("MAIN");
//                     }

//                     //NOTE : if there's no voiceover in content itself, disable code below
//                     if (
//                         targetedLessonList[currentStepNum].contents.some(
//                             item => item.voiceover.length > 0
//                         )
//                     ) {
//                         //save content voiceover
//                         targetedLessonList[currentStepNum].contents.forEach(item => {
//                             if (item.voiceover.length > 0) {
//                                 voiceoverUrlPromises.push(
//                                     storageRef.child(item.voiceover).getDownloadURL()
//                                 );
//                             } else {
//                                 //hack with dummy promises
//                                 voiceoverUrlPromises.push(
//                                     new Promise((resolve, _) => {
//                                         resolve("N/A");
//                                     })
//                                 );
//                             }
//                             voiceoverTypeList.push("CONTENT");
//                         });
//                     }

//                     let voiceoverUrls = await Promise.all(voiceoverUrlPromises);
//                     //console.log("CHECK  ", voiceoverUrls);

//                     //download blob at once
//                     let voiceoverDownloadPromises = [];

//                     voiceoverUrls.forEach(vUrl => {
//                         if (vUrl !== "N/A") {
//                             voiceoverDownloadPromises.push(
//                                 new Promise((resolve, reject) => {
//                                     let xhr = new XMLHttpRequest();
//                                     xhr.responseType = "blob";
//                                     xhr.onload = function (event) {
//                                         if (xhr.status === 200) {
//                                             resolve(xhr.response);
//                                         } else {
//                                             reject(xhr.status);
//                                         }
//                                     };
//                                     xhr.open("GET", vUrl);
//                                     xhr.send();
//                                 })
//                             );
//                         } else {
//                             //dummy promise again
//                             voiceoverDownloadPromises.push(
//                                 new Promise((resolve, _) => {
//                                     resolve("N/A");
//                                 })
//                             );
//                         }
//                     });

//                     //init force reset
//                     currentAudioIdx = 0;
//                     voiceoverBlobUrlList.length = 0;

//                     let downloadedBlobs = await Promise.all(voiceoverDownloadPromises);
//                     downloadedBlobs.forEach((blob, idx) => {
//                         if (blob !== "N/A") {
//                             voiceoverBlobUrlList.push({
//                                 blobUrl: URL.createObjectURL(blob),
//                                 type: voiceoverTypeList[idx]
//                             });
//                         } else {
//                             voiceoverBlobUrlList.push({
//                                 blobUrl: "N/A",
//                                 type: voiceoverTypeList[idx]
//                             });
//                         }
//                     });
//                     // audioElement.addEventListener("play", this.onAudioPlay);
//                     // audioElement.addEventListener("pause", this.onAudioPause);
//                     audioElement.addEventListener("ended", this.playNextAudio);

//                     if (voiceoverBlobUrlList[currentAudioIdx].type === "MAIN") {
//                         this.setState(
//                             {
//                                 isAudioDownloadedLocally: true,
//                                 isPlayingAudio: true,
//                                 clickDisabled: false
//                             },
//                             () => {
//                                 audioElement.src =
//                                     voiceoverBlobUrlList[currentAudioIdx].blobUrl;
//                                 audioElement.play();
//                             }
//                         );
//                     } else {
//                         this.setState(
//                             {
//                                 highlightedItemIdx: 0,
//                                 isAudioDownloadedLocally: true,
//                                 isPlayingAudio: true,
//                                 clickDisabled: false
//                             },
//                             () => {
//                                 if (voiceoverBlobUrlList[currentAudioIdx].blobUrl !== "N/A") {
//                                     audioElement.src =
//                                         voiceoverBlobUrlList[currentAudioIdx].blobUrl;
//                                     audioElement.play();
//                                 } else {
//                                     setTimeout(() => this.playNextAudio(), 500);
//                                 }
//                             }
//                         );
//                     }
//                 }
//             );
//         }
//     } else {
//         //resume playing
//         console.log("RESUME");

//         audioElement.play();
//         this.setState({
//             isPlayingAudio: true
//         });
//     }

// }
