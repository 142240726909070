import React, { Component } from "react";
import AudioVisualiser from "./AudioVisualiser";

export default class AudioAnalyser extends Component {
  state = {
    audioData: new Uint8Array(0)
  };

  componentDidMount() {
    const { mediaStream } = this.props;
    this.audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();

    this.analyser = this.audioContext.createAnalyser();
    this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);
    this.source = this.audioContext.createMediaStreamSource(mediaStream);
    this.source.connect(this.analyser);
    this.rafId = requestAnimationFrame(this.tick);
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.rafId);
    this.analyser.disconnect();
    this.source.disconnect();
  }

  tick = () => {
    this.analyser.getByteTimeDomainData(this.dataArray);
    this.setState({ audioData: this.dataArray });
    this.rafId = requestAnimationFrame(this.tick);
  };

  render() {
    const { audioData } = this.state;
    return <AudioVisualiser audioData={audioData} />;
  }
}
