import React, { Component, Fragment } from "react";
import "./ContentType2LessonPage.css";
import { compose } from "redux";
import { connect } from "react-redux";
import { Button, Col, Row, Progress, Spin } from "antd";
import { withRouter } from "react-router-dom";
import {
  getEnText,
  getNativeLangTransText,
  lessonPageInit,
  _saveProgress,
} from "../../../../SharedUtils";
import { withFirebase } from "react-redux-firebase";
import SelfRecording from "../../../SelfRecording/SelfRecording";
import {
  updateLessonProgress,
  updateLessonAchievementProgress,
} from "../../../../auth/authActions";
import _ from "lodash";
import { operationTypeEnum } from "../../../../OperationTypeConstant";
import LoadingComponent from "../../../../app/common/loading/LoadingComponent";

let voiceoverBlobUrlList = [];
let audioElement = new Audio();
let currentAudioIdx = 0;
let opsPaused = false;

let audioElementLT = new Audio();
let blobUrlLT = "";

//serve sir2000 and 44 basic sound(under introduction to english) for now
class ContentType2LessonPage extends Component {
  constructor(props) {
    super(props);
    this._saveProgress = _saveProgress.bind(this);
  }

  state = {
    targetedLessonList: [],
    currentStepNum: 0,
    isPlayingAudio: false,
    highlightedItemIdx: -1,
    isAudioDownloadedLocally: false,
    clickDisabled: false,
    isAllLessonsCompleted: false,
    isListeningTestPage: false,
    isLoading: false,
    listeningTestObj: {
      correctAnswerIdx: -1,
      tappedAnswerIdx: -1,
      isAnswerRevealed: false,
    },
  };

  componentDidMount() {
    const { course, match, dbAuth } = this.props;

    let courseId = match.params.courseId;
    let topicId = match.params.topicId;
    let subTopicId = match.params.subTopicId;
    let groupId = match.params.groupId;

    let { first, second, third } = lessonPageInit(
      course,
      dbAuth,
      courseId,
      topicId,
      subTopicId,
      groupId
    );

    this.setState({
      targetedLessonList: first,
      currentStepNum: second,
      isAllLessonsCompleted: third,
      courseId,
      topicId,
      subTopicId,
      groupId,
    });
  }

  componentWillUnmount() {
    audioElement.removeEventListener("ended", this.playNextAudio);
  }

  componentDidUpdate(prevProps) {
    const { courseId, topicId, completed, subTopicId, groupId } = this.state;
    const { operation, dbAuth, achievement } = this.props;
    if (
      !_.isEqual(prevProps.operation.isProcessing, operation.isProcessing) &&
      !operation.isProcessing
    ) {
      if (
        operation.operationType === operationTypeEnum.NO_OPS ||
        (prevProps.operation.operationType ===
          operationTypeEnum.UPDATE_LESSON_PROGRESS &&
          operation.operationType === operationTypeEnum.NO_OPS)
      ) {
        if (
          dbAuth.progressInfo.achievement &&
          dbAuth.progressInfo.achievement.lesson
        ) {
          let totaltoCompelete =
            achievement.currentWeekAchievement.lesson.totalToComplete;
          let totalCompleted = Object.entries(
            dbAuth.progressInfo.achievement.lesson
          ).length;
          if (totaltoCompelete >= totalCompleted && completed) {
            this.props.history.push(
              `/user-dashboard/courses/${courseId}/topics/${topicId}/subTopics/${subTopicId}/lessons/${groupId}/lesson_achievement`
            );
            let buttonSound = new Audio("/assets/Lesson_done.mp3");
            buttonSound.play();
          } else {
            if (topicId === "sir2000") window.history.back();
            else
              this.props.history.push(
                `/user-dashboard/courses/${courseId}/topics/${topicId}`
              );
          }
        } else {
          if (topicId === "sir2000") window.history.back();
          else
            this.props.history.push(
              `/user-dashboard/courses/${courseId}/topics/${topicId}`
            );
        }
      }
    }
  }

  onHandleSoundIconClick = async () => {
    const { targetedLessonList, currentStepNum, isAudioDownloadedLocally } =
      this.state;
    const { firebase } = this.props;
    const storageRef = firebase.storage().ref();
    console.log("PLAY");

    if (
      audioElement.currentTime === 0 ||
      audioElement.currentTime === audioElement.duration
    ) {
      if (isAudioDownloadedLocally) {
        if (opsPaused) {
          opsPaused = false;
          console.log(
            "RESUME FROM PAUSE",
            audioElement.currentTime === audioElement.duration
          );
          this.setState(
            {
              clickDisabled: true,
            },
            () => {
              this.setState(
                {
                  isPlayingAudio: true,
                  clickDisabled: false,
                },
                () => {
                  this.playNextAudio();
                }
              );
            }
          );
          // this.setState(
          //   {
          //     isPlayingAudio: true
          //   },
          //   () => this.playNextAudio()
          // );
        } else {
          console.log("REPEAT");
          this.setState(
            {
              clickDisabled: true,
            },
            () => {
              if (voiceoverBlobUrlList[currentAudioIdx].type === "MAIN") {
                this.setState(
                  {
                    isPlayingAudio: true,
                    clickDisabled: false,
                  },
                  () => {
                    audioElement.src =
                      voiceoverBlobUrlList[currentAudioIdx].blobUrl;
                    audioElement.play();
                  }
                );
              } else {
                this.setState(
                  {
                    highlightedItemIdx: 0,
                    isPlayingAudio: true,
                    clickDisabled: false,
                  },
                  () => {
                    if (
                      voiceoverBlobUrlList[currentAudioIdx].blobUrl !== "N/A"
                    ) {
                      audioElement.src =
                        voiceoverBlobUrlList[currentAudioIdx].blobUrl;
                      audioElement.play();
                    } else {
                      setTimeout(() => this.playNextAudio(), 500);
                    }
                  }
                );
              }
            }
          );
        }
      } else {
        //avoid multiple click
        console.log("INIT");
        this.setState(
          {
            clickDisabled: true,
          },
          async () => {
            let voiceoverUrlPromises = [];
            let voiceoverTypeList = [];

            //save main voiceover
            if (targetedLessonList[currentStepNum].voiceover.length > 0) {
              voiceoverUrlPromises.push(
                storageRef
                  .child(targetedLessonList[currentStepNum].voiceover)
                  .getDownloadURL()
              );
              voiceoverTypeList.push("MAIN");
            }

            //NOTE : if there's no voiceover in content itself, disable code below
            if (
              targetedLessonList[currentStepNum].contents.some(
                (item) => item.voiceover.length > 0
              )
            ) {
              //save content voiceover
              targetedLessonList[currentStepNum].contents.forEach((item) => {
                if (item.voiceover.length > 0) {
                  voiceoverUrlPromises.push(
                    storageRef.child(item.voiceover).getDownloadURL()
                  );
                } else {
                  //hack with dummy promises
                  voiceoverUrlPromises.push(
                    new Promise((resolve, _) => {
                      resolve("N/A");
                    })
                  );
                }
                voiceoverTypeList.push("CONTENT");
              });
            }

            let voiceoverUrls = await Promise.all(voiceoverUrlPromises);
            //console.log("CHECK  ", voiceoverUrls);

            //download blob at once
            let voiceoverDownloadPromises = [];

            voiceoverUrls.forEach((vUrl) => {
              if (vUrl !== "N/A") {
                voiceoverDownloadPromises.push(
                  new Promise((resolve, reject) => {
                    let xhr = new XMLHttpRequest();
                    xhr.responseType = "blob";
                    xhr.onload = function (event) {
                      if (xhr.status === 200) {
                        resolve(xhr.response);
                      } else {
                        reject(xhr.status);
                      }
                    };
                    xhr.open("GET", vUrl);
                    xhr.send();
                  })
                );
              } else {
                //dummy promise again
                voiceoverDownloadPromises.push(
                  new Promise((resolve, _) => {
                    resolve("N/A");
                  })
                );
              }
            });

            //init force reset
            currentAudioIdx = 0;
            voiceoverBlobUrlList.length = 0;

            let downloadedBlobs = await Promise.all(voiceoverDownloadPromises);
            downloadedBlobs.forEach((blob, idx) => {
              if (blob !== "N/A") {
                voiceoverBlobUrlList.push({
                  blobUrl: URL.createObjectURL(blob),
                  type: voiceoverTypeList[idx],
                });
              } else {
                voiceoverBlobUrlList.push({
                  blobUrl: "N/A",
                  type: voiceoverTypeList[idx],
                });
              }
            });
            // audioElement.addEventListener("play", this.onAudioPlay);
            // audioElement.addEventListener("pause", this.onAudioPause);
            audioElement.addEventListener("ended", this.playNextAudio);

            if (voiceoverBlobUrlList[currentAudioIdx].type === "MAIN") {
              this.setState(
                {
                  isAudioDownloadedLocally: true,
                  isPlayingAudio: true,
                  clickDisabled: false,
                },
                () => {
                  audioElement.src =
                    voiceoverBlobUrlList[currentAudioIdx].blobUrl;
                  audioElement.play();
                }
              );
            } else {
              this.setState(
                {
                  highlightedItemIdx: 0,
                  isAudioDownloadedLocally: true,
                  isPlayingAudio: true,
                  clickDisabled: false,
                },
                () => {
                  if (voiceoverBlobUrlList[currentAudioIdx].blobUrl !== "N/A") {
                    audioElement.src =
                      voiceoverBlobUrlList[currentAudioIdx].blobUrl;
                    audioElement.play();
                  } else {
                    setTimeout(() => this.playNextAudio(), 1000);
                  }
                }
              );
            }
          }
        );
      }
    } else {
      //resume playing
      console.log("RESUME");

      audioElement.play();
      this.setState({
        isPlayingAudio: true,
      });
    }
  };

  onHandlePauseIconClick = () => {
    console.log("PAUSE");
    audioElement.pause();
    this.setState({
      isPlayingAudio: false,
    });
  };

  onUpButtonClick = () => {
    //reset audio to prevent audio resume in next re-entrance
    audioElement.currentTime = 0;
    audioElement.pause();

    this._saveProgress();
  };

  _getRandomSoundIdx = () => {
    const { targetedLessonList, currentStepNum } = this.state;

    //recursive approach to get voice over
    let randomIdx = Math.floor(
      Math.random() * targetedLessonList[currentStepNum].contents.length
    );

    if (
      targetedLessonList[currentStepNum].contents[randomIdx].voiceover.length >
      0
    ) {
      return randomIdx;
    } else {
      return this._getRandomSoundIdx();
    }
  };

  onPreviousButtonClick = async () => {
    let selectButtonSound = new Audio("/assets/Select_sound03.mp3");
    selectButtonSound.play();
    const { currentStepNum, topicId } = this.state;

    if (currentStepNum === 0) {
      this.onUpButtonClick();
    }
    //since only sir2000has quick listening test in course lesson, hardcode implementation for now(unless supported by structure in future)
    else if (topicId === "sir2000") {
      window.scrollTo(0, 0);

      this.setState((prevState) => ({
        currentStepNum: prevState.currentStepNum - 1,

        isPlayingAudio: false,
        highlightedItemIdx: -1,
        isAudioDownloadedLocally: false,

        listeningTestObj: {
          correctAnswerIdx: -1,
          tappedAnswerIdx: -1,
          isAnswerRevealed: false,
        },
      }));
    } else {
      window.scrollTo(0, 0);

      audioElement.currentTime = 0;
      audioElement.pause();

      this.setState((prevState) => ({
        currentStepNum: prevState.currentStepNum - 1,

        isPlayingAudio: false,
        highlightedItemIdx: -1,
        isAudioDownloadedLocally: false,
      }));
    }
  };

  onNextButtonClick = async () => {
    let selectButtonSound = new Audio("/assets/Select_sound03.mp3");
    selectButtonSound.play();
    const { targetedLessonList, currentStepNum, topicId, isListeningTestPage } =
      this.state;
    const { firebase } = this.props;
    const storageRef = firebase.storage().ref();

    //since only sir2000has quick listening test in course lesson, hardcode implementation for now(unless supported by structure in future)
    if (topicId === "sir2000") {
      if (!isListeningTestPage) {
        //pick an random audio to play in listening page(only from list with voice over)
        this.setState({ isLoading: true }, async () => {
          let randomIdx = this._getRandomSoundIdx();

          //get url
          let voiceoverUrl = await storageRef
            .child(
              targetedLessonList[currentStepNum].contents[randomIdx].voiceover
            )
            .getDownloadURL();

          //download it upfront
          let randomAudioPromise = new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.responseType = "blob";
            xhr.onload = function (event) {
              if (xhr.status === 200) {
                resolve(xhr.response);
              } else {
                reject(xhr.status);
              }
            };
            xhr.open("GET", voiceoverUrl);
            xhr.send();
          });

          let downloadedBlob = await randomAudioPromise;
          blobUrlLT = URL.createObjectURL(downloadedBlob);

          audioElementLT.src = blobUrlLT;
          audioElementLT.play();

          window.scrollTo(0, 0);

          this.setState((prevState) => ({
            isListeningTestPage: true,
            listeningTestObj: {
              ...prevState.listeningTestObj,
              correctAnswerIdx: randomIdx,
            },

            isPlayingAudio: false,
            highlightedItemIdx: -1,
            isAudioDownloadedLocally: false,
            isLoading: false,
          }));
        });
      } else {
        window.scrollTo(0, 0);

        if (currentStepNum === targetedLessonList.length - 1) {
          //Note : below equivalent to currentStepNumber + 1 === targetedLessonList.length
          //save full progress here...
          this._saveProgress();
        } else {
          this.setState((prevState) => ({
            currentStepNum: prevState.currentStepNum + 1,

            isPlayingAudio: false,
            highlightedItemIdx: -1,
            isAudioDownloadedLocally: false,

            isListeningTestPage: !prevState.isListeningTestPage,
            listeningTestObj: {
              correctAnswerIdx: -1,
              tappedAnswerIdx: -1,
              isAnswerRevealed: false,
            },
          }));
        }
      }
    } else {
      if (currentStepNum === targetedLessonList.length - 1) {
        //Note : below equivalent to currentStepNumber + 1 === targetedLessonList.length
        //save full progress here...
        this._saveProgress();
      } else {
        window.scrollTo(0, 0);

        audioElement.currentTime = 0;
        audioElement.pause();

        this.setState((prevState) => ({
          currentStepNum: prevState.currentStepNum + 1,

          isPlayingAudio: false,
          highlightedItemIdx: -1,
          isAudioDownloadedLocally: false,
        }));
      }
    }
  };

  // _saveProgress = async () => {
  //   const {
  //     courseId,
  //     topicId,
  //     subTopicId,
  //     groupId,
  //     targetedLessonList,
  //     currentStepNum,

  //     isAllLessonsCompleted
  //   } = this.state;

  //   const { dbAuth, updateLessonProgress } = this.props;

  //   //check latest step number and decide total accessed lessons
  //   let filteredTargetedLessonList = [];
  //   if (currentStepNum === targetedLessonList.length - 1) {
  //     filteredTargetedLessonList = targetedLessonList.slice(
  //       0,
  //       currentStepNum + 1
  //     );
  //   } else {
  //     filteredTargetedLessonList = targetedLessonList.slice(0, currentStepNum);
  //   }

  //   let lessonIdList = filteredTargetedLessonList.map(item => item.lessonId);

  //   //retrieve previously saved lessonId list & ignore save if it's completely identical or nothing to be saved(exp: access 1st lesson and click up buttom)
  //   let targetedLessonId = `${topicId}-${subTopicId}-${groupId}`;

  //   if (
  //     !dbAuth.progressInfo.lessons ||
  //     !dbAuth.progressInfo.lessons[courseId] ||
  //     !dbAuth.progressInfo.lessons[courseId][targetedLessonId] ||
  //     (!isAllLessonsCompleted &&
  //       !_.isEqual(
  //         dbAuth.progressInfo.lessons[courseId][targetedLessonId],
  //         lessonIdList
  //       ) &&
  //       lessonIdList.length > 0)
  //   ) {
  //     await updateLessonProgress(
  //       lessonIdList,
  //       courseId,
  //       topicId,
  //       subTopicId,
  //       groupId
  //     );
  //   } else {
  //     this.props.history.push(
  //       `/user-dashboard/courses/${courseId}/topics/${topicId}`
  //     );
  //   }
  // };

  playNextAudio = () => {
    setTimeout(() => {
      //here pause flag is also true if audio completed
      if (
        audioElement.paused &&
        (audioElement.currentTime === 0 ||
          audioElement.currentTime === audioElement.duration) &&
        this.state.isPlayingAudio //access directly from latest state to potential old value
      ) {
        currentAudioIdx++;

        if (currentAudioIdx === voiceoverBlobUrlList.length) {
          //reset
          currentAudioIdx = 0;
          this.setState({
            highlightedItemIdx: -1,
            isPlayingAudio: false,
          });
        } else {
          if (voiceoverBlobUrlList[currentAudioIdx].type === "CONTENT") {
            this.setState(
              (prevState) => ({
                highlightedItemIdx: prevState.highlightedItemIdx + 1,
              }),
              () => {
                if (voiceoverBlobUrlList[currentAudioIdx].blobUrl === "N/A") {
                  setTimeout(() => this.playNextAudio(), 500);
                } else {
                  audioElement.src =
                    voiceoverBlobUrlList[currentAudioIdx].blobUrl;
                  audioElement.play();
                }
              }
            );
          } else {
            audioElement.src = voiceoverBlobUrlList[currentAudioIdx].blobUrl;
            audioElement.play();
          }
        }
      } else {
        //NOTE:special flag to resume delayed operation...
        opsPaused = true;
      }
    }, 500);
  };

  onHandleListeningTestSoundIconClick = () => {
    audioElementLT.play();
  };

  captureTappedAnswerIdx = (idx) => {
    this.setState((prevState) => ({
      listeningTestObj: {
        ...prevState.listeningTestObj,
        tappedAnswerIdx: idx,
      },
    }));
  };

  onCheckAnswerButtonClick = () => {
    this.setState((prevState) => ({
      listeningTestObj: {
        ...prevState.listeningTestObj,
        isAnswerRevealed: true,
      },
    }));
  };

  render() {
    const {
      targetedLessonList,
      currentStepNum,
      highlightedItemIdx,
      isPlayingAudio,
      clickDisabled,
      isListeningTestPage,
      listeningTestObj,
      isLoading,
    } = this.state;
    const { translation, dbAuth, operation, configuration } = this.props;

    if (targetedLessonList.length === 0) return <LoadingComponent />;
    else if (isLoading) return <LoadingComponent />;
    return (
      <Fragment>
        <Spin
          tip="Saving lesson progress..."
          spinning={operation.isProcessing}
          size="large"
          // style={{ marginTop: "256px" }}
        >
          {isListeningTestPage ? (
            <Row>
              <Col xs={2} sm={4} md={5} lg={6} xl={6} xxl={6} />
              <Col
                xs={20}
                sm={16}
                md={14}
                lg={12}
                xl={12}
                xxl={12}
                style={{
                  textAlign: "center",
                  marginTop: "32px",
                  // height: "850px"
                }}
              >
                <div style={{ display: "flex" }}>
                  {/* <Link
                  to={`/user-dashboard/courses/${courseId}/topics/${topicId}/subTopics/${subTopicId}`}
                >
                  <img
                    src="/assets/back-arrow-icon.svg"
                    alt=""
                    style={{ marginRight: "32px", cursor: "pointer" }}
                  />
                </Link> */}
                  <img
                    src="/assets/back-arrow-icon.svg"
                    alt=""
                    style={{ marginRight: "32px", cursor: "pointer" }}
                    onClick={this.onUpButtonClick}
                  />
                  <Progress
                    percent={
                      ((currentStepNum + 1) / targetedLessonList.length) * 100
                    }
                    status="normal"
                    showInfo={false}
                    strokeColor={{ "0%": "#82ABF7", "100%": "#4252BE" }}
                    strokeWidth="50px"
                    style={{ marginBottom: "40px" }}
                  />
                </div>
                <div
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "23px",
                    marginBottom: "40px",
                  }}
                >
                  {
                    getNativeLangTransText(
                      dbAuth.userInfo.nativeLanguage,
                      configuration.projectUI.tap_what_you_hear,
                      translation.translationObj
                    ).text
                  }
                </div>

                <img
                  src="/assets/sound-icon.svg"
                  alt=""
                  style={{ cursor: "pointer", marginBottom: "40px" }}
                  onClick={this.onHandleListeningTestSoundIconClick}
                />

                <Row justify="space-around">
                  {targetedLessonList[currentStepNum].contents.map(
                    (item, idx) => (
                      <Col key={idx} span={4}>
                        <img
                          src={
                            listeningTestObj.isAnswerRevealed
                              ? idx === listeningTestObj.correctAnswerIdx
                                ? "/assets/checked-circle-icon.svg"
                                : "/assets/clear-circle-icon.svg"
                              : "/assets/checked-circle-icon.svg"
                          }
                          alt=""
                          style={{
                            width: "30px",
                            height: "30px",
                            visibility: listeningTestObj.isAnswerRevealed
                              ? idx === listeningTestObj.correctAnswerIdx ||
                                idx === listeningTestObj.tappedAnswerIdx
                                ? "visible"
                                : "hidden"
                              : "hidden",
                          }}
                        />

                        <div
                          className={
                            "contentType2-listening-test-mode" +
                            (listeningTestObj.tappedAnswerIdx === idx
                              ? " selected"
                              : " not-selected") +
                            (listeningTestObj.isAnswerRevealed
                              ? idx === listeningTestObj.correctAnswerIdx
                                ? " correct"
                                : " not-correct"
                              : "")
                          }
                          style={{
                            cursor: "pointer",
                            fontSize: "20px",
                            borderRadius: "16px",
                            marginTop: "8px",
                          }}
                          onClick={
                            listeningTestObj.isAnswerRevealed
                              ? null
                              : () => this.captureTappedAnswerIdx(idx)
                          }
                        >
                          {getEnText(item.sentence, translation.translationObj)
                            .isHtml ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getEnText(
                                  item.sentence,
                                  translation.translationObj
                                ).text,
                              }}
                            />
                          ) : (
                            <span style={{ fontFamily: "Poppins-Regular" }}>
                              {
                                getEnText(
                                  item.sentence,
                                  translation.translationObj
                                ).text
                              }
                            </span>
                          )}
                        </div>
                      </Col>
                    )
                  )}
                </Row>
              </Col>
              <Col span={6} />
              <Col
                span={24}
                className="footer"
                style={{
                  borderTop: "4px solid #F1F1F1",
                }}
              >
                {listeningTestObj.isAnswerRevealed ? (
                  <Button
                    type="primary"
                    style={{
                      margin: "24px 0px",
                      borderRadius: "24px",
                      height: "auto",
                      fontFamily: "Poppins-Bold",
                      fontSize: "23px",
                      width: "250px",
                      boxShadow: "0px 6px 0  #2A3580",
                    }}
                    onClick={this.onNextButtonClick}
                  >
                    {
                      getNativeLangTransText(
                        dbAuth.userInfo.nativeLanguage,
                        configuration.projectUI.next,
                        translation.translationObj
                      ).text
                    }
                  </Button>
                ) : (
                  <Button
                    disabled={listeningTestObj.tappedAnswerIdx === -1}
                    type="primary"
                    style={{
                      margin: "24px 0px",
                      borderRadius: "24px",
                      height: "auto",
                      fontFamily: "Poppins-Bold",
                      fontSize: "23px",
                      width: "250px",
                      boxShadow:
                        listeningTestObj.tappedAnswerIdx === -1
                          ? "0px 6px 0 #D4D4D4"
                          : "0px 6px 0 #2A3580",
                    }}
                    onClick={this.onCheckAnswerButtonClick}
                  >
                    {
                      getNativeLangTransText(
                        dbAuth.userInfo.nativeLanguage,
                        configuration.projectUI.check,
                        translation.translationObj
                      ).text
                    }
                  </Button>
                )}
                {/* {console.log(listeningTestObj)} */}
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs={3} sm={4} md={5} lg={5} xl={5} xxl={5} />
              <Col
                xs={18}
                sm={16}
                md={14}
                lg={14}
                xl={14}
                xxl={14}
                style={{
                  textAlign: "center",
                  marginTop: "32px",
                  // height: "850px"
                }}
              >
                <div style={{ display: "flex" }}>
                  {/* <Link
                to={`/user-dashboard/courses/${courseId}/topics/${topicId}`}
              > */}
                  <img
                    src="/assets/back-arrow-icon.svg"
                    alt=""
                    style={{
                      marginRight: "32px",
                      cursor: "pointer",
                      marginBottom: "40px",
                    }}
                    onClick={this.onUpButtonClick}
                  />
                  {/* </Link> */}
                  <Progress
                    percent={
                      ((currentStepNum + 1) / targetedLessonList.length) * 100
                    }
                    status="normal"
                    showInfo={false}
                    strokeColor={{ "0%": "#82ABF7", "100%": "#4252BE" }}
                    strokeWidth="50px"
                    style={{ marginBottom: "40px" }}
                  />
                </div>

                {isPlayingAudio ? (
                  <Col span={24}>
                    <img
                      src="/assets/pause-icon.svg"
                      alt=""
                      onClick={this.onHandlePauseIconClick}
                      className="sound-button"
                    />
                  </Col>
                ) : (
                  <Col span={24}>
                    <img
                      src="/assets/sound-icon.svg"
                      alt=""
                      onClick={
                        clickDisabled ? null : this.onHandleSoundIconClick
                      }
                      className="sound-button"
                    />
                  </Col>
                )}
                {dbAuth.userInfo.nativeLanguage === "en" ? (
                  <Row style={{ fontSize: "28px" }}>
                    <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} />
                    <Col
                      xs={0}
                      sm={0}
                      md={20}
                      lg={20}
                      xl={20}
                      xxl={20}
                      style={{ paddingTop: "25px" }}
                    >
                      {getEnText(
                        targetedLessonList[currentStepNum].title,
                        translation.translationObj
                      ).isHtml ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: getEnText(
                              targetedLessonList[currentStepNum].title,
                              translation.translationObj
                            ).text,
                          }}
                        />
                      ) : (
                        <span style={{ fontFamily: "Poppins-Regular" }}>
                          {
                            getEnText(
                              targetedLessonList[currentStepNum].title,
                              translation.translationObj
                            ).text
                          }
                        </span>
                      )}

                      {targetedLessonList[currentStepNum].contents.map(
                        (item, idx) => (
                          <Fragment key={idx}>
                            <br />
                            {getEnText(
                              item.sentence,
                              translation.translationObj
                            ).isHtml ? (
                              <span
                                className={
                                  "contentType2" +
                                  (highlightedItemIdx === idx
                                    ? " highlighted"
                                    : " not-highlighted")
                                }
                                dangerouslySetInnerHTML={{
                                  __html: getEnText(
                                    item.sentence,
                                    translation.translationObj
                                  ).text,
                                }}
                              />
                            ) : (
                              <span
                                className={
                                  "contentType2" +
                                  (highlightedItemIdx === idx
                                    ? " highlighted"
                                    : " not-highlighted")
                                }
                                style={{ fontFamily: "Poppins-Bold" }}
                              >
                                {
                                  getEnText(
                                    item.sentence,
                                    translation.translationObj
                                  ).text
                                }
                              </span>
                            )}
                          </Fragment>
                        )
                      )}
                    </Col>
                    <Col
                      xs={20}
                      sm={20}
                      md={0}
                      lg={0}
                      xl={0}
                      xxl={0}
                      style={{ fontSize: "20px", paddingTop: "25px" }}
                    >
                      {getEnText(
                        targetedLessonList[currentStepNum].title,
                        translation.translationObj
                      ).isHtml ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: getEnText(
                              targetedLessonList[currentStepNum].title,
                              translation.translationObj
                            ).text,
                          }}
                        />
                      ) : (
                        <span
                          style={{
                            fontFamily: "Poppins-Regular",
                          }}
                        >
                          {
                            getEnText(
                              targetedLessonList[currentStepNum].title,
                              translation.translationObj
                            ).text
                          }
                        </span>
                      )}

                      {targetedLessonList[currentStepNum].contents.map(
                        (item, idx) => (
                          <Fragment key={idx}>
                            <br />
                            {getEnText(
                              item.sentence,
                              translation.translationObj
                            ).isHtml ? (
                              <span
                                className={
                                  "contentType2" +
                                  (highlightedItemIdx === idx
                                    ? " highlighted"
                                    : " not-highlighted")
                                }
                                dangerouslySetInnerHTML={{
                                  __html: getEnText(
                                    item.sentence,
                                    translation.translationObj
                                  ).text,
                                }}
                              />
                            ) : (
                              <span
                                className={
                                  "contentType2" +
                                  (highlightedItemIdx === idx
                                    ? " highlighted"
                                    : " not-highlighted")
                                }
                                style={{ fontFamily: "Poppins-Bold" }}
                              >
                                {
                                  getEnText(
                                    item.sentence,
                                    translation.translationObj
                                  ).text
                                }
                              </span>
                            )}
                          </Fragment>
                        )
                      )}
                    </Col>
                  </Row>
                ) : (
                  <Row
                    style={{
                      fontSize: "28px",
                      textAlign: "left",
                    }}
                  >
                    <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} />
                    <Col xs={0} sm={0} md={11} lg={11} xl={11} xxl={11}>
                      {getEnText(
                        targetedLessonList[currentStepNum].title,
                        translation.translationObj
                      ).isHtml ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: getEnText(
                              targetedLessonList[currentStepNum].title,
                              translation.translationObj
                            ).text,
                          }}
                        />
                      ) : (
                        <span style={{ fontFamily: "Poppins-Bold" }}>
                          {
                            getEnText(
                              targetedLessonList[currentStepNum].title,
                              translation.translationObj
                            ).text
                          }
                        </span>
                      )}

                      {targetedLessonList[currentStepNum].contents.map(
                        (item, idx) => (
                          <Fragment key={idx}>
                            <br />
                            {getEnText(
                              item.sentence,
                              translation.translationObj
                            ).isHtml ? (
                              <span
                                className={
                                  "contentType2" +
                                  (highlightedItemIdx === idx
                                    ? " highlighted"
                                    : " not-highlighted")
                                }
                                dangerouslySetInnerHTML={{
                                  __html: getEnText(
                                    item.sentence,
                                    translation.translationObj
                                  ).text,
                                }}
                              />
                            ) : (
                              <span
                                className={
                                  "contentType2" +
                                  (highlightedItemIdx === idx
                                    ? " highlighted"
                                    : " not-highlighted")
                                }
                                style={{ fontFamily: "Poppins-Bold" }}
                              >
                                {
                                  getEnText(
                                    item.sentence,
                                    translation.translationObj
                                  ).text
                                }
                              </span>
                            )}
                          </Fragment>
                        )
                      )}
                    </Col>

                    <Col
                      xs={20}
                      sm={20}
                      md={0}
                      lg={0}
                      xl={0}
                      xxl={0}
                      style={{ fontSize: "20px" }}
                    >
                      {getEnText(
                        targetedLessonList[currentStepNum].title,
                        translation.translationObj
                      ).isHtml ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: getEnText(
                              targetedLessonList[currentStepNum].title,
                              translation.translationObj
                            ).text,
                          }}
                        />
                      ) : (
                        <span
                          style={{
                            fontFamily: "Poppins-Bold",
                          }}
                        >
                          {
                            getEnText(
                              targetedLessonList[currentStepNum].title,
                              translation.translationObj
                            ).text
                          }
                        </span>
                      )}

                      {targetedLessonList[currentStepNum].contents.map(
                        (item, idx) => (
                          <Fragment key={idx}>
                            <br />
                            {getEnText(
                              item.sentence,
                              translation.translationObj
                            ).isHtml ? (
                              <span
                                className={
                                  "contentType2" +
                                  (highlightedItemIdx === idx
                                    ? " highlighted"
                                    : " not-highlighted")
                                }
                                dangerouslySetInnerHTML={{
                                  __html: getEnText(
                                    item.sentence,
                                    translation.translationObj
                                  ).text,
                                }}
                              />
                            ) : (
                              <span
                                className={
                                  "contentType2" +
                                  (highlightedItemIdx === idx
                                    ? " highlighted"
                                    : " not-highlighted")
                                }
                                style={{ fontFamily: "Poppins-Bold" }}
                              >
                                {
                                  getEnText(
                                    item.sentence,
                                    translation.translationObj
                                  ).text
                                }
                              </span>
                            )}
                          </Fragment>
                        )
                      )}
                    </Col>
                    <Col xs={2} sm={2} md={0} lg={0} xl={0} xxl={0} />
                    <Col xs={2} sm={2} md={0} lg={0} xl={0} xxl={0} />
                    {/* native language  */}
                    <Col
                      xs={0}
                      sm={0}
                      md={11}
                      lg={11}
                      xl={11}
                      xxl={11}
                      style={{ fontStyle: "italic" }}
                    >
                      {dbAuth.userInfo.nativeLanguage !== "en" &&
                      getNativeLangTransText(
                        dbAuth.userInfo.nativeLanguage,
                        targetedLessonList[currentStepNum].title,
                        translation.translationObj
                      ).isHtml ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              targetedLessonList[currentStepNum].title,
                              translation.translationObj
                            ).text,
                          }}
                        />
                      ) : (
                        <span style={{ fontFamily: "Poppins-Regular" }}>
                          {
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              targetedLessonList[currentStepNum].title,
                              translation.translationObj
                            ).text
                          }
                        </span>
                      )}

                      {targetedLessonList[currentStepNum].contents.map(
                        (item, idx) => (
                          <Fragment key={idx}>
                            <br />
                            {getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              item.sentence,
                              translation.translationObj
                            ).isHtml ? (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: getNativeLangTransText(
                                    dbAuth.userInfo.nativeLanguage,
                                    item.sentence,
                                    translation.translationObj
                                  ).text,
                                }}
                              />
                            ) : (
                              <span style={{ fontFamily: "Poppins-Regular" }}>
                                {
                                  getNativeLangTransText(
                                    dbAuth.userInfo.nativeLanguage,
                                    item.sentence,
                                    translation.translationObj
                                  ).text
                                }
                              </span>
                            )}
                          </Fragment>
                        )
                      )}
                    </Col>
                    {/* native language small */}
                    <Col
                      xs={20}
                      sm={20}
                      md={0}
                      lg={0}
                      xl={0}
                      xxl={0}
                      style={{ fontStyle: "italic", fontSize: "20px" }}
                    >
                      {dbAuth.userInfo.nativeLanguage !== "en" &&
                      getNativeLangTransText(
                        dbAuth.userInfo.nativeLanguage,
                        targetedLessonList[currentStepNum].title,
                        translation.translationObj
                      ).isHtml ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              targetedLessonList[currentStepNum].title,
                              translation.translationObj
                            ).text,
                          }}
                        />
                      ) : (
                        <span style={{ fontFamily: "Poppins-Regular" }}>
                          {
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              targetedLessonList[currentStepNum].title,
                              translation.translationObj
                            ).text
                          }
                        </span>
                      )}

                      {targetedLessonList[currentStepNum].contents.map(
                        (item, idx) => (
                          <Fragment key={idx}>
                            <br />
                            {getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              item.sentence,
                              translation.translationObj
                            ).isHtml ? (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: getNativeLangTransText(
                                    dbAuth.userInfo.nativeLanguage,
                                    item.sentence,
                                    translation.translationObj
                                  ).text,
                                }}
                              />
                            ) : (
                              <span style={{ fontFamily: "Poppins-Regular" }}>
                                {
                                  getNativeLangTransText(
                                    dbAuth.userInfo.nativeLanguage,
                                    item.sentence,
                                    translation.translationObj
                                  ).text
                                }
                              </span>
                            )}
                          </Fragment>
                        )
                      )}
                    </Col>
                  </Row>
                )}
              </Col>
              <Col
                xs={24}
                sm={0}
                md={0}
                lg={0}
                xl={0}
                xxl={0}
                style={{
                  textAlign: "center",
                  marginTop: "40px",
                  marginBottom: "240px",
                }}
              >
                <SelfRecording currentStepNum={currentStepNum} />
              </Col>
              <Col
                xs={0}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                xxl={0}
                style={{
                  textAlign: "center",
                  marginTop: "40px",
                  //marginBottom: currentStepNum === 0 ? "120px" : "240px"
                }}
              >
                <SelfRecording currentStepNum={currentStepNum} />
              </Col>
              <Col span={24} className="footer">
                <Col
                  xs={0}
                  sm={0}
                  md={0}
                  lg={0}
                  xl={0}
                  xxl={24}
                  style={{ paddingBottom: "24px" }}
                >
                  <SelfRecording currentStepNum={currentStepNum} />
                </Col>
                <Col
                  xs={0}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  style={{ borderTop: "4px solid #F1F1F1" }}
                >
                  {currentStepNum === 0 ? null : (
                    <Button
                      type="primary"
                      style={{
                        margin: "24px 10px",
                        borderRadius: "24px",
                        height: "auto",
                        fontFamily: "Poppins-Bold",
                        fontSize: "23px",
                        width: "250px",
                        boxShadow: "0px 6px 0  #2A3580",
                      }}
                      onClick={this.onPreviousButtonClick}
                    >
                      {
                        getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          configuration.projectUI.previous,
                          translation.translationObj
                        ).text
                      }
                      {/* {
                        getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          configuration.projectUI.previous,
                          translation.translationObj
                        ).text
                      } */}
                    </Button>
                  )}

                  <Button
                    type="primary"
                    style={{
                      margin: "24px 0px",
                      borderRadius: "24px",
                      height: "auto",
                      fontFamily: "Poppins-Bold",
                      fontSize: "23px",
                      width: "250px",
                      boxShadow: "0px 6px 0  #2A3580",
                    }}
                    onClick={this.onNextButtonClick}
                  >
                    {
                      getNativeLangTransText(
                        dbAuth.userInfo.nativeLanguage,
                        configuration.projectUI.next,
                        translation.translationObj
                      ).text
                    }
                  </Button>
                </Col>
                <Col
                  xs={24}
                  sm={0}
                  md={0}
                  lg={0}
                  xl={0}
                  xxl={0}
                  style={{ borderTop: "4px solid #F1F1F1" }}
                >
                  {currentStepNum === 0 ? null : (
                    <Button
                      type="primary"
                      style={{
                        margin: "24px 10px",
                        borderRadius: "24px",
                        height: "auto",
                        fontFamily: "Poppins-Bold",
                        fontSize: "18px",
                        width: "120px",
                        boxShadow: "0px 6px 0  #2A3580",
                      }}
                      onClick={this.onPreviousButtonClick}
                    >
                      {
                        getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          configuration.projectUI.previous,
                          translation.translationObj
                        ).text
                      }
                    </Button>
                  )}

                  <Button
                    type="primary"
                    style={{
                      margin: "24px 0px",
                      borderRadius: "24px",
                      height: "auto",
                      fontFamily: "Poppins-Bold",
                      fontSize: "18px",
                      width: "120px",
                      boxShadow: "0px 6px 0  #2A3580",
                    }}
                    onClick={this.onNextButtonClick}
                  >
                    {
                      getNativeLangTransText(
                        dbAuth.userInfo.nativeLanguage,
                        configuration.projectUI.next,
                        translation.translationObj
                      ).text
                    }
                  </Button>
                </Col>
              </Col>
            </Row>
          )}
        </Spin>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  course: state.course,
  translation: state.translation,
  dbAuth: state.auth,
  operation: state.operation,
  achievement: state.achievement,
  configuration: state.configuration,
});

const mapDispatchToProps = {
  updateLessonProgress,
  updateLessonAchievementProgress,
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(ContentType2LessonPage);
