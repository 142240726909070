import React, { Component, Fragment } from "react";
import "./TopicPage.css";
import { Row, Col, Breadcrumb } from "antd";
import BottomBar from "../../../BottomBar/BottomBar";
import UserDashboardNavBar from "../../UserDashboardNavBar/UserDashboardNavBar";
import UserDashboardNavBottomBar from "../../UserDashboardNavBar/UserDashboardNavBottomBar";
import WeeklyGoal from "../../WeeklyGoal/WeeklyGoal";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  loadTargetedCourseTopics,
  resetTargetedCourseTopics,
} from "../../../course/courseActions";
import { withRouter } from "react-router";
import _ from "lodash";
import LoadingComponent from "../../../app/common/loading/LoadingComponent";
import { courseColorMap } from "../../../SharedConstant";
import { Link } from "react-router-dom";
import { getEnText, getNativeLangTransText } from "../../../SharedUtils";

class ChooseTopicPage extends Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    const { match } = this.props;
    let courseId = match.params.courseId;

    this.setState(
      {
        courseId, //simply store in var for future use
      },
      () => this.props.loadTargetedCourseTopics(courseId)
    );
  }

  componentWillUnmount() {
    this.props.resetTargetedCourseTopics();
  }

  componentDidUpdate(prevProps) {
    const { course } = this.props;
    if (
      !_.isEqual(prevProps.course.topicInfoObj, course.topicInfoObj) &&
      course.topicInfoObj !== null
    ) {
      this.setState({
        isLoading: false,
      });
    }
  }

  onCourseClick = () => {
    this.props.history.push(`/user-dashboard/courses`);
  };

  render() {
    const { isLoading, courseId } = this.state;
    const {
      course,
      translation,
      onHandleResetLabelClick,
      dbAuth,
      configuration,
    } = this.props;

    if (isLoading) return <LoadingComponent />;

    return (
      <Fragment>
        <UserDashboardNavBar
          onHandleResetLabelClick={onHandleResetLabelClick}
        />
        <Row>
          <Col className="page-container" span={24}>
            <Row style={{ marginBottom: "16px" }}>
              <Col xs={2} sm={4} md={1} lg={2} xl={4} xxl={5} />
              <Col
                xs={0}
                sm={16}
                md={11}
                lg={10}
                xl={8}
                xxl={7}
                className="content-wrap"
              >
                <Breadcrumb
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "22px",
                  }}
                >
                  <Breadcrumb.Item onClick={this.onCourseClick}>
                    {
                      getNativeLangTransText(
                        dbAuth.userInfo.nativeLanguage,
                        configuration.projectUI.course,
                        translation.translationObj
                      ).text
                    }
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    {courseColorMap.get(courseId).label === "White"
                      ? getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          configuration.projectUI.white,
                          translation.translationObj
                        ).text
                      : courseColorMap.get(courseId).label === "Yellow"
                      ? getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          configuration.projectUI.yellow,
                          translation.translationObj
                        ).text
                      : courseColorMap.get(courseId).label === "Green"
                      ? getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          configuration.projectUI.green,
                          translation.translationObj
                        ).text
                      : getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          configuration.projectUI.blue,
                          translation.translationObj
                        ).text}
                  </Breadcrumb.Item>
                </Breadcrumb>
                <Row>
                  <Col span={24}>
                    <Row>
                      <Col span={24}>
                        <Row align="middle">
                          <Col flex="80px">
                            <img
                              src="/assets/owl-watching-icon.svg"
                              alt=""
                              style={{ width: "100%" }}
                            />
                          </Col>
                          <Col flex="auto" style={{ marginLeft: "8px" }}>
                            <div
                              style={{
                                fontFamily: "Poppins-Bold",
                                fontSize: "35px",
                                lineHeight: "normal",
                              }}
                            >
                              {
                                getNativeLangTransText(
                                  dbAuth.userInfo.nativeLanguage,
                                  configuration.projectUI.choose,
                                  translation.translationObj
                                ).text
                              }
                            </div>
                            <div
                              style={{
                                fontFamily: "Poppins-Regular",
                                fontSize: "23px",
                                lineHeight: "normal",
                              }}
                            >
                              {
                                getNativeLangTransText(
                                  dbAuth.userInfo.nativeLanguage,
                                  configuration.projectUI.the_courses,
                                  translation.translationObj
                                ).text
                              }
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24} style={{ marginTop: "16px" }}>
                        <div
                          style={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "23px",
                            lineHeight: "normal",
                          }}
                        >
                          {getNativeLangTransText(
                            dbAuth.userInfo.nativeLanguage,
                            course.topicInfoObj.description,
                            translation.translationObj 
                          ).text.length > 0 ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getNativeLangTransText(
                                  dbAuth.userInfo.nativeLanguage,
                                  course.topicInfoObj.description,
                                  translation.translationObj
                                ).text,
                              }}
                            />
                          ) : (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getEnText(
                                  course.topicInfoObj.description,
                                  translation.translationObj
                                ).text,
                              }}
                            />
                          )}
                        </div>
                        <Row style={{ textAlign: "center" }}>
                          {course.topicInfoObj.topicInfoList.map(
                            (topicInfo) => (
                              <Fragment key={topicInfo.docId}>
                                <Col
                                  offset={4}
                                  span={16}
                                  style={{ marginTop: "36px" }}
                                >
                                  <Link
                                    to={`/user-dashboard/courses/${courseId}/topics/${topicInfo.docId}`}
                                  >
                                    <div
                                      style={{
                                        color: "black",
                                        boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                                        borderRadius: "24px",
                                        border:
                                          "1px solid rgba(112,112,112,0.4)",
                                        fontFamily: "Poppins-Regular",
                                        fontSize: "23px",
                                        paddingTop: "35px",
                                        paddingBottom: "35px",
                                        background:
                                          courseColorMap.get(courseId)
                                            .hexColorCode,
                                        cursor: "pointer",
                                        whiteSpace: "normal",
                                        overflowWrap: "break-word",
                                      }}
                                    >
                                      {getNativeLangTransText(
                                        dbAuth.userInfo.nativeLanguage,
                                        topicInfo.name,
                                        translation.translationObj
                                      ).text.length > 0
                                        ? getNativeLangTransText(
                                            dbAuth.userInfo.nativeLanguage,
                                            topicInfo.name,
                                            translation.translationObj
                                          ).text
                                        : getEnText(
                                            topicInfo.name,
                                            translation.translationObj
                                          ).text}
                                    </div>
                                  </Link>
                                </Col>
                                <Col span={4} />
                              </Fragment>
                            )
                          )}
                          {/* <Col offset={4} span={16} style={{ marginTop: "36px" }}>
                        <div
                          className="choose-lesson-text-wrapper"
                          style={{
                            boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                            borderRadius: "24px",
                            border: "1px solid rgba(112,112,112,0.4)",
                            fontFamily: "Poppins-Regular",
                            fontSize: "25px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "40px"
                          }}
                        >
                          Quick Phonics
                        </div>
                      </Col>
                      <Col span={4} />
                      <Col offset={4} span={16} style={{ marginTop: "24px" }}>
                        <div
                          className="choose-lesson-text-wrapper"
                          style={{
                            boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                            borderRadius: "24px",
                            border: "1px solid rgba(112,112,112,0.4)",
                            fontFamily: "Poppins-Regular",
                            fontSize: "25px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "40px"
                          }}
                        >
                          S.I.R. 2020
                        </div>
                      </Col>
                      <Col span={4} />
                      <Col offset={4} span={16} style={{ marginTop: "24px" }}>
                        <div
                          className="choose-lesson-text-wrapper"
                          style={{
                            boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                            borderRadius: "24px",
                            border: "1px solid rgba(112,112,112,0.4)",
                            fontFamily: "Poppins-Regular",
                            fontSize: "25px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "40px"
                          }}
                        >
                          Introduction to English
                        </div>
                      </Col>
                      <Col span={4} />
                      <Col offset={4} span={16} style={{ marginTop: "24px" }}>
                        <div
                          className="choose-lesson-text-wrapper"
                          style={{
                            boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                            borderRadius: "24px",
                            border: "1px solid rgba(112,112,112,0.4)",
                            fontFamily: "Poppins-Regular",
                            fontSize: "25px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "40px"
                          }}
                        >
                          First Steps to English
                        </div>
                      </Col>
                      <Col span={4} /> */}
                        </Row>
                        <br />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col xs={20} sm={0} md={0} lg={0} xl={0} xxl={0}>
                <Breadcrumb
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "22px",
                  }}
                >
                  <Breadcrumb.Item onClick={this.onCourseClick}>
                    {
                      getNativeLangTransText(
                        dbAuth.userInfo.nativeLanguage,
                        configuration.projectUI.course,
                        translation.translationObj
                      ).text
                    }
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    {courseColorMap.get(courseId).label === "White"
                      ? getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          configuration.projectUI.white,
                          translation.translationObj
                        ).text
                      : courseColorMap.get(courseId).label === "Yellow"
                      ? getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          configuration.projectUI.yellow,
                          translation.translationObj
                        ).text
                      : courseColorMap.get(courseId).label === "Green"
                      ? getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          configuration.projectUI.green,
                          translation.translationObj
                        ).text
                      : getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          configuration.projectUI.blue,
                          translation.translationObj
                        ).text}
                  </Breadcrumb.Item>
                </Breadcrumb>
                <Row>
                  <Col span={24}>
                    <Row>
                      <Col span={24}>
                        <Row align="middle">
                          <Col flex="50px">
                            <img
                              src="/assets/owl-watching-icon.svg"
                              alt=""
                              style={{ width: "100%" }}
                            />
                          </Col>
                          <Col flex="auto" style={{ marginLeft: "8px" }}>
                            <div
                              style={{
                                fontFamily: "Poppins-Bold",
                                fontSize: "28px",
                                lineHeight: "normal",
                              }}
                            >
                              {
                                getNativeLangTransText(
                                  dbAuth.userInfo.nativeLanguage,
                                  configuration.projectUI.choose,
                                  translation.translationObj
                                ).text
                              }
                            </div>
                            <div
                              style={{
                                fontFamily: "Poppins-Regular",
                                fontSize: "18px",
                                lineHeight: "normal",
                              }}
                            >
                              {
                                getNativeLangTransText(
                                  dbAuth.userInfo.nativeLanguage,
                                  configuration.projectUI.the_courses,
                                  translation.translationObj
                                ).text
                              }
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24} style={{ marginTop: "16px" }}>
                        <div
                          style={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "18px",
                            lineHeight: "normal",
                          }}
                        >
                          {getNativeLangTransText(
                            dbAuth.userInfo.nativeLanguage,
                            course.topicInfoObj.description,
                            translation.translationObj
                          ).text.length > 0 ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getNativeLangTransText(
                                  dbAuth.userInfo.nativeLanguage,
                                  course.topicInfoObj.description,
                                  translation.translationObj
                                ).text,
                              }}
                            />
                          ) : (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getEnText(
                                  course.topicInfoObj.description,
                                  translation.translationObj
                                ).text,
                              }}
                            />
                          )}
                        </div>
                        <Row style={{ textAlign: "center" }}>
                          {course.topicInfoObj.topicInfoList.map(
                            (topicInfo) => (
                              <Fragment key={topicInfo.docId}>
                                <Col
                                  offset={4}
                                  span={16}
                                  style={{ marginTop: "36px" }}
                                >
                                  <Link
                                    to={`/user-dashboard/courses/${courseId}/topics/${topicInfo.docId}`}
                                  >
                                    <div
                                      style={{
                                        color: "black",
                                        boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                                        borderRadius: "24px",
                                        border:
                                          "1px solid rgba(112,112,112,0.4)",
                                        fontFamily: "Poppins-Regular",
                                        fontSize: "18px",
                                        paddingTop: "25px",
                                        paddingBottom: "25px",
                                        background:
                                          courseColorMap.get(courseId)
                                            .hexColorCode,
                                        cursor: "pointer",
                                        whiteSpace: "normal",
                                        overflowWrap: "break-word",
                                      }}
                                    >
                                      {getNativeLangTransText(
                                        dbAuth.userInfo.nativeLanguage,
                                        topicInfo.name,
                                        translation.translationObj
                                      ).text.length > 0
                                        ? getNativeLangTransText(
                                            dbAuth.userInfo.nativeLanguage,
                                            topicInfo.name,
                                            translation.translationObj
                                          ).text
                                        : getEnText(
                                            topicInfo.name,
                                            translation.translationObj
                                          ).text}
                                    </div>
                                  </Link>
                                </Col>
                                <Col span={4} />
                              </Fragment>
                            )
                          )}
                        </Row>
                        <br />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col xs={3} sm={5} md={1} lg={1} xl={1} xxl={1} />
              <Col xs={18} sm={14} md={10} lg={9} xl={7} xxl={6}>
                <WeeklyGoal />
              </Col>
            </Row>
            <Row>
              <Col
                xs={0}
                sm={0}
                md={24}
                lg={24}
                xl={24}
                className="footer-bottom"
              >
                <BottomBar />
              </Col>
              <Col
                xs={0}
                sm={24}
                md={0}
                lg={0}
                xl={0}
                style={{ marginBottom: "100px" }}
              >
                <BottomBar />
              </Col>
              <Col
                xs={24}
                sm={0}
                md={0}
                lg={0}
                xl={0}
                style={{ marginBottom: "70px" }}
              >
                <BottomBar />
              </Col>
            </Row>
            <Row>
              <Col
                xs={24}
                sm={24}
                md={0}
                lg={0}
                xl={0}
                style={{
                  textAlign: "center",
                  borderTop: "4px solid #F1F1F1",
                }}
                className="footer"
              >
                <UserDashboardNavBottomBar
                  onHandleResetLabelClick={onHandleResetLabelClick}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  course: state.course,
  translation: state.translation,
  configuration: state.configuration,
  dbAuth: state.auth,
});

const mapDispatchToProps = {
  loadTargetedCourseTopics,
  resetTargetedCourseTopics,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ChooseTopicPage);
