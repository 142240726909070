import React, { Component, Fragment } from "react";
import { Row, Col, Button, Collapse, Form, Input, message } from "antd";
import "../../HomePage.less";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";
import { withFirebase } from "react-redux-firebase";
import {
  signInWithEmail,
  initDBLoad,
  signInWithGoogle,
  signInWithFacebook,
  signInWithApple,
  logout
} from "../../../auth/authActions";
import LoadingComponent from "../../../app/common/loading/LoadingComponent";

// import Bowser from "bowser"
const { Panel } = Collapse;

class Login extends Component {
  state = {};

  componentDidUpdate() {}

  showRegister = () => {
    this.props.history.push("/register");
  };

  onForgotPassowrdClick = () => {
    this.props.history.push("/login/forgotPassword");
  };

  onLoginButtonClick = async (e) => {
    const { ecodeData } = this.state;
    const { signInWithEmail, history, initDBLoad, dbAuth } = this.props;
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        let result = await signInWithEmail(e, ecodeData);

        if (result && result.code === "Note") {
          message.info(result.code + " : " + result.message);

          await initDBLoad(this.props.auth.uid);
          history.push("/user-dashboard");
        } else if (result) {
          message.error(result.code + " : " + result.message);
          this.setState({
            isLoading: false,
          });
          if (result.code === "Expired E-Code") this.showRegister();
          //if(result.code == "Error") await this.props.logout();
        } else {
          await initDBLoad(this.props.auth.uid);
          history.push("/user-dashboard");
        }
      }
    );
  };

  onGoogleButtonClick = async (e) => {
    const { ecodeData } = this.state;
    const { signInWithGoogle, history, initDBLoad } = this.props;
    e.preventDefault();

    let result = await signInWithGoogle(ecodeData);
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        if (result && result.code === "Note") {
          message.info(result.code + " : " + result.message);
          await initDBLoad(this.props.auth.uid);
          history.push("/user-dashboard");
        } else if (result) {
          //console.log(result);
          //await this.props.logout();
          this.setState({
            isLoading: false,
          });
          if (result.code === "Expired E-Code") this.showRegister();
          else if (result.code === "No Account is found") {
            await this.props.logout();
            this.showRegister();
          }
          message.error(result.code + " : " + result.message);
        } else {
          await initDBLoad(this.props.auth.uid);
          history.push("/user-dashboard");
        }
      }
    );
  };

  onFacebookButtonClick = async (e) => {
    const { ecodeData } = this.state;
    const { signInWithFacebook, history, initDBLoad } = this.props;

    e.preventDefault();

    let result = await signInWithFacebook(ecodeData);
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        if (result && result.code === "Note") {
          message.info(result.code + " : " + result.message);
          await initDBLoad(this.props.auth.uid);
          history.push("/user-dashboard");
        } else if (result) {
          //await this.props.logout();
          this.setState({
            isLoading: false,
          });
          if (result.code === "Expired E-Code") this.showRegister();
          else if (result.code === "No Account is found") {
            await this.props.logout();
            this.showRegister();
          }
          message.error(result.code + " : " + result.message);
        } else {
          await initDBLoad(this.props.auth.uid);
          history.push("/user-dashboard");
        }
      }
    );
  };

  onAppleButtonClick = async (e) => {
    console.log("apple");
    const { ecodeData } = this.state;
    const { signInWithApple, history, initDBLoad } = this.props;

    e.preventDefault();

    let result = await signInWithApple(ecodeData);
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        if (result && result.code === "Note") {
          message.info(result.code + " : " + result.message);
          await initDBLoad(this.props.auth.uid);
          history.push("/user-dashboard");
        } else if (result) {
          //await this.props.logout();
          this.setState({
            isLoading: false,
          });
          message.error(result.code + " : " + result.message);
        } else {
          await initDBLoad(this.props.auth.uid);
          history.push("/user-dashboard");
        }
      }
    );
  };

  render() {
    const { dbAuth } = this.props;
    const { isLoading } = this.state;
    if (isLoading) return <LoadingComponent />;
    return (
      <Fragment>
        <Row>
          <Col
            md={24}
            lg={10}
            flex="auto"
            style={{
              backgroundImage: `url(/assets/Login/Login-Image.png)`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              width: "100vw",
              height: "100vh",
            }}
          >
            <div
              style={{
                position: "absolute",
                bottom: "0px",
                margin: "60px 60px",
              }}
            >
              <p
                style={{
                  font: "54px Poppins-Bold",
                  color: "white",
                  textAlign: "initial",
                }}
              >
                {" "}
                E-LEARNING ON THE GO!
              </p>
              <p
                style={{
                  font: "30px Poppins",
                  color: "white",
                  textAlign: "initial",
                }}
              >
                Learn Interactively & Virtually in English
              </p>
              <p
                style={{
                  font: "18px Poppins",
                  color: "white",
                  textAlign: "initial",
                }}
              >
                Maximise your potential with out exciting e-learning pllatform
                where you can explore fun additional activities that are
                tailered to the lessons.
              </p>
            </div>
          </Col>
          <Col xs={0} md={24} lg={14}>
            <Col flex="auto" style={{ margin: "60px" }}>
              <img
                src="/assets/home-page-logo.png"
                alt="Landing Page Row 1"
                style={{ width: "250px" }}
              />
              <Col
                style={{
                  textAlign: "center",
                  borderRadius: "10px",
                  margin: "80px 30px",
                  padding: "30px",
                  boxShadow:
                    "0px 1px 2px 0px #f2f2f2, 1px 2px 4px 0px #f2f2f2, 2px 4px 8px 0px #f2f2f2, 2px 4px 16px 0px #f2f2f2",
                }}
              >
                <div
                  style={{
                    font: "34px Poppins-Bold",
                    margin: "60px 0px",
                    color: "black",
                  }}
                >
                  Log in now to get full access.
                </div>

                <Form ref={this.form} onFinish={this.onLoginButtonClick}>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "The input is not a valid email!",
                      },
                      {
                        required: true,
                        message: "Please enter a valid email address",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Email Address"
                      size="large"
                      style={{
                        height: "50px",
                        borderRadius: "5px",
                        borderColor: "#ddedf9",
                        padding: "0px 30px",
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        pattern: new RegExp("^(?=.*[A-Za-z])(?=.*[0-9])(?=.{6,})"),
                        message:
                          "Password must contain at least a number and 6 character long",
                      },
                      {
                        required: true,
                        message: "Please enter your password",
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="Password"
                      size="large"
                      style={{
                        height: "50px",
                        borderRadius: "5px",
                        borderColor: "#ddedf9",
                        padding: "0px 30px",
                      }}
                    />
                  </Form.Item>
                  <Button
                    size="large"
                    htmlType="submit"
                    style={{
                      font: "18px Poppins-Bold",
                      color: "white",
                      backgroundColor: "#48ca5e",
                      border: "3px solid #48ca5e",
                      borderRadius: "15px",
                      width: "100%",
                      height: "50px",
                    }}
                    loading={this.state.signingUp}
                  >
                    Login
                  </Button>
                </Form>

                <Row style={{ paddingTop: "20px" }}>
                  <Col span={12}>
                    <Button
                      size="large"
                      htmlType="submit"
                      style={{
                        font: "18px Poppins-Bold",
                        color: "white",
                        backgroundColor: "white",
                        border: "1px solid black",
                        borderRadius: "15px",
                        width: "100%",
                        height: "50px",
                      }}
                      loading={this.state.signingUp}
                      onClick={this.onGoogleButtonClick}
                    >
                      <img src="/assets/Login/Google-Icon.svg" alt="Google" />
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      size="large"
                      htmlType="submit"
                      style={{
                        font: "18px Poppins-Bold",
                        color: "white",
                        backgroundColor: "#1977f2",
                        border: "3px solid #1977f2",
                        borderRadius: "15px",
                        width: "96%",
                        height: "50px",
                        margin: "0px 5px",
                      }}
                      loading={this.state.signingUp}
                      onClick={this.onFacebookButtonClick}
                    >
                      <img
                        src="/assets/Login/Facebook-Icon.svg"
                        alt="Facebook"
                      />
                    </Button>
                  </Col>
                  {/* <Col span={8}>
                    <Button
                      size="large"
                      htmlType="submit"
                      style={{
                        font: "18px Poppins-Bold",
                        color: "white",
                        backgroundColor: "black",
                        border: "3px solid black",
                        borderRadius: "15px",
                        width: "100%",
                        height: "50px",
                      }}
                      loading={this.state.signingUp}
                      onClick={this.onAppleButtonClick}
                    >
                      <img src="/assets/Login/Apple-Icon.svg" alt="Apple" />
                    </Button>
                  </Col> */}
                </Row>

                <br />
                <div style={{ textAlign: "center" }}>
                  Don't have an account?{" "}
                  <u
                    onClick={this.showRegister}
                    style={{ cursor: "pointer", color: "#3896dc" }}
                  >
                    Register
                  </u>{" "}
                  <br />
                  <br />
                  <u
                    onClick={this.onForgotPassowrdClick}
                    style={{ cursor: "pointer", color: "#3896dc" }}
                  >
                    Forgot password?
                  </u>{" "}
                </div>
              </Col>
            </Col>
          </Col>
          <Col xs={24} md={0}>
            <Col flex="auto" style={{ margin: "60px" }}>
              <img
                src="/assets/home-page-logo.png"
                alt="Landing Page Row 1"
                style={{ width: "250px" }}
              />
              <Col
                style={{
                  textAlign: "center",
                  borderRadius: "10px",
                  margin: "80px 0px",
                  padding: "30px",
                  boxShadow:
                    "0px 1px 2px 0px #f2f2f2, 1px 2px 4px 0px #f2f2f2, 2px 4px 8px 0px #f2f2f2, 2px 4px 16px 0px #f2f2f2",
                }}
              >
                <div
                  style={{
                    font: "24px Poppins-Bold",
                    margin: "50px 0px",
                    color: "black",
                  }}
                >
                  Log in now to get full access.
                </div>

                <Form ref={this.form} onFinish={this.onLoginButtonClick}>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "The input is not a valid email!",
                      },
                      {
                        required: true,
                        message: "Please enter a valid email address",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Email Address"
                      size="large"
                      style={{
                        height: "50px",
                        borderRadius: "5px",
                        borderColor: "#ddedf9",
                        padding: "0px 30px",
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        pattern: new RegExp("^(?=.*[A-Za-z])(?=.*[0-9])(?=.{6,})"),
                        message:
                          "Password must contain at least a number and 6 character long",
                      },
                      {
                        required: true,
                        message: "Please enter your password",
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="Password"
                      size="large"
                      style={{
                        height: "50px",
                        borderRadius: "5px",
                        borderColor: "#ddedf9",
                        padding: "0px 30px",
                      }}
                    />
                  </Form.Item>
                  <Button
                    size="large"
                    htmlType="submit"
                    style={{
                      font: "18px Poppins-Bold",
                      color: "white",
                      backgroundColor: "#48ca5e",
                      border: "3px solid #48ca5e",
                      borderRadius: "15px",
                      width: "100%",
                      height: "50px",
                    }}
                    loading={this.state.signingUp}
                  >
                    Login
                  </Button>
                </Form>

                <Row style={{ paddingTop: "20px" }}>
                  <Col span={24}>
                    <Button
                      size="large"
                      htmlType="submit"
                      style={{
                        font: "18px Poppins-Bold",
                        color: "white",
                        backgroundColor: "white",
                        border: "1px solid black",
                        borderRadius: "15px",
                        width: "100%",
                        height: "50px",
                      }}
                      loading={this.state.signingUp}
                      onClick={this.onGoogleButtonClick}
                    >
                      <img src="/assets/Login/Google-Icon.svg" alt="Google" />
                    </Button>
                  </Col>
                  <br />
                  <Col span={24}>
                    <Button
                      size="large"
                      htmlType="submit"
                      style={{
                        font: "18px Poppins-Bold",
                        color: "white",
                        backgroundColor: "#1977f2",
                        border: "3px solid #1977f2",
                        borderRadius: "15px",
                        width: "100%",
                        height: "50px",
                        margin: "15px 0px",
                      }}
                      loading={this.state.signingUp}
                      onClick={this.onFacebookButtonClick}
                    >
                      <img
                        src="/assets/Login/Facebook-Icon.svg"
                        alt="Facebook"
                      />
                    </Button>
                  </Col>
                  {/* <Col span={24}>
                    <Button
                      size="large"
                      htmlType="submit"
                      style={{
                        font: "18px Poppins-Bold",
                        color: "white",
                        backgroundColor: "black",
                        border: "3px solid black",
                        borderRadius: "15px",
                        width: "100%",
                        height: "50px",
                      }}
                      loading={this.state.signingUp}
                    >
                      <img src="/assets/Login/Apple-Icon.svg" alt="Apple" />
                    </Button>
                  </Col> */}
                </Row>

                <br />
                <div style={{ textAlign: "center" }}>
                  Don't have an account?{" "}
                  <u
                    onClick={this.showRegister}
                    style={{ cursor: "pointer", color: "#3896dc" }}
                  >
                    Register
                  </u>{" "}
                  <br />
                  <br />
                  <u
                    onClick={this.onForgotPassowrdClick}
                    style={{ cursor: "pointer", color: "#3896dc" }}
                  >
                    Forgot password?
                  </u>{" "}
                </div>
              </Col>
            </Col>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  contactUs: state.contactUs,
  dbAuth: state.auth,
});
const mapDispatchToProps = {
  signInWithEmail,
  initDBLoad,
  signInWithGoogle,
  signInWithFacebook,
  signInWithApple,
  logout
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(Login);
