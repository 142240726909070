import React, { Component, Fragment } from "react";
import BottomBar from "../../../BottomBar/BottomBar";
import { Col, Row, Progress } from "antd";
import UserDashboardNavBar from "../../UserDashboardNavBar/UserDashboardNavBar";
import UserDashboardNavBottomBar from "../../UserDashboardNavBar/UserDashboardNavBottomBar";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";
import { withFirebase } from "react-redux-firebase";
import {
  loadAllExamLevels,
  resetAllExamLevels,
} from "../../../course/courseActions";
import LoadingComponent from "../../../app/common/loading/LoadingComponent";
import { getNativeLangTransText } from "../../../SharedUtils";
import "./StatPage.css";
import _ from "lodash";
var moment = require("moment-timezone");
class StatPage extends Component {
  state = {
    loading: true
  };

  async componentDidMount() {
    await this.props.loadAllExamLevels();
    const { achievement, dbAuth, statistics } = this.props;
    // console.log(dbAuth);
    var totalCompletedLessonCount = 0;
    var totalCompletedQuizCount = 0;
    if (dbAuth.progressInfo.achievement) {
      if (dbAuth.progressInfo.achievement.quiz) {
        for (const object of Object.values(
          dbAuth.progressInfo.achievement.quiz
        )) {
          // console.log(topic, object.completed)
          if (object.completed) {
            totalCompletedQuizCount += 1;
          }
        }
      }
      if (dbAuth.progressInfo.achievement.lesson) {
        for (const object of Object.values(
          dbAuth.progressInfo.achievement.lesson
        )) {
          // console.log(topic, object.completed)
          if (object.completed) {
            totalCompletedLessonCount += 1;
          }
        }
      }
    }
    var totalWhiteCompleted = 0;
    if (dbAuth.progressInfo.lessons && dbAuth.progressInfo.lessons.beginner) {
      for (const [topic, object] of Object.entries(
        dbAuth.progressInfo.lessons.beginner
      )) {
        for (const [statisticsTopic, statisticsObject] of Object.entries(
          statistics.statisticsObj.lessons.beginner
        )) {
          if (topic === statisticsTopic && statisticsObject === object.length) {
            totalWhiteCompleted += 1;
          }
        }
      }
    }

    var totalYellowCompleted = 0;
    if (
      dbAuth.progressInfo.lessons &&
      dbAuth.progressInfo.lessons.intermediate
    ) {
      for (const [topic, object] of Object.entries(
        dbAuth.progressInfo.lessons.intermediate
      )) {
        for (const [statisticsTopic, statisticsObject] of Object.entries(
          statistics.statisticsObj.lessons.intermediate
        )) {
          if (topic === statisticsTopic && statisticsObject === object.length) {
            totalYellowCompleted += 1;
          }
        }
      }
    }
    var totalGreenCompleted = 0;
    if (dbAuth.progressInfo.lessons && dbAuth.progressInfo.lessons.advanced) {
      for (const [topic, object] of Object.entries(
        dbAuth.progressInfo.lessons.advanced
      )) {
        for (const [statisticsTopic, statisticsObject] of Object.entries(
          statistics.statisticsObj.lessons.advanced
        )) {
          if (topic === statisticsTopic && statisticsObject === object.length) {
            totalGreenCompleted += 1;
          }
        }
      }
    }
    var totalBlueCompleted = 0;
    if (dbAuth.progressInfo.lessons && dbAuth.progressInfo.lessons.expert) {
      // console.log(dbAuth.progressInfo.lessons.expert)
      for (const [topic, object] of Object.entries(
        dbAuth.progressInfo.lessons.expert
      )) {
        for (const [statisticsTopic, statisticsObject] of Object.entries(
          statistics.statisticsObj.lessons.expert
        )) {
          if (topic === statisticsTopic && statisticsObject === object.length) {
            totalBlueCompleted += 1;
          }
        }
      }
    }

    var totalQuizCompleted = 0;
    if (dbAuth.progressInfo.quiz && dbAuth.progressInfo.quiz.beginner) {
      for (const [topic, object] of Object.entries(
        dbAuth.progressInfo.quiz.beginner
      )) {
        for (const [statisticsTopic, statisticsObject] of Object.entries(
          statistics.statisticsObj.quiz.beginner
        )) {
          if (topic === statisticsTopic && statisticsObject === object.length) {
            totalQuizCompleted += 1;
          }
        }
      }
    }
    if (dbAuth.progressInfo.quiz && dbAuth.progressInfo.quiz.intermediate) {
      for (const [topic, object] of Object.entries(
        dbAuth.progressInfo.quiz.intermediate
      )) {
        for (const [statisticsTopic, statisticsObject] of Object.entries(
          statistics.statisticsObj.quiz.intermediate
        )) {
          if (topic === statisticsTopic && statisticsObject === object.length) {
            totalQuizCompleted += 1;
          }
        }
      }
    }
    if (dbAuth.progressInfo.quiz && dbAuth.progressInfo.quiz.advanced) {
      for (const [topic, object] of Object.entries(
        dbAuth.progressInfo.quiz.advanced
      )) {
        for (const [statisticsTopic, statisticsObject] of Object.entries(
          statistics.statisticsObj.quiz.advanced
        )) {
          if (topic === statisticsTopic && statisticsObject === object.length) {
            totalQuizCompleted += 1;
          }
        }
      }
    }
    if (dbAuth.progressInfo.quiz && dbAuth.progressInfo.quiz.expert) {
      for (const [topic, object] of Object.entries(
        dbAuth.progressInfo.quiz.expert
      )) {
        for (const [statisticsTopic, statisticsObject] of Object.entries(
          statistics.statisticsObj.quiz.expert
        )) {
          if (topic === statisticsTopic && statisticsObject === object.length) {
            totalQuizCompleted += 1;
          }
        }
      }
    }
    var totalQuizToComplete = Object.keys(
      statistics.statisticsObj.quiz.beginner
    ).length;
    totalQuizToComplete += Object.keys(
      statistics.statisticsObj.quiz.intermediate
    ).length;
    totalQuizToComplete += Object.keys(
      statistics.statisticsObj.quiz.advanced
    ).length;
    totalQuizToComplete += Object.keys(
      statistics.statisticsObj.quiz.expert
    ).length;

    this.setState({
      loadedAchievement: achievement,
      totalToCompleteWeeklyLesson:
        achievement.currentWeekAchievement.lesson.totalToComplete,
      totalToCompleteWeeklyQuiz:
        achievement.currentWeekAchievement.quiz.totalToComplete,
      totalCompletedWeeklyQuiz: totalCompletedQuizCount,
      totalCompletedWeeklyLesson: totalCompletedLessonCount,
      totalCompletedWhite: totalWhiteCompleted,
      totalToCompleteWhite: Object.keys(
        statistics.statisticsObj.lessons.beginner
      ).length,
      totalCompletedYellow: totalYellowCompleted,
      totalToCompleteYellow: Object.keys(
        statistics.statisticsObj.lessons.intermediate
      ).length,
      totalCompletedGreen: totalGreenCompleted,
      totalToCompleteGreen: Object.keys(
        statistics.statisticsObj.lessons.advanced
      ).length,
      totalCompletedBlue: totalBlueCompleted,
      totalToCompleteBlue: Object.keys(statistics.statisticsObj.lessons.expert)
        .length,
      totalCompletedQuiz: totalQuizCompleted,
      totalToCompleteQuiz: totalQuizToComplete,
    });
  }

  componentDidUpdate(prevProps) {
    const { course, dbAuth } = this.props;
    const {  loading } = this.state;
    if (
      !_.isEqual(prevProps.course.examInfoObj, course.examInfoObj) ||
      (loading && course.examInfoObj)
    ) {
      // console.log(course)

      var totalExamCompleted = 0;
      if (dbAuth.progressInfo.exams) {
        for (const [topic, object] of Object.entries(
          dbAuth.progressInfo.exams
        )) {
          // console.log(topic)
          for (const examObjects of Object.values(
            course.examInfoObj.examInfoList
          )) {
            if (topic === examObjects.docId) {
              var completedTest = 0;
              // console.log(Object.values(object))
              for (const mark of Object.values(object)) {
                if (mark > 49) {
                  completedTest += 1;
                  // console.log(completedTest)
                }
                if (completedTest === 4) {
                  totalExamCompleted += 1;
                }
              }
            }
          }
        }
      }

      this.setState({
        loading: false,
        course: course,
        totalCompletedExam: totalExamCompleted,
      });
    }
  }

  getAge(dateString) {
    console.log(dateString);
    var ageInMilliseconds = new Date() - new Date(dateString);
    return Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365); // convert to years
  }

  render() {
    const {
      loading,
      totalToCompleteWeeklyLesson,
      totalToCompleteWeeklyQuiz,
      totalCompletedWeeklyQuiz,
      totalCompletedWeeklyLesson,
      totalCompletedWhite,
      totalToCompleteWhite,
      totalCompletedYellow,
      totalToCompleteYellow,
      totalCompletedGreen,
      totalToCompleteGreen,
      totalCompletedBlue,
      totalToCompleteBlue,
      totalCompletedExam,
      totalCompletedQuiz,
      totalToCompleteQuiz,
    } = this.state;
    const { onHandleResetLabelClick, translation, configuration, dbAuth, course } = this.props;

    if (loading) return <LoadingComponent />;
    return (
      <Fragment>
        <UserDashboardNavBar
          onHandleResetLabelClick={onHandleResetLabelClick}
        />
        <Row>
          <Col className="page-container" span={24}>
            <Row style={{ marginBottom: "16px" }}>
              <Col xs={1} sm={2} md={3} lg={4} xl={5} xxl={6} />
              <Col
                xs={0}
                sm={20}
                md={18}
                lg={16}
                xl={14}
                xxl={12}
                className="content-wrap"
              >
                <div
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "35px",
                    lineHeight: "normal",
                  }}
                >
                  {dbAuth.userInfo.name}
                </div>
                <div
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "20px",
                    lineHeight: "normal",
                  }}
                >
                  {
                    getNativeLangTransText(
                      dbAuth.userInfo.nativeLanguage,
                      configuration.projectUI.joined,
                      translation.translationObj
                    ).text
                  }{" "}
                  {moment(dbAuth.userInfo.createdAt.toDate()).format(
                    "DD MMMM YYYY"
                  )}
                  <br />
                  {
                    getNativeLangTransText(
                      dbAuth.userInfo.nativeLanguage,
                      configuration.projectUI.ecode_expiration_date,
                      translation.translationObj
                    ).text
                  }
                  :{" "}
                  {moment(dbAuth.userInfo.subscription.endDate.toDate()).format(
                    "DD MMMM YYYY"
                  )}
                  <br />
                  {
                    getNativeLangTransText(
                      dbAuth.userInfo.nativeLanguage,
                      configuration.projectUI.age,
                      translation.translationObj
                    ).text
                  }
                  : {this.getAge(dbAuth.userInfo.dob)}
                </div>

                <br />
                <br />

                <div
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "35px",
                    lineHeight: "normal",
                  }}
                >
                  {
                    getNativeLangTransText(
                      dbAuth.userInfo.nativeLanguage,
                      configuration.projectUI.weekly_goal,
                      translation.translationObj
                    ).text
                  }
                </div>

                <Row style={{ marginTop: "16px" }}>
                  <Col offset={1} span={10} style={{ textAlign: "center" }}>
                    <img src="/assets/trophy-icon.svg" alt="" />
                    <Progress
                      percent={
                        (totalCompletedWeeklyLesson /
                          totalToCompleteWeeklyLesson) *
                        100
                      }
                      status="normal"
                      showInfo={false}
                      strokeColor="#FF9F29"
                    />
                    <div
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "20px",
                      }}
                    >
                      {totalCompletedWeeklyLesson < totalToCompleteWeeklyLesson
                        ? getNativeLangTransText(
                            dbAuth.userInfo.nativeLanguage,
                            configuration.projectUI.lesson_completed,
                            translation.translationObj
                          )
                            .text.replace("%a", totalCompletedWeeklyLesson)
                            .replace("%b", totalToCompleteWeeklyLesson)
                        : getNativeLangTransText(
                            dbAuth.userInfo.nativeLanguage,
                            configuration.projectUI
                              .you_have_completed_your_weekly_goal,
                            translation.translationObj
                          ).text}
                    </div>
                  </Col>
                  {/* <Col offset={1} span={7} style={{ textAlign: "center" }}>
                <img src="/assets/play-video-icon.svg" alt="" />
                <Progress
                  percent={
                    dbAuth.progressInfo.achievement &&
                    dbAuth.progressInfo.achievement.videoWatched
                      ? 100
                      : 0
                  }
                  status="normal"
                  showInfo={false}
                  strokeColor="#FF9F29"
                />
                <div
                  style={{ fontFamily: "Poppins-Regular", fontSize: "20px" }}
                >
                  Watch the weekly video!
                </div>
              </Col> */}
                  <Col offset={2} span={10} style={{ textAlign: "center" }}>
                    <img src="/assets/test-icon.svg" alt="" />
                    <Progress
                      percent={
                        (totalCompletedWeeklyQuiz / totalToCompleteWeeklyQuiz) *
                        100
                      }
                      status="normal"
                      showInfo={false}
                      strokeColor="#FF9F29"
                    />
                    <div
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "20px",
                      }}
                    >
                      {totalCompletedWeeklyQuiz < totalToCompleteWeeklyQuiz
                        ? getNativeLangTransText(
                            dbAuth.userInfo.nativeLanguage,
                            configuration.projectUI.quiz_completed,
                            translation.translationObj
                          )
                            .text.replace("%a", totalCompletedWeeklyQuiz)
                            .replace("%b", totalToCompleteWeeklyQuiz)
                        : getNativeLangTransText(
                            dbAuth.userInfo.nativeLanguage,
                            configuration.projectUI
                              .you_have_completed_your_weekly_goal,
                            translation.translationObj
                          ).text}
                    </div>
                  </Col>
                  <Col span={1} />
                </Row>

                <br />
                <br />

                <div
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "35px",
                    lineHeight: "normal",
                  }}
                >
                  {
                    getNativeLangTransText(
                      dbAuth.userInfo.nativeLanguage,
                      configuration.projectUI.statistic,
                      translation.translationObj
                    ).text
                  }
                </div>

                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Row
                      align="middle"
                      style={{
                        border: "1px solid rgba(112,112,112,0.3)",
                        boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                        borderRadius: "16px",
                        padding: "16px",
                      }}
                    >
                      <Col flex={1}>
                        <img
                          src="/assets/white-certificate-icon.svg"
                          alt=""
                          style={{ width: "100%", height: "50px" }}
                        />
                      </Col>
                      <Col
                        flex={9}
                        style={{
                          textAlign: "center",
                          marginLeft: "16px",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "20px",
                          }}
                        >
                          {
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.white_course,
                              translation.translationObj
                            ).text
                          }
                        </div>
                        <div
                          style={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "12px",
                          }}
                        >
                          {totalCompletedWhite < totalToCompleteWhite
                            ? getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI.completed_lessons,
                                translation.translationObj
                              )
                                .text.replace("%a", totalCompletedWhite)
                                .replace("%b", totalToCompleteWhite)
                            : getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI.completed_white_course,
                                translation.translationObj
                              ).text}
                        </div>

                        {(totalCompletedWhite / totalToCompleteWhite) * 100 ===
                        100 ? (
                          <div
                            style={{
                              fontFamily: "Poppins-Regular",
                              fontSize: "14px",
                              color: "#FF9F29",
                            }}
                          >
                            {
                              getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI.completed,
                                translation.translationObj
                              ).text
                            }
                          </div>
                        ) : (
                          <Progress
                            percent={
                              (totalCompletedWhite / totalToCompleteWhite) * 100
                            }
                            status="normal"
                            showInfo={false}
                            strokeColor="#FF9F29"
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>

                  <Col span={12}>
                    {" "}
                    <Row
                      align="middle"
                      style={{
                        border: "1px solid rgba(112,112,112,0.3)",
                        boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                        borderRadius: "16px",
                        padding: "16px",
                      }}
                    >
                      <Col flex={1}>
                        <img
                          src="/assets/yellow-certificate-icon.svg"
                          alt=""
                          style={{ width: "100%", height: "50px" }}
                        />
                      </Col>
                      <Col
                        flex={9}
                        style={{
                          textAlign: "center",
                          marginLeft: "16px",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "20px",
                          }}
                        >
                          {
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.yellow_course,
                              translation.translationObj
                            ).text
                          }
                        </div>
                        <div
                          style={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "12px",
                          }}
                        >
                          {totalCompletedYellow < totalToCompleteYellow
                            ? getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI.completed_lessons,
                                translation.translationObj
                              )
                                .text.replace("%a", totalCompletedYellow)
                                .replace("%b", totalToCompleteYellow)
                            : getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI.completed_yellow_course,
                                translation.translationObj
                              ).text}
                        </div>

                        {(totalCompletedYellow / totalToCompleteYellow) *
                          100 ===
                        100 ? (
                          <div
                            style={{
                              fontFamily: "Poppins-Regular",
                              fontSize: "14px",
                              color: "#FF9F29",
                            }}
                          >
                            {
                              getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI.completed,
                                translation.translationObj
                              ).text
                            }
                          </div>
                        ) : (
                          <Progress
                            percent={
                              (totalCompletedYellow / totalToCompleteYellow) *
                              100
                            }
                            status="normal"
                            showInfo={false}
                            strokeColor="#FF9F29"
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>

                  <Col span={12}>
                    <Row
                      align="middle"
                      style={{
                        border: "1px solid rgba(112,112,112,0.3)",
                        boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                        borderRadius: "16px",
                        padding: "16px",
                      }}
                    >
                      <Col flex={1}>
                        <img
                          src="/assets/green-certificate-icon.svg"
                          alt=""
                          style={{ width: "100%", height: "50px" }}
                        />
                      </Col>
                      <Col
                        flex={9}
                        style={{
                          textAlign: "center",
                          marginLeft: "16px",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "20px",
                          }}
                        >
                          {
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.green_course,
                              translation.translationObj
                            ).text
                          }
                        </div>
                        <div
                          style={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "12px",
                          }}
                        >
                          {totalCompletedGreen < totalToCompleteGreen
                            ? getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI.completed_lessons,
                                translation.translationObj
                              )
                                .text.replace("%a", totalCompletedGreen)
                                .replace("%b", totalToCompleteGreen)
                            : getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI.completed_green_course,
                                translation.translationObj
                              ).text}
                        </div>

                        {(totalCompletedGreen / totalToCompleteGreen) * 100 ===
                        100 ? (
                          <div
                            style={{
                              fontFamily: "Poppins-Regular",
                              fontSize: "14px",
                              color: "#FF9F29",
                            }}
                          >
                            {
                              getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI.completed,
                                translation.translationObj
                              ).text
                            }
                          </div>
                        ) : (
                          <Progress
                            percent={
                              (totalCompletedGreen / totalToCompleteGreen) * 100
                            }
                            status="normal"
                            showInfo={false}
                            strokeColor="#FF9F29"
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>

                  <Col span={12}>
                    <Row
                      align="middle"
                      style={{
                        border: "1px solid rgba(112,112,112,0.3)",
                        boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                        borderRadius: "16px",
                        padding: "16px",
                      }}
                    >
                      <Col flex={1}>
                        <img
                          src="/assets/blue-certificate-icon.svg"
                          alt=""
                          style={{ width: "100%", height: "50px" }}
                        />
                      </Col>
                      <Col
                        flex={9}
                        style={{
                          textAlign: "center",
                          marginLeft: "16px",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "20px",
                          }}
                        >
                          {
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.blue_course,
                              translation.translationObj
                            ).text
                          }
                        </div>
                        <div
                          style={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "12px",
                          }}
                        >
                          {totalCompletedBlue < totalToCompleteBlue
                            ? getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI.completed_lessons,
                                translation.translationObj
                              )
                                .text.replace("%a", totalCompletedBlue)
                                .replace("%b", totalToCompleteBlue)
                            : getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI.completed_blue_course,
                                translation.translationObj
                              ).text}
                        </div>

                        {(totalCompletedBlue / totalToCompleteBlue) * 100 ===
                        100 ? (
                          <div
                            style={{
                              fontFamily: "Poppins-Regular",
                              fontSize: "14px",
                              color: "#FF9F29",
                            }}
                          >
                            {
                              getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI.completed,
                                translation.translationObj
                              ).text
                            }
                          </div>
                        ) : (
                          <Progress
                            percent={
                              (totalCompletedBlue / totalToCompleteBlue) * 100
                            }
                            status="normal"
                            showInfo={false}
                            strokeColor="#FF9F29"
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>

                  <Col span={12}>
                    <Row
                      align="middle"
                      style={{
                        border: "1px solid rgba(112,112,112,0.3)",
                        boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                        borderRadius: "16px",
                        padding: "16px",
                      }}
                    >
                      <Col flex={1}>
                        <img
                          src="/assets/test-icon.svg"
                          alt=""
                          style={{ width: "100%", height: "50px" }}
                        />
                      </Col>
                      <Col
                        flex={9}
                        style={{
                          textAlign: "center",
                          marginLeft: "16px",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "20px",
                          }}
                        >
                          {
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.quiz,
                              translation.translationObj
                            ).text
                          }
                        </div>
                        <div
                          style={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "12px",
                          }}
                        >
                          {totalCompletedQuiz < totalToCompleteQuiz
                            ? getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI.completed_quiz,
                                translation.translationObj
                              )
                                .text.replace("%a", totalCompletedQuiz)
                                .replace("%b", totalToCompleteQuiz)
                            : getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI
                                  .you_have_completed_all_quizzes,
                                translation.translationObj
                              ).text}
                        </div>

                        {(totalCompletedQuiz / totalToCompleteQuiz) * 100 ===
                        100 ? (
                          <div
                            style={{
                              fontFamily: "Poppins-Regular",
                              fontSize: "14px",
                              color: "#FF9F29",
                            }}
                          >
                            {
                              getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI.completed,
                                translation.translationObj
                              ).text
                            }
                          </div>
                        ) : (
                          <Progress
                            percent={
                              (totalCompletedQuiz / totalToCompleteQuiz) * 100
                            }
                            status="normal"
                            showInfo={false}
                            strokeColor="#FF9F29"
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>

                  <Col span={12}>
                    <Row
                      align="middle"
                      style={{
                        border: "1px solid rgba(112,112,112,0.3)",
                        boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                        borderRadius: "16px",
                        padding: "16px",
                      }}
                    >
                      <Col flex={1}>
                        <img
                          src="/assets/exam-stat-icon.svg"
                          alt=""
                          style={{ width: "100%", height: "50px" }}
                        />
                      </Col>
                      <Col
                        flex={9}
                        style={{
                          textAlign: "center",
                          marginLeft: "16px",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "20px",
                          }}
                        >
                          {
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.exam,
                              translation.translationObj
                            ).text
                          }
                        </div>
                        <div
                          style={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "12px",
                          }}
                        >
                          {getNativeLangTransText(
                            dbAuth.userInfo.nativeLanguage,
                            configuration.projectUI.completed_exams,
                            translation.translationObj
                          )
                            .text.replace("%a", totalCompletedExam)
                            .replace(
                              "%b",
                              course.examInfoObj.examInfoList.length
                            )}
                        </div>
                        {(totalCompletedExam /
                          course.examInfoObj.examInfoList.length) *
                          100 ===
                        100 ? (
                          <div
                            style={{
                              fontFamily: "Poppins-Regular",
                              fontSize: "14px",
                              color: "#FF9F29",
                            }}
                          >
                            {
                              getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI.completed,
                                translation.translationObj
                              ).text
                            }
                          </div>
                        ) : (
                          <Progress
                            percent={
                              (totalCompletedExam /
                                course.examInfoObj.examInfoList.length) *
                              100
                            }
                            status="normal"
                            showInfo={false}
                            strokeColor="#FF9F29"
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col xs={22} sm={0} md={0} lg={0} xl={0} xxl={0}>
                <div
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "28px",
                    lineHeight: "normal",
                  }}
                >
                  {dbAuth.userInfo.name}
                </div>
                <div
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "18px",
                    lineHeight: "normal",
                  }}
                >
                  Joined{" "}
                  {moment(dbAuth.userInfo.createdAt.toDate()).format(
                    "DD MMMM YYYY"
                  )}
                  <br />
                  Age: {dbAuth.userInfo.age}
                </div>

                <br />
                <br />

                <div
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "25px",
                    lineHeight: "normal",
                  }}
                >
                  {
                    getNativeLangTransText(
                      dbAuth.userInfo.nativeLanguage,
                      configuration.projectUI.weekly_goal,
                      translation.translationObj
                    ).text
                  }
                </div>

                <Row style={{ marginTop: "16px" }}>
                  <Col offset={1} span={10} style={{ textAlign: "center" }}>
                    <img
                      src="/assets/trophy-icon.svg"
                      alt=""
                      style={{ width: "50px" }}
                    />
                    <Progress
                      percent={
                        (totalCompletedWeeklyLesson /
                          totalToCompleteWeeklyLesson) *
                        100
                      }
                      status="normal"
                      showInfo={false}
                      strokeColor="#FF9F29"
                    />
                    <div
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "16px",
                      }}
                    >
                      {totalCompletedWeeklyLesson < totalToCompleteWeeklyLesson
                        ? getNativeLangTransText(
                            dbAuth.userInfo.nativeLanguage,
                            configuration.projectUI.lesson_completed,
                            translation.translationObj
                          )
                            .text.replace("%a", totalCompletedWeeklyLesson)
                            .replace("%b", totalToCompleteWeeklyLesson)
                        : `You have completed your weekly goal!`}
                    </div>
                  </Col>
                  {/* <Col offset={1} span={7} style={{ textAlign: "center" }}>
                <img src="/assets/play-video-icon.svg" alt="" style={{width:"50px"}} />
                <Progress
                  percent={
                    dbAuth.progressInfo.achievement &&
                    dbAuth.progressInfo.achievement.videoWatched
                      ? 100
                      : 0
                  }
                  status="normal"
                  showInfo={false}
                  strokeColor="#FF9F29"
                />
                <div
                  style={{ fontFamily: "Poppins-Regular", fontSize: "16px" }}
                >
                  Watch the weekly video!
                </div>
              </Col> */}
                  <Col offset={2} span={10} style={{ textAlign: "center" }}>
                    <img
                      src="/assets/test-icon.svg"
                      alt=""
                      style={{ width: "50px" }}
                    />
                    <Progress
                      percent={
                        (totalCompletedWeeklyQuiz / totalToCompleteWeeklyQuiz) *
                        100
                      }
                      status="normal"
                      showInfo={false}
                      strokeColor="#FF9F29"
                    />
                    <div
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "16px",
                      }}
                    >
                      {totalCompletedWeeklyQuiz < totalToCompleteWeeklyQuiz
                        ? `${totalCompletedWeeklyQuiz} out of ${totalToCompleteWeeklyQuiz} Quiz completed!`
                        : `You have completed your weekly goal!`}
                    </div>
                  </Col>
                  <Col span={1} />
                </Row>

                <br />
                <br />

                <div
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "25px",
                    lineHeight: "normal",
                  }}
                >
                  Statistics
                </div>

                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Row
                      align="middle"
                      style={{
                        border: "1px solid rgba(112,112,112,0.3)",
                        boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                        borderRadius: "16px",
                        padding: "16px",
                      }}
                    >
                      <Col flex={1}>
                        <img
                          src="/assets/white-certificate-icon.svg"
                          alt=""
                          style={{ width: "100%", height: "40px" }}
                        />
                      </Col>
                      <Col
                        flex={9}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "18px",
                          }}
                        >
                          {
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.white_course,
                              translation.translationObj
                            ).text
                          }
                        </div>
                        <div
                          style={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "12px",
                          }}
                        >
                          {totalCompletedWhite < totalToCompleteWhite
                            ? getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI.completed_lessons,
                                translation.translationObj
                              )
                                .text.replace("%a", totalCompletedWhite)
                                .replace("%b", totalToCompleteWhite)
                            : getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI.completed_white_course,
                                translation.translationObj
                              ).text}
                        </div>

                        {(totalCompletedWhite / totalToCompleteWhite) * 100 ===
                        100 ? (
                          <div
                            style={{
                              fontFamily: "Poppins-Regular",
                              fontSize: "14px",
                              color: "#FF9F29",
                            }}
                          >
                            {
                              getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI.completed,
                                translation.translationObj
                              ).text
                            }
                          </div>
                        ) : (
                          <Progress
                            percent={
                              (totalCompletedWhite / totalToCompleteWhite) * 100
                            }
                            status="normal"
                            showInfo={false}
                            strokeColor="#FF9F29"
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>

                  <Col span={12}>
                    {" "}
                    <Row
                      align="middle"
                      style={{
                        border: "1px solid rgba(112,112,112,0.3)",
                        boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                        borderRadius: "16px",
                        padding: "16px",
                      }}
                    >
                      <Col flex={1}>
                        <img
                          src="/assets/yellow-certificate-icon.svg"
                          alt=""
                          style={{ width: "100%", height: "40px" }}
                        />
                      </Col>
                      <Col
                        flex={9}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "18px",
                          }}
                        >
                          {
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.yellow_course,
                              translation.translationObj
                            ).text
                          }
                        </div>
                        <div
                          style={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "12px",
                          }}
                        >
                          {totalCompletedYellow < totalToCompleteYellow
                            ? getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI.completed_lessons,
                                translation.translationObj
                              )
                                .text.replace("%a", totalCompletedYellow)
                                .replace("%b", totalToCompleteYellow)
                            : getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI.completed_yellow_course,
                                translation.translationObj
                              ).text}
                        </div>

                        {(totalCompletedYellow / totalToCompleteYellow) *
                          100 ===
                        100 ? (
                          <div
                            style={{
                              fontFamily: "Poppins-Regular",
                              fontSize: "14px",
                              color: "#FF9F29",
                            }}
                          >
                            {
                              getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI.completed,
                                translation.translationObj
                              ).text
                            }
                          </div>
                        ) : (
                          <Progress
                            percent={
                              (totalCompletedYellow / totalToCompleteYellow) *
                              100
                            }
                            status="normal"
                            showInfo={false}
                            strokeColor="#FF9F29"
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>

                  <Col span={12}>
                    <Row
                      align="middle"
                      style={{
                        border: "1px solid rgba(112,112,112,0.3)",
                        boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                        borderRadius: "16px",
                        padding: "16px",
                      }}
                    >
                      <Col flex={1}>
                        <img
                          src="/assets/green-certificate-icon.svg"
                          alt=""
                          style={{ width: "100%", height: "40px" }}
                        />
                      </Col>
                      <Col
                        flex={9}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "18px",
                          }}
                        >
                          {
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.green_course,
                              translation.translationObj
                            ).text
                          }
                        </div>
                        <div
                          style={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "12px",
                          }}
                        >
                          {totalCompletedGreen < totalToCompleteGreen
                            ? getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI.completed_lessons,
                                translation.translationObj
                              )
                                .text.replace("%a", totalCompletedGreen)
                                .replace("%b", totalToCompleteGreen)
                            : getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI.completed_green_course,
                                translation.translationObj
                              ).text}
                        </div>

                        {(totalCompletedGreen / totalToCompleteGreen) * 100 ===
                        100 ? (
                          <div
                            style={{
                              fontFamily: "Poppins-Regular",
                              fontSize: "14px",
                              color: "#FF9F29",
                            }}
                          >
                            {
                              getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI.completed,
                                translation.translationObj
                              ).text
                            }
                          </div>
                        ) : (
                          <Progress
                            percent={
                              (totalCompletedGreen / totalToCompleteGreen) * 100
                            }
                            status="normal"
                            showInfo={false}
                            strokeColor="#FF9F29"
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>

                  <Col span={12}>
                    <Row
                      align="middle"
                      style={{
                        border: "1px solid rgba(112,112,112,0.3)",
                        boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                        borderRadius: "16px",
                        padding: "16px",
                      }}
                    >
                      <Col flex={1}>
                        <img
                          src="/assets/blue-certificate-icon.svg"
                          alt=""
                          style={{ width: "100%", height: "40px" }}
                        />
                      </Col>
                      <Col
                        flex={9}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "18px",
                          }}
                        >
                          {
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.blue_course,
                              translation.translationObj
                            ).text
                          }
                        </div>
                        <div
                          style={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "12px",
                          }}
                        >
                          {totalCompletedBlue < totalToCompleteBlue
                            ? getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI.completed_lessons,
                                translation.translationObj
                              )
                                .text.replace("%a", totalCompletedBlue)
                                .replace("%b", totalToCompleteBlue)
                            : getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI.completed_blue_course,
                                translation.translationObj
                              ).text}
                        </div>

                        {(totalCompletedBlue / totalToCompleteBlue) * 100 ===
                        100 ? (
                          <div
                            style={{
                              fontFamily: "Poppins-Regular",
                              fontSize: "14px",
                              color: "#FF9F29",
                            }}
                          >
                            {
                              getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI.completed,
                                translation.translationObj
                              ).text
                            }
                          </div>
                        ) : (
                          <Progress
                            percent={
                              (totalCompletedBlue / totalToCompleteBlue) * 100
                            }
                            status="normal"
                            showInfo={false}
                            strokeColor="#FF9F29"
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>

                  <Col span={12}>
                    <Row
                      align="middle"
                      style={{
                        border: "1px solid rgba(112,112,112,0.3)",
                        boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                        borderRadius: "16px",
                        padding: "16px",
                      }}
                    >
                      <Col flex={1}>
                        <img
                          src="/assets/test-icon.svg"
                          alt=""
                          style={{ width: "100%", height: "40px" }}
                        />
                      </Col>
                      <Col
                        flex={9}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "18px",
                          }}
                        >
                          {
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.quiz,
                              translation.translationObj
                            ).text
                          }
                        </div>
                        <div
                          style={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "12px",
                          }}
                        >
                          {totalCompletedQuiz < totalToCompleteQuiz
                            ? getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI.completed_quiz,
                                translation.translationObj
                              )
                                .text.replace("%a", totalCompletedQuiz)
                                .replace("%b", totalToCompleteQuiz)
                            : getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI
                                  .you_have_completed_all_quizzes,
                                translation.translationObj
                              )}
                        </div>

                        {(totalCompletedQuiz / totalToCompleteQuiz) * 100 ===
                        100 ? (
                          <div
                            style={{
                              fontFamily: "Poppins-Regular",
                              fontSize: "14px",
                              color: "#FF9F29",
                            }}
                          >
                            {
                              getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI.completed,
                                translation.translationObj
                              ).text
                            }
                          </div>
                        ) : (
                          <Progress
                            percent={
                              (totalCompletedQuiz / totalToCompleteQuiz) * 100
                            }
                            status="normal"
                            showInfo={false}
                            strokeColor="#FF9F29"
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>

                  <Col span={12}>
                    <Row
                      align="middle"
                      style={{
                        border: "1px solid rgba(112,112,112,0.3)",
                        boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                        borderRadius: "16px",
                        padding: "16px",
                      }}
                    >
                      <Col flex={1}>
                        <img
                          src="/assets/exam-stat-icon.svg"
                          alt=""
                          style={{ width: "100%", height: "40px" }}
                        />
                      </Col>
                      <Col
                        flex={9}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "18px",
                          }}
                        >
                          {
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.exam,
                              translation.translationObj
                            ).text
                          }
                        </div>
                        <div
                          style={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "12px",
                          }}
                        >
                          {getNativeLangTransText(
                            dbAuth.userInfo.nativeLanguage,
                            configuration.projectUI.completed_exams,
                            translation.translationObj
                          )
                            .text.replace("%a", totalCompletedExam)
                            .replace(
                              "%b",
                              course.examInfoObj.examInfoList.length
                            )}
                        </div>
                        {(totalCompletedExam /
                          course.examInfoObj.examInfoList.length) *
                          100 ===
                        100 ? (
                          <div
                            style={{
                              fontFamily: "Poppins-Regular",
                              fontSize: "14px",
                              color: "#FF9F29",
                            }}
                          >
                            {
                              getNativeLangTransText(
                                dbAuth.userInfo.nativeLanguage,
                                configuration.projectUI.completed,
                                translation.translationObj
                              ).text
                            }
                          </div>
                        ) : (
                          <Progress
                            percent={
                              (totalCompletedExam /
                                course.examInfoObj.examInfoList.length) *
                              100
                            }
                            status="normal"
                            showInfo={false}
                            strokeColor="#FF9F29"
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col
                xs={0}
                sm={0}
                md={24}
                lg={24}
                xl={24}
                className="footer-bottom"
              >
                <BottomBar />
              </Col>
              <Col
                xs={0}
                sm={24}
                md={0}
                lg={0}
                xl={0}
                style={{ marginBottom: "100px" }}
              >
                <BottomBar />
              </Col>
              <Col
                xs={24}
                sm={0}
                md={0}
                lg={0}
                xl={0}
                style={{ marginBottom: "70px" }}
              >
                <BottomBar />
              </Col>
            </Row>
            <Row>
              <Col
                xs={24}
                sm={24}
                md={0}
                lg={0}
                xl={0}
                style={{
                  textAlign: "center",
                  borderTop: "4px solid #F1F1F1",
                }}
                className="footer"
              >
                <UserDashboardNavBottomBar
                  onHandleResetLabelClick={onHandleResetLabelClick}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  dbAuth: state.auth,
  achievement: state.achievement,
  statistics: state.statistics,
  course: state.course,
  configuration: state.configuration,
  translation: state.translation,
});
const mapDispatchToProps = {
  loadAllExamLevels,
  resetAllExamLevels,
};
export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(StatPage);
 