import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDYElIoVijQA0-Ny5nWq5GodIhnwtU8rgE",
  authDomain: "dev-oe2u.firebaseapp.com",
  databaseURL: "https://dev-oe2u.firebaseio.com",
  projectId: "dev-oe2u",
  storageBucket: "dev-oe2u.appspot.com",
  messagingSenderId: "1083996665230",
  appId: "1:1083996665230:web:2f1dd50e10e34ed2bb60df"
};

const prdEslConfig = {
  apiKey: "AIzaSyAW9kvuyttc9yergj26Pbw8p-E-FBfQluw",
  authDomain: "prd-esl.firebaseapp.com",
  projectId: "prd-esl",
  storageBucket: "prd-esl.appspot.com",
  messagingSenderId: "863888156303",
  appId: "1:863888156303:web:57a7522500b5e95dd7b2fa",
};

//firebase.initializeApp(firebaseConfig);
firebase.initializeApp(prdEslConfig);
firebase.firestore();

export default firebase;
