import React, { Fragment } from "react";
import { Modal, Row, Col } from "antd";


const RetakeExamModal = ({ isVisible, onCancel }) => {
  return (
    <Fragment>
      <Modal
        visible={isVisible}
        onCancel={onCancel}
        closeIcon={<img src="/assets/blue-close-icon.svg" alt="" />}
        footer={null}
        width={400}
      >
        <Row style={{ margin: "24px" }}>
          <Col span={24} style={{ textAlign: "center" }}>
            <div style={{ fontFamily: "Poppins-Bold", fontSize: "25px" }}>
              Would you like to re-take Test 2?
            </div>
            <br />
            <div style={{ fontFamily: "Poppins-Regular", fontSize: "25px" }}>
              Note that this is a timed test, the exam will ends once the timer
              expires.
            </div>
          </Col>
        </Row>

        <Row
          style={{
            borderTop: "1px solid rgba(112,112,112,0.4)",
            textAlign: "center"
          }}
        >
          <Col
            span={12}
            style={{
              fontFamily: "Poppins-Bold",
              fontSize: "25px",
              color: "#4252BE",
              padding: "8px",
              borderRight: "1px solid rgba(112,112,112,0.4)"
            }}
          >
            No
          </Col>
          <Col
            span={12}
            style={{
              fontFamily: "Poppins-Bold",
              fontSize: "25px",
              color: "#4252BE",
              padding: "8px"
            }}
          >
            Start Now
          </Col>
        </Row>
      </Modal>
    </Fragment>
  );
};

export default RetakeExamModal;
