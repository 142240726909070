import React, { Component, Fragment } from "react";
import "./ContentType9Page.css";
import {
  _saveQuizProgress,
  questionPageInit,
  getEnText,
} from "../../../../SharedUtils";
import { operationTypeEnum } from "../../../../OperationTypeConstant";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import _ from "lodash";
import {
  updateQuizProgress,
  updateQuizAchievementProgress,
} from "../../../../auth/authActions";
import { Spin, Button, Col, Row, Progress } from "antd";
import { getNativeLangTransText } from "../../../../SharedUtils";

class ContentType9Page extends Component {
  constructor(props) {
    super(props);
    this._saveQuizProgress = _saveQuizProgress.bind(this);
  }

  state = {
    targetedQuestionList: [],
    currentStepNum: 0,
    isAllQuestionsCompleted: false,

    typedAnswerList: [],
    answerList: [],

    quizTestObj: {
      isAnswerRevealed: false,
    },
    inputValue: "",
  };

  async componentDidMount() {
    const { course, match, dbAuth } = this.props;

    let quizId = match.params.quizId;
    let topicId = match.params.topicId;
    let groupId = match.params.groupId;

    let { first, second, third } = questionPageInit(
      course,
      dbAuth,
      quizId,
      topicId,
      groupId
    );

    //attempts to include potential hint into content
    let answerList = [];
    let typedAnswerList = [];

    let targetedQuestionList = [...first];
    let answers = [...first[second].answers];
    targetedQuestionList[second].contents.forEach((item, idx) => {
      // console.log("DDD ", item);
      if (item === "#ANSWER") {
        // console.log(item, idx);
        if (answers[0] && answers[0].hint.length > 0) {
          answerList[idx] = answers.shift();
          typedAnswerList[idx] = "";
        } else {
          answerList[idx] = answers.shift();
          typedAnswerList[idx] = "";
        }
      }
    });

    // console.log("UU", targetedQuestionList);
    // console.log("WTF", answerList);

    this.setState({
      targetedQuestionList,
      currentStepNum: second,
      isAllQuestionsCompleted: third,
      quizId,
      topicId,
      groupId,
      answerList,
      typedAnswerList,
    });
  }

  componentDidUpdate(prevProps) {
    const { quizId, topicId, completed, groupId } = this.state;
    const { operation, dbAuth, achievement } = this.props;
    if (
      !_.isEqual(prevProps.operation.isProcessing, operation.isProcessing) &&
      !operation.isProcessing
    ) {
      if (
        operation.operationType === operationTypeEnum.NO_OPS ||
        (prevProps.operation.operationType ===
          operationTypeEnum.UPDATE_QUIZ_PROGRESS &&
          operation.operationType === operationTypeEnum.NO_OPS)
      ) {
        if (
          dbAuth.progressInfo.achievement &&
          dbAuth.progressInfo.achievement.quiz
        ) {
          let totaltoCompelete =
            achievement.currentWeekAchievement.quiz.totalToComplete;
          let totalCompleted = Object.entries(
            dbAuth.progressInfo.achievement.quiz
          ).length;
          if (totaltoCompelete >= totalCompleted && completed) {
            console.log("successfully done");
            this.props.history.push(
              `/user-dashboard/quiz/all-quizzes/${quizId}/topics/${topicId}/groups/${groupId}/quiz_achievement`
            );
            let buttonSound = new Audio("/assets/Lesson_done.mp3");
            buttonSound.play();
          } else {
            this.props.history.push(
              `/user-dashboard/quiz/all-quizzes/${quizId}/topics/${topicId}`
            );
          }
        } else {
          this.props.history.push(
            `/user-dashboard/quiz/all-quizzes/${quizId}/topics/${topicId}`
          );
        }
      }
    }
  }

  onNextButtonClick = () => {
    let selectButtonSound = new Audio("/assets/Select_sound03.mp3");
    selectButtonSound.play();
    const { targetedQuestionList, currentStepNum } = this.state;
    if (currentStepNum === targetedQuestionList.length - 1) {
      //Note : below equivalent to currentStepNumber + 1 === targetedQuestionList.length
      //save full progress here...
      this._saveQuizProgress();
    } else {
      let answerList = [];
      let typedAnswerList = [];
      let answers = targetedQuestionList[currentStepNum + 1].answers;
      targetedQuestionList[currentStepNum + 1].contents.forEach((item, idx) => {
        if (item === "#ANSWER") {
          if (answers[0] && answers[0].hint.length > 0) {
            answerList[idx] = answers.shift();
            typedAnswerList[idx] = "";
          } else {
            answerList[idx] = answers.shift();
            typedAnswerList[idx] = "";
            console.log(typedAnswerList);
          }
        }
      });

      window.scrollTo(0, 0);
      this.setState((prevState) => ({
        currentStepNum: prevState.currentStepNum + 1,
        quizTestObj: {
          typedAnswerList: [],
          isAnswerRevealed: false,
        },
        answerList: answerList,
        typedAnswerList: typedAnswerList,
        inputValue: "",
      }));
    }
  };

  onCheckAnswerButtonClick = () => {
    this.setState((prevState) => ({
      quizTestObj: {
        ...prevState.quizTestObj,
        isAnswerRevealed: true,
      },
    }));
  };

  updateInputValue = (idx, evt) => {
    let value = evt.target.value;
    this.setState((prevState) => {
      let test = prevState.typedAnswerList;
      test[idx] = value;
      return {
        typedAnswerList: test,
        inputValue: value,
      };
    });
  };

  onUpButtonClick = async () => {
    this._saveQuizProgress();
  };
  render() {
    const {
      targetedQuestionList,
      currentStepNum,
      quizTestObj,
      answerList,
      typedAnswerList,
      inputValue,
    } = this.state;
    const { course, operation, translation, dbAuth, configuration } =
      this.props;

    if (
      targetedQuestionList.length === 0 ||
      answerList.length !== typedAnswerList.length ||
      answerList.length === 0
    )
      return null;

    return (
      <Fragment>
        <Spin
          tip="Saving quiz progress..."
          spinning={operation.isProcessing}
          size="large"
        >
          <Row>
            <Col xs={1} sm={2} md={3} lg={4} xl={6} xxl={6} />
            <Col
              xs={0}
              sm={0}
              md={18}
              lg={16}
              xl={12}
              xxl={12}
              style={{
                marginTop: "32px",
                // minHeight: "850px"
              }}
            >
              <div style={{ display: "flex" }}>
                <img
                  src="/assets/back-arrow-icon.svg"
                  alt=""
                  style={{ marginRight: "32px", cursor: "pointer" }}
                  onClick={this.onUpButtonClick}
                />
                <Progress
                  percent={
                    ((currentStepNum + 1) / targetedQuestionList.length) * 100
                  }
                  status="normal"
                  showInfo={false}
                  strokeColor={{ "0%": "#82ABF7", "100%": "#4252BE" }}
                  strokeWidth="50px"
                />
              </div>

              <br />
              <br />

              <div
                style={{
                  //fontFamily: "Poppins-Regular",
                  fontSize: "23px",
                  lineHeight: "normal",
                }}
              >
                {/* {targetedQuizQuestionObj.description} */}
                {getEnText(
                  course.quizQuestionInfoObj.questionGroupObj.description,
                  translation.translationObj
                ).isHtml ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: getEnText(
                        course.quizQuestionInfoObj.questionGroupObj.description
                      ).text,
                    }}
                  />
                ) : (
                  <span style={{ fontFamily: "Poppins-Regular" }}>
                    {
                      getEnText(
                        course.quizQuestionInfoObj.questionGroupObj.description,
                        translation.translationObj
                      ).text
                    }
                  </span>
                )}
              </div>

              <br />
              <br />
              <br />

              <Row align="bottom">
                {targetedQuestionList[currentStepNum].contents.map(
                  (item, idx) => {
                    if (item === "#ANSWER") {
                      // console.log(answerList, idx);
                      return (
                        <Fragment key={idx}>
                          <Col
                            style={{
                              fontFamily: "Poppins-Bold",
                              fontSize: "20px",
                              padding: "0px 4px",
                              color: "#cec0e7",
                            }}
                          >
                            {/* {console.log(idx)} */}
                            {answerList[idx] &&
                              answerList[idx].hint &&
                              `(${answerList[idx].hint})`}
                          </Col>

                          <Col
                            style={{
                              fontFamily: "Poppins-Bold",
                              fontSize: "20px",
                              padding: "0px 4px",
                            }}
                          >
                            <span
                              style={{
                                color: "#049F00",
                                fontFamily: "Poppins-Bold",
                                fontSize: "18px",
                                visibility: quizTestObj.isAnswerRevealed
                                  ? typedAnswerList[idx] ===
                                    answerList[idx].answer
                                    ? "hidden"
                                    : "visible"
                                  : "hidden",
                              }}
                            >
                              {answerList[idx] && answerList[idx].answer}
                            </span>
                            {/* {console.log(answerList[idx])} */}
                            <div
                              style={{
                                display: "flex",
                                border: "1px solid #4252BE",
                                padding: "4px",
                                borderRadius: "8px",
                                background: quizTestObj.isAnswerRevealed
                                  ? typedAnswerList[idx] ===
                                    answerList[idx].answer
                                    ? "#D0F8CF"
                                    : "#FFF7F7"
                                  : null,
                              }}
                            >
                              <img
                                src={
                                  quizTestObj.isAnswerRevealed
                                    ? typedAnswerList[idx] ===
                                      answerList[idx].answer
                                      ? "/assets/checked-circle-icon.svg"
                                      : "/assets/clear-circle-icon.svg"
                                    : null
                                }
                                alt=""
                                style={{ marginRight: "4px" }}
                              />
                              <input
                                style={{
                                  width: "100%",
                                  fontFamily: "Poppins-Regular",
                                  fontSize: "16px",
                                  border: "none",
                                  outline: "none",
                                  background: "inherit",
                                }}
                                disabled={quizTestObj.isAnswerRevealed}
                                //   defaultValue={answerList[currentStepNum][idx]}
                                // disabled={true}
                                value={inputValue}
                                onChange={(e) => this.updateInputValue(idx, e)}
                              />
                            </div>
                          </Col>
                        </Fragment>
                      );
                    } else {
                      return (
                        <Col
                          key={idx}
                          style={{
                            fontFamily: "Poppins-Bold",
                            fontSize: "20px",
                            padding: "0px 4px",
                          }}
                        >
                          {item}
                        </Col>
                      );
                    }
                  }
                )}
              </Row>
            </Col>

            <Col
              xs={22}
              sm={20}
              md={0}
              lg={0}
              xl={0}
              xxl={0}
              style={{
                marginTop: "32px",
                // minHeight: "850px"
              }}
            >
              <div style={{ display: "flex" }}>
                <img
                  src="/assets/back-arrow-icon.svg"
                  alt=""
                  style={{
                    marginRight: "32px",
                    cursor: "pointer",
                    width: "16px",
                  }}
                  onClick={this.onUpButtonClick}
                />
                <Progress
                  percent={
                    ((currentStepNum + 1) / targetedQuestionList.length) * 100
                  }
                  status="normal"
                  showInfo={false}
                  strokeColor={{ "0%": "#82ABF7", "100%": "#4252BE" }}
                  strokeWidth="30px"
                />
              </div>

              <br />
              <br />

              <div
                style={{
                  //fontFamily: "Poppins-Regular",
                  fontSize: "18px",
                  lineHeight: "normal",
                }}
              >
                {/* {targetedQuizQuestionObj.description} */}
                {getEnText(
                  course.quizQuestionInfoObj.questionGroupObj.description,
                  translation.translationObj
                ).isHtml ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: getEnText(
                        course.quizQuestionInfoObj.questionGroupObj.description
                      ).text,
                    }}
                  />
                ) : (
                  <span style={{ fontFamily: "Poppins-Regular" }}>
                    {
                      getEnText(
                        course.quizQuestionInfoObj.questionGroupObj.description,
                        translation.translationObj
                      ).text
                    }
                  </span>
                )}
              </div>

              <br />
              <br />
              <br />

              <Row align="bottom">
                {targetedQuestionList[currentStepNum].contents.map(
                  (item, idx) => {
                    if (item === "#ANSWER") {
                      // console.log(answerList, idx);
                      return (
                        <Fragment key={idx}>
                          <Col
                            style={{
                              fontFamily: "Poppins-Bold",
                              fontSize: "16px",
                              padding: "0px 4px",
                              color: "#cec0e7",
                            }}
                          >
                            {/* {console.log(idx)} */}
                            {answerList[idx] &&
                              answerList[idx].hint &&
                              `(${answerList[idx].hint})`}
                          </Col>

                          <Col
                            style={{
                              fontFamily: "Poppins-Bold",
                              fontSize: "16px",
                              // padding: "0px 4px"
                            }}
                          >
                            <span
                              style={{
                                color: "#049F00",
                                fontFamily: "Poppins-Bold",
                                fontSize: "13px",
                                visibility: quizTestObj.isAnswerRevealed
                                  ? typedAnswerList[idx] === answerList[idx] &&
                                    answerList[idx].answer
                                    ? "hidden"
                                    : "visible"
                                  : "hidden",
                              }}
                            >
                              {answerList[idx] && answerList[idx].answer}
                            </span>

                            <div
                              style={{
                                display: "flex",
                                border: "1px solid #4252BE",
                                padding: "2px",
                                borderRadius: "8px",
                                background: quizTestObj.isAnswerRevealed
                                  ? typedAnswerList[idx] === answerList[idx] &&
                                    answerList[idx].answer
                                    ? "#D0F8CF"
                                    : "#FFF7F7"
                                  : null,
                              }}
                            >
                              <img
                                src={
                                  quizTestObj.isAnswerRevealed
                                    ? typedAnswerList[idx] ===
                                        answerList[idx] &&
                                      answerList[idx].answer
                                      ? "/assets/checked-circle-icon.svg"
                                      : "/assets/clear-circle-icon.svg"
                                    : null
                                }
                                alt=""
                                style={{ marginRight: "4px" }}
                              />
                              <input
                                style={{
                                  width: "100%",
                                  fontFamily: "Poppins-Regular",
                                  fontSize: "14px",
                                  border: "none",
                                  outline: "none",
                                  background: "inherit",
                                }}
                                //   defaultValue={answerList[currentStepNum][idx]}
                                // disabled={true}
                                onChange={(e) => this.updateInputValue(idx, e)}
                              />
                            </div>
                          </Col>
                        </Fragment>
                      );
                    } else {
                      return (
                        <Col
                          key={idx}
                          style={{
                            fontFamily: "Poppins-Bold",
                            fontSize: "16px",
                            padding: "0px 4px",
                          }}
                        >
                          {item}
                        </Col>
                      );
                    }
                  }
                )}
              </Row>
            </Col>

            <Col span={6} />

            <Col
              span={24}
              className="footer"
              style={{
                borderTop: "4px solid #F1F1F1",
              }}
            >
              {quizTestObj.isAnswerRevealed ? (
                <Button
                  type="primary"
                  style={{
                    margin: "24px 0px",
                    borderRadius: "24px",
                    height: "auto",
                    fontFamily: "Poppins-Bold",
                    fontSize: "23px",
                    width: "250px",
                    boxShadow: "0px 6px 0  #2A3580",
                  }}
                  onClick={this.onNextButtonClick}
                >
                  {
                    getNativeLangTransText(
                      dbAuth.userInfo.nativeLanguage,
                      configuration.projectUI.next,
                      translation.translationObj
                    ).text
                  }
                </Button>
              ) : (
                <Button
                  // disabled={quizTestObj.typedAnswerList === ""}
                  type="primary"
                  style={{
                    margin: "24px 0px",
                    borderRadius: "24px",
                    height: "auto",
                    fontFamily: "Poppins-Bold",
                    fontSize: "23px",
                    width: "250px",
                    boxShadow: "0px 6px 0  #2A3580",
                  }}
                  // disabled={quizTestObj.tappedAnswerIdx === -1}
                  onClick={this.onCheckAnswerButtonClick}
                >
                  {
                    getNativeLangTransText(
                      dbAuth.userInfo.nativeLanguage,
                      configuration.projectUI.check,
                      translation.translationObj
                    ).text
                  }
                </Button>
              )}
            </Col>
          </Row>
        </Spin>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  course: state.course,
  translation: state.translation,
  dbAuth: state.auth,
  operation: state.operation,
  achievement: state.achievement,
  configuration: state.configuration,
});

const mapDispatchToProps = {
  updateQuizProgress,
  updateQuizAchievementProgress,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ContentType9Page);
