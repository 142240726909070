import React, { Component, Fragment } from "react";
import "./CoursePage.css";
import { Row, Col } from "antd";
import BottomBar from "../../../BottomBar/BottomBar";
import UserDashboardNavBar from "../../UserDashboardNavBar/UserDashboardNavBar";
import UserDashboardNavBottomBar from "../../UserDashboardNavBar/UserDashboardNavBottomBar";
import WeeklyGoal from "../../WeeklyGoal/WeeklyGoal";
import { Link } from "react-router-dom";
import { getNativeLangTransText } from "../../../SharedUtils";
import { compose } from "redux";
import { connect } from "react-redux";

class CoursePage extends Component {
  render() {
    const { onHandleResetLabelClick, dbAuth, configuration, translation } =
      this.props;
    return (
      <Fragment>
        <UserDashboardNavBar
          onHandleResetLabelClick={onHandleResetLabelClick}
        />
        <Row style={{ marginBottom: "16px" }}>
          <Col offset={1} span={22}>
            <Row>
              <Col xs={2} sm={4} md={1} lg={2} xl={4} xxl={5} />
              <Col xs={0} sm={16} md={11} lg={10} xl={8} xxl={7}>
                <Row>
                  <Col span={24}>
                    <Row align="middle">
                      <Col flex="80px">
                        <img
                          src="/assets/owl-watching-icon.svg"
                          alt=""
                          style={{ width: "100%" }}
                        />
                      </Col>
                      <Col flex="auto" style={{ marginLeft: "8px" }}>
                        <div
                          style={{
                            fontFamily: "Poppins-Bold",
                            fontSize: "35px",
                            lineHeight: "normal",
                          }}
                        >
                          {
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.choose_what,
                              translation.translationObj
                            ).text
                          }
                        </div>
                        <div
                          style={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "23px",
                            lineHeight: "normal",
                          }}
                        >
                          {
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.courses_to_learn_today,
                              translation.translationObj
                            ).text
                          }
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24} style={{ marginTop: "16px" }}>
                    <Row gutter={[60, 60]} style={{ textAlign: "center" }}>
                      <Col span={12}>
                        <Link
                          to="/user-dashboard/courses/beginner"
                          className="course-page-text-wrapper"
                          style={{
                            color: "black",
                            background: "white",
                            fontFamily: "Poppins-Regular",
                            fontSize: "23px",
                          }}
                        >
                          {
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.white,
                              translation.translationObj
                            ).text
                          }
                          <br />
                          {
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.beginner,
                              translation.translationObj
                            ).text
                          }
                        </Link>
                      </Col>
                      <Col span={12}>
                        <Link
                          to="/user-dashboard/courses/intermediate"
                          className="course-page-text-wrapper"
                          style={{
                            color: "black",
                            background: "#FFF173",
                            fontFamily: "Poppins-Regular",
                            fontSize: "23px",
                          }}
                        >
                          {
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.yellow,
                              translation.translationObj
                            ).text
                          }
                          <br />
                          {
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.intermediate,
                              translation.translationObj
                            ).text
                          }
                        </Link>
                      </Col>
                      <Col span={12}>
                        <Link
                          className="course-page-text-wrapper"
                          to="/user-dashboard/courses/advanced"
                          style={{
                            color: "black",
                            background: "#9ED65D",
                            fontFamily: "Poppins-Regular",
                            fontSize: "23px",
                          }}
                        >
                          {
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.green,
                              translation.translationObj
                            ).text
                          }
                          <br />
                          {
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.advanced,
                              translation.translationObj
                            ).text
                          }
                        </Link>
                      </Col>
                      <Col span={12}>
                        <Link
                          className="course-page-text-wrapper"
                          to="/user-dashboard/courses/expert"
                          style={{
                            color: "black",
                            background: "#7EC4FB",
                            fontFamily: "Poppins-Regular",
                            fontSize: "23px",
                          }}
                        >
                          {
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.blue,
                              translation.translationObj
                            ).text
                          }
                          <br />
                          {
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.expert,
                              translation.translationObj
                            ).text
                          }
                        </Link>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {/* <ChooseLesson/> */}
                {/* <SIR2000Topics /> */}
                {/* <SIR2000TopicsGroup /> */}
              </Col>
              <Col xs={20} sm={0} md={0} lg={0} xl={0} xxl={0}>
                <Row>
                  <Col span={24}>
                    <Row align="middle">
                      <Col flex="80px">
                        <img
                          src="/assets/owl-watching-icon.svg"
                          alt=""
                          style={{ width: "80%" }}
                        />
                      </Col>
                      <Col flex="auto" style={{ marginLeft: "8px" }}>
                        <div
                          style={{
                            fontFamily: "Poppins-Bold",
                            fontSize: "28px",
                            lineHeight: "normal",
                          }}
                        >
                          {
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.choose_what,
                              translation.translationObj
                            ).text
                          }
                        </div>
                        <div
                          style={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "18px",
                            lineHeight: "normal",
                          }}
                        >
                          {
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.courses_to_learn_today,
                              translation.translationObj
                            ).text
                          }
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24} style={{ marginTop: "16px" }}>
                    <Row gutter={[60, 60]} style={{ textAlign: "center" }}>
                      <Col span={12}>
                        <Link
                          to="/user-dashboard/courses/beginner"
                          className="course-page-text-wrapper-small"
                          style={{
                            color: "black",
                            background: "white",
                            fontFamily: "Poppins-Regular",
                            fontSize: "18px",
                          }}
                        >
                          {
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.white,
                              translation.translationObj
                            ).text
                          }
                          <br />
                          {
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.beginner,
                              translation.translationObj
                            ).text
                          }
                        </Link>
                      </Col>
                      <Col span={12}>
                        <Link
                          to="/user-dashboard/courses/intermediate"
                          className="course-page-text-wrapper-small"
                          style={{
                            color: "black",
                            background: "#FFF173",
                            fontFamily: "Poppins-Regular",
                            fontSize: "18px",
                          }}
                        >
                          {
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.yellow,
                              translation.translationObj
                            ).text
                          }
                          <br />
                          {
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.intermediate,
                              translation.translationObj
                            ).text
                          }
                        </Link>
                      </Col>
                      <Col span={12}>
                        <Link
                          className="course-page-text-wrapper-small"
                          to="/user-dashboard/courses/advanced"
                          style={{
                            color: "black",
                            background: "#9ED65D",
                            fontFamily: "Poppins-Regular",
                            fontSize: "18px",
                          }}
                        >
                          {
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.green,
                              translation.translationObj
                            ).text
                          }
                          <br />
                          {
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.advanced,
                              translation.translationObj
                            ).text
                          }
                        </Link>
                      </Col>
                      <Col span={12}>
                        <Link
                          className="course-page-text-wrapper-small"
                          to="/user-dashboard/courses/expert"
                          style={{
                            color: "black",
                            background: "#7EC4FB",
                            fontFamily: "Poppins-Regular",
                            fontSize: "18px",
                          }}
                        >
                          {
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.blue,
                              translation.translationObj
                            ).text
                          }
                          <br />
                          {
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.expert,
                              translation.translationObj
                            ).text
                          }
                        </Link>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col xs={3} sm={5} md={1} lg={1} xl={1} xxl={1} />
              <Col xs={18} sm={14} md={10} lg={9} xl={7} xxl={6}>
                <WeeklyGoal />
              </Col>
            </Row>
          </Col>
          <Col span={6} />
        </Row>
        <Row>
          <Col xs={0} sm={0} md={24} lg={24} xl={24}>
            <BottomBar />
          </Col>
          <Col
            xs={0}
            sm={24}
            md={0}
            lg={0}
            xl={0}
            style={{ marginBottom: "100px" }}
          >
            <BottomBar />
          </Col>
          <Col
            xs={24}
            sm={0}
            md={0}
            lg={0}
            xl={0}
            style={{ marginBottom: "70px" }}
          >
            <BottomBar />
          </Col>
        </Row>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={0}
            lg={0}
            xl={0}
            style={{
              textAlign: "center",
              borderTop: "4px solid #F1F1F1",
            }}
            className="footer"
          >
            <UserDashboardNavBottomBar
              onHandleResetLabelClick={onHandleResetLabelClick}
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  dbAuth: state.auth,
  configuration: state.configuration,
  translation: state.translation,
});

export default compose(connect(mapStateToProps))(CoursePage);
