import React, { Fragment, Component } from "react";
import { Progress, Row, Col, Spin } from "antd";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";
import { withFirebase } from "react-redux-firebase";
import {
  loadAllExamLevels,
  resetAllExamLevels,
} from "../../course/courseActions";
import { getNativeLangTransText } from "../../SharedUtils";
import _ from "lodash";

class WeeklyGoal extends Component {
  state = {
    loading: true,
    thisWeekMenuObj: {},
    dbAuth: this.props.dbAuth,
    achievement: this.props.achievement,
    statistics: this.props.statistics,
  };

  async componentDidMount() {
    await this.props.loadAllExamLevels();
  }

  componentDidUpdate(prevProps) {
    const { course } = this.props;
    const { loading } = this.state;
    const { dbAuth, statistics, achievement } = this.props;

    // console.log(course.examInfoObj)
    if (
      !_.isEqual(prevProps.course.examInfoObj, course.examInfoObj) ||
      (loading && course.examInfoObj) ||
      !_.isEqual(prevProps.dbAuth.progressInfo, dbAuth.progressInfo)
    ) {
      var totalWhiteCompleted = 0;
      if (dbAuth.progressInfo.lessons && dbAuth.progressInfo.lessons.beginner) {
        for (const [topic, object] of Object.entries(
          dbAuth.progressInfo.lessons.beginner
        )) {
          for (const [statisticsTopic, statisticsObject] of Object.entries(
            statistics.statisticsObj.lessons.beginner
          )) {
            if (
              topic === statisticsTopic &&
              statisticsObject === object.length
            ) {
              totalWhiteCompleted += 1;
            }
          }
        }
      }

      var totalYellowCompleted = 0;
      if (
        dbAuth.progressInfo.lessons &&
        dbAuth.progressInfo.lessons.intermediate
      ) {
        for (const [topic, object] of Object.entries(
          dbAuth.progressInfo.lessons.intermediate
        )) {
          for (const [statisticsTopic, statisticsObject] of Object.entries(
            statistics.statisticsObj.lessons.intermediate
          )) {
            if (
              topic === statisticsTopic &&
              statisticsObject === object.length
            ) {
              totalYellowCompleted += 1;
            }
          }
        }
      }
      var totalGreenCompleted = 0;
      if (dbAuth.progressInfo.lessons && dbAuth.progressInfo.lessons.advanced) {
        for (const [topic, object] of Object.entries(
          dbAuth.progressInfo.lessons.advanced
        )) {
          for (const [statisticsTopic, statisticsObject] of Object.entries(
            statistics.statisticsObj.lessons.advanced
          )) {
            if (
              topic === statisticsTopic &&
              statisticsObject === object.length
            ) {
              totalGreenCompleted += 1;
            }
          }
        }
      }
      var totalBlueCompleted = 0;
      if (dbAuth.progressInfo.lessons && dbAuth.progressInfo.lessons.expert) {
        // console.log(dbAuth.progressInfo.lessons.expert)
        for (const [topic, object] of Object.entries(
          dbAuth.progressInfo.lessons.expert
        )) {
          for (const [statisticsTopic, statisticsObject] of Object.entries(
            statistics.statisticsObj.lessons.expert
          )) {
            if (
              topic === statisticsTopic &&
              statisticsObject === object.length
            ) {
              totalBlueCompleted += 1;
            }
          }
        }
      }

      var totalQuizCompleted = 0;
      if (dbAuth.progressInfo.quiz && dbAuth.progressInfo.quiz.beginner) {
        for (const [topic, object] of Object.entries(
          dbAuth.progressInfo.quiz.beginner
        )) {
          for (const [statisticsTopic, statisticsObject] of Object.entries(
            statistics.statisticsObj.quiz.beginner
          )) {
            if (
              topic === statisticsTopic &&
              statisticsObject === object.length
            ) {
              totalQuizCompleted += 1;
            }
          }
        }
      }
      if (dbAuth.progressInfo.quiz && dbAuth.progressInfo.quiz.intermediate) {
        for (const [topic, object] of Object.entries(
          dbAuth.progressInfo.quiz.intermediate
        )) {
          for (const [statisticsTopic, statisticsObject] of Object.entries(
            statistics.statisticsObj.quiz.intermediate
          )) {
            if (
              topic === statisticsTopic &&
              statisticsObject === object.length
            ) {
              totalQuizCompleted += 1;
            }
          }
        }
      }
      if (dbAuth.progressInfo.quiz && dbAuth.progressInfo.quiz.advanced) {
        for (const [topic, object] of Object.entries(
          dbAuth.progressInfo.quiz.advanced
        )) {
          for (const [statisticsTopic, statisticsObject] of Object.entries(
            statistics.statisticsObj.quiz.advanced
          )) {
            if (
              topic === statisticsTopic &&
              statisticsObject === object.length
            ) {
              totalQuizCompleted += 1;
            }
          }
        }
      }
      if (dbAuth.progressInfo.quiz && dbAuth.progressInfo.quiz.expert) {
        for (const [topic, object] of Object.entries(
          dbAuth.progressInfo.quiz.expert
        )) {
          for (const [statisticsTopic, statisticsObject] of Object.entries(
            statistics.statisticsObj.quiz.expert
          )) {
            if (
              topic === statisticsTopic &&
              statisticsObject === object.length
            ) {
              totalQuizCompleted += 1;
            }
          }
        }
      }
      var totalQuizToComplete = Object.keys(
        statistics.statisticsObj.quiz.beginner
      ).length;
      totalQuizToComplete += Object.keys(
        statistics.statisticsObj.quiz.intermediate
      ).length;
      totalQuizToComplete += Object.keys(
        statistics.statisticsObj.quiz.advanced
      ).length;
      totalQuizToComplete += Object.keys(
        statistics.statisticsObj.quiz.expert
      ).length;

      var totalCompletedLessonCount = 0;
      var totalCompletedQuizCount = 0;

      if (dbAuth.progressInfo.achievement) {
        if (dbAuth.progressInfo.achievement.quiz) {
          for (const object of Object.values(
            dbAuth.progressInfo.achievement.quiz
          )) {
            // console.log(topic, object.completed)
            if (object.completed) {
              totalCompletedQuizCount += 1;
            }
          }
        }
        if (dbAuth.progressInfo.achievement.lesson) {
          for (const object of Object.values(
            dbAuth.progressInfo.achievement.lesson
          )) {
            // console.log(topic, object.completed)
            if (object.completed) {
              totalCompletedLessonCount += 1;
            }
          }
        }
      }

      this.setState({
        totalCompletedWhite: totalWhiteCompleted,
        totalToCompleteWhite: Object.keys(
          statistics.statisticsObj.lessons.beginner
        ).length,
        totalCompletedYellow: totalYellowCompleted,
        totalToCompleteYellow: Object.keys(
          statistics.statisticsObj.lessons.intermediate
        ).length,
        totalCompletedGreen: totalGreenCompleted,
        totalToCompleteGreen: Object.keys(
          statistics.statisticsObj.lessons.advanced
        ).length,
        totalCompletedBlue: totalBlueCompleted,
        totalToCompleteBlue: Object.keys(
          statistics.statisticsObj.lessons.expert
        ).length,
        totalCompletedQuiz: totalQuizCompleted,
        totalToCompleteQuiz: totalQuizToComplete,
        totalToCompleteWeeklyLesson:
          achievement.currentWeekAchievement.lesson &&
          achievement.currentWeekAchievement.lesson.totalToComplete
            ? achievement.currentWeekAchievement.lesson.totalToComplete
            : 0,
        totalToCompleteWeeklyQuiz:
          achievement.currentWeekAchievement.quiz &&
          achievement.currentWeekAchievement.quiz.totalToComplete
            ? achievement.currentWeekAchievement.quiz.totalToComplete
            : 0,
        totalCompletedWeeklyQuiz: totalCompletedQuizCount,
        totalCompletedWeeklyLesson: totalCompletedLessonCount,
      });

      // console.log(course)

      var totalExamCompleted = 0;
      if (dbAuth.progressInfo.exams) {
        for (const [topic, object] of Object.entries(
          dbAuth.progressInfo.exams
        )) {
          // console.log(topic)
          for (const examObjects of Object.values(
            course.examInfoObj.examInfoList
          )) {
            if (topic === examObjects.docId) {
              var completedTest = 0;
              // console.log(Object.values(object))
              for (const mark of Object.values(object)) {
                if (mark > 49) {
                  completedTest += 1;
                  // console.log(completedTest)
                }
                if (completedTest === 4) {
                  totalExamCompleted += 1;
                }
              }
            }
          }
        }
      }
      // if (achievement.currentWeekAchievement.lesson.totalToComplete ){ console.log(achievement.currentWeekAchievement.lesson.totalToComplete) }else console.log(0)}
      // console.log(course)
      this.setState({
        loading: false,
        course: course,
        totalCompletedExam: totalExamCompleted,
      });
    }
  }

  // componentWillUnmount() {
  //   this.props.resetAllExamLevels();
  // }

  render() {
    const {
      loading,
      course,
      totalToCompleteWeeklyLesson,
      totalToCompleteWeeklyQuiz,
      totalCompletedWeeklyQuiz,
      totalCompletedWeeklyLesson,
      totalCompletedWhite,
      totalToCompleteWhite,
      totalCompletedYellow,
      totalToCompleteYellow,
      totalCompletedGreen,
      totalToCompleteGreen,
      totalCompletedBlue,
      totalToCompleteBlue,
      totalCompletedExam,
      totalCompletedQuiz,
      totalToCompleteQuiz,
    } = this.state;
    // console.log(course)
    const { translation, configuration, dbAuth } = this.props;
    if (loading) {
      return (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin />
        </div>
      );
    }
    return (
      <Fragment>
        <Row style={{ marginBottom: "16px" }}>
          <Col xs={0} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <div
              style={{
                border: "2px solid rgba(112,112,112,0.3)",
                //   boxShadow: "0 0 5px rgba(0,0,0,0.4)",
                borderRadius: "16px",
                padding: "48px",
              }}
            >
              <div style={{ fontFamily: "Poppins-Bold", fontSize: "23px" }}>
                {
                  getNativeLangTransText(
                    dbAuth.userInfo.nativeLanguage,
                    configuration.projectUI.weekly_goal,
                    translation.translationObj
                  ).text
                }
              </div>
              <div style={{ textAlign: "center", marginTop: "16px" }}>
                <img src="/assets/trophy-icon.svg" alt="" />
                <Progress
                  percent={
                    (totalCompletedWeeklyLesson / totalToCompleteWeeklyLesson) *
                    100
                  }
                  status="normal"
                  showInfo={false}
                  strokeColor="#FF9F29"
                />
                <div
                  style={{ fontFamily: "Poppins-Regular", fontSize: "20px" }}
                >
                  {totalCompletedWeeklyLesson < totalToCompleteWeeklyLesson
                    ? getNativeLangTransText(
                        dbAuth.userInfo.nativeLanguage,
                        configuration.projectUI.lesson_completed,
                        translation.translationObj
                      )
                        .text.replace("%a", totalCompletedWeeklyLesson)
                        .replace("%b", totalToCompleteWeeklyLesson)
                    : getNativeLangTransText(
                        dbAuth.userInfo.nativeLanguage,
                        configuration.projectUI
                          .you_have_completed_your_weekly_goal,
                        translation.translationObj
                      ).text}
                </div>
              </div>
              <br />

              <br />
              <div style={{ textAlign: "center", marginTop: "16px" }}>
                <img src="/assets/test-icon.svg" alt="" />
                <Progress
                  percent={
                    (totalCompletedWeeklyQuiz / totalToCompleteWeeklyQuiz) * 100
                  }
                  status="normal"
                  showInfo={false}
                  strokeColor="#FF9F29"
                />
                <div
                  style={{ fontFamily: "Poppins-Regular", fontSize: "20px" }}
                >
                  {totalCompletedWeeklyQuiz < totalToCompleteWeeklyQuiz
                    ? getNativeLangTransText(
                        dbAuth.userInfo.nativeLanguage,
                        configuration.projectUI.quiz_completed,
                        translation.translationObj
                      )
                        .text.replace("%a", totalCompletedWeeklyQuiz)
                        .replace("%b", totalToCompleteWeeklyQuiz)
                    : getNativeLangTransText(
                        dbAuth.userInfo.nativeLanguage,
                        configuration.projectUI
                          .you_have_completed_your_weekly_goal,
                        translation.translationObj
                      ).text}
                </div>
              </div>
              <br />
              {this.props.match.path === "/user-dashboard/courses" ||
              this.props.match.path.includes("/user-dashboard/quiz") ||
              this.props.match.path.includes("/user-dashboard/exam") ? (
                <div style={{ fontFamily: "Poppins-Bold", fontSize: "23px" }}>
                  {
                    getNativeLangTransText(
                      dbAuth.userInfo.nativeLanguage,
                      configuration.projectUI.statistic,
                      translation.translationObj
                    ).text
                  }
                </div>
              ) : null}
              <br />
              {this.props.match.path === "/user-dashboard/courses" ||
              this.props.match.params.courseId === "beginner" ? (
                <Row
                  align="middle"
                  style={{
                    border: "1px solid rgba(112,112,112,0.3)",
                    boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                    borderRadius: "16px",
                    padding: "16px",
                  }}
                >
                  <Col flex={1}>
                    <img
                      src="/assets/white-certificate-icon.svg"
                      alt=""
                      style={{ width: "100%", height: "50px" }}
                    />
                  </Col>
                  <Col
                    flex={9}
                    style={{
                      textAlign: "center",
                      marginLeft: "16px",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "20px",
                      }}
                    >
                      {
                        getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          configuration.projectUI.white_course,
                          translation.translationObj
                        ).text
                      }
                    </div>
                    <div
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "12px",
                      }}
                    >
                      {totalCompletedWhite < totalToCompleteWhite
                        ? getNativeLangTransText(
                            dbAuth.userInfo.nativeLanguage,
                            configuration.projectUI.completed_lessons,
                            translation.translationObj
                          )
                            .text.replace("%a", totalCompletedWhite)
                            .replace("%b", totalToCompleteWhite)
                        : getNativeLangTransText(
                            dbAuth.userInfo.nativeLanguage,
                            configuration.projectUI.completed_white_course,
                            translation.translationObj
                          ).text}
                    </div>

                    {(totalCompletedWhite / totalToCompleteWhite) * 100 ===
                    100 ? (
                      <div
                        style={{
                          fontFamily: "Poppins-Regular",
                          fontSize: "14px",
                          color: "#FF9F29",
                        }}
                      >
                        Completed
                      </div>
                    ) : (
                      <Progress
                        percent={
                          (totalCompletedWhite / totalToCompleteWhite) * 100
                        }
                        status="normal"
                        showInfo={false}
                        strokeColor="#FF9F29"
                      />
                    )}
                  </Col>
                </Row>
              ) : null}

              {this.props.match.path === "/user-dashboard/courses" ||
              this.props.match.params.courseId === "beginner" ? (
                <br />
              ) : null}

              {this.props.match.path === "/user-dashboard/courses" ||
              this.props.match.params.courseId === "intermediate" ? (
                <Row
                  align="middle"
                  style={{
                    border: "1px solid rgba(112,112,112,0.3)",
                    boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                    borderRadius: "16px",
                    padding: "16px",
                  }}
                >
                  <Col flex={1}>
                    <img
                      src="/assets/yellow-certificate-icon.svg"
                      alt=""
                      style={{ width: "100%", height: "50px" }}
                    />
                  </Col>
                  <Col
                    flex={9}
                    style={{
                      textAlign: "center",
                      marginLeft: "16px",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "20px",
                      }}
                    >
                      {
                        getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          configuration.projectUI.yellow_course,
                          translation.translationObj
                        ).text
                      }
                    </div>
                    <div
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "12px",
                      }}
                    >
                      {totalCompletedYellow < totalToCompleteYellow
                        ? getNativeLangTransText(
                            dbAuth.userInfo.nativeLanguage,
                            configuration.projectUI.completed_lessons,
                            translation.translationObj
                          )
                            .text.replace("%a", totalCompletedYellow)
                            .replace("%b", totalToCompleteYellow)
                        : getNativeLangTransText(
                            dbAuth.userInfo.nativeLanguage,
                            configuration.projectUI.completed_yellow_course,
                            translation.translationObj
                          ).text}
                    </div>

                    {(totalCompletedYellow / totalToCompleteYellow) * 100 ===
                    100 ? (
                      <div
                        style={{
                          fontFamily: "Poppins-Regular",
                          fontSize: "14px",
                          color: "#FF9F29",
                        }}
                      >
                        Completed
                      </div>
                    ) : (
                      <Progress
                        percent={
                          (totalCompletedYellow / totalToCompleteYellow) * 100
                        }
                        status="normal"
                        showInfo={false}
                        strokeColor="#FF9F29"
                      />
                    )}
                  </Col>
                </Row>
              ) : null}
              {this.props.match.path === "/user-dashboard/courses" ||
              this.props.match.params.courseId === "intermediate" ? (
                <br />
              ) : null}

              {this.props.match.path === "/user-dashboard/courses" ||
              this.props.match.params.courseId === "advanced" ? (
                <Row
                  align="middle"
                  style={{
                    border: "1px solid rgba(112,112,112,0.3)",
                    boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                    borderRadius: "16px",
                    padding: "16px",
                  }}
                >
                  <Col flex={1}>
                    <img
                      src="/assets/green-certificate-icon.svg"
                      alt=""
                      style={{ width: "100%", height: "50px" }}
                    />
                  </Col>
                  <Col
                    flex={9}
                    style={{
                      textAlign: "center",
                      marginLeft: "16px",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "20px",
                      }}
                    >
                      {
                        getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          configuration.projectUI.green_course,
                          translation.translationObj
                        ).text
                      }
                    </div>
                    <div
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "12px",
                      }}
                    >
                      {totalCompletedGreen < totalToCompleteGreen
                        ? getNativeLangTransText(
                            dbAuth.userInfo.nativeLanguage,
                            configuration.projectUI.completed_lessons,
                            translation.translationObj
                          )
                            .text.replace("%a", totalCompletedGreen)
                            .replace("%b", totalToCompleteGreen)
                        : getNativeLangTransText(
                            dbAuth.userInfo.nativeLanguage,
                            configuration.projectUI.completed_green_course,
                            translation.translationObj
                          ).text}
                    </div>

                    {(totalCompletedGreen / totalToCompleteGreen) * 100 ===
                    100 ? (
                      <div
                        style={{
                          fontFamily: "Poppins-Regular",
                          fontSize: "14px",
                          color: "#FF9F29",
                        }}
                      >
                        Completed
                      </div>
                    ) : (
                      <Progress
                        percent={
                          (totalCompletedGreen / totalToCompleteGreen) * 100
                        }
                        status="normal"
                        showInfo={false}
                        strokeColor="#FF9F29"
                      />
                    )}
                  </Col>
                </Row>
              ) : null}

              {this.props.match.path === "/user-dashboard/courses" ||
              this.props.match.params.courseId === "advanced" ? (
                <br />
              ) : null}

              {this.props.match.path === "/user-dashboard/courses" ||
              this.props.match.params.courseId === "expert" ? (
                <Row
                  align="middle"
                  style={{
                    border: "1px solid rgba(112,112,112,0.3)",
                    boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                    borderRadius: "16px",
                    padding: "16px",
                  }}
                >
                  <Col flex={1}>
                    <img
                      src="/assets/blue-certificate-icon.svg"
                      alt=""
                      style={{ width: "100%", height: "50px" }}
                    />
                  </Col>
                  <Col
                    flex={9}
                    style={{
                      textAlign: "center",
                      marginLeft: "16px",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "20px",
                      }}
                    >
                      {
                        getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          configuration.projectUI.blue_course,
                          translation.translationObj
                        ).text
                      }
                    </div>
                    <div
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "12px",
                      }}
                    >
                      {totalCompletedBlue < totalToCompleteBlue
                        ? getNativeLangTransText(
                            dbAuth.userInfo.nativeLanguage,
                            configuration.projectUI.completed_lessons,
                            translation.translationObj
                          )
                            .text.replace("%a", totalCompletedBlue)
                            .replace("%b", totalToCompleteBlue)
                        : getNativeLangTransText(
                            dbAuth.userInfo.nativeLanguage,
                            configuration.projectUI.completed_blue_course,
                            translation.translationObj
                          ).text}
                    </div>

                    {(totalCompletedBlue / totalToCompleteBlue) * 100 ===
                    100 ? (
                      <div
                        style={{
                          fontFamily: "Poppins-Regular",
                          fontSize: "14px",
                          color: "#FF9F29",
                        }}
                      >
                        Completed
                      </div>
                    ) : (
                      <Progress
                        percent={
                          (totalCompletedBlue / totalToCompleteBlue) * 100
                        }
                        status="normal"
                        showInfo={false}
                        strokeColor="#FF9F29"
                      />
                    )}
                  </Col>
                </Row>
              ) : null}

              {/* {this.props.match.path === "/user-dashboard/courses" ||
          this.props.match.params.courseId === "expert" ? (
            <br />
          ) : null} */}

              {this.props.match.path.includes("/user-dashboard/quiz") ? (
                <Row
                  align="middle"
                  style={{
                    border: "1px solid rgba(112,112,112,0.3)",
                    boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                    borderRadius: "16px",
                    padding: "16px",
                  }}
                >
                  <Col flex={1}>
                    <img
                      src="/assets/test-icon.svg"
                      alt=""
                      style={{ width: "100%", height: "50px" }}
                    />
                  </Col>
                  <Col
                    flex={9}
                    style={{
                      textAlign: "center",
                      marginLeft: "16px",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "20px",
                      }}
                    >
                      {
                        getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          configuration.projectUI.quiz,
                          translation.translationObj
                        ).text
                      }
                    </div>
                    <div
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "12px",
                      }}
                    >
                      {totalCompletedQuiz < totalToCompleteQuiz
                        ? getNativeLangTransText(
                            dbAuth.userInfo.nativeLanguage,
                            configuration.projectUI.completed_quiz,
                            translation.translationObj
                          )
                            .text.replace("%a", totalCompletedQuiz)
                            .replace("%b", totalToCompleteQuiz)
                        : getNativeLangTransText(
                            dbAuth.userInfo.nativeLanguage,
                            configuration.projectUI
                              .you_have_completed_all_quizzes,
                            translation.translationObj
                          ).text}
                    </div>

                    {(totalCompletedQuiz / totalToCompleteQuiz) * 100 ===
                    100 ? (
                      <div
                        style={{
                          fontFamily: "Poppins-Regular",
                          fontSize: "14px",
                          color: "#FF9F29",
                        }}
                      >
                        Completed
                      </div>
                    ) : (
                      <Progress
                        percent={
                          (totalCompletedQuiz / totalToCompleteQuiz) * 100
                        }
                        status="normal"
                        showInfo={false}
                        strokeColor="#FF9F29"
                      />
                    )}
                  </Col>
                </Row>
              ) : null}

              {this.props.match.path === "/user-dashboard/quiz" ? <br /> : null}

              {this.props.match.path.includes("/user-dashboard/exam") ? (
                <Row
                  align="middle"
                  style={{
                    border: "1px solid rgba(112,112,112,0.3)",
                    boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                    borderRadius: "16px",
                    padding: "16px",
                  }}
                >
                  <Col flex={1}>
                    <img
                      src="/assets/exam-stat-icon.svg"
                      alt=""
                      style={{ width: "100%", height: "50px" }}
                    />
                  </Col>
                  <Col
                    flex={9}
                    style={{
                      textAlign: "center",
                      marginLeft: "16px",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "20px",
                      }}
                    >
                      {
                        getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          configuration.projectUI.exam,
                          translation.translationObj
                        ).text
                      }
                    </div>
                    <div
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "12px",
                      }}
                    >
                      {getNativeLangTransText(
                        dbAuth.userInfo.nativeLanguage,
                        configuration.projectUI.completed_exams,
                        translation.translationObj
                      )
                        .text.replace("%a", totalCompletedExam)
                        .replace("%b", course.examInfoObj.examInfoList.length)}
                    </div>
                    {(totalCompletedExam /
                      course.examInfoObj.examInfoList.length) *
                      100 ===
                    100 ? (
                      <div
                        style={{
                          fontFamily: "Poppins-Regular",
                          fontSize: "14px",
                          color: "#FF9F29",
                        }}
                      >
                        Completed
                      </div>
                    ) : (
                      <Progress
                        percent={
                          (totalCompletedExam /
                            course.examInfoObj.examInfoList.length) *
                          100
                        }
                        status="normal"
                        showInfo={false}
                        strokeColor="#FF9F29"
                      />
                    )}
                  </Col>
                </Row>
              ) : null}

              {/* <div style={{ textAlign: "center" }}>
            <Button
              type="link"
              style={{
                fontFamily: "Poppins-Bold",
                fontSize: "22px",
                textAlign: "center"
              }}
            >
              View all
            </Button>
          </div> */}
            </div>
          </Col>

          <Col xs={24} sm={0} md={0} lg={0} xl={0} xxl={0}>
            <div
              style={{
                border: "2px solid rgba(112,112,112,0.3)",
                //   boxShadow: "0 0 5px rgba(0,0,0,0.4)",
                borderRadius: "16px",
                padding: "38px",
              }}
            >
              <div style={{ fontFamily: "Poppins-Bold", fontSize: "20px" }}>
                {
                  getNativeLangTransText(
                    dbAuth.userInfo.nativeLanguage,
                    configuration.projectUI.weekly_goal,
                    translation.translationObj
                  ).text
                }
              </div>
              <div style={{ textAlign: "center", marginTop: "16px" }}>
                <img src="/assets/trophy-icon.svg" alt="" width="50px" />
                <Progress
                  percent={
                    (totalCompletedWeeklyLesson / totalToCompleteWeeklyLesson) *
                    100
                  }
                  status="normal"
                  showInfo={false}
                  strokeColor="#FF9F29"
                />
                <div
                  style={{ fontFamily: "Poppins-Regular", fontSize: "18px" }}
                >
                  {totalCompletedWeeklyLesson < totalToCompleteWeeklyLesson
                    ? getNativeLangTransText(
                        dbAuth.userInfo.nativeLanguage,
                        configuration.projectUI.lesson_completed,
                        translation.translationObj
                      )
                        .text.replace("%a", totalCompletedWeeklyLesson)
                        .replace("%b", totalToCompleteWeeklyLesson)
                    : getNativeLangTransText(
                        dbAuth.userInfo.nativeLanguage,
                        configuration.projectUI
                          .you_have_completed_your_weekly_goal,
                        translation.translationObj
                      ).text}
                </div>
              </div>
              <br />
              {/* <div style={{ textAlign: "center", marginTop: "16px" }}>
            <img src="/assets/play-video-icon.svg" alt="" width="50px" />
            <Progress
              percent={
                dbAuth.progressInfo.achievement &&
                dbAuth.progressInfo.achievement.videoWatched
                  ? 100
                  : 0
              }
              status="normal"
              showInfo={false}
              strokeColor="#FF9F29"
            />
            <div style={{ fontFamily: "Poppins-Regular", fontSize: "18px" }}>
              Watch the weekly video
            </div> */}
              {/* <Button
              type="link"
              style={{ fontFamily: "Poppins-Bold", fontSize: "22px" }}
            >
              View all
            </Button> */}
              {/* </div> */}
              <br />
              <div style={{ textAlign: "center", marginTop: "16px" }}>
                <img src="/assets/test-icon.svg" alt="" width="50px" />
                <Progress
                  percent={
                    (totalCompletedWeeklyQuiz / totalToCompleteWeeklyQuiz) * 100
                  }
                  status="normal"
                  showInfo={false}
                  strokeColor="#FF9F29"
                />
                <div
                  style={{ fontFamily: "Poppins-Regular", fontSize: "18px" }}
                >
                  {totalCompletedWeeklyQuiz < totalToCompleteWeeklyQuiz
                    ? getNativeLangTransText(
                        dbAuth.userInfo.nativeLanguage,
                        configuration.projectUI.quiz_completed,
                        translation.translationObj
                      )
                        .text.replace("%a", totalCompletedWeeklyQuiz)
                        .replace("%b", totalToCompleteWeeklyQuiz)
                    : getNativeLangTransText(
                        dbAuth.userInfo.nativeLanguage,
                        configuration.projectUI
                          .you_have_completed_your_weekly_goal,
                        translation.translationObj
                      ).text}
                </div>
              </div>
              <br />
              {this.props.match.path === "/user-dashboard/courses" ||
              this.props.match.path.includes("/user-dashboard/quiz") ||
              this.props.match.path.includes("/user-dashboard/exam") ? (
                <div style={{ fontFamily: "Poppins-Bold", fontSize: "20px" }}>
                  {
                    getNativeLangTransText(
                      dbAuth.userInfo.nativeLanguage,
                      configuration.projectUI.statistic,
                      translation.translationObj
                    ).text
                  }
                </div>
              ) : null}
              <br />
              {this.props.match.path === "/user-dashboard/courses" ||
              this.props.match.params.courseId === "beginner" ? (
                <Row
                  align="middle"
                  style={{
                    border: "1px solid rgba(112,112,112,0.3)",
                    boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                    borderRadius: "16px",
                    padding: "16px",
                  }}
                >
                  <Col flex={1}>
                    <img
                      src="/assets/white-certificate-icon.svg"
                      alt=""
                      style={{ width: "100%", height: "40px" }}
                    />
                  </Col>
                  <Col
                    flex={9}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "18px",
                      }}
                    >
                      {
                        getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          configuration.projectUI.white_course,
                          translation.translationObj
                        ).text
                      }
                    </div>
                    <div
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "12px",
                      }}
                    >
                      {totalCompletedWhite < totalToCompleteWhite
                        ? getNativeLangTransText(
                            dbAuth.userInfo.nativeLanguage,
                            configuration.projectUI.completed_lessons,
                            translation.translationObj
                          )
                            .text.replace("%a", totalCompletedWhite)
                            .replace("%b", totalToCompleteWhite)
                        : getNativeLangTransText(
                            dbAuth.userInfo.nativeLanguage,
                            configuration.projectUI.completed_white_course,
                            translation.translationObj
                          ).text}
                    </div>

                    {(totalCompletedWhite / totalToCompleteWhite) * 100 ===
                    100 ? (
                      <div
                        style={{
                          fontFamily: "Poppins-Regular",
                          fontSize: "14px",
                          color: "#FF9F29",
                        }}
                      >
                        Completed
                      </div>
                    ) : (
                      <Progress
                        percent={
                          (totalCompletedWhite / totalToCompleteWhite) * 100
                        }
                        status="normal"
                        showInfo={false}
                        strokeColor="#FF9F29"
                      />
                    )}
                  </Col>
                </Row>
              ) : null}

              {this.props.match.path === "/user-dashboard/courses" ||
              this.props.match.params.courseId === "behinner" ? (
                <br />
              ) : null}

              {this.props.match.path === "/user-dashboard/courses" ||
              this.props.match.params.courseId === "intermediate" ? (
                <Row
                  align="middle"
                  style={{
                    border: "1px solid rgba(112,112,112,0.3)",
                    boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                    borderRadius: "16px",
                    padding: "16px",
                  }}
                >
                  <Col flex={1}>
                    <img
                      src="/assets/yellow-certificate-icon.svg"
                      alt=""
                      style={{ width: "100%", height: "50px" }}
                    />
                  </Col>
                  <Col
                    flex={9}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "18px",
                      }}
                    >
                      {
                        getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          configuration.projectUI.yellow_course,
                          translation.translationObj
                        ).text
                      }
                    </div>
                    <div
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "12px",
                      }}
                    >
                      {totalCompletedYellow < totalToCompleteYellow
                        ? getNativeLangTransText(
                            dbAuth.userInfo.nativeLanguage,
                            configuration.projectUI.completed_lessons,
                            translation.translationObj
                          )
                            .text.replace("%a", totalCompletedYellow)
                            .replace("%b", totalToCompleteYellow)
                        : getNativeLangTransText(
                            dbAuth.userInfo.nativeLanguage,
                            configuration.projectUI.completed_yellow_course,
                            translation.translationObj
                          ).text}
                    </div>

                    {(totalCompletedYellow / totalToCompleteYellow) * 100 ===
                    100 ? (
                      <div
                        style={{
                          fontFamily: "Poppins-Regular",
                          fontSize: "14px",
                          color: "#FF9F29",
                        }}
                      >
                        Completed
                      </div>
                    ) : (
                      <Progress
                        percent={
                          (totalCompletedYellow / totalToCompleteYellow) * 100
                        }
                        status="normal"
                        showInfo={false}
                        strokeColor="#FF9F29"
                      />
                    )}
                  </Col>
                </Row>
              ) : null}
              {this.props.match.path === "/user-dashboard/courses" ||
              this.props.match.params.courseId === "intermediate" ? (
                <br />
              ) : null}

              {this.props.match.path === "/user-dashboard/courses" ||
              this.props.match.params.courseId === "advanced" ? (
                <Row
                  align="middle"
                  style={{
                    border: "1px solid rgba(112,112,112,0.3)",
                    boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                    borderRadius: "16px",
                    padding: "16px",
                  }}
                >
                  <Col flex={1}>
                    <img
                      src="/assets/green-certificate-icon.svg"
                      alt=""
                      style={{ width: "100%", height: "50px" }}
                    />
                  </Col>
                  <Col
                    flex={9}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "18px",
                      }}
                    >
                      {
                        getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          configuration.projectUI.green_course,
                          translation.translationObj
                        ).text
                      }
                    </div>
                    <div
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "12px",
                      }}
                    >
                      {totalCompletedGreen < totalToCompleteGreen
                        ? getNativeLangTransText(
                            dbAuth.userInfo.nativeLanguage,
                            configuration.projectUI.completed_lessons,
                            translation.translationObj
                          )
                            .text.replace("%a", totalCompletedGreen)
                            .replace("%b", totalToCompleteGreen)
                        : getNativeLangTransText(
                            dbAuth.userInfo.nativeLanguage,
                            configuration.projectUI.completed_green_course,
                            translation.translationObj
                          ).text}
                    </div>

                    {(totalCompletedGreen / totalToCompleteGreen) * 100 ===
                    100 ? (
                      <div
                        style={{
                          fontFamily: "Poppins-Regular",
                          fontSize: "14px",
                          color: "#FF9F29",
                        }}
                      >
                        Completed
                      </div>
                    ) : (
                      <Progress
                        percent={
                          (totalCompletedGreen / totalToCompleteGreen) * 100
                        }
                        status="normal"
                        showInfo={false}
                        strokeColor="#FF9F29"
                      />
                    )}
                  </Col>
                </Row>
              ) : null}

              {this.props.match.path === "/user-dashboard/courses" ||
              this.props.match.params.courseId === "advanced" ? (
                <br />
              ) : null}

              {this.props.match.path === "/user-dashboard/courses" ||
              this.props.match.params.courseId === "expert" ? (
                <Row
                  align="middle"
                  style={{
                    border: "1px solid rgba(112,112,112,0.3)",
                    boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                    borderRadius: "16px",
                    padding: "16px",
                  }}
                >
                  <Col flex={1}>
                    <img
                      src="/assets/blue-certificate-icon.svg"
                      alt=""
                      style={{ width: "100%", height: "50px" }}
                    />
                  </Col>
                  <Col
                    flex={9}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "18px",
                      }}
                    >
                      {
                        getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          configuration.projectUI.blue_course,
                          translation.translationObj
                        ).text
                      }
                    </div>
                    <div
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "12px",
                      }}
                    >
                      {totalCompletedBlue < totalToCompleteBlue
                        ? getNativeLangTransText(
                            dbAuth.userInfo.nativeLanguage,
                            configuration.projectUI.completed_lessons,
                            translation.translationObj
                          )
                            .text.replace("%a", totalCompletedBlue)
                            .replace("%b", totalToCompleteBlue)
                        : getNativeLangTransText(
                            dbAuth.userInfo.nativeLanguage,
                            configuration.projectUI.completed_blue_course,
                            translation.translationObj
                          ).text}
                    </div>

                    {(totalCompletedBlue / totalToCompleteBlue) * 100 ===
                    100 ? (
                      <div
                        style={{
                          fontFamily: "Poppins-Regular",
                          fontSize: "14px",
                          color: "#FF9F29",
                        }}
                      >
                        Completed
                      </div>
                    ) : (
                      <Progress
                        percent={
                          (totalCompletedBlue / totalToCompleteBlue) * 100
                        }
                        status="normal"
                        showInfo={false}
                        strokeColor="#FF9F29"
                      />
                    )}
                  </Col>
                </Row>
              ) : null}

              {/* {this.props.match.path === "/user-dashboard/courses" ||
          this.props.match.params.courseId === "expert" ? (
            <br />
          ) : null} */}

              {this.props.match.path.includes("/user-dashboard/quiz") ? (
                <Row
                  align="middle"
                  style={{
                    border: "1px solid rgba(112,112,112,0.3)",
                    boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                    borderRadius: "16px",
                    padding: "16px",
                  }}
                >
                  <Col flex={1}>
                    <img
                      src="/assets/test-icon.svg"
                      alt=""
                      style={{ width: "100%", height: "40px" }}
                    />
                  </Col>
                  <Col
                    flex={9}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "18px",
                      }}
                    >
                      {
                        getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          configuration.projectUI.quiz,
                          translation.translationObj
                        ).text
                      }
                    </div>
                    <div
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "12px",
                      }}
                    >
                      {totalCompletedQuiz < totalToCompleteQuiz
                        ? getNativeLangTransText(
                            dbAuth.userInfo.nativeLanguage,
                            configuration.projectUI.completed_quiz,
                            translation.translationObj
                          )
                            .text.replace("%a", totalCompletedQuiz)
                            .replace("%b", totalToCompleteQuiz)
                        : getNativeLangTransText(
                            dbAuth.userInfo.nativeLanguage,
                            configuration.projectUI
                              .you_have_completed_all_quizzes,
                            translation.translationObj
                          ).text}
                    </div>

                    {(totalCompletedQuiz / totalToCompleteQuiz) * 100 ===
                    100 ? (
                      <div
                        style={{
                          fontFamily: "Poppins-Regular",
                          fontSize: "14px",
                          color: "#FF9F29",
                        }}
                      >
                        Completed
                      </div>
                    ) : (
                      <Progress
                        percent={
                          (totalCompletedQuiz / totalToCompleteQuiz) * 100
                        }
                        status="normal"
                        showInfo={false}
                        strokeColor="#FF9F29"
                      />
                    )}
                  </Col>
                </Row>
              ) : null}

              {this.props.match.path === "/user-dashboard/quiz" ? <br /> : null}

              {this.props.match.path.includes("/user-dashboard/exam") ? (
                <Row
                  align="middle"
                  style={{
                    border: "1px solid rgba(112,112,112,0.3)",
                    boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                    borderRadius: "16px",
                    padding: "16px",
                  }}
                >
                  <Col flex={1}>
                    <img
                      src="/assets/exam-stat-icon.svg"
                      alt=""
                      style={{ width: "100%", height: "50px" }}
                    />
                  </Col>
                  <Col
                    flex={9}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "18px",
                      }}
                    >
                      {
                        getNativeLangTransText(
                          dbAuth.userInfo.nativeLanguage,
                          configuration.projectUI.exam,
                          translation.translationObj
                        ).text
                      }
                    </div>
                    <div
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "12px",
                      }}
                    >
                      {getNativeLangTransText(
                        dbAuth.userInfo.nativeLanguage,
                        configuration.projectUI.completed_exams,
                        translation.translationObj
                      )
                        .text.replace("%a", totalCompletedExam)
                        .replace("%b", course.examInfoObj.examInfoList.length)}
                    </div>
                    {(totalCompletedExam /
                      course.examInfoObj.examInfoList.length) *
                      100 ===
                    100 ? (
                      <div
                        style={{
                          fontFamily: "Poppins-Regular",
                          fontSize: "14px",
                          color: "#FF9F29",
                        }}
                      >
                        Completed
                      </div>
                    ) : (
                      <Progress
                        percent={
                          (totalCompletedExam /
                            course.examInfoObj.examInfoList.length) *
                          100
                        }
                        status="normal"
                        showInfo={false}
                        strokeColor="#FF9F29"
                      />
                    )}
                  </Col>
                </Row>
              ) : null}
            </div>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  dbAuth: state.auth,
  statistics: state.statistics,
  achievement: state.achievement,
  course: state.course,
  configuration: state.configuration,
  translation: state.translation,
});
const mapDispatchToProps = {
  loadAllExamLevels,
  resetAllExamLevels,
};

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(WeeklyGoal);
