import React, { Component, Fragment } from "react";
import "./ExamLevelDetailsPage.css";
import UserDashboardNavBar from "../../UserDashboardNavBar/UserDashboardNavBar";
import UserDashboardNavBottomBar from "../../UserDashboardNavBar/UserDashboardNavBottomBar";
import { Row, Col, Progress, Breadcrumb, Button } from "antd";
import WeeklyGoal from "../../WeeklyGoal/WeeklyGoal";
import BottomBar from "../../../BottomBar/BottomBar";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  loadTargetedExamGroups,
  resetTargetedExamGroups,
} from "../../../course/courseActions";
import { withRouter } from "react-router";
import _ from "lodash";
import LoadingComponent from "../../../app/common/loading/LoadingComponent";
import { getNativeLangTransText } from "../../../SharedUtils";
import { Link } from "react-router-dom";

class ExamLevelDetailsPage extends Component {
  state = {
    isLoading: true,
    dbAuth: this.props.dbAuth,
  };

  componentDidMount() {
    const { match } = this.props;
    let examId = match.params.examId;
    this.setState(
      {
        examId,
      },
      () => this.props.loadTargetedExamGroups(examId)
    );
  }

  componentDidUpdate(prevProps) {
    const { course } = this.props;
    const { dbAuth } = this.state;
    // console.log("trigger");
    if (
      !_.isEqual(prevProps.course.examGroupInfoObj, course.examGroupInfoObj) &&
      course.examGroupInfoObj !== null
    ) {
      // console.log("refresh");
      var totalCompletedTest = 0;
      if (dbAuth.progressInfo.exams) {
        for (const [topic, object] of Object.entries(
          dbAuth.progressInfo.exams
        )) {
          if (course.examGroupInfoObj.examInfoObj.docId === topic) {
            for (const mark of Object.values(object)) {
              if (mark > 49) {
                totalCompletedTest += 1;
              }
            }
          }
        }
      }

      var userMarks = [];
      course.examGroupInfoObj.examGroupInfoList.forEach((test) => {
        // console.log(test)
        let testName = `test${test.name}`;
        if (dbAuth.progressInfo.exams) {
          if (
            dbAuth.progressInfo.exams[course.examGroupInfoObj.examInfoObj.docId]
          ) {
            if (
              dbAuth.progressInfo.exams[
                course.examGroupInfoObj.examInfoObj.docId
              ][testName]
            ) {
              userMarks.push(
                dbAuth.progressInfo.exams[
                  course.examGroupInfoObj.examInfoObj.docId
                ][testName]
              );
              // console.log(dbAuth.progressInfo.exams[course.examGroupInfoObj.examInfoObj.docId][testName])
            } else {
              userMarks.push(-1);
            }
          } else {
            userMarks.push(-1);
          }
        } else {
          userMarks.push(-1);
        }
      });
      console.log(userMarks.some((value) => value < 49));
      console.log(userMarks);
      this.setState({
        totalCompletedTest: totalCompletedTest,
        userMarks: userMarks,
        isLoading: false,
      });
    }
  }

  componentWillUnmount() {
    this.props.resetTargetedExamGroups();
  }

  onTestLabelClick = (groupId) => {
    const { examId } = this.state;
    this.props.history.push(`/user-dashboard/exam/${examId}/groups/${groupId}`);
  };

  onExamsClick = () => {
    this.props.history.push(`/user-dashboard/exam`);
  };

  onViewCertClick = () => {
    const { examId } = this.state;
    this.props.history.push(`/user-dashboard/exam/${examId}/certificate`);
  };

  render() {
    const { isLoading, totalCompletedTest, userMarks } = this.state;
    const {
      course,
      onHandleResetLabelClick,
      dbAuth,
      translation,
      configuration,
    } = this.props;

    if (isLoading) return <LoadingComponent />;

    return (
      <Fragment>
        <UserDashboardNavBar
          onHandleResetLabelClick={onHandleResetLabelClick}
        />
        <Row>
          <Col className="page-container" span={24}>
            <Row style={{ marginBottom: "16px" }}>
              <Col xs={2} sm={4} md={2} lg={4} xl={5} xxl={6} />
              <Col
                xs={20}
                sm={16}
                md={20}
                lg={16}
                xl={14}
                xxl={12}
                className="content-wrap"
              >
                <Breadcrumb
                  style={{ fontFamily: "Poppins-Bold", fontSize: "22px" }}
                >
                  <Breadcrumb.Item onClick={this.onExamsClick}>
                    Exams
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    {course.examGroupInfoObj.examInfoObj.name}
                  </Breadcrumb.Item>
                </Breadcrumb>
                <Row>
                  <Col xs={0} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <Row>
                      <Col span={24}>
                        <Link
                          style={{
                            fontFamily: "Poppins-Bold",
                            fontSize: "22px",
                            color: "#4252BE",
                            cursor: "pointer",
                          }}
                          to={"/user-dashboard/exam"}
                        >
                          {"< Back"}
                        </Link>
                        <br />
                        <Progress
                          percent={
                            (totalCompletedTest /
                              course.examGroupInfoObj.examGroupInfoList
                                .length) *
                            100
                          }
                          status="normal"
                          showInfo={false}
                          strokeColor="#82ABF7"
                          strokeWidth="25px"
                        />
                        <div
                          style={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "20px",
                          }}
                        >
                          {`${totalCompletedTest} out of ${course.examGroupInfoObj.examGroupInfoList.length} Tests Completed`}
                        </div>
                        <br />

                        <Row gutter={[32, 32]}>
                          {course.examGroupInfoObj.examGroupInfoList.map(
                            (item, idx) => {
                              if (userMarks[idx] > 49) {
                                return (
                                  <Col span={12} key={idx}>
                                    <div
                                      style={{
                                        borderColor: "green",
                                        borderRadius: "50%",
                                        borderStyle: "solid",
                                        width: "100%",
                                        paddingBottom: "100%",
                                        textAlign: "center",
                                        boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.onTestLabelClick(item.docId)
                                      }
                                    >
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "50%",
                                          left: "50%",
                                          transform: "translate(-50%, -50%)",
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontFamily: "Poppins-Bold",
                                            fontSize: "18px",
                                            color: "green",
                                          }}
                                        >
                                          Test {idx + 1}
                                        </span>
                                        <br />
                                        <span
                                          style={{
                                            fontFamily: "Poppins-Regular",
                                            fontSize: "18px",
                                            color: "green",
                                          }}
                                        >
                                          {`Pass - ${userMarks[idx]}%`}
                                        </span>
                                      </div>
                                    </div>
                                  </Col>
                                );
                              } else {
                                return (
                                  <Col span={12} key={idx}>
                                    <div
                                      style={{
                                        borderRadius: "50%",
                                        width: "100%",
                                        paddingBottom: "100%",
                                        textAlign: "center",
                                        boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.onTestLabelClick(item.docId)
                                      }
                                    >
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "50%",
                                          left: "50%",
                                          transform: "translate(-50%, -50%)",
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontFamily: "Poppins-Bold",
                                            fontSize: "18px",
                                          }}
                                        >
                                          Test {idx + 1}
                                        </span>
                                        <br />
                                        <span
                                          style={{
                                            fontFamily: "Poppins-Regular",
                                            fontSize: "18px",
                                          }}
                                        >
                                          {/* {console.log(`test${idx + 1}`)}
                                    {console.log(Object.keys(dbAuth.progressInfo.exams[course.examGroupInfoObj.examInfoObj.docId])[idx])}
                                      {console.log(`test${idx + 1}` === Object.keys(dbAuth.progressInfo.exams[course.examGroupInfoObj.examInfoObj.docId])[idx])} */}
                                          {userMarks[idx] !== -1
                                            ? userMarks[idx] > 49
                                              ? `Pass - ${userMarks[idx]}%`
                                              : `${userMarks[idx]}% - Try Again`
                                            : "Not Started"}
                                        </span>
                                      </div>
                                    </div>
                                  </Col>
                                );
                              }
                            }
                          )}
                        </Row>
                        {userMarks.some((value) => value < 49) ? null : (
                          <Button
                            className="exam-start-button"
                            onClick={() =>
                              this.onViewCertClick()
                            }
                          >
                            View Certificate
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={24} sm={0} md={0} lg={0} xl={0} xxl={0}>
                    <Row>
                      <Col span={24}>
                        <Link
                          style={{
                            fontFamily: "Poppins-Bold",
                            fontSize: "20px",
                            color: "#4252BE",
                            cursor: "pointer",
                          }}
                          to={"/user-dashboard/exam"}
                        >
                          {"< " +
                            getNativeLangTransText(
                              dbAuth.userInfo.nativeLanguage,
                              configuration.projectUI.back,
                              translation.translationObj
                            ).text}
                        </Link>
                        <br />
                        <Progress
                          percent={
                            (totalCompletedTest /
                              course.examGroupInfoObj.examGroupInfoList
                                .length) *
                            100
                          }
                          status="normal"
                          showInfo={false}
                          strokeColor="#82ABF7"
                          strokeWidth="23px"
                        />
                        <div
                          style={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "18px",
                          }}
                        >
                          {`${totalCompletedTest} out of ${course.examGroupInfoObj.examGroupInfoList.length} Tests Completed`}
                        </div>
                        <br />

                        <Row gutter={[32, 32]}>
                          {course.examGroupInfoObj.examGroupInfoList.map(
                            (item, idx) => {
                              if (userMarks[idx] > 49) {
                                return (
                                  <Col span={12} key={idx}>
                                    <div
                                      style={{
                                        borderColor: "green",
                                        borderRadius: "50%",
                                        borderStyle: "solid",
                                        width: "100%",
                                        paddingBottom: "100%",
                                        textAlign: "center",
                                        boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.onTestLabelClick(item.docId)
                                      }
                                    >
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "50%",
                                          left: "50%",
                                          transform: "translate(-50%, -50%)",
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontFamily: "Poppins-Bold",
                                            fontSize: "16px",
                                            color: "green",
                                          }}
                                        >
                                          Test {idx + 1}
                                        </span>
                                        <br />
                                        <span
                                          style={{
                                            fontFamily: "Poppins-Regular",
                                            fontSize: "16px",
                                            color: "green",
                                          }}
                                        >
                                          {`Pass - ${userMarks[idx]}%`}
                                        </span>
                                      </div>
                                    </div>
                                  </Col>
                                );
                              } else {
                                return (
                                  <Col span={12} key={idx}>
                                    <div
                                      style={{
                                        borderRadius: "50%",
                                        width: "100%",
                                        paddingBottom: "100%",
                                        textAlign: "center",
                                        boxShadow: "0 0 5px rgba(0,0,0,0.14)",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.onTestLabelClick(item.docId)
                                      }
                                    >
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "50%",
                                          left: "50%",
                                          transform: "translate(-50%, -50%)",
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontFamily: "Poppins-Bold",
                                            fontSize: "18px",
                                          }}
                                        >
                                          Test {idx + 1}
                                        </span>
                                        <br />
                                        <span
                                          style={{
                                            fontFamily: "Poppins-Regular",
                                            fontSize: "16px",
                                          }}
                                        >
                                          {/* {console.log(`test${idx + 1}`)}
                                    {console.log(Object.keys(dbAuth.progressInfo.exams[course.examGroupInfoObj.examInfoObj.docId])[idx])}
                                      {console.log(`test${idx + 1}` === Object.keys(dbAuth.progressInfo.exams[course.examGroupInfoObj.examInfoObj.docId])[idx])} */}
                                          {userMarks[idx] !== -1
                                            ? userMarks[idx] > 49
                                              ? `Pass - ${userMarks[idx]}%`
                                              : `${userMarks[idx]}% - Try Again`
                                            : "Not Started"}
                                        </span>
                                      </div>
                                    </div>
                                  </Col>
                                );
                              }
                            }
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>

                  <Col
                    offset={1}
                    xs={0}
                    sm={0}
                    md={11}
                    lg={11}
                    xl={11}
                    xxl={11}
                  >
                    <WeeklyGoal />
                  </Col>
                </Row>
              </Col>
              <Col xs={3} sm={5} md={0} lg={0} xl={0} xxl={0} />
              <Col xs={18} sm={14} md={0} lg={0} xl={0} xxl={0}>
                <WeeklyGoal />
              </Col>
            </Row>
            <Row>
              <Col
                xs={0}
                sm={0}
                md={24}
                lg={24}
                xl={24}
                className="footer-bottom"
              >
                <BottomBar />
              </Col>
              <Col
                xs={0}
                sm={24}
                md={0}
                lg={0}
                xl={0}
                style={{ marginBottom: "100px" }}
              >
                <BottomBar />
              </Col>
              <Col
                xs={24}
                sm={0}
                md={0}
                lg={0}
                xl={0}
                style={{ marginBottom: "70px" }}
              >
                <BottomBar />
              </Col>
            </Row>
            <Row>
              <Col
                xs={24}
                sm={24}
                md={0}
                lg={0}
                xl={0}
                style={{
                  textAlign: "center",
                  borderTop: "4px solid #F1F1F1",
                }}
                className="footer"
              >
                <UserDashboardNavBottomBar
                  onHandleResetLabelClick={onHandleResetLabelClick}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  course: state.course,
  dbAuth: state.auth,
  translation: state.translation,
  configuration: state.configuration,
});

const mapDispatchToProps = {
  loadTargetedExamGroups,
  resetTargetedExamGroups,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ExamLevelDetailsPage);
