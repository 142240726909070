import {
  Breadcrumb,
  Button,
  Col,
  Input,
  Row,
  Space,
  Table,
  notification,
} from "antd";
import React, { Component, Fragment } from "react";
import GenerateECodesModal from "./GenerateECodesModal";
import Moment from "moment";
import { loadAllECodes, generateEcodes } from "./ecodeActions";
import {
  LoadingOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import FileSaver from "file-saver";
import { withFirebase } from "react-redux-firebase";
import LoadingComponent from "../app/common/loading/LoadingComponent";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";
import _ from "lodash";

class GenerateECodesPage extends Component {
  state = {
    isGenerateECodesModalVisible: false,
    isLoading: true,
    newEcodesInfoObj: { project: {}, duration: "", totalECodes: "" },
    reset: false,
    projectList: [
      { projectId: "prd-esl", projectName: "ESL", projectCode: "ESL" },
      {
        projectId: "prd-rav-business",
        projectName: "MLMBizMastery",
        projectCode: "MLM",
      },
    ],
    authorized: true,
    disabled: false,
  };

  componentDidMount = () => {
    const { configuration } = this.props;
    this.props.loadAllECodes(configuration.idsInfoObj);
  };

  componentDidUpdate(prevProps) {
    const { ecodesRecords, configuration, auth } = this.props;
    if (
      !_.isEqual(
        prevProps.ecodesRecords.ecodeGenerationInfoObj,
        ecodesRecords.ecodeGenerationInfoObj
      ) ||
      (ecodesRecords.ecodeGenerationInfoObj !== null && this.state.isLoading)
    ) {
      let loadedECodeData = [];
      let totalCodes = 0;
      //console.log(ecodesRecords)
      if (ecodesRecords.ecodeGenerationInfoObj) {
        ecodesRecords.ecodeGenerationInfoObj.forEach((ecodesRecord, idx) => {
          //console.log(ecodesRecord);
          totalCodes += parseInt(ecodesRecord.totalECodes);
          loadedECodeData.push({
            key: idx,
            userInfo: ecodesRecord.userInfo,
            projectName: ecodesRecord.projectName,
            createdAt: Moment(
              new Date(ecodesRecord.createdAt.seconds * 1000)
            ).format("MMMM Do YYYY, h:mm a"),
            csvFilePath: ecodesRecord.csvFilePath,
            totalECodes: ecodesRecord.totalECodes,
            clientName: ecodesRecord.clientName,
          });
        });
        if (
          ecodesRecords.ecodeGenerationInfoObj.length === loadedECodeData.length
        )
          this.setState({
            authorized: auth.userInfo.admin ? auth.userInfo.admin : false,
            isLoading: false,
            loadedECodeData,
            totalCodes,
          });
      }
    }

    if (
      !ecodesRecords.loading &&
      ecodesRecords.generateEcodesSuccess &&
      this.state.reset
    ) {
      notification.open({
        key,
        message: "Success",
        description: "Successfully generated E-Codes",
        icon: (
          <CheckCircleTwoTone
            twoToneColor="
        #52c41a"
          />
        ),
      });
      this.props.loadAllECodes(configuration.idsInfoObj);
      this.setState({
        isGenerateECodesModalVisible: false,
        reset: false,
        isLoading: true,
        disabled: false,
        newEcodesInfoObj: { project: {}, duration: "", totalECodes: "" },
      });
    } else if (
      !ecodesRecords.loading &&
      ecodesRecords.generateEcodesFailure &&
      this.state.reset
    ) {
      notification.open({
        key,
        message: "Failed",
        description: ecodesRecords.errMsg,
        duration: 3,
        icon: (
          <CloseCircleTwoTone
            twoToneColor="
        #ff5255"
          />
        ),
      });
      this.setState({
        reset: false,
        disabled: false,
      });
    }
  }

  onHandleGenerateECodePencilIconClick = (record) => {
    const { ecodesRecords } = this.props;
    // console.log(ecodesRecords.ecodeGenerationInfoObj[record.key]);
    const eCodeBundleId =
      ecodesRecords.ecodeGenerationInfoObj[record.key].docId;
    this.props.history.push(`/user-dashboard/admin/${eCodeBundleId}`);
  };

  onHandleGenerateECodesButtonClick = () => {
    this.setState({
      isGenerateECodesModalVisible: true,
    });
  };

  onHandleGenerateECodesCancelButtonClick = () => {
    this.setState({
      isGenerateECodesModalVisible: false,
    });
  };

  onHandleGenerateECodesGenerateButtonClick = async () => {
    const { newEcodesInfoObj } = this.state;
    notification.open({
      key,
      message: "Generating E-Codes",
      duration: 0,
      icon: <LoadingOutlined />,
    });
    this.props.generateEcodes(newEcodesInfoObj);
    this.setState({
      reset: true,
      disabled: true,
      isGenerateECodesModalVisible: false,
    });
  };

  onHandleGenerateECodeDownloadIconClick = async (record) => {
    const { ecodesRecords } = this.props;
    console.log(ecodesRecords.ecodeGenerationInfoObj[record.key]);
    const ecodeList =
      ecodesRecords.ecodeGenerationInfoObj[record.key].ecodeList;
    let json = [];
    ecodeList.forEach((ecode) => {
      let dataSet = {
        code: ecode.code,
        duration: ecode.durationInDays,
        status: ecode.status,
      };
      json.push(dataSet);
    });
    let JsonFields = ["code", "duration", "status"];

    let csv = JsonFields.join(",") + "\n";

    json.forEach((element) => {
      let code = element.code;
      let duration = element.duration;
      let status = element.status;

      csv += code + "," + duration + "," + status + "\n";
    });
    // console.log(csv);

    const csvData = new Blob([csv], {
      type: "text/csv;charset=utf-8;",
    });

    FileSaver.saveAs(csvData, `${record.projectName}.csv`);
  };

  onDurationChange = (e, inputField) => {
    let inputVal = e.target.value;
    this.setState((prevState) => ({
      newEcodesInfoObj: {
        ...prevState.newEcodesInfoObj,
        [inputField]: inputVal,
      },
    }));
  };

  onEcodeNumberChange = (e, inputField) => {
    let inputVal = e.target.value;
    this.setState((prevState) => ({
      newEcodesInfoObj: {
        ...prevState.newEcodesInfoObj,
        [inputField]: inputVal,
      },
    }));
  };

  onClientNameChange = (e, inputField) => {
    let inputVal = e.target.value;
    this.setState((prevState) => ({
      newEcodesInfoObj: {
        ...prevState.newEcodesInfoObj,
        [inputField]: inputVal,
      },
    }));
  };

  onHandleProjectSelectionMenuClick = ({ key }) => {
    const { projectList } = this.state;
    projectList.forEach((project) => {
      if (project.projectId === key) {
        this.setState((prevState) => ({
          newEcodesInfoObj: {
            ...prevState.newEcodesInfoObj,
            project: project,
          },
        }));
      }
    });
  };

  render() {
    const {
      isGenerateECodesModalVisible,
      isLoading,
      loadedECodeData,
      newEcodesInfoObj,
      totalCodes,
      projectList,
      authorized,
    } = this.state;
    const { configuration } = this.props;
    if (isLoading) return <LoadingComponent />;
    const eCodeColumns = [
      {
        title: "Project",
        dataIndex: "projectName",
        key: "projectName",
      },
      {
        title: "Assigned Client",
        dataIndex: "clientName",
        key: "clientName",
      },
      {
        title: "Number of E-Codes",
        dataIndex: "totalECodes",
        key: "totalECodes",
        align: "center",
      },
      {
        title: "Generated Date",
        dataIndex: "createdAt",
        key: "createdAt",
      },
      {
        title: "",
        dataIndex: "downloadAction",
        key: "downloadAction",
        render: (_, record) => (
          <div style={{ textAlign: "end", marginRight: "2rem" }}>
            <Space size={32}>
              <img
                src="/assets/pencil-icon.svg"
                alt=""
                style={{ cursor: "pointer" }}
                onClick={() =>
                  this.onHandleGenerateECodePencilIconClick(record)
                }
              />
              <img
                src="/assets/download-icon.svg"
                alt=""
                style={{ cursor: "pointer" }}
                onClick={() =>
                  this.onHandleGenerateECodeDownloadIconClick(record)
                }
              />
            </Space>
          </div>
        ),
      },
    ];
    if (!authorized) {
      return (
        <h1>
          <div>User not authorized</div>
        </h1>
      );
    } else {
      return (
        <Fragment>
          <GenerateECodesModal
            isVisible={isGenerateECodesModalVisible}
            onCancel={this.onHandleGenerateECodesCancelButtonClick}
            onGenerate={this.onHandleGenerateECodesGenerateButtonClick}
            projectList={projectList}
            onDurationChange={this.onDurationChange}
            onEcodeNumberChange={this.onEcodeNumberChange}
            onHandleProjectSelectionMenuClick={
              this.onHandleProjectSelectionMenuClick
            }
            newEcodesInfoObj={newEcodesInfoObj}
            onClientNameChange={this.onClientNameChange}
          />
          <Row
            align="bottom"
            justify="space-between"
            style={{ padding: "20px" }}
          >
            <Col>
              <div
                style={{
                  fontFamily: "Poppins-Bold",
                  fontSize: "35px",
                  lineHeight: "normal",
                }}
              >
                Generate E-Codes
              </div>
            </Col>

            <Col style={{ marginRight: "1rem" }}>
              <Button
                type="primary"
                style={{
                  borderRadius: "2rem",
                  padding: "0.4rem 4rem",
                  fontFamily: "Poppins-Regular",
                  fontSize: "1rem",
                  height: "auto",
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                  color: "white",
                }}
                disabled={this.state.disabled}
                onClick={this.onHandleGenerateECodesButtonClick}
              >
                Generate E-Codes
              </Button>
            </Col>
            <Col span={24}>
              <Table
                dataSource={loadedECodeData}
                columns={eCodeColumns}
                pagination={{
                  showSizeChanger: false,
                  pageSize: 10, //TODO
                  position: ["bottomCenter"],
                  // onChange: TODO,
                  current: 1, //TODO
                }}
                footer={() => <span>Total E-codes: {totalCodes} </span>}
                style={{ marginTop: "2rem" }}
              />
            </Col>
          </Row>
          <br />
          {/* <Input
            placeholder="Search"
            prefix={
              <img
                src="/assets/search-icon.svg"
                alt=""
                style={{
                  width: "1rem",
                  height: "1rem",
                  marginRight: "0.5rem",
                }}
              />
            }
            size="large"
            style={{ borderRadius: "24px", width: "20rem" }}
          /> */}
        </Fragment>
      );
    }
  }
}

const key = "generateEcodesNotification";

const mapStateToProps = (state) => ({
  ecodesRecords: state.ecodesRecords,
  configuration: state.configuration,
  auth: state.auth,
});
const mapDispatchToProps = {
  loadAllECodes,
  generateEcodes,
};
export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(GenerateECodesPage);
